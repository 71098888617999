export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const SET_USER_SUCCESS_MESSAGE = "SET_USER_SUCCESS_MESSAGE";
export const SET_USER_ERROR = "SET_USER_ERROR";
export const CLEAR_USER_MESSAGE = "CLEAR_USER_MESSAGE";
export const SET_FAVORITES = "SET_FAVORITES";
export const REMOVE_FAVORITES = "REMOVE_FAVORITES";
export const SET_CONTINUE_WATCHING = "SET_CONTINUE_WATCHING";
export const SET_TUTORIAL_WATCHED = "SET_TUTORIAL_WATCHED";
export const UPDATE_SUBSCRIPTION_DATA = "UPDATE_SUBSCRIPTION_DATA"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE"
export const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD"

export const ADD_SECONDARY_USER = "ADD_SECONDARY_USER"
export const REMOVE_SECONDARY_USER = "REMOVE_SECONDARY_USER"


