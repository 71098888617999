import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { getFormattedStyle } from '../global/utils';
import Text from './text';
const defaultProps = {
    className: undefined,
};
const StyledSubTitle = styled.div `
    ${(props) => props.style && Object.keys(props.style).map((key) => {
    const value = props.style[key];
    if (value !== null && value !== undefined) {
        return `
                ${key}: ${value};
            `;
    }
    return '';
}).join('\n')}
`;
function SubTitle({ className, content, style, }) {
    const formattedStyle = !isEmpty(style)
        ? getFormattedStyle(style) : {};
    return (React.createElement(StyledSubTitle, { className: className, content: content, style: formattedStyle },
        React.createElement(Text, { styles: style }, content)));
}
SubTitle.defaultProps = defaultProps;
export default SubTitle;
