import {createStore} from "little-state-machine";


createStore({
    data: {
        buttonState: {
            startMembershipButton: true
        },
        selectedPlan: null,
        activeTest: null,
        subscription: {
            name: null,
            btPid: null,
            price: null
        },
        coupon: {
            couponRedeemed: null,
            buttonState: true,
            errorMessage: null,
            status: null,
            couponResponse: null,
            amountToCharge: null
        }

    }
}, {
    persist: "none"
})
