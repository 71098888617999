import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    validateCouponCode,
    restartSubscription,
    setAccountSettingsMessage,
    setCoupon,
    swapMembership
} from "../store/actions";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import {PaymentInfoLine} from "../Components/PaymentInfoLine"
import "./accountConfirmation.scss";


const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            swapMembership,
            validateCouponCode,
            setAccountSettingsMessage,
            setCoupon,
            restartSubscription
        },
        dispatch
    )
};
const mapStateToProps = appState => ({
    alert: {message: appState.AccountSettings.message, type: appState.AccountSettings.messageType},
    coupon: appState.AccountSettings.coupon,
    eventLoading: appState.AccountSettings.changeSubscriptionLoading,
    loading: appState.AccountSettings.loading,
    response: appState.AccountSettings.response,
    subscription: appState.User.data.subscription,
    user: appState.User.data,
});

const ConfirmationContainer = styled.div`
    border-color: ${props => props.borderColor};
`


const AccountConfirmationModal = ({
                                      alert,
                                      coupon,
                                      eventLoading,
                                      loading,
                                      response,
                                      validateCouponCode,
                                      restartSubscription,
                                      setAccountSettingsMessage,
                                      setCoupon,
                                      subscription,
                                      swapMembership,
                                      user,
                                      ...props
                                  }) => {
    // const [isChangeSuccessful, setIsChangeSuccessful] = React.useState(false)
    const [borderColor, setBorderColor] = React.useState('#F88A09')
    const [disableCouponButton, setDisableCouponButton] = React.useState(true)
    const [couponRedeemed, setCouponRedeemed] = React.useState(null)

    const couponInput = React.useRef();
    const navigate = useNavigate();

    function resetCouponSettings() {
        setAccountSettingsMessage({message: null, type: null})
        setCoupon(null)
    }

    const confirmChange = () => {
        // prevent user from sending multiple requests
        if (!eventLoading) {
            // if subscription is cancelled, the user should be sent to the re register route, else SWAP plans
            if (subscription.account_status === 'Canceled') {
                let data = {
                    payment_method_nonce: props.braintreePaymentNonce,
                    coupon: couponRedeemed,
                    subscription: props.swapPlan.name
                }
                restartSubscription(data)
            } else {
                swapMembership(couponRedeemed);
            }

            setBorderColor('#4AC86A');
        }
    }

    const closeModal = () => {
        // Set any error or success messages from a previous input to null
        resetCouponSettings()
        props.setIsSubscriptionModalShown(false);
    }


    const unlockCoupon = () => {
        if (couponInput.current.value === "") {
            setDisableCouponButton(true)
        } else {
            setDisableCouponButton(false)
        }
    }

    const checkCouponValidity = () => {
        /**
         * Build the coupon object to send to the server.
         * @type {{coupon: *, is_swap: boolean}}
         * is_swap determines whether the user is trying to swap plans
         */
        let code = {
            coupon: couponInput.current.value,
            is_swap: props.swapPlan.name !== subscription.plan_name,
            swap_to: props.swapPlan.name
        };

        setCouponRedeemed(code)

        // Set any error or success messages from a previous code to null
        resetCouponSettings()

        // send the new code object to the server
        validateCouponCode(code);
    }

    return (
        <div className="overlay">
            <div className="account-confirmation">
                <ConfirmationContainer className="account-confirmation_modal" borderColor={borderColor}>
                    <div className="modal-content_container">
                        <div className="close-modal_x" onClick={closeModal}></div>
                        <h1>{response ? response.title : 'Confirm Account Change'}</h1>
                        <div className="subtitle bold-text">
                            {response ? response.content : 'Please review your new account details:'}
                        </div>
                        {
                            props.swapPlan &&
                            <div className="account-details">
                                <PaymentInfoLine>Payment Type:
                                    <span>{props.swapPlan.duration}</span>
                                </PaymentInfoLine>
                                <PaymentInfoLine>Next Payment Date:
                                    <span>{subscription.next_billing_date}</span>
                                </PaymentInfoLine>
                                <PaymentInfoLine>Payment Amount:
                                    <span className="d-inline-block">
                                    ${(Math.round((
                                        props.swapPlan.price.amount -
                                        (!!coupon ? Number(coupon.value) : 0) + Number.EPSILON) * 100) / 100).toFixed(2).replace(/\.0+$/, '')}
                                </span>
                                    <span className="alert-danger ml-2 d-inline-block">
                                    <small>{coupon !== null && `(a savings of $${coupon.value})`}</small>
                                </span>
                                </PaymentInfoLine>
                                {
                                    alert.message &&
                                    <div dangerouslySetInnerHTML={{__html: alert.message}}
                                         className={`mt-3 alert alert-${alert.type}`}/>
                                }

                                {
                                    (response === null || response.status === 'error') &&
                                    <div className="d-flex flex-row align-items-center mt-3 gap-7">
                                        <input ref={couponInput} type="text" onChange={unlockCoupon}
                                               placeholder={'Enter Coupon'}/>
                                        <button
                                            className={`btn ${disableCouponButton ? 'disabled-button' : 'active-button'} apply-coupon_button`}
                                            onClick={!disableCouponButton ? checkCouponValidity : () => {
                                            }}>
                                            {
                                                !loading ?
                                                    <span className="apply-text">Apply</span>
                                                    :
                                                    <ScaleLoader height="10px" color="#fff"/>
                                            }
                                        </button>
                                    </div>
                                }
                            </div>
                        }
                        {
                            response && response.status === 'success' &&
                            <div className="subtitle">
                                Your account will continue to renew on each payment date until you cancel.
                            </div>
                        }
                        {
                            response && response.status === 'success' ? (
                                <div className="account-button_container">
                                    <div className="account-button full-button" onClick={() => navigate(0)}>
                                        <div className="account-button_text">Close Window</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="account-button_container">
                                    <div className="account-button back-button" onClick={closeModal}>
                                        <div className="account-button_text">Back</div>
                                    </div>
                                    <div className="account-button" onClick={confirmChange}>
                                        <div className="account-button_text">
                                            {
                                                eventLoading ?
                                                    <ScaleLoader height="10px" color="#fff"/>
                                                    :
                                                    <span>Confirm Change</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </ConfirmationContainer>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountConfirmationModal)
