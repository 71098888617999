import React, { useCallback, } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import '../../../components/Login/desktop-login.scss';
import RegisterWithPartnerCodeForm from '../../../components/Forms/registerWithPartnerCode';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import LoginBackgroundImage from '../../../assets/images/login-background.jpg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MainLogo from '../../../assets/images/black-m365-logo.svg';
const StyleRegisterPartnerPage = styled.div `
    width: 100%;
    height: 100vh;
    background-image: url('${LoginBackgroundImage}');
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const StyleRegisterPartnerContainer = styled.div `
    position: relative;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    max-width: 475px;
    border: 4px solid #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FAF7F4;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;
const StyleMainLogo = styled.div `
    position: relative;
    z-index: 1;
    background-image: url('${MainLogo}');
    background-size: contain;
    background-repeat: no-repeat;
    width: 104px;
    height: 104px;
    top: 38px;
    left: 46px;
    @media screen and (max-width: 1200px) {
        width: 78px;
        height: 78px;
    }
    @media screen and (max-width: 900px) {
        width: 60px;
        height: 60px;
    }
`;
const StyleRegisterPartnerContent = styled.div `
    padding: 42px 60px;
    min-width: 473px;
    display: flex;
    flex-direction: column;
    flex: 3;
    justify-content: space-around;
    min-height: 580px;
`;
const StyleRegisterTitle = styled.h1 `
    font-size: 36px;
    color: #54989C;
    margin-bottom: 28px;
    font-family: Sofia Pro Bold;
    letter-spacing: 1px;
`;
const StyledLink = styled.div `
    text-align: center;
    font-family: Sofia Pro;
    font-size: 20px;
    letter-spacing: 1px;
    > a {
        color: #54989C !important;
        font-family: Sofia Pro Bold;
    }
`;
// eslint-disable-next-line react/prop-types
function RegisterPartnerWithInviteCode() {
    const navigate = useNavigate();
    const onClickLoginLink = useCallback(() => {
        navigate('/login');
    }, [navigate]);
    return (React.createElement(StyleRegisterPartnerPage, null,
        React.createElement(StyleMainLogo, null),
        React.createElement(StyleRegisterPartnerContainer, null,
            React.createElement(StyleRegisterPartnerContent, null,
                React.createElement(StyleRegisterTitle, null, "Register Partner"),
                React.createElement(RegisterWithPartnerCodeForm, { formClass: "desktop-form_container", buttonClass: "desktop-button_container" }),
                React.createElement(StyledLink, null,
                    "Already Have Account?\u00A0",
                    React.createElement("a", { onClick: onClickLoginLink }, "Login"))))));
}
export default RegisterPartnerWithInviteCode;
