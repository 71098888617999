import * as TYPES from './actionTypes'

const initialState = {
    error: "",
    loading: true,
    data: null,
    taggedVideos: null,
    all:null,
}

const Video = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_VIDEO:
            return {
                ...state,
                loading: true
            }

        case TYPES.SET_VIDEO:
            return {
                ...state,
                data: action.payload,
                loading: false
            }
        case TYPES.GET_ALL_VIDEOS:
            return {
                ...state,
                loading: true
            }
        case TYPES.SET_ALL_VIDEOS:
            return {
                ...state,
                all: state.all === null ? action.payload.assets :  state.all.concat(action.payload.assets),
                loading: false
            }

        case TYPES.VIDEO_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }

        case TYPES.UPDATE_WATCHLIST_STATUS:
            return {
                ...state,
                data: { ...state.data, on_watchlist: action.payload }
            }
        case TYPES.SET_TAGGED_VIDEOS:
            return {
                ...state,
                taggedVideos: action.payload
            }

        default:
            return state;
    }
}

export default Video
