import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import {bindActionCreators} from "redux";
import {HandleRouteChange} from "../../utils/Segment/segment-methods";
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import {getAllVideos} from './store/actions';
import AssetListItem from "../../components/AssetListItem/ListItem";
import LazyLoad from 'react-lazyload';
import {forceCheck} from 'react-lazyload';

import "./all-videos.scss";

// -DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getAllVideos
        },
        dispatch
    )
};

// -MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    videos: appState.Video.all,
});


const AllVideos = ({user, getAllVideos, videos}) => {
    let navigate = useNavigate();
    let location = useLocation()

    const [isShownVideos, setIsShownVideos] = useState(undefined);
    const [isSkip, setIsSkip] = useState(0);
    const [isFlag, setIsFlag] = useState(false);
    const [isScrollBoxHeight, setIsScrollBoxHeight] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'All Videos')
        }
    }, [user, location])

    useEffect(() => {

        if (videos === null|| videos.length === 0) {
            window.scroll(0, 0);
            getAllVideos({skip: 0, limit: 20});
        }

        if (isFlag) {
            incrementSkip(isSkip);
            setIsFlag(false);
        }
        styleVideos();
    }, [videos]);

    useEffect(() => {
        //get 90% of the screen height
        let boxHeight = (window.innerHeight * .90);
        setIsScrollBoxHeight(boxHeight);
    }, [window.innerHeight, window.innerWidth]);

    const styleVideos = () => {
        if (isSkip === 0 && videos !== null) {
            setIsSkip(videos.length);
            setIsShownVideos(videos);
        }
    }

    const getMoreVideos = (e) => {
        forceCheck();
        let bottom;

        let scrollDifference = (e.target.scrollHeight - e.target.scrollTop) - e.target.clientHeight;

        if (scrollDifference < 10 && scrollDifference > -10) {
            bottom = true;
        } else {
            bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        }

        if (bottom && isLoading === false) {
            setIsLoading(true)
            setIsFlag(true);
            getAllVideos({skip: isSkip, limit: 20});
            setTimeout(()=>{setIsLoading(false)},500)
        }

    }

    const incrementSkip = (skip) => {
        if (videos === null || videos.length === 0) {
            return;
        }
        let currentVideos = [...videos];

        let nextSkip = currentVideos.length;
        setIsShownVideos(currentVideos);
        setIsSkip(nextSkip);
        forceCheck();
    }

    const goBack = () => {
        navigate(-1)
    }
    // console.log("Videos", videos);
    // console.log("Shown Videos", isShownVideos);

    return (

        isShownVideos === undefined || isShownVideos === null || isScrollBoxHeight === undefined ?
            <LoadingScreen/>
            :
            <div id="account-watchlist_page">
                <div className="back-link pointer">
                    <div className="back-button" onClick={goBack}/>
                </div>

                <h1 className="pt-5 font-bold text-center mb-5">All Videos</h1>
                <div className="container lazy-load_container" style={{overflowY: "scroll", height: isScrollBoxHeight}}
                     onScroll={getMoreVideos}>

                    {
                        isShownVideos.map((w, i) => (

                            <LazyLoad key={i} overflow offset={600} height={353} scroll>
                                <AssetListItem
                                    link={`/watch/${w.uuid}`}
                                    name={w.title}
                                    category={w.core_category_title[0]}
                                    src={w.poster}
                                    key={i}
                                    once={true} id={i} count={i + 1}
                                />
                            </LazyLoad>
                        ))
                    }
                </div>
            </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AllVideos)
