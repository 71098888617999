import {call, put, takeEvery} from "redux-saga/effects"

import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
import {addToWatchlist, getWatchlist, removeFromWatchlist} from "../../../helpers/backend-helper";
import {updateWatchlistStatus} from "../../Video/store/actions";
import {setUserSuccessMessage, setUserError} from "../../../store/user/actions";
import {captureException} from "@sentry/react";
import {updateWatchlistStatusCourse} from "../../Courses/store/actions";


function* loadWatchlist() {

    try {
        const response = yield call(getWatchlist);
        yield put(ACTIONS.setWatchlist(response.data))

    } catch (e) {
        captureException(e)
    }
}

function* addAssetToWatchlist({payload}) {

    try {
        yield call(addToWatchlist, payload);
        // update video redux state

        if (payload.assetType === 'video') {
            yield put(updateWatchlistStatus(true))
        }

        if (payload.assetType === 'course') {
            yield put(updateWatchlistStatusCourse(true))
        }
        // if(payload.assetType === 'challenge'){
        //     yield put(updateWatchlistStatusChallenge(true))
        // }

        yield put(setUserSuccessMessage(response.message))


    } catch (e) {
        captureException(e)
        yield put(setUserError(e.message))
    }


    yield put(ACTIONS.addToWatchlistComplete())
}

function* removeAssetFromWatchlist({payload}) {
    try {
        const response = yield call(removeFromWatchlist, payload);

        // update video redux state

        if (payload.assetType === 'video') {
            yield put(updateWatchlistStatus(false))
        }

        if (payload.assetType === 'course') {
            yield put(updateWatchlistStatusCourse(false))
        }


        yield put(setUserSuccessMessage(response.message))


    } catch (e) {
        captureException(e)
        yield put(setUserError(e.message))
    }
    yield put(ACTIONS.addToWatchlistComplete())
}

function* WatchlistSaga() {
    yield takeEvery(TYPES.ADD_TO_WATCHLIST, addAssetToWatchlist)
    yield takeEvery(TYPES.GET_WATCHLIST, loadWatchlist)
    yield takeEvery(TYPES.REMOVE_FROM_WATCHLIST, removeAssetFromWatchlist)
}

export default WatchlistSaga
