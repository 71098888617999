import React, {Fragment} from 'react';
import CategoryPill from '../Pills/categoryPill';
import ProgressBar from '../ProgressBar/progress-bar';

import './trendingCard.scss';
import styled from "styled-components";
import PropTypes from 'prop-types';

const CourseCard = (props) => {

    const CourseItem = styled.div`
        height: ${props.cardHeight}px;
        background-image: url(${props.src})
    `

    return (
        <Fragment>
            <CourseItem className="course-card mx-1">
                <CategoryPill category={props.category} position="top-left" />
                <div className="course-length">
                    {props.duration} Videos
                </div>
                <div className="flex-container">
                    <h5>
                        {props.title}
                    </h5>
                    <ProgressBar progressPercentage={props.progress/100} />
                    <div className="course-card_text">
                        <div className="progress-text">
                            Course Progress
                        </div>
                        <div className="percent-watched">
                            {`${Math.round(props.progress)}%`}
                        </div>
                    </div>
                </div>
            </CourseItem>
        </Fragment>
    )
}

CourseCard.propTypes = {
    cardHeight: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired, 
}

export default CourseCard;
