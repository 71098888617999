import {takeEvery, put, call, all, fork, join} from "redux-saga/effects"

import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import {
    getCoreCategories,
    getSingleCoreCategoryResources,
    getSingleCoreCategoryVideos,
    getSingleCoreCategoryTrending,
    getSingleCoreCategoryCourses
} from "../../../helpers/backend-helper";


function* fetchCoreCategories() {
    try {
        const response = yield call(getCoreCategories);
        yield put(ACTIONS.setCoreCategories(response))
    } catch (error) {
        console.log('to sentry: ', error)
    }
}


function* getCategoryTrending({payload}) {
    try {
        const response = yield call(getSingleCoreCategoryTrending, payload)
        yield put(ACTIONS.setSingleCategoryTrending(response.trending))
        yield put(ACTIONS.setSingleCategory(response.category))

    } catch (e) {
        yield put(ACTIONS.singleCategoryError(e))
        console.log('to sentry: ', e)
    }
}

function* getCategoryVideos({payload}) {
    try {
        const response = yield call(getSingleCoreCategoryVideos, payload)
        yield put(ACTIONS.setSingleCategoryVideos(response.videos))

    } catch (e) {
        yield put(ACTIONS.singleCategoryError(e))
        console.log('to sentry: ', e)
    }
}

function* getCategoryCourses({payload}) {
    try {
        const response = yield call(getSingleCoreCategoryCourses, payload)
        yield put(ACTIONS.setSingleCategoryCourses(response.courses))

    } catch (e) {
        yield put(ACTIONS.singleCategoryError(e))
        console.log('to sentry: ', e)
    }
}

function* getCategoryResources({payload}) {
    try {
        const response = yield call(getSingleCoreCategoryResources, payload)
        yield put(ACTIONS.setSingleCategoryResources(response.resources))

    } catch (e) {
        yield put(ACTIONS.singleCategoryError(e))
        console.log('to sentry: ', e)
    }
}

function* getSingleCategory({payload}) {

    let response
    try {
        response = yield all([
            fork(getCategoryVideos, {payload}),
            fork(getCategoryTrending, {payload}),
            fork(getCategoryResources, {payload}),
            fork(getCategoryCourses, {payload})
        ]);

        yield join([...response])

        yield put(ACTIONS.setLastCategoryUUID(payload.uuid))

    } catch (e) {
        yield put(ACTIONS.singleCategoryError(e))
        console.log('to sentry: ', e)
    }
}


function* CoreCategoriesSaga() {
    yield takeEvery(TYPES.GET_CORE_CATEGORIES, fetchCoreCategories)
    yield takeEvery(TYPES.GET_SINGLE_CATEGORY, getSingleCategory)
}


export default CoreCategoriesSaga
