import {call, put, takeEvery} from "redux-saga/effects"

import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
import {
    loadSystemSettings,
} from "../../../helpers/backend-helper"


function* LoadSystemSettings() {
    try {
        const response = yield call(loadSystemSettings)
        const current_settings = JSON.parse(localStorage.getItem('system_settings'));


        // CHECK APP VERSION IS THE SAME. IF NOT, UPDATE LOCAL STATE WITH NEW SETTINGS
        if ((current_settings && current_settings.WEB_VERSION !== response.WEB_VERSION) || !current_settings) {
            localStorage.setItem('system_settings', JSON.stringify(response));

            console.log('settings do not exist or app version mismatch')
        }

        yield put(ACTIONS.systemSettingsSuccess(response))


    } catch (error) {
        console.log(error)
        // TODO: connect sentry
    }
}


function* SystemSettingsSaga() {
    yield takeEvery(TYPES.LOAD_SYSTEM_SETTINGS, LoadSystemSettings)


}

export default SystemSettingsSaga
