/**
 * Declare all of the API endpoints // POSTMAN FOR REFERENCE
 * https://grey-crescent-932402.postman.co/workspace/2a34f4f1-eef0-422d-950b-219b685a6bb3
 *
 * @type {string}
 */

// -AUTH
export const POST_LOGIN_URL = '/login';
export const POST_LOGOUT_URL = '/logout';
export const POST_REGISTER_URL = '/api/register';
export const SANCTUM_COOKIE_URL = '/sanctum/csrf-cookie';

// -SYSTEM SETTINGS
export const LOAD_SYSTEM_SETTINGS_URL = '/system-settings';

// -USER
export const GET_USER_URL = '/auth-user';
export const POST_PASSWORD_FORGET_URL = '/forget-pwd';
export const POST_EDIT_PROFILE_URL = '/post-profile';
export const UPDATE_USER_PROFILE_URL = '/update-profile';
export const UPDATE_PROFILE_IMAGE_URL = '/update-profile-image';
export const UPDATE_USER_PASSWORD_URL = '/update-password';
export const ADD_SECONDARY_USER_URL = '/invite-user';
export const REMOVE_SECONDARY_USER_URL = '/remove-user';

// -SUBSCRIPTION
export const CANCEL_SUBSCRIPTION_URL = '/cancel-membership';
export const RESTART_SUBSCRIPTION_URL = '/returning-registration';
export const CHANGE_MEMBERSHIP_FREQUENCY = '/update-membership';

// -DASHBOARD
export const LOAD_DASHBOARD_URL = '/get-dashboard';

// -ONBOARDING
export const GET_INTRO_SLIDER_URL = '/intro-slider';
export const GET_INTRO_QUESTIONS_URL = '/intro-questions';
export const SAVE_INTRO_QUESTIONS_URL = '/event-pa';
export const GET_WELCOME_VIDEO_URL = '/welcome-video';
export const HIDE_WELCOME_VIDEO_URL = '/welcome-video/';
export const RESET_WELCOME_VIDEO_URL = '/welcome-video/';
export const RESET_ONBOARDING_URL = '/reset-onboarding/';

// -VIDEOS
export const SINGLE_VIDEO_URL = '/video/';
export const GET_ALL_VIDEOS = '/videos';

// -VIDEO EVENTS
export const SEND_VIDEO_EVENT_URL = '/send-event/video/';
export const GET_VIDEOS_BY_TAG_URL = '/videos-by-tag';

// -WATCHLIST
export const ADD_TO_WATCHLIST_URL = '/watchlist-asset/';
export const REMOVE_FROM_WATCHLIST_URL = '/watchlist-asset/';
export const GET_WATCHLIST_URL = '/watchlist/all';

// -HISTORY
export const GET_HISTORY_URL = '/watch-history/';
export const REMOVE_FROM_HISTORY_URL = '/single-watch-history/';

// -CONTINUE WATCHING
export const GET_CONTINUE_WATCHING_URL = '/watch-history';

// -WORKSHEET
export const GET_SINGLE_WORKSHEET_URL = '/worksheet/';
export const GET_ALL_WORKSHEETS = '/get-worksheets';
export const POST_SINGLE_WORKSHEET_URL = '/worksheet/';

// -NOTIFICATIONS
export const GET_NOTIFICATION_PREFERENCES_URL = '/notification-preferences';
export const UPDATE_NOTIFICATION_PREFERENCES_URL = '/notification-preferences';

// -MEMBERSHIP PERKS
export const GET_MEMBERSHIP_PERKS_URL = '/membership-perks';

// -EXTRAS
export const GET_EXTRAS_URL = '/get-extras/';

// -FAQ
export const GET_FAQ_URL = '/faq';

// -CORE CATEGORIES
export const GET_CORE_CATEGORIES_URL = '/all-core-categories';
export const GET_SINGLE_CATEGORY_URL = '/core-category/';

// -MY MARRIAGE
export const GET_INFO_MESSAGES_URL = '/info-message/';
export const GET_STATS_URL = '/stats/';
export const GET_PERFORMANCE_SCORES_URL = '/performance-scores/';
export const GET_MY_RECOMMENDED_ASSETS_URL = '/my-recommended/';
export const POST_CHECKUP_URL = '/v2/event/pa';

// -COURSES
export const GET_SINGLE_COURSE_URL = '/get-course/';
export const SAVE_COURSE_PROGRESS_URL = '/course-progress';
export const RESTART_COURSE_URL = '/course-restart';
export const GET_ALL_COURSES = '/get-courses';

// - COUPON
export const POST_COUPON_CODE_URL = '/member-coupon';
export const ACCEPT_MEMBER_COUPON_URL = '/accept-member-coupon';
// -Second User
export const GET_INFO_DRAWER_URL = '/info-message/remove-invited-user';

// -CHECK CANCELLATION OFFERS
export const GET_CANCELLATION_OFFERS_URL = '/offers/cancellation/';

// -POP QUIZ
export const GET_POP_QUIZ_URL = '/quiz-question';
export const POST_POP_QUIZ_ANSWER_URL = '/quiz-answer';

// -DELETE ACCOUNT FOR USER
export const POST_DELETE_ACCOUNT_URL = '/v2/delete-account';

// -PARTNER PAIRING CODE
export const PARTNER_PAIR_CODE_URL = '/v2/partner-code';

// -REEDEM PARTNER CODE
export const POST_REEDEEM_PARTNER_CODE_URL = 'v2/redeem-partner-code';

// -GET SUBSCRIPTION PLAN TYPE
export const GET_SUSBSCRIPTION_PLAN_TYPE = 'v2/offerings/';
