
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import CategoryPill from '../../Pills/categoryPill';
import GeneralButton from '../../buttons/generalButton';
import WhitePlayButton from '../../../assets/images/white-play-icon.svg';
import RestartIcon from '../../../assets/images/refresh-white.svg';
import ProgressBar from '../progress-bar';
import PropTypes from 'prop-types';
import './course-header.scss';

const CourseHeader = (props) => {


    let numerator;

    if (props.numerator === undefined) {
        numerator = 0;
    } else {
        numerator = props.numerator
    }

    return (
        <div className="course-header_container" style={{ backgroundImage: `url(${props.src})` }}>
            <div className="back-container">
                <Link to={'/browse'} className="back-link">
                    <div className="back-button"></div>
                </Link>

            </div>

            <div className="course-flex_container">
                {
                    props.category &&
                    <CategoryPill
                        category={props.category}
                        position={""}
                    />
                }
                <h1 className="course-title">
                    {props.title}
                </h1>
                {props.isCourseComplete ? (
                    <div >
                        <GeneralButton
                            buttonText={"Restart Course"}
                            backgroundColor={"#2D5F62"}
                            textColor={"#fff"}
                            icon={RestartIcon}
                            onClick={props.restartCourse}
                        />
                    </div>
                ) : (
                    <Link to={`/get-course-lesson/${props.course_uuid}/${props.lesson_uuid}`}>
                        <GeneralButton
                            buttonText={props.buttonText}
                            backgroundColor={"#2D5F62"}
                            textColor={"#fff"}
                            icon={WhitePlayButton}
                            onClick={() => { return }}
                        />
                    </Link>
                )}


                {props.buttonText === "Resume Course" && (
                    <Fragment>
                        <div id="mobile-course_progress" className='course-progress_bar'>
                            <ProgressBar numerator={numerator} denominator={props.totalLessons} />
                        </div>
                        <div className='progress-text'>
                            <div className="course-progress">
                                {props.isCourseComplete ? (
                                    <b>Course Complete</b>
                                ) : (
                                    'Course Progress'
                                )}
                            </div>
                            {props.isCourseComplete ? (
                                <div className="progress-complete"></div>
                            ) : (
                                <div className="progress-percent">
                                    {numerator === 0 ? 0 : Math.round((numerator / props.totalLessons) * 100)}%
                                </div>
                            )}

                        </div>
                    </Fragment>
                )}

            </div>
        </div>
    )

}

CourseHeader.propTypes = {
    backgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    category: PropTypes.string,
    course_uuid: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    isCourseComplete: PropTypes.bool.isRequired,
    lesson_uuid: PropTypes.string,
    numerator: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    position: PropTypes.string,
    restartCourse: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    totalLessons: PropTypes.number.isRequired,
}

export default CourseHeader;
