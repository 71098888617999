import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import GeneralButton from '../../buttons/generalButton';
import WhitePlayButton from '../../../assets/images/white-play-icon.svg';
import RestartIcon from '../../../assets/images/refresh-white.svg';
import ProgressBar from '../progress-bar';
import PropTypes from 'prop-types';
import './course-header-desktop.scss';
import WatchlistButton from "../../buttons/watchlistButton";
import {UseSystemSettings} from "../../../utils/SystemSettings/provider/system-settings";

const CourseHeaderDesktop = (props) => {

    const {settings} = UseSystemSettings();

    let numerator;

    if (props.numerator === undefined) {
        numerator = 0;
    } else {
        numerator = props.numerator
    }

    return (
        <div id="course-header_desktop__container">
            <div className="course-content_container">
                <div className='content'>
                    <div className="category">
                        {props.category}
                    </div>
                    <div className="course-title">
                        {props.title}
                    </div>
                    <div className="course-text" dangerouslySetInnerHTML={{__html: props.intro}}/>
                    <div className="button-container mb-4">
                        <WatchlistButton onWatchlist={props.onWatchlist}
                                         icons={{
                                             checkmark: settings.ICONS.shapes.checkmark.svg,
                                             plus: settings.ICONS.shapes.plus.svg
                                         }}
                                         loader={{
                                             color: '#fff',
                                             width: '8px'
                                         }}
                                         buttonType={`circle`}
                                         assetID={props.course_uuid}
                                         assetType={`course`}

                        />
                    </div>
                </div>

                <div className='content'>
                    {props.isCourseComplete ? (
                        <div>
                            <GeneralButton
                                buttonText={"Restart Course"}
                                backgroundColor={"#2D5F62"}
                                textColor={"#fff"}
                                icon={RestartIcon}
                                onClick={props.restartCourse}
                            />
                        </div>
                    ) : (
                        <Link to={`/get-course-lesson/${props.course_uuid}/${props.lesson_uuid}`}>
                            <GeneralButton
                                buttonText={props.buttonText}
                                backgroundColor={"#2D5F62"}
                                textColor={"#fff"}
                                icon={WhitePlayButton}
                                onClick={() => {
                                    return
                                }}
                            />
                        </Link>
                    )}
                </div>

                {props.buttonText === "Resume Course" && (
                    <Fragment>
                        <div id="desktop-course_progress" className='course-progress_bar'>
                            <ProgressBar numerator={numerator} denominator={props.totalLessons}/>
                        </div>
                        <div className='progress-text'>
                            <div className="course-progress">
                                {props.isCourseComplete ? (
                                    <b>Course Complete</b>
                                ) : (
                                    'Course Progress'
                                )}
                            </div>
                            {props.isCourseComplete ? (
                                <div className="progress-complete"></div>
                            ) : (
                                <div className="progress-percent">
                                    {numerator === 0 ? 0 : Math.round((numerator / props.totalLessons) * 100)}%
                                </div>
                            )}

                        </div>
                    </Fragment>
                )}

            </div>
            <div className="large-banner_image" style={{backgroundImage: `url(${props.src})`}}>

            </div>
        </div>
    )

}

CourseHeaderDesktop.propTypes = {
    backgroundColor: PropTypes.string,
    buttonText: PropTypes.string,
    category: PropTypes.string,
    course_uuid: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    intro: PropTypes.string.isRequired,
    isCourseComplete: PropTypes.bool.isRequired,
    lesson_uuid: PropTypes.string,
    numerator: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    position: PropTypes.string,
    restartCourse: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    textColor: PropTypes.string,
    title: PropTypes.string.isRequired,
    totalLessons: PropTypes.number.isRequired,
}

export default CourseHeaderDesktop;
