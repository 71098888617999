"use strict";
let __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (let s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (let p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
let __rest = (this && this.__rest) || function (s, e) {
    let t = {};
    for (let p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (let i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", {value: true});
exports.IconBase = exports.GenIcon = void 0;
let React = require("react");
let iconContext_1 = require("./iconContext");

function Tree2Element(tree) {
    return tree && tree.map(function (node, i) {
        return React.createElement(node.tag, __assign({key: i}, node.attr), Tree2Element(node.child));
    });
}

function GenIcon(data) {
    return function (props) {
        return (React.createElement(IconBase, __assign({attr: __assign({}, data.attr)}, props), Tree2Element(data.child)));
    };
}

exports.GenIcon = GenIcon;

function IconBase(props) {
    let elem = function (conf) {
        let attr = props.attr;
        let size = props.size;
        let title = props.title;
        let svgProps = __rest(props, ["attr", "size", "title"]);
        let computedSize = size || conf.size || "1em";
        let className;

        if (conf.className)
            className = conf.className;

        if (props.className)
            className = (className ? className + ' ' : '') + props.className;

        return (
            React.createElement("svg", __assign({
                        stroke: "currentColor",
                        fill: "currentColor",
                        strokeWidth: "0"
                    },
                    conf.attr,
                    attr,
                    svgProps,
                    {
                        className: className,
                        style: __assign(__assign({color: props.primary || conf.color}, conf.style), props.style),
                        height: computedSize,
                        width: computedSize,
                        xmlns: "http://www.w3.org/2000/svg"
                    }),
                title && React.createElement("title", null, title),
                props.children));
    };
    return iconContext_1.IconContext !== undefined
        ? React.createElement(iconContext_1.IconContext.Consumer, null, function (conf) {
            return elem(conf);
        })
        : elem(iconContext_1.DefaultContext);
}

exports.IconBase = IconBase;
