import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {HandleRouteChange} from "../../../utils/Segment/segment-methods";

import ProfileLayout from "../ProfileLayout";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {getHistory, removeVideoFromHistory} from "../store/actions";

import CustomLoader from "../../../components/CustomLoader";
import AssetCard from "../../../components/cards/watchlistCard2022";

import {ListContainer} from "./component-styles";

import PropTypes from 'prop-types';

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getHistory,
            removeVideoFromHistory
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    history: appState.AccountSettings.History,
});

// TODO: CAN WE COMBINE THIS WITH CONTINUE WATCHING SCREEN?

const WatchHistoryScreen = ({user, history, getHistory, removeVideoFromHistory}) => {
    const [isStyledHistory, setIsStyledHistory] = useState([]);
    let location = useLocation()
    const pageTitle = "My History"
    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', pageTitle)
        }
    }, [user, location])

    useEffect(() => {
        !history && getHistory();
        history && styleHistory()
    }, [location, history])


    const styleHistory = () => {
        let styledArray = [];


        if (history.length === 0) {
            let noHistory = <div className="no-history">No Watch History Exists</div>
            styledArray.push(noHistory);
        }

        history.assets.forEach((e, i) => {
            let link;
            switch (e.asset_type) {
                case "video":
                    link = '/watch';
                    break;
                case "course":
                    link = "/course";
                    break;
                case "challenge":
                    link = "/challenge";
                    break;
                default:
                    link = '/watch';
            }
            styledArray.push(
                <AssetCard
                    link={`${link}/${e.uuid}`}
                    uuid={e.uuid}
                    name={e.title}
                    subtitle={e.coreCategory[0]}
                    src={e.poster}
                    duration={e.duration}
                    key={Math.random(100)}
                    source={"History"}
                    styleCards={styleHistory}
                    watchlist={history}
                    position={i}
                    assetType={e.asset_type}
                    removeVideo={removeVideoFromHistory}
                />
            );
        });

        setIsStyledHistory(styledArray);
    }


    return (
        <ProfileLayout title={pageTitle} content="component-container">
            <ListContainer>
                <div className="back-link pointer">
                    <div className="back-button"/>
                </div>
                <h1 className="font-bold font-primary">{pageTitle}</h1>
                <div className="watchlist-container">
                    {
                        isStyledHistory.length === 0 ?
                            <div className="full-size_loader">
                                <div className="loader-container">
                                    <CustomLoader/>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                {
                                    isStyledHistory
                                }
                            </React.Fragment>
                    }
                </div>
            </ListContainer>
        </ProfileLayout>
    )
}

WatchHistoryScreen.propTypes = {
    user: PropTypes.object,
    history: PropTypes.array,
    getHistory: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchHistoryScreen)
