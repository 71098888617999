import React, {Fragment, useState} from 'react';
import GeneralButton from "../buttons/generalButton";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {loginUser as getLoginUser} from '../../store/actions';
import ScaleLoader from "react-spinners/ScaleLoader";

import {Alert} from "reactstrap";
import PropTypes from 'prop-types';

const LoginForm = (props) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {isValid}} = useForm({mode: 'onChange'});


    const onSubmit = (data) => {
        //- GET THE UTM DATA
        setIsFormSubmitted(true);
        const query = new URLSearchParams(window.location.search)
        const utm_source = query.get('utm_source') || '';
        const utm_medium = query.get('utm_medium') || '';
        const utm_campaign = query.get('utm_campaign') || '';
        const utm_content = query.get('utm_content') || '';
        const utm_term = query.get('utm_term') || '';

        dispatch(getLoginUser({
            email: data.email,
            password: data.password,
            utm_source,
            utm_medium,
            utm_campaign,
            utm_content,
            utm_term
        }, navigate));
    }

    const {loginError, loginLoading} = useSelector(state => ({
        loginError: state.Login.error,
        loginLoading: state.Login.loading
    }))

    return (
        <Fragment>
            <div className={props.formClass}>
                {
                    loginError &&
                    <Alert className="login-error" color="danger">
                        {loginError}
                    </Alert>
                }
                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                    <input className="email-input" type="text"
                           placeholder="Email Address"
                           {...register("email", {required: true})} />
                    <input className="password-input" type="password"
                           placeholder="Password"
                           {...register("password", {required: true})} />
                </form>
            </div>

            <div className={props.buttonClass}>
                <GeneralButton
                    disabled={loginLoading}
                    buttonText={(isValid && isFormSubmitted && !loginError) ? (<ScaleLoader height="10px" color="#fff"/>) : ("Log in")}
                    textColor={isValid ? "#fff" : "#47505F"}
                    backgroundColor={isValid && !loginLoading ? "#2D5F62" : "#EDE4DD"}
                    onClick={handleSubmit(onSubmit)}
                />
                <Link to={'/password/reset'} className="login-forgot_password">Forgot Password?</Link>
            </div>
        </Fragment>
    )

}

LoginForm.propTypes = {
    buttonClass: PropTypes.string,
    formClass: PropTypes.string,
}

export default LoginForm;
