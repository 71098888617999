import React, {Fragment} from 'react'
import Components from "../../Helpers/componentHelper"
import PropTypes from "prop-types";
import styled from "styled-components";
import {useForm, FormProvider} from 'react-hook-form';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

const mapStateToProps = appState => ({});

const FormElement = styled.form`
    ${props => props.properties}
`
/**
 *
 * @param block
 * @param data
 * @param activeTest
 * @param reference
 * @returns {JSX.Element}
 * @constructor
 */
const Form = ({block, data, activeTest}) => {
    // console.log('FORM data: ', data)
    // console.log('FORM block: ', block)
    const methods = useForm();
    // todo: form errors originated from child Components should be worked into this.
    const [formErrors, setFormErrors] = React.useState(null)


    // React.useEffect(() => {
    //     const subscription = methods.watch((value, {name, type}) => console.log(value, name, type));
    //     return () => subscription.unsubscribe();
    // }, [methods.watch]);


    return (
        <FormProvider {...methods}>
            <FormElement id={block.attributes.id ?? null}
                         properties={data.properties}
                         className={block.attributes.class ?? null}>
                {
                    formErrors &&
                    <div className="alert alert-danger">
                        {formErrors}
                    </div>
                }
                {
                    (block.children || []).map(child => {
                            child.activeTest = activeTest
                            child.register = methods.register
                            child.functions = block.functions ?? null
                            return <Fragment key={Math.random()}>{Components(child)}</Fragment>;
                        }
                    )
                }
            </FormElement>
        </FormProvider>
    )
}

Form.propTypes = {
    block: PropTypes.shape({
        children: PropTypes.arrayOf(
            PropTypes.shape({
                component: PropTypes.string.isRequired,
                data: PropTypes.any,
            })
        )
    }).isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
