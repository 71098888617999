import React, { useState, useEffect } from 'react';
import PerkCoupon from '../../../components/cards/perk-coupon';
import MarriageInfoDrawer from '../../../components/Modals/marriage-info-drawer';
import ToastCard from '../../../components/cards/toastCard';
import CustomLoader from '../../../components/CustomLoader';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getMembershipPerks } from "../store/actions";

import PropTypes from 'prop-types';

import './membership-perks-12-2022.scss';
import ProfileLayout from "../ProfileLayout";
import {HandleRouteChange} from "../../../utils/Segment/segment-methods";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getMembershipPerks,

        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    membershipPerks: appState.AccountSettings.MembershipPerks,
    user: appState.User.data,
});

const MembershipPerksScreen = ({ user, membershipPerks, getMembershipPerks }) => {
    const pageTitle = "Membership Perks"
    const [isStyledPerks, setIsStyledPerks] = useState(undefined);
    const [isShowDrawer, setIsShowDrawer] = useState(false);
    const [isShowToast, setIsShowToast] = useState(false);

    useEffect(() => {
        if (membershipPerks === null) {
            getMembershipPerks();
        }
        stylePerks(membershipPerks);

    }, [membershipPerks]);


    /**
     * Send page view to Mixpanel
     */
    React.useEffect(() => {
        if (JSON.stringify(user) !== '{}') {
            HandleRouteChange({pathname: '/account/membership-perks'}, user, 'page-view', pageTitle)
        }
    }, [user])


    const copyCode = (code) => {
        setIsCouponCode(code);
        navigator.clipboard.writeText(code);
        setIsShowToast(true);
        setTimeout(() => {
            setIsShowToast(false);
        }, 3000)
    }

    const stylePerks = (array) => {
        if (array === null) {
            return;
        }
        // console.log('check here for saga', array);
        let coupon;
        let styledCoupons = [];

        array.forEach((e) => {
            coupon = (
                <PerkCoupon
                    perk={e}
                    key={Math.random(100)}
                    copyCode={copyCode}
                    user={user}
                />);
            styledCoupons.push(coupon);
        });
        setIsStyledPerks(styledCoupons);
    }

    const openDrawer = () => {
        setIsShowDrawer(true);
    }

    const closeDrawer = () => {
        setIsShowDrawer(false);
    }

    const backToSidebar = () => {
        let width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        let sidebar = document.getElementById("account-sidebar");
        let displayArea = document.getElementById("account-display_container");

        if (width <= 480) {
            sidebar.style.display = "none";
            displayArea.style.display = "block";
        } else {
            sidebar.style.display = "block";
            displayArea.style.display = "block";
        }
    }

    return (
        <ProfileLayout title={pageTitle} content="component-container">
            <div id="membership-perks_2022">
                <div className="back-link pointer" onClick={backToSidebar}>
                    <div className="back-button" />
                </div>
                {isShowToast &&
                    <div className="toast-perk_container">
                        <ToastCard
                            toastText={`"${isCouponCode}" copied to clipboard`}
                        />
                    </div>
                }
                <h1 className="font-bold font-primary">{pageTitle}</h1>
                <div className="perk-display">
                    {isStyledPerks ? (
                        <div className="membership-perk_container">
                            {isStyledPerks}
                        </div>
                    ) : (
                        <div className="full-size_loader">
                            <div className="loader-container">
                                <CustomLoader />
                            </div>
                        </div>
                    )}
                </div>

                {isShowDrawer &&
                    <div className="drawer-container">
                        <MarriageInfoDrawer
                            header={"My Membership"}
                            body={"<p>This page is your one-stop shop for your special Membership discounts and offers! The offers are valid until your subscription is cancelled.</p>"}
                            closeModal={closeDrawer}
                        />
                    </div>
                }
            </div>
        </ProfileLayout>
    )
}

MembershipPerksScreen.propTypes = {
    getMembershipPerks: PropTypes.func,
    membershipPerks: PropTypes.array,
    user: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipPerksScreen);
