import React from "react";
import {getCardHeight__worksheetPage} from "../../utils/responsive-card-height";
import {Link} from "react-router-dom";
import NewCarousel from "../../components/Carousels/new-carousel";
import WorksheetCard from "../../components/cards/worksheetCard";


function StyleRelatedWorksheets(related_worksheets = [], settings, screenWidth) {
    let carousel;
    let worksheetArray = [];

    const cardHeight = getCardHeight__worksheetPage('RELATED', settings, screenWidth)

    related_worksheets.forEach((e) => {
        let worksheetCard;
        worksheetCard = (
            <Link to={`/read/${e.assets.uuid}`} key={Math.random(100)}>
                <WorksheetCard
                    assetType={"worksheet"}
                    cardHeight={cardHeight}
                    src={e.assets.portrait_thumbnail}
                    title={e.assets.title}
                />
            </Link>
        );
        worksheetArray.push(worksheetCard);
    });

    carousel =
        <NewCarousel
            cards={worksheetArray}
            config={settings.WORKSHEET_LAYOUT.RELATED.slider ?? {}}
            partialVisible={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            showDots={false}
            source={"single worksheet page - related worksheets"}
        />;


    return carousel
}


export default StyleRelatedWorksheets;
