import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactJWPlayer from 'react-jw-player';
import axios from 'axios';
import PropTypes from 'prop-types';
import ChallengeDetails from '../components/ChallengeDetails';
import ChallengeComplete from '../components/ChallengeComplete/index.jsx';

import LoadingScreen from '../components/LoadingScreen';

import ToastCard from '../components/cards/toastCard';


import './challenge-video.scss';
import { connect } from "react-redux";
import { HandleRouteChange } from "../utils/Segment/segment-methods";
import { bindActionCreators } from "redux";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data
});

const ChallengeVideoPage = ({ user }) => {
    let lockedChallenge = useRef();
    let location = useLocation()

    const [isChallengeComplete, setIsChallengeComplete] = useState(false);
    const [isCurrentChallenge, setIsCurrentChallenge] = useState();
    const [isProgress, setIsProgress] = useState();
    const [isActiveVideo, setIsActiveVideo] = useState();
    const [isNextVideo, setIsNextVideo] = useState();
    const [isBonus, setIsBonus] = useState(false);
    const [isToastShown, setIsToastShown] = useState(false);

    let { challenge_uuid, lesson_uuid } = useParams();
    let challengeComplete = false;
    let nextVideoStatus = "disabled";


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location && lesson_uuid && isCurrentChallenge) {
            HandleRouteChange(location, user, 'page-view', 'Challenge Video', isCurrentChallenge.title)
        }
    }, [user, location, lesson_uuid, isCurrentChallenge])


    useEffect(() => {
        getChallengeData();
    }, [lesson_uuid]);



    const getChallengeData = async () => {

        setIsActiveVideo(undefined);
        setIsNextVideo(undefined)

        await axios.get(`/get-single-challenge/${challenge_uuid}`).then(resp => {
            // console.log("challenge data", resp.data);
            setIsCurrentChallenge(resp.data);
            setIsProgress(resp.data.progress);
            getLesson(resp.data.lessons, resp.data.progress);
        })
    }

    const getLesson = (lessons, progress) => {
        // check the days and bonus for uuid
        let days = lessons.days;
        let chosenLesson, nextLesson;
        let currentPosition;
        let bonus = false;
        // need a check to see if this is a bonus item, if so use the bonus array
        // find the current lesson
        chosenLesson = days.filter((e) => {
            return e.uuid === lesson_uuid
        });
        // if the current lesson is a bonus
        if (lessons.bonus !== undefined && lessons.bonus.length > 0 && chosenLesson.length === 0) {
            bonus = true;
            setIsBonus(true);
            chosenLesson = lessons.bonus.filter((e) => {
                return e.uuid === lesson_uuid
            });
        }
        // console.log('lessons', lessons);
        currentPosition = chosenLesson[0].position;
        // make an exception for last element
        // if bonus exists next becomes 1st bonus
        // if not no next card appears
        let currentStatus, nextStatus;
        // need to make an exception for being the last video
        // with and without bonus

        if (progress === null) {
            chosenLesson[0].status = "active"
        } else if (progress.lessons_completed.length > 0) {
            // check lessons complete for uuids
            currentStatus = progress.lessons_completed.filter(e => e.lesson_uuid === chosenLesson[0].uuid);
            if (currentStatus.length > 0) {
                chosenLesson[0].status = "complete";
            }
            if (currentStatus.length === 0) {
                chosenLesson[0].status === "active";
            }
        }
        // if last day
        if (chosenLesson[0].position === lessons.days.length) {
            // and bonus exists
            if (lessons.bonus !== undefined) {
                nextLesson = lessons.bonus[0];
            } else {
                nextLesson = null;
            }
        }
        // if bonus, and last bonus day
        if (bonus && chosenLesson[0].position === lessons.bonus.length) {
            // no next
            nextLesson = null;
        }
        //if not the last and bonus exists
        if (nextLesson !== null) {
            // if next lesson is not chosen
            if (!nextLesson) {
                // if bonus pick next from bonus
                if (bonus) {
                    nextLesson = lessons.bonus[currentPosition];
                } else {
                    nextLesson = lessons.days[currentPosition];
                }
            }
            // if first video, with no progress
            // console.log('progress', progress);
            // debugger;
            if (progress === null) {
                nextLesson.status = "disabled";

            } else if (progress.lessons_completed.length > 0) {
                // check lessons complete for uuids
                nextStatus = progress.lessons_completed.filter(e => e.lesson_uuid === nextLesson.uuid);
                if (nextStatus.length > 0) {
                    nextLesson.status = "complete";
                } else {
                    nextLesson.status = "active";
                }

                if (currentStatus.length === 0) {
                    nextLesson.status = "disabled";
                }

            }
        }

        // if the current video is a bonus video
        setIsNextVideo(nextLesson);
        setIsActiveVideo(chosenLesson[0]);
    }

    // use js to hide and show close / back button

    const onPlay = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.add('hide');
    };
    const onResume = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.add('hide');
    }
    const onPause = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.remove('hide');
    }
    const onReady = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.remove('hide');
    }

    const onEnded = () => {

        let closeButton = document.getElementById('close-button');
        closeButton.classList.remove('hide');
        setIsToastShown(true);
        if (!challengeComplete) {
            unlockNext();
            setTimeout(() => {
                setIsToastShown(false);
                unlockNext()
            }, 3000);
        }

        // check progress here and see
        if (challengeComplete) {
            setIsChallengeComplete(true);
        }
    }



    const sendProgress = async () => {

        let progressObj = {
            lesson_uuid: lesson_uuid,
            challenge_uuid: challenge_uuid,
        }

        // console.log('progressObj', progressObj);
        if (isProgress !== null && isProgress.is_complete) {
            challengeComplete = true;
        } else {
            if (isProgress !== null) {
                if (isProgress.lessons_completed !== undefined && isProgress.lessons_completed.length > 0) {
                    for (let i = 0; i < isProgress.lessons_completed.length; i++) {
                        if (isProgress.lessons_completed[i].lesson_uuid === lesson_uuid) {
                            return;
                        }
                    }

                }
            }

            await axios.post('/challenge-progress', progressObj).then((resp) => {
                // console.log("response challenge progress", resp)
                // unlock the next challenge with js, get dot and container classes
                // dont if it doesnt exist

                if (resp.data.progress.is_complete) {
                    challengeComplete = true;
                } else {
                    unlockNext();
                }
            });
        }
    }

    const unlockNext = () => {
        if(challengeComplete){
            return;
        }
        let lockedElementArray = Array.from(lockedChallenge.current.children);
        let lockedElement = lockedElementArray[0];
        lockedElement.href = `/get-challenge-lesson/${challenge_uuid}/${isNextVideo.uuid}`;
        // status of the isNextVideo needs to be changed to unlock proper link,
        // cannot set state without resetting video

        lockedElement = Array.from(lockedElement.children[0].children);

        let statusDot = lockedElement[0];
        let challengeStep = lockedElement[1];
        statusDot.classList.remove('disabled');
        statusDot.classList.add('active');
        challengeStep.classList.remove('disabled');
        challengeStep.classList.add('active');
    }

    const changeVideoStatusActive = () => {
        nextVideoStatus = "active";
    }

    const closeConfetti = () => {
        setIsChallengeComplete(false)
    }

    const getVTT = (video) => {
        if (video.vtt) {
            return [{
                file: `${video.vtt}`,
                label: 'English',
                kind: 'captions',
                'default': false
            }]
        } else {
            return [];
        }

    }


    return (
        <div id="challenge-video_page">
            {isToastShown && (<div className="toast-challenge_container">
                <ToastCard
                    toastText={`Your progress has been saved!`}
                    error={''}
                />
            </div>)}
            {isChallengeComplete && isCurrentChallenge ? (
                <ChallengeComplete
                    challenge={true}
                    challengeImage={isCurrentChallenge.thumbnail}
                    days={isCurrentChallenge.lessons.days.length}
                    title={isCurrentChallenge.title}
                    closeConfetti={closeConfetti}
                />
            ) : (
                <div>
                    {isActiveVideo ? (
                        <div>
                            <div className="video-container">

                                <div id="close-button" className="hide">
                                    <Link to={`/challenge/${challenge_uuid}`}>
                                        <div className="close-video"></div>
                                    </Link>
                                </div>
                                <ReactJWPlayer
                                    playerId={`player-${isActiveVideo.uuid}`}
                                    playerScript="https://cdn.jwplayer.com/libraries/PKPzctjx.js"
                                    playlist={[{
                                        image: isActiveVideo.thumbnail,
                                        sources: [{
                                            file: isActiveVideo.video_url
                                        }, {
                                            file: isActiveVideo.video_url
                                        }],
                                        tracks: getVTT(isActiveVideo),
                                    }]}
                                    onPlay={onPlay}
                                    onPause={onPause}
                                    onReady={onReady}
                                    onResume={onResume}
                                    onOneHundredPercent={onEnded}
                                    onSeventyFivePercent={sendProgress}
                                />
                            </div>

                            <div id="challenge-detail">
                                <ChallengeDetails
                                    currentDay={isActiveVideo.position}
                                    challengeDescription={isActiveVideo.description}
                                    uuid={isNextVideo ? isNextVideo.uuid : ''}
                                    src={isNextVideo ? isNextVideo.thumbnail : ''}
                                    stepNumber={isActiveVideo.position}
                                    status={isNextVideo ? isNextVideo.status : ''}
                                    changeVideoStatusActive={changeVideoStatusActive}
                                    currentName={isActiveVideo.name}
                                    nextName={isNextVideo ? isNextVideo.name : ''}
                                    type={isNextVideo ? isNextVideo.type : ''}
                                    duration={isNextVideo ? isNextVideo.duration : ''}
                                    description={isNextVideo ? isNextVideo.description : ''}
                                    day={isNextVideo ? isNextVideo.position : ''}
                                    key={Math.random(100)}
                                    onEnded={onEnded}
                                    challenge_uuid={challenge_uuid}
                                    lesson_uuid={isNextVideo ? isNextVideo.uuid : ''}
                                    lockedChallenge={lockedChallenge}
                                    isBonus={isBonus}
                                />
                            </div>
                        </div>
                    ) : (
                        <LoadingScreen />
                    )}
                </div>
            )}


        </div>
    )
}

ChallengeVideoPage.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeVideoPage)
