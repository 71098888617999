import { upperFirst } from 'lodash';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import './subscriptionCard.scss';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    USER_SUBSCRIPTION_INFO_PROPTYPES,
    SUBSCRIPTION_TYPE_PLAN_ITEM_PROPTYPES,
} from '../../global/propTypesConstants';

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {},
    dispatch,
);

// -MAP PROPS
const mapStateToProps = (appState) => ({
    userSubscriptionInfo: appState.User.data.subscription,
});

function SubscriptionCard({
    userSubscriptionInfo,
    plan,
    setIsCouponModalShown,
    setSelectedPlan,
    setSwapPlan,
    setIsSubscriptionModalShown,
}) {
    const [isCardActive, setIsCardActive] = useState(false);

    const subscriptionCardContainerClasses = classNames('subscription-card', {
        'active-card': isCardActive,
    });

    useEffect(() => {
        if (plan.name === userSubscriptionInfo.plan_name && userSubscriptionInfo.account_status !== 'Canceled' && !isCardActive) {
            setIsCardActive(true);
            setSelectedPlan(plan);
        }
    }, [
        isCardActive,
        plan,
        setIsCardActive,
        setSelectedPlan,
        userSubscriptionInfo,
    ]);

    const subscriptionCardClicked = () => {
        if (isCardActive) {
            setIsCouponModalShown(true);
        } else {
            setSwapPlan(plan);
            setIsSubscriptionModalShown(true);
        }
    };

    let nextSubscriptionPrice = plan?.price?.amount ?? 0;

    if (userSubscriptionInfo.plan_name === plan.name) {
        nextSubscriptionPrice = userSubscriptionInfo?.next_billing_amount;
    }

    let switchButtonText = '';

    if (isCardActive) {
        switchButtonText = 'Apply Coupon';
    } else if (userSubscriptionInfo.account_status === 'Canceled') {
        switchButtonText = `Resubscribe ${plan.duration}`;
    } else {
        switchButtonText = `Switch to ${plan.duration}`;
    }

    return (
        // eslint-disable-next-line max-len
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
            className={subscriptionCardContainerClasses}
            onClick={() => subscriptionCardClicked()}
        >
            {
                isCardActive
                && <div className="check-mark" />

            }
            {
                (plan.bestValue)
                && (
                    <div className="best-value_container">
                        <div className="best-value_tag">
                            <div className="best-value_text">Best Value</div>
                        </div>
                    </div>
                )

            }
            <div className="subscription-content">
                <div className="d-flex justify-content-between flex-row align-items-start">
                    <div className="subscription-type">
                        <div className="subscription-duration">{upperFirst(plan?.duration)}</div>
                        {userSubscriptionInfo.next_billing_date && userSubscriptionInfo.account_status !== 'Canceled' && (
                            <div className="subscription-expiration">
                                Renews on
                                {' '}
                                {userSubscriptionInfo.next_billing_date}
                            </div>
                        )}
                    </div>
                    <div className="subscription-price">
                        <div
                            className="price"
                        >
                            $
                            {nextSubscriptionPrice}
                            <span>
                                /
                                {plan.price.renewalDuration}
                            </span>
                            <div className="red-line" />
                        </div>
                    </div>
                </div>
                <div className="subscription-button-sub-text-info-container">
                    <div className="switch-button_container">
                        <div className="switch-button_text">
                            {switchButtonText}
                        </div>
                    </div>
                    {plan.subText && (
                        <div
                            className="subscription-sub-text"
                        >
                            {plan.subText}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

SubscriptionCard.propTypes = {
    plan: SUBSCRIPTION_TYPE_PLAN_ITEM_PROPTYPES.isRequired,
    setIsCouponModalShown: PropTypes.func.isRequired,
    setSelectedPlan: PropTypes.func.isRequired,
    setSwapPlan: PropTypes.func.isRequired,
    setIsSubscriptionModalShown: PropTypes.func.isRequired,
    userSubscriptionInfo: USER_SUBSCRIPTION_INFO_PROPTYPES.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionCard);
