//-DEPENDENCIES
import React, { useState, useEffect, Fragment } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
//-HELPERS
// import {UseSystemSettings} from "../../utils/SystemSettings/provider/system-settings";

//-ASSETS
import WhiteWorksheet from '../../assets/images/white-worksheet.svg';

//-COMPONENTS
import VideoHeaderDesktop from '../../components/common/header-component/video-header-desktop';
import WatchlistButton from '../../components/buttons/watchlistButton';
import GeneralButton from '../../components/buttons/generalButton';
import AudioOnly from '../../components/AudioOnly';
import AudioSwitch from '../../components/buttons/audioSwitch';
import LoadingScreen from "../../components/LoadingScreen";
import VideoPlayer from "../../components/VideoPlayer";
import StyleRelatedVideos from "./related-videos";

//-ACTIONS
import { getVideo as getSingleVideo, sendVideoProgress as saveProgress } from './store/actions'


//-EVENTS
import * as VIDEO_PLAYER_EVENTS from "./video-events";
import DesktopNav from "../../components/common/nav/desktop-nav";
import MobileFooter from "../../components/common/footer-component/mobile-footer";
import { HandleRouteChange, Track } from "../../utils/Segment/segment-methods";
import VideoSaga from './store/sagas';


//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getSingleVideo,
            saveProgress,
        },
        dispatch
    )
};

//-MAP PROPS1
const mapStateToProps = appState => ({
    screenWidth: appState.UserInterface.screenWidth,
    settings: appState.SystemSettings.settings,
    user: appState.User.data,
    video: appState.Video.data,
    videoLoading: appState.Video.loading
});

//-START FUNCTIONAL COMPONENT
const SingleVideo = ({
    getSingleVideo,
    saveProgress,
    screenWidth,
    settings,
    user,
    video,
    videoLoading,
}) => {


    let { asset_id } = useParams();
    // const {settings} = UseSystemSettings();
    let location = useLocation()
    let navigate = useNavigate()

    //-LOCAL STATE
    const [isAudioSelected, setIsAudioSelected] = useState(false);
    const [isAudioSrc, setIsAudioSrc] = useState();
    const [isDesktopCarousel, setIsDesktopCarousel] = useState();
    const [isStyledMobileCards, setIsStyledMobileCards] = useState();
    const [teaserPhoto, setTeaserPhoto] = useState(false);
    const [isNoRelated, setIsNoRelated] = useState(false);
    //-USE EFFECT
    //Get the video on load and reload is asset_id URL param is updated
    useEffect(() => {
        window.scroll(0, 0);
        //-check to see if the asset_id matches the one in Redux. If so, load from redux.
        if (!video || asset_id !== video.asset_id) {
            getSingleVideo(asset_id)
        }


    }, [asset_id]);

    useEffect(() => {
        if (video && settings) {

            if (video.related_videos !== undefined) {
                const related = StyleRelatedVideos(video.related_videos, settings, screenWidth, navigate);
                setIsNoRelated(false);
                setIsDesktopCarousel(related.desktop);
                setIsStyledMobileCards(related.mobile);

            } else {
                setIsNoRelated(true);
            }
            // set the component with the related video data

            // TODO: Notate videos with is_teaser in database and prevent sending these videos to the mobile app
            if (!video.videoURL) {
                setTeaserPhoto(true)
            }

            setIsAudioSrc(video.audio);
        }

    }, [video, settings, screenWidth])


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && video && location && asset_id === video.asset_id) {
            HandleRouteChange(location, user, 'page-view', 'Single Video', video.title, video.core_categories)
        }
    }, [user, video, location])


    /**
     * Toggle the page with the audio only feature
     */
    const toggleAudioOnly = () => {
        setIsAudioSelected(!isAudioSelected);
    }

    /**
     * Connecting questions as a method so that we can send the action to segment
     * @param url
     */
    function openConnectingQuestions(url) {
        Track('connecting_questions_viewed', { title: video.title, category: video.core_categories })

        window.open(url);
    }

    /**
     * TODO: This needs to be revised. Clicking this button multiple times before the page reroutes results in the browser
     * TODO CONT: stepping back multiple pages. If a user clicks a video, then a related video, and then the back arrow, the user
     * TODO CONT: is routed back to the previous video. The expected behavior is that the single video page will close
     * TODO CONT: and the user will be routed back to the original page they were on before selecting the first video.
     */
    const goBack = (asset_id, current_position, saveProgress) => {
        VIDEO_PLAYER_EVENTS.sendProgress({ asset_id, current_position, saveProgress }).then(() => {
            window.history.back()
        })
    }


    return (
        <>
            {
                !video || videoLoading || videoLoading === undefined || settings === null || JSON.stringify(user) === '{}' ?
                    <LoadingScreen />
                    :
                    isAudioSelected ?
                        <AudioOnly
                            audio={isAudioSrc}
                            title={video.title}
                            isAudioSelected={isAudioSelected}
                            toggleAudioOnly={toggleAudioOnly}
                        />
                        :
                        <Fragment>
                            <DesktopNav user={user} />
                            <div className="single-video_asset__page">
                                <VideoHeaderDesktop
                                    className="d-none d-md-flex"
                                    video={video}
                                    user={user}
                                    screenWidth={screenWidth}
                                    settings={settings}
                                    saveProgress={saveProgress}
                                    clickAction={() => openConnectingQuestions(video.connecting_questions.url)}
                                />
                                <div className="mobile-video_container d-md-none">
                                    <div id="close-button" className="hide pointer">
                                        <div
                                            onClick={() => goBack(video.asset_id, window.jwplayer(`player-${video.asset_id}`).getPosition(), saveProgress)}>
                                            <div className="close-video" />
                                        </div>
                                    </div>
                                    <VideoPlayer
                                        playerId={`player-${video.asset_id}`}
                                        playerScript="https://cdn.jwplayer.com/libraries/PKPzctjx.js"
                                        video={video}
                                        user={user}
                                        onPlay
                                        onReady
                                        onResume
                                        onPause
                                        onSeventyFivePercent
                                        onNinetyFivePercent
                                        saveProgress={saveProgress}
                                        document={self.document}
                                    />
                                </div>
                                <div className="asset-content_container d-md-none">
                                    <div className="title-container">
                                        <h3>{video.title}</h3>

                                        <WatchlistButton onWatchlist={video.on_watchlist}
                                            buttonType={`normal`}
                                            loader={{
                                                color: '#2D5F62',
                                                width: '4px'
                                            }}
                                            icons={{
                                                checkmark: settings.ICONS.shapes.checkmark.svg,
                                                plus: settings.ICONS.shapes.plus.svg
                                            }}
                                            assetID={asset_id}
                                        />


                                    </div>
                                    <div className="asset-subtitle">
                                        {
                                            (video.core_categories || []).map((t,i) => (
                                                <span key={i}>{t}</span>
                                            ))
                                        }
                                    </div>
                                    <div dangerouslySetInnerHTML={{ __html: video.description }}
                                        className="video-description" />
                                    {
                                        video.connecting_questions &&
                                        <GeneralButton
                                            className="d-md-none"
                                            buttonText={"Connecting Questions"}
                                            backgroundColor={settings.COLORS.system.PRIMARY.main.hex}
                                            icon={WhiteWorksheet}
                                            onClick={() => openConnectingQuestions(video.connecting_questions.url)}
                                        />
                                    }

                                    <div className="video-audio_switch__container">
                                        <AudioSwitch
                                            isAudioSelected={isAudioSelected}
                                            toggleAudioOnly={toggleAudioOnly}
                                        />
                                    </div>
                                </div>
                                {!isNoRelated && (
                                    <div className="asset-content_container">
                                        <div className="related-video_container">
                                            <h3 className="m-t-32 m-b-16">{settings.VIDEO_LAYOUT.RELATED.title || ''}</h3>
                                            <div className="d-none d-sm-block">
                                                {isDesktopCarousel}
                                            </div>
                                            <div className="d-sm-none">
                                                {isStyledMobileCards}
                                            </div>
                                        </div>
                                    </div>
                                )}

                            </div>
                            <MobileFooter />
                        </Fragment>
            }
        </>
    )
}

SingleVideo.propTypes = {
    getSingleVideo: PropTypes.func,
    saveProgress: PropTypes.func,
    screenWidth: PropTypes.number,
    settings: PropTypes.object,
    user: PropTypes.object,
    video: PropTypes.object,
    videoLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleVideo)
