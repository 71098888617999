import { isArray, isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { getFormattedStyle } from '../global/utils';
import Text from './text';
const defaultProps = {
    className: undefined,
};
const StyledTitle = styled.div `
    white-space: pre;
    width: 100%;
    ${(props) => props.style && `
    ${Object.keys(props.style).map((key) => `${key}: ${props.style[key]};`).join('\n')}
`}
`;
// eslint-disable-next-line react/prop-types
function Title({ className, elements, style, }) {
    const formattedStyle = !isEmpty(style)
        ? getFormattedStyle(style) : {};
    return (React.createElement(StyledTitle, { className: className, elements: elements, style: formattedStyle }, isArray(elements) ? (elements.map((element) => (
    // eslint-disable-next-line react/jsx-key
    React.createElement(Text, { styles: element?.style ?? {} }, element?.content)))) : (React.createElement(Text, { styles: elements?.style ?? {} }, elements?.content))));
}
Title.defaultProps = defaultProps;
export default Title;
