import React from "react";

import "./loginCards.scss";
import PropTypes from 'prop-types';

const LoginTextCard = (props) => {

    return (
        <div className="text-card_container" style={{ backgroundImage: `url(${props.backgroundImage})` }}>
            <div className="slide-text_container">
                <div className="login-title">{props.title}</div>
                <div className="login-text">{props.text}</div>
            </div>
        </div>
    )
}

LoginTextCard.propTypes = {
    backgroundImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired, 
    text: PropTypes.string.isRequired,
}

export default LoginTextCard;
