import React from 'react';
import PropTypes from 'prop-types';
import './pills.scss';

const TimePill = (props) => {

    return(
        <div className={`pill-container dark-pill ${props.position}`}>
            <div className="pill-text light-text time-pill">{props.duration}</div>
        </div>
    )
}

TimePill.propTypes = {
    duration: PropTypes.string.isRequired,
    position: PropTypes.string,
}

export default TimePill;
