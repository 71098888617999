import { isEmpty } from 'lodash';
import React, { useCallback, useState, } from 'react';
import styled from 'styled-components';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Alert } from 'reactstrap';
import { registerUserWithPartnerCode } from '../../store/auth/registerWithPartnerCode/actions';
import GeneralButton from '../buttons/generalButton.jsx';
const defaultProps = {
    buttonClass: undefined,
    formClass: undefined,
};
const StyledInput = styled.input `
    border: 1px solid #E3DCD3;
    border-radius: 8px;
    margin-bottom: 8px;
    display: block;
    width: 100%;
    padding: 12px 0 12px 12px;
`;
function RegisterWithPartnerCodeForm({ buttonClass, formClass, }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const { register, handleSubmit, formState: { isValid } } = useForm({ mode: 'onChange' });
    const registerInfo = useSelector((state) => state.RegisterWithPartnerCode);
    const onSubmit = useCallback((data) => {
        setIsFormSubmitted(true);
        const bodyParams = {
            first_name: data.first_name,
            email: data.email,
            password: data.password,
            partner_code: data.partner_code,
            device_name: 'web',
        };
        dispatch(registerUserWithPartnerCode(bodyParams, navigate));
    }, [
        dispatch,
        navigate,
    ]);
    const { isFetching, error: errorMsg, } = registerInfo;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: formClass },
            !isEmpty(errorMsg)
                && (
                // @ts-expect-error: TypeScript error
                React.createElement(Alert, { className: "login-error", color: "danger" }, errorMsg)),
            React.createElement("form", { className: "register-partner-form", onSubmit: handleSubmit(onSubmit) },
                React.createElement(StyledInput, { className: "name-input", type: "text", placeholder: "First Name", ...register('first_name', { required: true }) }),
                React.createElement(StyledInput, { className: "email-input", type: "text", placeholder: "Email Address", ...register('email', { required: true }) }),
                React.createElement(StyledInput, { className: "password-input", type: "password", placeholder: "Password", ...register('password', { required: true }) }),
                React.createElement(StyledInput, { className: "partner-code-input", type: "text", placeholder: "Partner Code", ...register('partner_code', { required: true }) }))),
        React.createElement("div", { className: buttonClass },
            React.createElement(GeneralButton, { disabled: isFetching, buttonText: (isValid && isFormSubmitted && !errorMsg) ? (React.createElement(ScaleLoader, { height: "10px", color: "#fff" })) : ('Redeem'), textColor: isValid ? '#fff' : '#47505F', backgroundColor: isValid && !isFetching ? '#2D5F62' : '#EDE4DD', onClick: handleSubmit(onSubmit) }))));
}
RegisterWithPartnerCodeForm.defaultProps = defaultProps;
export default RegisterWithPartnerCodeForm;
