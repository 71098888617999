/**
 * Hero component should contain
 *      - type*
 *      - src
 *      - properties
 */
import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {VideoPlayer} from "../Video/player";
import {Image} from "../Image";

export const Hero = ({data}) => {
    // console.log('HERO: ', data)
    return (
        <Fragment>
            {
                data.type === "video" ?
                    <VideoPlayer data={data}/>
                    :
                    <Image data={data}/>
            }
        </Fragment>
    )
}

Hero.propTypes = {
    data: PropTypes.shape({
        src: PropTypes.string.isRequired,
        type: PropTypes.oneOf(['video', 'image']).isRequired,
        properties: PropTypes.shape({
            color: PropTypes.string,
            padding: PropTypes.string,
            fontSize: PropTypes.string,
            textAlign: PropTypes.string,
            fontWeight: PropTypes.string,
            borderRadius: PropTypes.string,
            backgroundColor: PropTypes.string
        }).isRequired
    }).isRequired

}
