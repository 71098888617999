import {takeEvery, put, call} from "redux-saga/effects"

import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import {
    getSingleCourse,
    restartSingleCourse,
    saveCourseProgress,
    getAllCourses
} from "../../../helpers/backend-helper";


function* GetSingleCourse({uuid}) {
    try {
        const response = yield call(getSingleCourse, uuid);
        yield put(ACTIONS.setSingleCourse(response))
        yield put(ACTIONS.setLastCourseUUID(uuid))
    } catch (error) {
        yield put(ACTIONS.setCourseError(error))
        console.log('to sentry: ', error)
    }
}

function* RestartCourse({payload}) {
    
    try {
        const response = yield call(restartSingleCourse, payload);
        // console.log("response restart course", response)
        // next line is throwing the error
        yield put(ACTIONS.restartCourseSuccess)

    } catch (e) {
        yield put(ACTIONS.restartCourseError(e))
        console.log('to sentry: ', e)
    }
}

function* SaveCourseProgress({payload}) {
    // console.log('SaveCourseProgress', payload)
    try {
        yield call(saveCourseProgress, payload);
    } catch (e) {
        console.log('to sentry: ', e)
    }
}

function* retrieveAllCourses({payload}) {
    try {
        const response = yield call(getAllCourses, payload)
        yield put(ACTIONS.setAllCourses(response))
    } catch (error) {
        // yield put(ACTIONS.videoError(error))
    }
}


function* CoursesSaga() {
    yield takeEvery(TYPES.GET_SINGLE_COURSE, GetSingleCourse);
    yield takeEvery(TYPES.RESTART_COURSE, RestartCourse);
    yield takeEvery(TYPES.SAVE_COURSE_PROGRESS, SaveCourseProgress);
    yield takeEvery(TYPES.GET_ALL_COURSES, retrieveAllCourses)
}


export default CoursesSaga
