import React from 'react';
import PropTypes from 'prop-types';
import {BounceLoader, ScaleLoader} from "react-spinners";
import styled from "styled-components";


const LoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`


const CustomLoader = (props) => {
    if (props.loaderSize === "fullscreen") {
        return (
            <LoaderContainer>
                <ScaleLoader height="10px" width={props.width} color={props.color}/>
            </LoaderContainer>
        )
    } else if (props.type === 'bounce') {
        return (
            <LoaderContainer>
                <BounceLoader height="10px" width={props.width} color={props.color}/>
            </LoaderContainer>
        )
    } else {
        return (
            <LoaderContainer>
                <ScaleLoader height="10px" width={props.width} color={props.color}/>
            </LoaderContainer>
        )
    }
}

CustomLoader.propTypes = {
    loaderSize: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
}


export default CustomLoader;
