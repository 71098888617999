import {all, fork} from "redux-saga/effects"

import RegisterSaga from "./auth/register/saga"
import AuthSaga from "../pages/Auth/Login/store/saga"
import ForgetPasswordSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import CoreCategoriesSaga from "../pages/CoreCategory/store/sagas";
import DashboardSaga from "../pages/Dashboard/store/sagas";
import UserInterfaceSaga from "../utils/UserInterface/store/sagas";
import SystemSettingsSaga from "../utils/SystemSettings/store/sagas";
import IntroSliderSaga from "../components/Login/LoginCarousel/store/sagas";
import UserSaga from "./user/saga";
import VideoSaga from "../pages/Video/store/sagas";
import WatchlistSaga from "../pages/Watchlist/store/sagas";
import WorksheetSaga from "../pages/Worksheet/store/sagas";
import MyMarriageSaga from "../pages/MyMarriage/store/sagas";
import CoursesSaga from "../pages/Courses/store/sagas";
import AccountSettingsSaga from "../pages/Account/store/sagas";

import PopQuizSaga from "../components/PopQuiz/store/saga";
import RegisterWithPartnerCodeSaga from './auth/registerWithPartnerCode/saga';

// eslint-disable-next-line max-len
/* The implementation of this new onboarding feature is on hold for now. This can be brought back in the future.
import PreAuthTemplate from '../onBoarding/preAuthTemplate/preAuthTemplate.saga';
import ForgotPasswordTemplate from '../onBoarding/auth/forgotPassword/forgotPasswordTemplate.saga';
*/

export default function* rootSaga() {
    yield all([
        fork(AccountSettingsSaga),
        fork(AuthSaga),
        fork(CoreCategoriesSaga),
        fork(CoursesSaga),
        fork(DashboardSaga),
        fork(ForgetPasswordSaga),
        fork(IntroSliderSaga),
        fork(MyMarriageSaga),
        fork(ProfileSaga),
        fork(RegisterSaga),
        fork(SystemSettingsSaga),
        fork(UserInterfaceSaga),
        fork(UserSaga),
        fork(VideoSaga),
        fork(WatchlistSaga),
        fork(WorksheetSaga),
        fork(PopQuizSaga),
        fork(RegisterWithPartnerCodeSaga),
        // eslint-disable-next-line max-len
        /* The implementation of this new onboarding feature is on hold for now. This can be brought back in the future.
        fork(PreAuthTemplate),
        fork(ForgotPasswordTemplate),
        */
    ]);
}
