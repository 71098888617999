/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useState, } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutUser } from '../../../../Auth/Login/store/actions.js';
import { deleteAccount } from '../../../../../helpers/backend-helper.js';
import DeleteAccountModal from '../../../Modals/DeleteAccount.modal';
// -DISPATCH PROPS
const mapDispatchToProps = (dispatch) => bindActionCreators({
    logOut: logoutUser,
}, dispatch);
// -MAP PROPS
const mapStateToProps = (appState) => ({
    user: appState.User.data,
});
const Container = styled.div `
    height: 100vh;
    position: relative;
    max-width: 650px;
    width: 100%;
`;
const StyledTitle = styled.h3 `
    font-family: Sofia Pro Bold;
`;
const StyledSubTitle = styled.div `
    font-family: Sofia Pro;
    line-height: 1.5;
`;
const ButtonContainer = styled.div `
    position: relative;
    background: #F2F2F2;
    border: 1px solid #D93B31;
    border-radius: 10px;
    cursor: pointer;
    width: 128px;
    height: 23px;
    margin-top: 22px;
`;
const ButtonText = styled.div `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #D93B31;
    font-size: 12px;
    white-space: nowrap;
`;
// eslint-disable-next-line react/prop-types
function AccounDelete({ user, logOut, }) {
    const navigate = useNavigate();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const onClickDeleteAccount = useCallback(() => {
        setIsDeleteModalOpen(true);
    }, []);
    const onCloseDeleteModal = useCallback(() => {
        setIsDeleteModalOpen(false);
    }, []);
    const onConfirmDeleteAccount = useCallback(() => {
        const bodyParams = {
            email: user?.email,
        };
        deleteAccount(bodyParams).then(() => {
            logOut(navigate);
        }).catch((error) => {
            // eslint-disable-next-line no-console
            console.log('Error: Problem deleting account of the user', error);
        });
    }, [
        user,
        logOut,
        navigate,
    ]);
    return (React.createElement(Container, null,
        React.createElement(StyledTitle, null, "Delete Account"),
        React.createElement(StyledSubTitle, null, "Deleting your account removes all access permanently. \u00A0This action is irreversible. Please consider carefully before proceeding. \u00A0Thank you for your understanding"),
        React.createElement(ButtonContainer, { onClick: onClickDeleteAccount },
            React.createElement(ButtonText, null, "Delete Account")),
        isDeleteModalOpen && (React.createElement(DeleteAccountModal, { onClose: onCloseDeleteModal, onDeleteAccount: onConfirmDeleteAccount }))));
}
export default connect(mapStateToProps, mapDispatchToProps)(AccounDelete);
