var GenIcon = require('../lib/iconBase').GenIcon
module.exports.SplittyCircleCheckMultiFill = function SplittyCircleCheckMultiFill(props) {
    return GenIcon({
        "tag": "svg",
        "attr": {"fill": "currentColor", "viewBox": "0 0 22 22"},
        "child": [

            {
                "tag": "circle",
                "attr": {
                    "cx": "11", "cy": "11", "r": "11",
                    "fill": props.primary
                },

            },
            {
                "tag": "path",
                "attr": {
                    "d": "M8.91584 13.4467L6.2038 10.9216L5.28027 11.7754L8.91584 15.1604L16.7203 7.89386L15.8033 7.04004L8.91584 13.4467Z",
                    "fill": props.secondary
                }
            }
        ]
    })(props);
};
