import AccountPassword2022 from '../Sidebar/Submenu/AccountPassword/account-password-12-2022.jsx';
// eslint-disable-next-line import/extensions
import NotificationSettings2022 from '../Sidebar/Submenu/AccountNotifications';
import AccountSubscription from '../Sidebar/Submenu/AccountSubscription/accountSubscription';
import AccountDelete from '../Sidebar/Submenu/AccountDelete/index';
import PartnerPairingInviteLink from '../Sidebar/Submenu/AccountSecondUser/partner-pairing-invite';

const ProfileSubMenu = {
    items: [
        {
            id: 'partnerPairing',
            text: 'Partner Pairing',
            component: PartnerPairingInviteLink,
            displayToPrimaryOnly: true,
        },
        {
            id: 'notifications',
            text: 'Notifications',
            component: NotificationSettings2022,
        },

        {
            id: 'password',
            text: 'Password',
            component: AccountPassword2022,
        },
        {
            id: 'subscription',
            text: 'Subscription & Payment',
            component: AccountSubscription,
        },
        {
            id: 'account-delete',
            text: 'Delete Account',
            component: AccountDelete,
        },

    ],
};

export default ProfileSubMenu;
