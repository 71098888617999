import React from "react";
import GeneralButton from "../../buttons/generalButton";
import Worksheet from "../../../assets/images/worksheet.svg";
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';
import "./worksheet-header.scss";
import styled from "styled-components";

const WorksheetHeader = (props) => {
    // category, card text, card title

    const BannerContainer = styled.div`
        min-height: ${props.screenWidth * .36}px;
        background-color:${props.worksheet.meta.backgroundColor};
    `
    const WorksheetThumbnail = styled.div`
        background-color:${props.worksheet.meta.backgroundColor};
        background-image:url(${props.worksheet.thumbnail});
        min-height: ${props.screenWidth * .36}px;

        @media (max-width: 768px) {
            min-height: ${props.screenWidth * 720/1280}px;
        }
    `

    const Content = styled.div`
        width: ${props.screenWidth * .33}px;
        max-width: 560px;
    `
    const WorksheetContainer = styled.div`
        padding-left: ${props.screenWidth * .0875}px;
        background: linear-gradient(90deg, ${props.worksheet.meta.backgroundColor} 68.94%, rgba(120, 165, 168, 0)  73.68%);


    `

    return (
        <BannerContainer className={`worksheet-header_container ${props.className}`}>
            <WorksheetContainer className="desktop-column">
                <Content className="desktop-description">
                    <div className="worksheet-title">{props.worksheet.title}</div>
                    <div className="card-description pb-4">
                        {props.worksheet.description}
                    </div>

                    <GeneralButton
                        onClick={props.clickAction}
                        icon={Worksheet}
                        backgroundColor={"#2D5F62"}
                        buttonText={"Open Worksheet"}
                        textColor={"#fff"}
                        className={`max-300`}
                    />

                </Content>
            </WorksheetContainer>
            <WorksheetThumbnail className="card-image"/>

        </BannerContainer>
    )
}

WorksheetHeader.propTypes = {
    clickAction: PropTypes.func.isRequired,
    screenWidth: PropTypes.number.isRequired,
    worksheet: PropTypes.object.isRequired,
}

export default WorksheetHeader;
