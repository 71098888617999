import * as React from 'react'
// import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti'
import { width } from 'styled-system'

export default () => {
    // const {width, height} = useWindowSize();
    let width =window.innerWidth;
    let height = window.innerHeight;
    return (
        <Confetti
            width={width}
            height={height}
        />
    )
}