
const SidebarMenu = {
    items: [
        {
            type: "item",
            text: "My Membership",
            link: "/account/"
        },
        {
            type: "spacer",
            classNames: ['py-3']
        },
        {
            type: "item",
            text: "My Watchlist",
            link: "/account/watchlist"
        },
        {
            type: "item",
            text: "My History",
            link: "/account/my-history"
        },
        {
            type: "item",
            text: "Continue Watching",
            link: "/account/continue-watching"
        },
        {
            type: "spacer",
            classNames: ['py-3']
        },
        {
            type: "item",
            text: "Membership Perks",
            link: "/account/my-membership-perks"
        },
        {
            type: "item",
            text: "Extras",
            link: "/account/extras"
        },
        {
            type: "item",
            text: "FAQ's",
            link: "/account/faqs"
        },
        {
            type: "logout",
            text: "Logout",
        }
    ]
}

export default SidebarMenu
