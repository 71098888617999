import ActionType from './actionTypes';
export const registerUserWithPartnerCode = (user, navigate) => ({
    type: ActionType.REGISTER_USER_WITH_PARTNER_CODE,
    payload: {
        user,
        navigate,
    },
});
export const registerUserSuccessfulWithPartnerCode = (user, navigate) => ({
    type: ActionType.REGISTER_USER_WITH_PARTNER_CODE_SUCCESSFUL,
    payload: {
        user,
        navigate,
    },
});
export const registerUserFailedWithPartnerCode = (errorMsg) => ({
    type: ActionType.REGISTER_USER_WITH_PARTNER_CODE_FAILED,
    payload: errorMsg,
});
