import React, { useEffect, useState } from 'react'
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import './complete-registration.scss';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import {Alias, HandleRouteChange} from "../utils/Segment/segment-methods";
import PropTypes from 'prop-types';

const mapStateToProps = appState => ({
    user: appState.User.data,
});

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

const CompleteRegistration = ({ user }) => {
    let location = useLocation()
    let navigate = useNavigate()
    const { register, handleSubmit, formState: { errors } } = useForm();

    // set local state
    const [showPassword, setShowPassword] = useState(false)

    // declare button state
    const [profileButtonActive, setProfileButtonActive] = useState(false);

    // set errors state
    const [profileErrors, setProfileErrors] = useState(null);


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            Alias(user.email)
            HandleRouteChange(location, user, 'page-view', 'Complete Registration')

        }
        window.scroll(0, 0);
    }, [user, location])


    // useEffect(() => {
    //     initializePhone();
    // }, [user]);


    // const initializePhone = () => {
    //     const input = document.querySelector("#phone");
    //     var iti = intlTelInput(input, {
    //         // any initialization options go here
    //         allowDropdown: true,
    //         autoPlaceholder: true,
    //         formatOnDisplay: true,
    //         separateDialCode: true,
    //     });
    // }

    const togglePassword = () => {
        setShowPassword(!showPassword)
    }

    const updateProfile = (data) => {
        axios.put('/complete-registration', {
            password: data.password,
            rb: data.referred_by

        }).then((response) => {
            setProfileButtonActive(false)

            if (response.data.status === 'success') {
                toast("Your profile has been updated!", {
                    className: 'success--toast',
                    bodyClassName: 'success--body',
                    progressClassName: 'success--progress'
                });
                // redirect to /browse
                if (user.onboarding_complete) {
                    navigate('/browse')
                } else {
                    navigate('/pick-quiz')
                }

            } else {
                toast("Oh No! There was an error with your request.", {
                    className: 'error--toast',
                    bodyClassName: 'error--body',
                    progressClassName: 'error--progress'
                });
                setProfileErrors(response.data.message);
            }


        }).catch(error => {
            let er = Object.values(error.response.data.errors);
            er = er.flat();
            setProfileErrors(er);

            toast("There was an error!", {
                className: 'error--toast',
                bodyClassName: 'error--body',
                progressClassName: 'error--progress'
            });
        })
    }

    return (
        <div className="complete-registration">
            <div className="m365-desktop_logo" />
            <div className="teal-overlay" />
            <div className="profile-select__container">

                <div className="two-profile-container">

                    <form>

                        <div className="profile-select__title cursive">Complete Account Details</div>
                        {
                            profileErrors &&
                            <div className="alert alert-danger" role="alert">
                                {profileErrors}
                            </div>
                        }
                        <div className="form-group">
                            <label className="input-label" htmlFor="password">
                                Password (required)
                            </label>
                            <div className="input-group" id="show_hide_password">
                                <input
                                    {...register("password", { required: true, maxLength: 40 })}
                                    id="password"
                                    onFocus={() => setProfileButtonActive(true)}
                                    className="form-control"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    autoComplete="false" />

                                <div onClick={togglePassword} className="pointer input-group-addon">
                                    {
                                        showPassword ?
                                            <span>Hide</span>
                                            :
                                            <span>Show</span>
                                    }
                                </div>
                            </div>

                            <div>
                                {
                                    errors.password &&
                                    <div className="alert alert-danger m-t-5">
                                        Please enter a new password
                                    </div>
                                }
                                <div className="sub-text p-b-10 pt-1 password-subtext">
                                    Passwords must be at least 8 characters in length.
                                </div>
                            </div>
                        </div>


                        {/* <div className="form-group">
                            <label className="input-label" htmlFor="mobile_phone">
                                Mobile Phone number
                                <input
                                    {...register("phone", {required: false, minLength: 6, maxLength: 20})}
                                    onFocus={() => setProfileButtonActive(true)}
                                    onChange={v => setPhoneNumber(v.target.value)}
                                    className="form-control"
                                    type="text"
                                    id="phone"
                                    name="phone"/>
                            </label>
                        </div> */}
                        {user.referred_by === null &&
                            <div className="form-group">
                                <label className="input-label" htmlFor="referred_by">
                                    How did you hear about us?
                                    <select
                                        {...register("referred_by", { required: false })}
                                        className="form-control"
                                        name="referred_by"
                                        id="referred_by">
                                        <option value="na">--Select--</option>
                                        <option value="friend">Friend</option>
                                        <option value="social">Social Media</option>
                                        <option value="web">Web Search</option>
                                        <option value="church">Church</option>
                                        <option value="counselor">Counselor</option>
                                        <option value="employer">Employer</option>
                                        <option value="event">Event</option>
                                        <option value="other">Other</option>
                                    </select>
                                </label>
                            </div>
                        }

                        <div className="form-btn">
                            <button
                                className={`update-profile ${profileButtonActive ? 'btn-active' : 'btn-inactive'}`}
                                onClick={handleSubmit(updateProfile)}
                                disabled={!profileButtonActive}
                            >
                                Save Profile
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


CompleteRegistration.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteRegistration);
