import React from 'react';

import './desktop-footer.scss';
import {Track} from "../../../utils/Segment/segment-methods";
import {useNavigate} from "react-router-dom";

const DesktopFooter = () => {
    let navigate = useNavigate()

    function RouteUser(link, isExternal = false, name) {
        Track(`Clicked Footer Link`, {Link: link, Title: name}).then(() => {
            if (isExternal) {
                window.open(link)
            } else {
                navigate(`${link}`)
            }

        })

    }

    return (
        <div id="desktop-footer">
            <div className="footer-row">
                <div className="page-links">
                    <div className="link-column">
                        <div className="link-container">
                            <div className="column-title">Member Perks</div>
                            <div className="pointer"
                                 onClick={() => RouteUser("/account/my-membership-perks", true, 'Gift Membership')}>
                                Gift Membership
                            </div>
                            <div className="pointer"
                                 onClick={() => RouteUser("https://marriage365.com/books/", true, 'Books')}>
                                Books
                            </div>
                            <div className="pointer"
                                 onClick={() => RouteUser("https://marriage365.com/coaching/", true, 'Coaching')}>
                                Coaching
                            </div>
                        </div>

                        <div className="accessibility-links">
                            <div className="pointer"
                                 onClick={() => RouteUser("https://marriage365.com/accessibility-statement/", true, 'Accessibility')}>Accessibility
                            </div> / <div className="pointer"
                                   onClick={() => RouteUser("https://marriage365.com/privacy-policy/", true, 'Privacy Policy')}>Privacy</div> / <div
                            className="pointer"
                            onClick={() => RouteUser("https://marriage365.com/membership-terms-of-service/", true, 'Terms of Service')}>Terms
                            of
                            Use</div>
                        </div>
                    </div>
                    <div className="link-column">
                        <div className="link-container">
                            <div className="column-title">Help</div>
                            <div className="pointer" onClick={() => RouteUser("/account/", false, 'Account')}>
                                Subscription & Payment
                            </div>
                            <div className="pointer"
                                 onClick={() => RouteUser("https://marriage365.com/contact/", true, 'Feedback')}>
                                Feedback
                            </div>
                            <div className="pointer"
                                 onClick={() => RouteUser("https://marriage365.com/contact/", true, 'Contact Us')}>
                                Contact Us
                            </div>
                        </div>

                        <div className="trademarks">
                            {`© ${new Date().getFullYear()} Marriage365. All Rights Reserved.`}
                        </div>
                    </div>
                </div>
                <div className="app-store_links">
                    <div className="link-container">
                        <div className="column-title">
                            Download App
                        </div>
                        <div className="store-buttons">
                            <div className="pointer"
                                 onClick={() => RouteUser("https://apps.apple.com/us/app/marriage365/id1628739510?_ga=2.242944901.686767227.1670632231-356310726.1669299883", true, 'Apple Store')}>
                                <div className="apple-store"></div>
                            </div>
                            <div className="pointer"
                                 onClick={() => RouteUser("https://play.google.com/store/apps/details?id=com.marriage365.app&_ga=2.242944901.686767227.1670632231-356310726.1669299883", true, 'Android Store')}>
                                <div className="google-store"></div>
                            </div>
                        </div>
                    </div>

                    <div className="social-links">
                        <div className="pointer"
                             onClick={() => RouteUser("https://www.instagram.com/marriage365", true, 'Instagram')}>
                            <div className="social-icon instagram"></div>
                        </div>
                        <div className="pointer"
                             onClick={() => RouteUser("https://m.facebook.com/marriage365", true, 'Facebook')}>
                            <div className="social-icon facebook"></div>
                        </div>
                        <div className="pointer"
                             onClick={() => RouteUser("https://twitter.com/marriage365", true, 'Twitter')}>
                            <div className="social-icon twitter"></div>
                        </div>
                        <div className="pointer"
                             onClick={() => RouteUser("https://www.youtube.com/c/marriage365", true, 'YouTube')}>
                            <div className="social-icon youtube"></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}

export default DesktopFooter;

