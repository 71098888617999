import React from 'react'
import classNames from "classnames"

function ProfileMain({className, ...props}) {
    const compClass = classNames({
        "profile-main": true,
        [`${className}`]: className,
    });
    return (
        <div className={compClass}>
            {props.children}
        </div>
    )
}

export default ProfileMain
