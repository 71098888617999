import React from 'react';
import PropTypes from 'prop-types';
import './progress-bar.scss';

const CustomProgressBar = (props) => {

    let percentage = props.progressPercentage;
    percentage = percentage + "%";

    return (
        <div id="progress">
            <div className="progress-bar_container">
                <div className="progress-bar"></div>
                <div className="current-progress" style={{width:`${percentage}`}}></div>
            </div>
        </div>
    )
}

CustomProgressBar.propTypes = {
    progressPercentage: PropTypes.number,
}

export default CustomProgressBar;
