import React, {Fragment, useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";

import "./pick-quiz.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {HandleRouteChange} from "../../utils/Segment/segment-methods";
import PropTypes from 'prop-types';

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data
});


const StartCheckup = ({user}) => {
    let navigate = useNavigate()
    let location = useLocation()

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Start Checkup', 'Start Checkup')
        }
    }, [user, location])


    const startAssessment = () => {
        navigate('/quiz/full')
    }

    const closeAssessment = () => {
        navigate(-1)
    }


    return (
        <div className="pick-quiz_page">
            <div className="m365-logo"/>
            <div className="close-page pointer" onClick={closeAssessment}/>
            <div className="pick-quiz_content">
                <h1 className="pick-title">Marriage365 Checkup</h1>
                <div className="pick-card_container">

                    <div className="full-quiz_container choice-container">
                        <div className="quiz-question_length py-2">
                            <div className="question-icon"/>
                            <div className="question-length font-bold">
                                28 Questions
                            </div>
                        </div>
                    </div>
                    <div className="full-quiz_container choice-container">
                        <div className="quiz-time_length py-2">
                            <div className="time-icon"/>
                            <div className="time-length font-bold">
                                5 minutes
                            </div>
                        </div>
                    </div>

                </div>
                <div className="pick-text">
                    <h4 className="font-bold pt-4">Marriage365 Checkup Tips</h4>
                    <ul>
                        <li>Take this Marriage365 Checkup monthly to track your progress</li>
                        <li>Be honest so we can suggest the best resources for you</li>
                        <li>The questions are about you</li>
                        <li>The Marriage365 Checkup helps track your individual growth</li>
                    </ul>


                </div>

                <button className="start-button_container" onClick={startAssessment}>
                    <div className="start-assessment font-semibold">
                        Get Started
                    </div>
                </button>


            </div>
        </div>
    )

}

StartCheckup.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(StartCheckup);

