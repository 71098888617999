import React from 'react';
import { Link } from 'react-router-dom';
import LoginCarousel from './LoginCarousel/login-carousel';
import './desktop-login.scss';
import LoginForm from "../Forms/login";

const DesktopLogin = () => {
    return (
        <div className="desktop-login_page">
            <div className="m365-desktop_logo" />
            <div className="desktop-login_container">
                <div className="carousel-container">
                    <LoginCarousel />
                </div>
                <div className="login-content">
                    <h1 className="login-title">Log in</h1>
                    <LoginForm formClass={`desktop-form_container`} buttonClass={`desktop-button_container`} />
                    <div className="login-link">
                        New to Marriage365? <a href={'https://marriage365.com/membership'}>Get Started.</a>
                    </div>
                    <div className="login-link register-partner-link">
                        Need to Connect Partner? <a href={'/register-partner'}>Click Here</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopLogin;
