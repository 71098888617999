import React, {useState, useEffect} from 'react';

import "./accountConfirmation.scss";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    applyMemberCoupon,
    cancelSubscription,
    getCancellationOffer,
    setCancellationOffer, updateSubscriptionServerResponse
} from "../store/actions";
import CustomLoader from "../../../components/CustomLoader";
import {HandleRouteChange} from "../../../utils/Segment/segment-methods";
import {useLocation, useNavigate} from "react-router-dom";
import CancellationForm from "../Components/FormContainer";
import CancellationModalData from "../Data/CancellationDataModal";
import styled from "styled-components";

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            applyMemberCoupon,
            cancelSubscription,
            getCancellationOffer,
            setCancellationOffer,
            updateSubscriptionServerResponse,
        },
        dispatch
    )
};

const mapStateToProps = appState => ({
    cancellationOfferAvailable: appState.AccountSettings.cancellationOffer,
    eventLoading: appState.AccountSettings.changeSubscriptionLoading,
    message: appState.AccountSettings.message,
    response: appState.AccountSettings.response,
    subscription: appState.User.data.subscription,
    user: appState.User.data,
});


const CancelMembershipModalContainer = styled.div`
    border-width: 5px;
    border-style: solid;
    border-color: ${props => props.borderColor};

`


const CancelMembershipModal = ({
                                   applyMemberCoupon,
                                   cancellationOfferAvailable,
                                   cancelSubscription,
                                   eventLoading,
                                   getCancellationOffer,
                                   message,
                                   response,
                                   setCancellationOffer,
                                   subscription,
                                   updateSubscriptionServerResponse,
                                   user,
                                   ...props
                               }) => {

    const [isLetUsKnowShown, setIsLetUsKnowShown] = useState(false);
    const [isSavingsConfirmationShown, setIsSavingsConfirmationShown] = useState(false);
    const [isCongratulationsShown, setIsCongratulationsShown] = useState(false);
    const [userHasCanceled, setUserHasCanceled] = useState(false);

    const cancelModal = React.useRef();


    let location = useLocation()
    let navigate = useNavigate()


    const [formErrors, setFormErrors] = React.useState(null)

    useEffect(() => {
        // I don't expect this to be a high traffic component that will cause unnecessary load on the server so
        // I'm allowing this to run on each component load to ensure the offer is updated with the server
        getCancellationOffer(props.planDetails.cancellationOffer.coupon)
    }, []);

    useEffect(() => {
        cancellationOfferAvailable !== null && !cancellationOfferAvailable && (setIsLetUsKnowShown(true))
    }, [cancellationOfferAvailable])


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Cancellation Form')
            setUserHasCanceled(subscription.account_status === 'Canceled')
        }
    }, [user, location])


    const confirmCancel = () => {
        // show let us know
        setIsLetUsKnowShown(true);
        // change color red
        cancelModal.current.style.border = "5px solid #D93B31";
        // console.log(cancelModal);
    }

    const goBackToConfirm = () => {
        setIsLetUsKnowShown(false);

        if (cancellationOfferAvailable) {
            setIsSavingsConfirmationShown(false);
            cancelModal.current.style.border = "5px solid #F88A09";
        } else {
            closeModal()
        }
    }


    const storeAnswers = (data) => {
        setFormErrors(null)

        // if (data.reasonWhy === "other") {
        //     data.reasonWhy = data.other
        // }

        //todo: consider moving form errors to be displayed in the actual form
        if (data.reasonWhy === null) {
            // set error
            setFormErrors('Please tell us why you are cancelling.')
        } else {
            setIsLetUsKnowShown(false);
            // setIsConfirmCancelShown(true);
            cancelMembership(data)
        }
    }

    const backToLook = () => {
        setIsLetUsKnowShown(true);
        // setIsConfirmCancelShown(false);
    }

    const cancelMembership = (data) => {
        // redux action here
        // setIsConfirmCancelShown(false)
        cancelSubscription(data, navigate)
    }

    const confirmSavings = () => {
        setIsSavingsConfirmationShown(true);
    }

    const applySavings = () => {
        // cancelModal.current.style.border = "5px solid #4AC86A";

        setIsCongratulationsShown(true);
        setIsSavingsConfirmationShown(false);

        // confirm the cancellation can be offered
        if (cancellationOfferAvailable) {
            // send the cancellation coupon to the server
            applyMemberCoupon(props.planDetails.cancellationOffer.coupon, 'cancellation')
        }


    }

    const openCongratulations = () => {
        setIsCongratulationsShown(true);
    }

    const closeModal = () => {
        // set Redux back to null so that the next load doesn't default to the previous setting
        setCancellationOffer(null)
        updateSubscriptionServerResponse(null)
        props.setIsCancelMembershipModalShown(false);
    }


    return (
        <div className="overlay">
            <CancelMembershipModalContainer className="account-cancel_modal position-relative"
                                            ref={cancelModal}
                                            borderColor={response && response.status === 'success' ? '#4AC86A' : '#F88A09'}>
                {
                    cancellationOfferAvailable === null || eventLoading ?
                        <CustomLoader/>
                        :
                        <div className="modal-content_container">
                            <div className="close-modal_x" onClick={closeModal}></div>
                            {
                                response === null && !userHasCanceled && !isCongratulationsShown && !isLetUsKnowShown && !isSavingsConfirmationShown && cancellationOfferAvailable && (
                                    <React.Fragment>
                                        <h1>Before you go...</h1>
                                        <div className="modal-text bold-text">How about
                                            saving {props.planDetails.cancellationOffer.amount} on your
                                            next {props.planDetails.cancellationOffer.duration} of membership?
                                        </div>
                                        <div className="account-button_container">
                                            <div className="account-button short-red_button small-text_button"
                                                 onClick={confirmCancel}>
                                                <div className="account-button_text"><span>NO</span>, I'd like to cancel
                                                    my <br/> membership.
                                                </div>
                                            </div>
                                            <div className="account-button long-teal_button small-text_button"
                                                 onClick={confirmSavings}>
                                                <div className="account-button_text"><span>YES</span>, I'd love to save some
                                                    money
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }

                            {
                                isLetUsKnowShown &&
                                <React.Fragment>
                                    {
                                        formErrors &&
                                        <div className="alert alert-danger">1
                                            {formErrors}
                                        </div>
                                    }
                                    <CancellationForm stepBack={goBackToConfirm}
                                                      activeTest={'__CANCELLATION-QUESTIONS'}
                                                      data={CancellationModalData.Form.content}
                                                      storeAnswers={storeAnswers}/>
                                </React.Fragment>

                            }
                            {
                                message &&
                                <React.Fragment>
                                    <h1>{message.title}</h1>
                                    <div className="modal-text">{message.content ?? null}</div>
                                    <div className="account-button_container">
                                        <div className="account-button full-button" onClick={() => navigate(0)}>
                                            <div className="account-button_text">Close Window</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                (isSavingsConfirmationShown && response === null) &&
                                <React.Fragment>
                                    <h1>That's great news!</h1>
                                    <div className="modal-text bold-text">We are so happy you're choosing to continue to
                                        learn and grow by keeping your Marriage365 membership active.
                                    </div>
                                    <div className="account-button_container">
                                        <div className="account-button back-button" onClick={goBackToConfirm}>
                                            <div className="account-button_text__container">
                                                <div className="back-arrow"></div>
                                                <div className="account-button_text">Back</div>
                                            </div>
                                        </div>
                                        <div className="account-button" onClick={applySavings}>
                                            <div className="account-button_text">Confirm Savings</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                response && response.status === 'success' &&
                                <React.Fragment>
                                    <h1>Congratulations!</h1>
                                    <div className="modal-text">Your next payment will include a
                                        ${props.planDetails.cancellationOffer.amount} savings on your
                                        next {props.planDetails.cancellationOffer.duration} of membership. Your
                                        membership will continue to renew until you cancel.
                                    </div>
                                    <div className="account-button_container">
                                        <div className="account-button full-button" onClick={() => navigate(0)}>
                                            <div className="account-button_text">Close Window</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                            {
                                response && response.status === 'error' &&
                                <React.Fragment>
                                    <h1>{response.title}</h1>
                                    <div className="modal-text">{response.content}</div>
                                    <div className="account-button_container">
                                        <div className="account-button full-button" onClick={() => navigate(0)}>
                                            <div className="account-button_text">Please try again</div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>
                }
            </CancelMembershipModalContainer>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelMembershipModal)
