import * as TYPES from './actionTypes'

const initialState = {
    screenWidth: typeof window === 'object' ? window.innerWidth : null,

}

const UserInterface = (state = initialState, action) => {
    switch (action.type) {

        case TYPES.SCREEN_RESIZE_SUCCESS:
            return {...state, screenWidth: action.screenWidth}

        case TYPES.SET_TRENDING_SLIDER:
            return {...state, payload: action.payload}


        default:
            return state;

    }

}

export default UserInterface
