import * as TYPES from "./actionTypes";


export const getCoreCategories = () => {
    return {
        type: TYPES.GET_CORE_CATEGORIES
    }
}

export const setCoreCategories = (payload) => {
    return {
        type: TYPES.SET_CORE_CATEGORIES,
        payload: payload
    }
}

export const setLastCategoryUUID = (payload) => {
    return {
        type: TYPES.SET_LAST_LOADED_UUID,
        payload: payload
    }
}


export const getSingleCoreCategory = (uuid, skip = 0, limit = 10) => {
    return {
        type: TYPES.GET_SINGLE_CATEGORY,
        payload: {uuid, skip, limit}
    }

}
export const setSingleCategory = (payload) => {
    return {
        type: TYPES.SET_SINGLE_CATEGORY,
        payload: payload
    }

}
export const singleCategoryError = error => {
    return {
        type: TYPES.SINGLE_CATEGORY_ERROR,
        payload: error
    }

}

export const setSingleCategoryTrending = payload => {
    return {
        type: TYPES.SINGLE_CATEGORY_TRENDING,
        payload: payload
    }
}

export const setSingleCategoryVideos = payload => {
    return {
        type: TYPES.SINGLE_CATEGORY_VIDEOS,
        payload: payload
    }
}

export const setSingleCategoryResources = payload => {
    return {
        type: TYPES.SINGLE_CATEGORY_RESOURCES,
        payload: payload
    }
}

export const setSingleCategoryCourses = payload => {
    return {
        type: TYPES.SINGLE_CATEGORY_COURSES,
        payload: payload
    }
}
