import React from "react";
import "./header-promo.scss";
import {useNavigate} from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import ComponentRenderer from "../../@splitty/Renderer/component-renderer";
import {Track} from "../../utils/Segment/segment-methods";

const HeaderPromoBanner = ({content}) => {
    const navigate = useNavigate();

    const routeUser = (url, is_external = false) => {
        Track('Clicked Promo Banner').then(() => {
        })

        if (!is_external) {
            navigate(url)
        } else {
            window.open(url)
        }

    }

    const PromoBanner = styled.div`
        background-color: ${content.properties.bannerBackgroundColor}
    `

    return (
        <PromoBanner id="promo-header-styled" className="promo-header">
            <div
                className="promo-header__container d-block d-md-flex justify-content-center align-items-center flex-md-row"
                style={{
                    maxWidth: "800px",
                    width: "80%",
                    backgroundColor: content.properties.bannerBackgroundColor
                }}>
                <>
                    <div className="banner-text d-block" dangerouslySetInnerHTML={{__html: content.title}}/>

                    <div className="btn__container">
                        {
                            content.properties.showButton &&
                            <div onClick={() => routeUser(content.destination, content.properties.is_external)}
                                 className="footer__btn pointer">{content.button_text}
                            </div>
                        }
                    </div>
                </>
            </div>
        </PromoBanner>

        // <div className="promo-header" >
        //     <div className="promo-header__container d-block d-lg-flex justify-content-center align-items-center flex-lg-row">
        //         <div className="image-container col my-4 my-lg-0">
        //             <img src="https://d305pf0gb904h3.cloudfront.net/promo/nyny2023/text-wide-outlined.png" alt="" className="img-fluid img-poster"/>
        //         </div>
        //         <div className="banner-content_container col flex-column d-flex" style={{flex: 4}}>
        //             <div className="banner-content-top"><span style={{color:'#fff', fontFamily:"Sofia Pro Bold"}}>FREE</span> <span style={{fontStyle: "italic", fontFamily:"Sofia Pro Bold"}}>365 SELF DISCOVERY QUESTIONS</span>
        //                 <span style={{fontWeight:400}}> EBOOK AVAILABLE IN THE APP!</span></div>
        //             <div className="banner-content-bottom my-2 my-lg-0">Available in Membership Perks in your profile. Ends January 31.</div>
        //         </div>
        //         <div className="mobile-app-callout col flex-column d-flex">
        //             <div className="app-content-top">To receive your free eBook, download the Marriage365 app</div>
        //             <div className="app-content-bottom mt-4 mt-lg-2 d-flex flex-row justify-content-center align-items-center">
        //                 <div className="apple-image-container col justify-content-end d-flex">
        //                     <a href={"https://apps.apple.com/us/app/marriage365/id1628739510"} target={"_blank"}>
        //                         <img src="https://d305pf0gb904h3.cloudfront.net/promo/nyny2023/apple-store-button.png" alt=""/>
        //                     </a>
        //                 </div>
        //
        //                 <div className="google-image-container col">
        //                     <a href={"https://play.google.com/store/apps/details?id=com.marriage365.app"} target={"_blank"}>
        //                         <img src="https://d305pf0gb904h3.cloudfront.net/promo/nyny2023/google-play-button.png" alt=""/>
        //                     </a>
        //                 </div>
        //             </div>
        //
        //
        //
        //         </div>
        //     </div>
        // </div>
        // <ComponentRenderer/>
    )

}

HeaderPromoBanner.propTypes = {
    button_text: PropTypes.string,
    destination: PropTypes.string,
    properties: PropTypes.object,
    title: PropTypes.string,
}

export default HeaderPromoBanner;
