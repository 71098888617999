import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Highlight, connectAutoComplete} from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
import './autocomplete.scss'

class AutoComplete extends Component {
    static propTypes = {
        hits: PropTypes.arrayOf(PropTypes.object).isRequired,
        currentRefinement: PropTypes.string.isRequired,
        refine: PropTypes.func.isRequired,
        onSuggestionSelected: PropTypes.func.isRequired,
    };

    state = {
        value: this.props.currentRefinement,
    };

    onChange = (event, {newValue}) => {
        // if (!newValue) {
        //     this.props.onSuggestionCleared();
        // }
        this.setState({
            value: newValue,
        });
    };

    onSuggestionsFetchRequested = ({value}) => {
        this.props.refine(value);
    };

    onSuggestionsClearRequested = () => {
        this.props.refine();
    };

    getSuggestionValue(hit) {
        return hit.title;

    }

    renderSuggestion(hit) {
        return <Highlight attribute="title" hit={hit} tagName="mark"/>;

    }

    renderSectionTitle(section) {
        return null
    }

    getSectionSuggestions(section) {
        return section.hits;
    }


    render() {
        const {hits, onSuggestionSelected} = this.props;
        const {value} = this.state;

        const inputProps = {
            placeholder: 'Search Topics',
            onChange: this.onChange,
            value,
        };

        return (
            <AutoSuggest
                suggestions={hits}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={onSuggestionSelected}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={inputProps}
                renderSectionTitle={this.renderSectionTitle}
                getSectionSuggestions={this.getSectionSuggestions}
            />
        );
    }
}

export default connectAutoComplete(AutoComplete);
