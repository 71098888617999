import React from "react";
import {Link, useNavigate} from "react-router-dom";
import MarriagePickAsset from "../../MarriagePickAssets";
import GeneralButton from "../../buttons/generalButton";
import RightChevron from "../../../assets/images/white-chevron-right.svg";
import PropTypes from 'prop-types';
import "./my-marriage-header.scss";


const MyMarriageCategoryHeader = (props) => {

    let navigate = useNavigate();

    const buttonLink = (slug) => {
        navigate(`/category/${slug}`)
    }

    return (
        <div className="my-marriage_category_header__container">
            <Link className="back-link" to={"/checkup"}>
                <div className="back-button"/>
            </Link>
            <div className="my-marriage_header__content">
                <div className="category-title_container">
                    <h1 className="my-marriage_title">{props.category.title}</h1>
                    <div className="category-icon" style={{backgroundImage: `url(${props.category.icon})`}}/>
                </div>

                <div className="pick-history">
                    <MarriagePickAsset
                        isCurrentScore={props.isCurrentScore}
                        isScoreHistory={props.isScoreHistory}
                        showDrawer={props.showDrawer}
                        user={props.user}
                    />
                </div>
            </div>
        </div>
    )


}

MyMarriageCategoryHeader.propTypes = {
    category: PropTypes.object.isRequired,
    isCurrentScore: PropTypes.number,
    isScoreHistory: PropTypes.arrayOf(PropTypes.object),
    showDrawer: PropTypes.func,
    user: PropTypes.object,
}

export default MyMarriageCategoryHeader;
