//-DEPENDENCIES
import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {useLocation, useParams} from "react-router-dom"
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
//-HELPERS
import CarouselCards from "../../classes/CarouselCards";
import {CreatePerformanceScoreCards} from "./my-marriage-performance-score";
import {UseSystemSettings} from "../../utils/SystemSettings/provider/system-settings";

//-ACTIONS
import {
    getCategoryStats,
    getInfoMessages,
    getMyRecommendedAssets,
    getPerformanceScores
} from "./store/actions";
import {getSingleCoreCategory as getCategory} from "../CoreCategory/store/actions";

//-COMPONENTS
import LoadingScreen from "../../components/LoadingScreen";
import MarriageInfoDrawer from "../../components/Modals/marriage-info-drawer";
import MyMarriageCategoryHeader from "../../components/common/header-component/my-marriage-category-header";
import NewCarousel from "../../components/Carousels/new-carousel";

import "./my-marriage-category.scss";
import {HandleRouteChange} from "../../utils/Segment/segment-methods";


//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getInfoMessages,
            getMyRecommendedAssets,
            getCategoryStats,
            getPerformanceScores,
            getCategory
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    category: appState.CoreCategories.category,
    categoryLoading: appState.CoreCategories.categoryLoading,
    categoryStats: appState.MyMarriage.categoryStats,
    infoMessages: appState.MyMarriage.info,
    lastLoadedUUID: appState.CoreCategories.lastLoadedUUID,
    myPerformanceScores: appState.MyMarriage.scores,
    myPerformanceScoresLoading: appState.MyMarriage.scoresLoading,
    myRecommended: appState.MyMarriage.recommended,
    screenWidth: appState.UserInterface.screenWidth,
    user: appState.User.data,
});

//-START FUNCTIONAL COMPONENT
const MyMarriageCategory = ({
                                category,
                                categoryLoading,
                                categoryStats,
                                getCategory,
                                getCategoryStats,
                                getInfoMessages,
                                getMyRecommendedAssets,
                                getPerformanceScores,
                                infoMessages,
                                lastLoadedUUID,
                                myPerformanceScores,
                                myPerformanceScoresLoading,
                                myRecommended,
                                screenWidth,
                                user
                            }) => {

    let {category_id} = useParams();
    let location = useLocation()
    const {settings} = UseSystemSettings();
    //-LOCAL STATE
    const [isDrawerShown, setIsDrawerShown] = useState(false);
    const [isStyledCourseCards, setIsStyledCourseCards] = useState([]);
    const [isStyledPerformance, setIsStyledPerformance] = useState([]);
    const [isStyledRecommendedCards, setIsStyledRecommendedCards] = useState([]);
    const [isStyledWorksheetCards, setIsStyledWorksheetCards] = useState([]);


    //-USE EFFECTS
    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location && category) {
            HandleRouteChange(location, user, 'page-view', 'My Marriage Category', category.title)
        }
    }, [user, location, category])


    useEffect(() => {
        window.scroll(0, 0)


        if (!myRecommended || lastLoadedUUID !== category_id) {
            getMyRecommendedAssets(category_id)
        }

        if (!infoMessages || lastLoadedUUID !== category_id) {
            getInfoMessages("my-marriage-overall-score")
        }

        if (!categoryStats || lastLoadedUUID !== category_id) {
            getCategoryStats(category_id)
        }

        //TODO: REFINE THIS SAGA WORKER. WE ARE GETTING ALL RESOURCES, TRENDING, COURSES & VIDEOS IN THIS CALL, BUT
        // THEY ARE NOT NEEDED HERE
        if (!category || lastLoadedUUID !== category_id) {
            getCategory(category_id)
        }

        if (!myPerformanceScores || lastLoadedUUID !== category_id) {
            getPerformanceScores(category_id)
        }

    }, [category_id]);


    useEffect(() => {

        if (myRecommended && category && settings) {
            const Card = new CarouselCards(settings, screenWidth)
            setIsStyledRecommendedCards(Card.LandscapeGeneralCard(myRecommended.videos, "video", "MY_MARRIAGE_LAYOUT", "VIDEOS").cards);
            setIsStyledCourseCards(Card.PortraitRecommendedCard(myRecommended.courses, "course", "MY_MARRIAGE_LAYOUT", "COURSES").cards);
            setIsStyledWorksheetCards(Card.WorksheetCard(myRecommended.worksheets, "resource", "MY_MARRIAGE_LAYOUT", "RESOURCES").cards);

        }

    }, [myRecommended, category, settings, screenWidth])

    useEffect(() => {
        if (myPerformanceScores && settings) {
            const cards = CreatePerformanceScoreCards(myPerformanceScores);
            setIsStyledPerformance(cards);
        }

    }, [myPerformanceScores, settings, screenWidth])


    const showDrawer = () => {
        setIsDrawerShown(true);
    }

    return (
        <>
            {
                settings === null || categoryLoading || JSON.stringify(user) === '{}' || categoryStats === null ?
                    <LoadingScreen/>
                    :
                    <div id="my-marriage_category__page">
                        <MyMarriageCategoryHeader
                            isCurrentScore={categoryStats.current.score}
                            isScoreHistory={categoryStats.history}
                            category={category}
                            showDrawer={showDrawer}
                            user={user}
                        />
                        <div className="my-marriage_content">

                            <div className="recommended-container p-l-16 p-r-16">
                                <div className="section-header">
                                    <h3 className="home-title">{settings.MY_MARRIAGE_LAYOUT.VIDEOS.title}</h3>
                                </div>
                                {
                                    isStyledRecommendedCards.length !== 0 &&
                                    <NewCarousel
                                        config={settings.MY_MARRIAGE_LAYOUT.VIDEOS.slider}
                                        cards={isStyledRecommendedCards}
                                        showDots={false}
                                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                        partialVisible={true}
                                    />
                                }
                            </div>

                            <div className="my-courses_container">
                                <div className="section-header">
                                    <h3 className="home-title">{settings.MY_MARRIAGE_LAYOUT.COURSES.title}</h3>
                                </div>
                                {
                                    isStyledCourseCards.length !== 0 &&
                                    <NewCarousel
                                        config={settings.MY_MARRIAGE_LAYOUT.COURSES.slider}
                                        cards={isStyledCourseCards}
                                        showDots={false}
                                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                        partialVisible={false}
                                    />
                                }
                            </div>

                            <div className="worksheet-card_container">
                                <div className="section-header">
                                    <h3 className="home-title">{settings.MY_MARRIAGE_LAYOUT.RESOURCES.title}</h3>
                                </div>
                                {
                                    isStyledWorksheetCards.length !== 0 &&
                                    <NewCarousel
                                        config={settings.MY_MARRIAGE_LAYOUT.RESOURCES.slider}
                                        cards={isStyledWorksheetCards}
                                        showDots={false}
                                        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                        partialVisible={true}
                                    />
                                }
                            </div>
                            {
                                !myPerformanceScoresLoading &&
                                <div className="performance-card_container">
                                    {isStyledPerformance}
                                </div>
                            }


                        </div>
                        {
                            isDrawerShown && (
                                <div className="drawer-container">
                                    <MarriageInfoDrawer
                                        header={infoMessages.message.header}
                                        body={infoMessages.message.body}
                                        closeModal={() => {
                                            setIsDrawerShown(false);
                                        }}
                                    />
                                </div>

                            )
                        }
                    </div>
            }
        </>
    )
}

MyMarriageCategory.propTypes = {
    category: PropTypes.object,
    categoryLoading: PropTypes.bool,
    categoryStats: PropTypes.object,
    getCategory: PropTypes.func,
    getCategoryStats: PropTypes.func,
    getInfoMessages: PropTypes.func,
    getMyRecommendedAssets: PropTypes.func,
    getPerformanceScores: PropTypes.func,
    infoMessages: PropTypes.object,
    lastLoadedUUID: PropTypes.string,
    myPerformanceScores: PropTypes.object,
    myPerformanceScoresLoading: PropTypes.bool,
    myRecommended: PropTypes.object,
    screenWidth: PropTypes.number,
    user: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMarriageCategory)
