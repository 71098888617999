import React from 'react';
import CategoryPill from '../Pills/categoryPill';

import './trendingCard.scss';
import styled from "styled-components";

const RecommendedCard = (props) => {

    const RecommendedItem = styled.div`
        height: ${props.cardHeight}px;
        background-image: url(${props.src})
    `

    return (
        <RecommendedItem className="recommended-card">
            <div className="flex-container">
                {/* <div className="asset-type">
                    Course
                </div> */}
                <h6 className="large-card_title">
                    {props.title}
                </h6>
            </div>
            {/* Need proper category  */}
            {
                props.category && <CategoryPill category={[{title: "Self-Discovery"}]} position="bottom-left"/>
            }

            <div className="asset-quantity">
                {props.lessonNumber} Videos
            </div>
        </RecommendedItem>
    )
}

export default RecommendedCard
