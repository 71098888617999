/**
 * Alert component should contain
 *      - text*
 *      - properties
 */
import React from "react"
import styled from "styled-components"
import * as STYLES from "../../Templates/Global/default-styles"


const AlertElement = styled.div`
        ${STYLES.ALERT}
        ${props => props.properties}
    `

export const Alert = ({data}) => {
    return (
        <AlertElement className={data.className} properties={data.properties}>
            {data.text}
        </AlertElement>

    )
}
