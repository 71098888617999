import * as TYPES from "./actionTypes"

export const getWorksheet = (uuid) => {
    return {
        type: TYPES.GET_SINGLE_WORKSHEET,
        uuid: uuid
    }
}


export const setWorksheet = payload => {
    return {
        type: TYPES.SET_SINGLE_WORKSHEET,
        payload: payload
    }
}


export const worksheetError = error => {
    return {
        type: TYPES.WORKSHEET_ERROR,
        payload: error
    }
}

export const getAllWorksheets = (payload) => {
    return {
        type: TYPES.GET_ALL_WORKSHEETS,
        payload: payload
    }
}

export const setAllWorksheets = payload => {
    return {
        type: TYPES.SET_ALL_WORKSHEETS,
        payload: payload
    }
}

export const worksheetViewed = uuid => {
    return {
        type: TYPES.WORKSHEET_VIEWED,
        uuid: uuid
    }
}
