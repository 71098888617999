import React, { useState, useRef } from 'react';

import ScaleLoader from 'react-spinners/ScaleLoader';
import PropTypes from 'prop-types';
import './challenge-pick-asset.scss';

const ChallengePickAsset = (props) => {

    const [isOverviewShown, setIsOverviewShown] = useState(true);
    const [isDescriptionShown, setIsDescriptionShown] = useState(false);

    let videoResourcePicked = true;

    const overviewSection = useRef(null);
    const descriptionSection = useRef(null);

    const overviewBtn = useRef(null);
    const descriptionBtn = useRef(null);

    let { challengeSteps, bonusSteps, description } = props;

    const toggleSelector = (e) => {
        let className = e.currentTarget.className;
        className = className.split(' ');
        let openElement = document.querySelector('.highlighted');
        let classToOpen = '';
        let openElementArray = [...openElement.classList];

        if (openElementArray.includes(className[0])) {
            return;
        }
        switch (className[0]) {
            case 'overview':
                classToOpen = overviewBtn;
                setIsOverviewShown(true);
                setIsDescriptionShown(false);
                overviewSection.current.classList.remove('hide-section');
                descriptionSection.current.classList.add('hide-section');
                break;
            case 'description':
                classToOpen = descriptionBtn;
                setIsOverviewShown(false);
                setIsDescriptionShown(true);
                overviewSection.current.classList.add('hide-section');
                descriptionSection.current.classList.remove('hide-section');
                break;
        }
        //set other two classes to closed
        openElement.classList.remove('highlighted');
        classToOpen.current.classList.add('highlighted');
    }

    // console.log('challenge pick asset', props);

    return (
        <div className="challenge-pick-asset-container">
            <div className="selector-container">
                <div className="selector">
                    <div className="overview highlighted" ref={overviewBtn} onClick={toggleSelector}>
                        Overview
                    </div>
                    <div className="description" ref={descriptionBtn} onClick={toggleSelector}>
                        Description
                    </div>
                </div>

                {challengeSteps.length > 0 && (
                    <div className="overview-section" ref={overviewSection}>
                        {challengeSteps}
                        {bonusSteps}
                    </div>
                )}
                {description != undefined && (
                    <div className="description-section hide-section" ref={descriptionSection}>
                        <div className='description-text' dangerouslySetInnerHTML={{__html:`${description}`}}></div>
                    </div>
                )}

                {/* {videos.length === 0 && (
                    <div className="trending-height">
                        <div className="trending-loader-container">
                            <ScaleLoader height="10px" color="#000"/>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    )
}

ChallengePickAsset.propTypes = {
    challengeSteps: PropTypes.arrayOf(PropTypes.element).isRequired,
    bonusSteps: PropTypes.arrayOf(PropTypes.element),
    description: PropTypes.string, 
}

export default ChallengePickAsset;
