import React, {useState, useEffect} from 'react';
import Accordion from '../../../components/base/accordionComponent/accordion';
import CustomLoader from '../../../components/CustomLoader';
import {HandleRouteChange, IncrementPeopleProperties, Track} from "../../../utils/Segment/segment-methods";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getFAQ} from "../store/actions";

import PropTypes from 'prop-types';

import './faq-12-2022.scss';
import ProfileLayout from "../ProfileLayout";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getFAQ,
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    FAQ: appState.AccountSettings.FAQ,
    user: appState.User.data,
});

const FaqContent = ({user, FAQ, getFAQ}) => {
    const pageTitle = "FAQ's"
    const [isGeneral, setIsGeneral] = useState();
    const [isCheckup, setIsCheckup] = useState();
    const [isBilling, setIsBilling] = useState();


    useEffect(() => {
        if (FAQ === null) {
            getFAQ();
        }
        sortFAQ(FAQ);
    }, [FAQ])


    useEffect(() => {
        if (JSON.stringify(user) !== '{}') {
            HandleRouteChange('/account/faqs', user, 'page-view', pageTitle)
        }
    }, [user])


    const sortFAQ = (array) => {
        if (array === null) {
            return;
        }
        let generalArray = [];
        let checkupArray = [];
        let billingArray = [];

        array.forEach((e) => {
            if (e.category === "General") {
                generalArray.push(e);
            }
            if (e.category === "Account & Billing") {
                billingArray.push(e);
            }
            if (e.category === "Marriage365 Checkup") {
                checkupArray.push(e);
            }
        });
        setIsGeneral(generalArray);
        setIsCheckup(checkupArray);
        setIsBilling(billingArray);
    }

    function ContactSupport() {
        IncrementPeopleProperties('support_contact').then(() => {
            window.open('https://marriage365.com/contact');
        })
    }

    function TrackOpenedQuestion(question) {
        IncrementPeopleProperties('faq_viewed').then(() => {
            Track('faq_viewed', {title: question}).then(() => {

            })
        })


    }

    const backToSidebar = () => {
        let width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;

        let sidebar = document.getElementById("account-sidebar");
        let displayArea = document.getElementById("account-display_container");

        if (width <= 480) {
            sidebar.style.display = "none";
            displayArea.style.display = "block";
        } else {
            sidebar.style.display = "block";
            displayArea.style.display = "block";
        }
    }

    return (
        <ProfileLayout title="FAQ's" content="component-container">
            <div id="account-faq_2022">
                <div className="back-link pointer" onClick={backToSidebar}>
                    <div className="back-button"/>
                </div>
                <h1 className="font-bold font-primary">FAQ's</h1>
                {/* <div className="small mb-3">If you don't see the answer to your question</div>
                <div onClick={ContactSupport} className='contact-support_container pointer'>
                    <div className="contact-support_button">Contact Support</div>
                </div> */}
                <div className="faq-display">


                    {isBilling !== undefined ? (
                        <div className="account-faq_container">
                            <h6 className="faq-title font-bold mt-3 mb-3">General</h6>
                            {isGeneral.map((e, i) => (
                                <Accordion trackClick={TrackOpenedQuestion} question={e.question} answer={e.answer}
                                           key={Math.random(100)}/>
                            ))
                            }
                            <h6 className="faq-title font-bold mb-3">Marriage365 Checkup</h6>
                            {isCheckup.map((e, i) => (
                                <Accordion trackClick={TrackOpenedQuestion} question={e.question} answer={e.answer}
                                           key={Math.random(100)}/>
                            ))
                            }
                            <h6 className="faq-title font-bold mb-3">Account & Billing</h6>
                            {isBilling.map((e, i) => (
                                <Accordion trackClick={TrackOpenedQuestion} question={e.question} answer={e.answer}
                                           key={Math.random(100)}/>
                            ))
                            }
                        </div>
                    ) : (
                        <div className="full-size_loader">
                            <div className="loader-container">
                                <CustomLoader/>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </ProfileLayout>

    )
}

FaqContent.propTypes = {
    user: PropTypes.object,
    FAQ: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqContent);
