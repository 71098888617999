/**
 * Return the core category UUID by slug
 * @param core_categories
 * @param slug
 * @returns {*}
 */
export function GetUuidFromSlug(core_categories, slug) {
    const category = core_categories.filter(c => (
        c.meta.slug === slug
    ))[0]
    return category.uuid;
}
