/**
 * Remove or Add the video to the watchlist depending on current watchlist status
 * --
 * @param asset_id
 * @param isOnWatchlist
 * @param addFunction
 * @param removeFunction
 * @param assetType
 * @param properties
 */
export const adjustWatchlist = (asset_id, isOnWatchlist, addFunction, removeFunction, assetType) => {
    isOnWatchlist ? removeFunction({asset_id, assetType}) : addFunction({asset_id, assetType})
}

