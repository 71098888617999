import React, {useState, useEffect, useRef, Fragment} from 'react';
import CategoryCard from '../components/cards/categoryCard';

import SearchResultCard from '../components/cards/searchResultCard';
import RefinementPill from '../components/Pills/refinementPill';
import RefinementList from '../components/Pills/refinementList';
import axios from 'axios';

import algoliasearch from 'algoliasearch/lite';
import {
    InstantSearch,
    Index,
    Configure,
    connectSearchBox,
    Hits,
    connectRefinementList,
    SearchBox,
    ClearRefinements
} from 'react-instantsearch-dom';

const searchClient = algoliasearch(
    'UV8941WIXZ',
    '7d375c1399d29b1b8830b5a8d26dc1ea'
);

import './search-page.scss';
import MobileFooter from "../components/common/footer-component/mobile-footer";
import LoadingScreen from "../components/LoadingScreen";
import {bindActionCreators} from "redux";
import {useLocation} from "react-router-dom";
import {HandleRouteChange} from "../utils/Segment/segment-methods";
import {connect} from "react-redux";


//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data
});

const SearchPage = ({user}) => {
    let location = useLocation()
    const [isCategoryCards, setIsCategoryCards] = useState();
    const [isHitsShown, setIsHitsShown] = useState(false);
    const [isRecentSearches, setIsRecentSearches] = useState();
    const [isRecentArray, setIsRecentArray] = useState();
    const [isPopularSearches, setIsPopularSearches] = useState();

    const [isRecentShown, setIsRecentShown] = useState(false);
    const [isPopularShown, setIsPopularShown] = useState(false);
    const [isCategoryCardsShown, setIsCategoryCardsShown] = useState(true);
    const [isSearchResultShown, setIsSearchResultShown] = useState(false);

    const [isSearchText, setIsSearchText] = useState();


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Search')
        }
    }, [user, location])

    useEffect(() => {
        getSearchData();
        getRecentSearchData();
        getPopularSearchData();
    }, []);

    const CustomRefinementList = connectRefinementList(RefinementList);

    const getSearchData = () => {
        axios.get('/all-core-categories').then((resp) => {
            // console.log('search response', resp);
            styleCategoryCards(resp.data);
        });
    }

    const getRecentSearchData = () => {
        axios.get('/recent-search').then((resp) => {
            // console.log('recent search', resp);
            setIsRecentArray(resp.data.data);
            styleRecentSearches(resp.data.data)
        })

    }

    const getPopularSearchData = () => {
        axios.get('/popular-search').then((resp) => {
            // console.log('recent search', resp);
            stylePopularSearches(resp.data.data);
        })
    }

    const styleCategoryCards = (array) => {
        array = array.sort((a, b) => a.title !== b.title ? a.title < b.title ? -1 : 1 : 0);
        let tempArray = [];

        array.forEach((item) => {

            let styledCategoryCard = <CategoryCard icon={item.icon_svg} categoryTitle={item.title}
                                                   backgroundColor={item.meta.backgroundColor.hex} location={"menu"}
                                                   link={item.meta.slug} key={Math.random(100)}
                                                   user={user}/>
            tempArray.push(styledCategoryCard);
        })
        setIsCategoryCards(tempArray);
    }

    const styleRecentSearches = (array) => {
        // need to search on click and have a button to delete keyword
        let styledArray = [];
        let styledRecent;

        // on click these should fill the search bar with the text
        array.forEach((e, i) => {
            styledRecent = (
                <div className="recent-search_container">
                    <div className='recent-search_text' onClick={populateSearch}>
                        {e}
                    </div>
                    <div className="delete-recent" onClick={(e) => {
                        let recentSearchArray = array;

                        // console.log('recent search array', recentSearchArray);
                        let termText;

                        if (e.current === undefined) {
                            termText = e.currentTarget.parentElement;
                            termText = termText.children[0].innerText;

                        }
                        let deleteObj = {
                            search_term: termText,
                        }
                        let filteredArray = recentSearchArray.filter(e => e != termText);
                        // console.log('filtered array', filteredArray);
                        styleRecentSearches(filteredArray);

                        // console.log('search term to delete', deleteObj);
                        // axios.delete('/recent-search', deleteObj).then((resp) => {
                        //     console.log('resp to delete', resp);
                        // })
                        // setIsRecentSearches();
                    }
                    }></div>
                </div>);
            styledArray.push(styledRecent);
        });
        setIsRecentSearches(styledArray)

    }

    const stylePopularSearches = (array) => {
        let styledArray = [];
        let styledRecent;

        // on click these should fill the search bar with the text

        array.forEach((e, i) => {
            styledRecent = (
                <div className="popular-search_container" onClick={populateSearch}>
                    <div className='popular-search_text'>
                        {e}
                    </div>

                </div>);
            styledArray.push(styledRecent);
        });
        setIsPopularSearches(styledArray)
    }

    const showRecent = () => {
        // debugger;
        if (isRecentShown || isHitsShown) {
            return;
        }

        // console.log('show recent ran');

        setIsCategoryCardsShown(false);
        setIsRecentShown(true);
        setIsPopularShown(true);
        // width of the input should shrink and cancel button appear
        let searchBar = document.getElementById('search-form');
        searchBar.style.width = "87%";
        let cancelButton = document.getElementById('cancel-button');
        cancelButton.style.display = "block";
        cancelButton.style.zIndex = "6";
    }
    const cancelSearch = () => {
        // Clear search
        let searchBar = document.getElementById('search-form');
        let searchInput = searchBar.children[0].children[0].children[0].children[0].children[0];
        searchInput.value = '';
        searchInput.blur();
        // return search header to original state
        searchBar.style.width = "100%";
        let cancelButton = document.getElementById('cancel-button');
        cancelButton.style.display = "none";
        cancelButton.style.zIndex = "1";

        setIsCategoryCardsShown(true);
        setIsRecentShown(false);
        setIsPopularShown(false);
        setIsHitsShown(false);

        let resetButton = document.getElementsByClassName('ais-SearchBox-reset');
        // console.log('submit-button', resetButton);
        resetButton = resetButton[0];
        resetButton.click();
        let hitContainer = document.getElementById('hit-container');
        hitContainer.style.display = "none";
        // take focus off the input to prevent searches overlapping categories
        // need to use the SearchBox Reset function
        // and the clear all refinements function with algolia

    }

    const checkInput = () => {
        // console.log('check input ran');
        setIsCategoryCardsShown(false);
        setIsRecentShown(false);
        setIsPopularShown(false);
        setIsSearchResultShown(true);
        setIsHitsShown(true);
        let hitContainer = document.getElementById('hit-container');
        hitContainer.style.display = "block";
        let searchBar = document.getElementById('search-form');
        searchBar.style.width = "87%";
        let cancelButton = document.getElementById('cancel-button');
        cancelButton.style.display = "block";
        cancelButton.style.zIndex = "6";
    }

    const clearAllRecent = () => {
        //clear all recent searches on backend

        // find recent searches and delete elements
        axios.delete('/all-recent-search').then((resp) => {
            // console.log('resp to delete all', resp);
        });
        setIsRecentSearches([]);
    }

    const populateSearch = (e) => {
        // debugger;
        let searchTerm = e.currentTarget.innerText;
        let searchBar = document.getElementById('search-form');
        let searchInput = searchBar.children[0].children[0].children[0].children[0].children[0];

        // console.log('search input', searchInput);
        // try typing in the message

        searchInput.value = searchTerm;
        // var word = searchTerm;
        // var n = 0;
        // var x = searchInput;

        // (function loop() {
        //     x.value += word[n];
        //     if (++n < word.length) {
        //         setTimeout(loop, 300);
        //     }
        // })();

        // console.log('search Input', searchInput);
        let submitButton = document.getElementsByClassName('ais-SearchBox-submit');
        submitButton = submitButton[0];
        submitButton.click();
        setIsSearchText(searchTerm)
        // see if you can submit the form with the value
    }

    const startSearch = (text) => {
        // use this to save the term as the recent search

        // console.log('Start Search Ran', text);

    }


    // const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
    //     //the refine function is whats needed to restart the search

    //     <form noValidate action="" role="search">
    //       <input
    //         type="search"
    //         value={currentRefinement}
    //         onChange={event => refine(event.currentTarget.value)}
    //       />
    //       <button onClick={() => refine('')}>Reset query</button>
    //       {isSearchStalled ? 'My search is stalled' : ''}
    //     </form>
    //   );

    //   const CustomSearchBox = connectSearchBox(SearchBox);

    // console.log('isRecentShown', isRecentShown);

    if (isRecentSearches && isPopularSearches) {
        return (
            <>
                <div id="search-page">
                    <div className='search-header'>
                        <div className='search-input_container'>
                            <div className='search-icon'></div>

                            <Fragment>

                                <div id="search-form" onChange={checkInput}>
                                    <div className="form-group">


                                        <InstantSearch
                                            indexName="assets"
                                            searchClient={searchClient}

                                        >
                                            <Configure hitsPerPage={5}/>
                                            <div className="search-container" onFocus={showRecent}>
                                                {/* <CustomSearchBox */}
                                                <SearchBox
                                                    translations={{
                                                        placeholder: "Search by topic or keyword",

                                                    }}
                                                    // fires right before search is initiated
                                                    // onSubmit={(e)=>{
                                                    //     e.preventDefault();
                                                    //     startSearch(isSearchText)}}

                                                />
                                                <div className="white-cover"></div>
                                            </div>

                                            <Index indexName="assets"/>
                                            <div id='hit-container'>
                                                <CustomRefinementList

                                                    attribute="index_type"
                                                    defaultRefinement={['video', 'course', 'challenge', 'worksheet']}

                                                />
                                                <Hits

                                                    hitComponent={SearchResultCard}/>
                                            </div>


                                        </InstantSearch>

                                    </div>
                                </div>

                            </Fragment>


                            {/* <input className='search-input' placeholder="Search by topic or keyword" ref={searchBar} onFocus={showRecent} onChange={startSearch} /> */}

                        </div>

                        <div id='cancel-button' onClick={cancelSearch}>Cancel</div>
                    </div>
                    <div className="search-results">
                        {/* {isSearchResultShown && (
                        <Fragment>
                            <div className="search-button_container">
                                <div className="search-button_category">All</div>
                                <div className="search-button_category">Videos</div>
                                <div className="search-button_category">Worksheets</div>
                                <div className="search-button_category">Courses</div>
                                <div className="search-button_category">Challenges</div>
                            </div>


                        </Fragment>
                   )} */}


                    </div>
                    {isCategoryCardsShown && (
                        <div className="search-category_menu">
                            <div className='search-title'>Category</div>
                            {isCategoryCards}
                        </div>
                    )}
                    {isRecentShown && isRecentSearches.length !== 0 && (
                        <div className="recent-searches">
                            <div className="recent-title_container">
                                <div className="recent-title">Recent Searches</div>
                                <div className="clear-all" onClick={clearAllRecent}>Clear All</div>
                            </div>
                            {isRecentSearches}
                        </div>
                    )}
                    {isPopularShown && (
                        <div className='popular-searches'>
                            <div className="popular-title_container">
                                <div className="popular-title">Popular Searches</div>
                            </div>
                            {isPopularSearches}
                        </div>
                    )}


                </div>
                <MobileFooter/>
            </>
        )
    } else {
        return (<LoadingScreen/>)
    }


}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage)
