import {Link} from "react-router-dom"
import GeneralAssetCard from "../components/cards/generalAssetCard"
import React from "react"
import {getCardHeight__universal} from "../utils/responsive-card-height"
import RecommendedCard from "../components/cards/recommended-card";
import WorksheetCard from "../components/cards/worksheetCard";

export default class CarouselCards {

    constructor(settings, screenWidth) {
        this.settings = settings
        this.screenWidth = screenWidth
    }

    /**
     *
     * @param assets
     * @param asset_type
     * @param LAYOUT_KEY
     * @param INDEX_KEY
     * @param progressPercentage
     * @param categoryTitle
     * @returns {{cards: *[]}}
     * @constructor
     */
    LandscapeGeneralCard(assets = [],
                         asset_type = 'video',
                         LAYOUT_KEY,
                         INDEX_KEY,
                         progressPercentage = null,
                         categoryTitle = null) {

        let cards = [], card
        const cardHeight = getCardHeight__universal(LAYOUT_KEY, INDEX_KEY, this.settings, this.screenWidth)

        if (asset_type === 'video') {
            asset_type = 'watch'
        }

        assets.forEach((asset) => {
            card = (
                <Link to={`/${asset_type}/${asset.asset_id}`} key={Math.random(100)}>
                    <GeneralAssetCard
                        progressPercentage={progressPercentage}
                        category={categoryTitle}
                        duration={asset.meta.duration}
                        title={asset.meta.title}
                        src={asset.meta.thumbnail}
                        assetType={asset_type}
                        cardHeight={cardHeight}
                    />
                </Link>)
            cards.push(card)
        })

        return {
            "cards": cards
        }
    }


    /**
     *
     * @param assets
     * @param asset_type
     * @param LAYOUT_KEY
     * @param INDEX_KEY
     * @param progressPercentage
     * @param categoryTitle
     * @returns {{cards: *[]}}
     * @constructor
     */
    PortraitRecommendedCard(assets = [],
                            asset_type,
                            LAYOUT_KEY,
                            INDEX_KEY,
                            progressPercentage = null,
                            categoryTitle = null) {
        let cards = [], card
        const cardHeight = getCardHeight__universal(LAYOUT_KEY, INDEX_KEY, this.settings, this.screenWidth)

        assets.forEach((asset) => {
            card = (
                <Link to={`/${asset_type}/${asset.uuid}`} key={Math.random(100)}>
                    <RecommendedCard
                        category={categoryTitle}
                        lessonNumber={asset.videos}
                        title={asset.title}
                        src={asset.portrait_thumbnail}
                        assetType={asset_type}
                        cardHeight={cardHeight}
                    />
                </Link>);
            cards.push(card);
        });

        return {
            "cards": cards
        }
    }

    /**
     *
     * @param assets
     * @param asset_type
     * @param LAYOUT_KEY
     * @param INDEX_KEY
     * @returns {{cards: *[]}}
     * @constructor
     */
    WorksheetCard(assets = [],
                  asset_type,
                  LAYOUT_KEY,
                  INDEX_KEY) {
        let cards = [], card
        const cardHeight = getCardHeight__universal(LAYOUT_KEY, INDEX_KEY, this.settings, this.screenWidth)

        assets.forEach((asset) => {
            card = (
                <Link to={`/read/${asset.assets.uuid}`} key={Math.random(100)}>
                    <WorksheetCard
                        assetType={asset.assets.category}
                        cardHeight={cardHeight}
                        src={asset.assets.portrait_thumbnail}
                        title={asset.assets.title}
                    />
                </Link>);
            cards.push(card);
        });
        return {
            "cards": cards
        }
    }

} // END CLASS

