
import React from 'react';
import PropTypes from 'prop-types';
import './checkup-bar.scss';

const CheckupBar = (props) => {

    return (
        <div className='checkup-bar_container'>
            <div className="current-progress">{props.currentQuestion}/{props.totalQuestions}</div>
            <div className="bar-container">
                <div className="bar-progress" style={{width:`${props.percentage}%`}}>

                </div>
            </div>
        </div>
    )
}

CheckupBar.propTypes = {
    currentQuestion: PropTypes.number.isRequired,
    totalQuestions: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
}

export default CheckupBar;