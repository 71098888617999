import * as TYPES from "./actionTypes"

export const loginUser = (user, navigate) => {
    return {
        type: TYPES.LOGIN_USER,
        payload: {user, navigate},
    }
}

export const loginSuccess = (user, navigate, utm) => {
    return {
        type: TYPES.LOGIN_SUCCESS,
        payload: {user, navigate, utm},
    }
}

export const logoutUser = () => {
    return {
        type: TYPES.LOGOUT_USER,
        payload: {}
    }
}

export const logoutUserSuccess = () => {
    return {
        type: TYPES.LOGOUT_USER_SUCCESS,
        payload: {},
    }
}

export const loginError = error => {
    return {
        type: TYPES.LOGIN_ERROR,
        payload: error,
    }
}



