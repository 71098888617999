import { takeEvery, put, call } from 'redux-saga/effects';
import * as TYPES from './actionTypes';
import * as ACTIONS from './actions';
import {
    SUBSCRIPTION_PLANS,
} from '../../../global/constants';

// Include Both Helper File with needed methods
import {
    applyCouponCode,
    cancelSubscription,
    cancellationOffer,
    changeMembershipFrequency,
    checkCouponCode,
    getContinueWatching,
    getExtras,
    getFAQ,
    getHistory,
    getInfoDrawer,
    getMembershipPerks,
    getNotificationPreferences,
    getSubscriptionPlanType,
    postSurvey,
    removeFromHistory,
    restartSubscription,
    updateNotificationPreferences,
} from '../../../helpers/backend-helper';

import { setUser, setUserSuccessMessage, setUserError } from '../../../store/user/actions';

function* cancelUserSubscription({ payload: { payload, navigate } }) {
    try {
        const response = yield call(cancelSubscription, payload);
        // response is the user resource from the server
        // set the user object with the updated subscription from BT
        yield put(setUser(response.user));

        // set the subscription message in redux
        yield put(ACTIONS.changeSubscriptionSuccess(response.message));

        // navigate('/account/', {replace: true})
    } catch (error) {
        yield put(ACTIONS.changeSubscriptionFail(error));
    }
}

function* restartUserSubscription({ payload }) {
    try {
        const response = yield call(restartSubscription, payload);
        // response is the user resource from the server
        // set the user object with the updated subscription from BT
        yield put(setUser(response.user));

        // set the subscription message in redux
        yield put(ACTIONS.updateSubscriptionServerResponse(response.message));

        // navigate(route, {replace: true})
    } catch (error) {
        yield put(ACTIONS.updateSubscriptionServerResponse(error.message));
    }
}

function* swapMembership({ payload }) {
    try {
        const response = yield call(changeMembershipFrequency, payload);
        yield put(setUser(response.user));

        yield put(ACTIONS.updateSubscriptionServerResponse(response.message));
    } catch (error) {
        yield put(ACTIONS.updateSubscriptionServerResponse(error.message));
    }
}

function* loadHistory({ payload }) {
    try {
        const response = yield call(getHistory, payload);
        yield put(ACTIONS.setHistory(response.data));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* removeVideoFromHistory({ uuid, assetType }) {
    try {
        const response = yield call(removeFromHistory, { uuid, assetType });
        // update video redux state
        yield put(setUserSuccessMessage(response.message));
    } catch (e) {
        console.log('to sentry: ', e);
        yield put(setUserError(e.message));
    }
    // yield put(ACTIONS.addToHistoryComplete())
}

function* loadContinueWatching({ payload }) {
    try {
        const response = yield call(getContinueWatching, payload);
        // console.log('continue response ', response);
        yield put(ACTIONS.setContinueWatching(response.data));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* loadNotificationPreferences() {
    try {
        const response = yield call(getNotificationPreferences);
        yield put(ACTIONS.setNotificationPreferences(response));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* postNotificationPreferences({ payload }) {
    try {
        const response = yield call(updateNotificationPreferences, payload);
        const updatedPreferences = yield call(getNotificationPreferences);
        yield put(ACTIONS.setNotificationPreferences(updatedPreferences));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* loadMembershipPerks() {
    try {
        const response = yield call(getMembershipPerks);
        yield put(ACTIONS.setMembershipPerks(response));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* loadFAQ() {
    try {
        const response = yield call(getFAQ);
        yield put(ACTIONS.setFAQ(response));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* loadNakedConversations(payload) {
    try {
        const response = yield call(getExtras, payload.payload);
        yield put(ACTIONS.setNakedConversations(response));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* loadMemberStories(payload) {
    try {
        const response = yield call(getExtras, payload.payload);
        yield put(ACTIONS.setMemberStories(response));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* postCoupon(payload) {
    // console.log("post coupon ran", payload);
    try {
        const response = yield call(checkCouponCode, payload.payload);
        // console.log("check coupon code response", response);
        yield put(ACTIONS.setAccountSettingsMessage({ message: response.message, type: 'success' }));
        yield put(ACTIONS.setCoupon({ code: response.coupon_code, value: response.value, amount: response.payment_amount }));
    } catch (e) {
        yield put(ACTIONS.setAccountSettingsMessage({ message: e.message, type: 'danger' }));
    }
}

function* acceptMemberCoupon({ payload }) {
    try {
        const response = yield call(applyCouponCode, payload);
        yield put(ACTIONS.updateSubscriptionServerResponse(response.message));
        yield put(ACTIONS.setCoupon(null));
    } catch (e) {
        yield put(ACTIONS.updateSubscriptionServerResponse(e.message));
        yield put(ACTIONS.setCoupon(null));
    }
}

function* getRemoveSecondaryInfoDrawer() {
    try {
        const response = yield call(getInfoDrawer);
        // console.log("infoDrawer response", response);
        yield put(ACTIONS.setInfoDrawer(response));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* getCancellationOffer({ payload }) {
    try {
        const response = yield call(cancellationOffer, payload);
        yield put(ACTIONS.setCancellationOffer(response.offers));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* loadSubscriptionPlanType() {
    try {
        const response = yield call(getSubscriptionPlanType, 3);
        yield put(ACTIONS.setSubscriptionPlanType(response));
    } catch (e) {
        console.log('to sentry: ', e);
    }
}

function* AccountSettingsSaga() {
    yield takeEvery(TYPES.APPLY_MEMBER_COUPON, acceptMemberCoupon);
    yield takeEvery(TYPES.CANCEL_SUBSCRIPTION, cancelUserSubscription);
    yield takeEvery(TYPES.GET_CANCELLATION_OFFER, getCancellationOffer);
    yield takeEvery(TYPES.GET_CONTINUE_WATCHING, loadContinueWatching);
    yield takeEvery(TYPES.GET_FAQ, loadFAQ);
    yield takeEvery(TYPES.GET_HISTORY, loadHistory);
    yield takeEvery(TYPES.GET_INFO_DRAWER, getRemoveSecondaryInfoDrawer);
    yield takeEvery(TYPES.GET_MEMBERSHIP_PERKS, loadMembershipPerks);
    yield takeEvery(TYPES.GET_MEMBER_STORIES, loadMemberStories);
    yield takeEvery(TYPES.GET_NAKED_CONVERSATIONS, loadNakedConversations);
    yield takeEvery(TYPES.GET_NOTIFICATION_PREFERENCES, loadNotificationPreferences);
    yield takeEvery(TYPES.GET_SUSBSCRIPTION_PLAN_TYPE, loadSubscriptionPlanType);
    yield takeEvery(TYPES.POST_COUPON_CODE, postCoupon);
    yield takeEvery(TYPES.REMOVE_VIDEO_FROM_HISTORY, removeVideoFromHistory);
    yield takeEvery(TYPES.RESTART_SUBSCRIPTION, restartUserSubscription);
    yield takeEvery(TYPES.SWAP_MEMBERSHIP, swapMembership);
    yield takeEvery(TYPES.UPDATE_NOTIFICATION_PREFERENCES, postNotificationPreferences);
}

export default AccountSettingsSaga;
