import React from 'react';
import PropTypes from 'prop-types';
import './pills.scss';
import {Track} from "../../utils/Segment/segment-methods";
import {useNavigate} from "react-router-dom";

const AllPill = (props) => {
    let navigate = useNavigate()
    // On click go to the section, use props to know where
    /**
     *
     * @param {String} link
     * @param {Object} properties
     */
    function RouteUser(link, properties) {
        Track(`Clicked All Button`, properties).then(() => {
            navigate(`${link}`)
        })
    }


    return (
        <div className={'pointer all-pill_container'}
             onClick={() => RouteUser(props.link, {
                 Link: props.link,
                 Slider: props.slider,
             })}>
            <div className="all-pill_text">
                All
            </div>
            <div className="chevron-right"/>
        </div>
    )
}

AllPill.propTypes = {
    link: PropTypes.string.isRequired,
}

export default AllPill;
