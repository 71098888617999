import React from 'react';
import styled from 'styled-components';
import { CommonColor, } from '../global/colors';
const defaultProps = {
    className: undefined,
};
const Container = styled.div `
    background-color: ${CommonColor.white};
    border-radius: 8px;
    box-shadow: 0px 3px 12px 0px rgba(57, 57, 57, 0.25);
    padding: 24px;
    width: 100%;
`;
function Card({ className, children, }) {
    return (React.createElement(Container, { className: className }, children));
}
Card.defaultProps = defaultProps;
export default Card;
