import * as TYPES from "./actionTypes"

export const loadSystemSettings = () => {
    return {
        type: TYPES.LOAD_SYSTEM_SETTINGS
    }
}

export const systemSettingsSuccess = (payload) => {
    return {
        type: TYPES.LOAD_SYSTEM_SETTINGS_SUCCESS,
        payload: payload
    }

}
