import React from 'react'
import {AddActiveClassToElement} from "../../../utils/DomUtilities";
import SidebarMenu from "../Data/SidebarMenu"
import {MenuSpacer, MenuItemLink, MenuItem, MenuList, MenuLogoutButton} from "./Components/menu-items"
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {logoutUser} from "../../Auth/Login/store/actions";

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            logoutUser,
        },
        dispatch
    )
};
const mapStateToProps = appState => ({
});
function Menu({logoutUser}) {
    // variables for Sidebar
    let menu = {
        classes: {
            main: 'profile-menu',
            item: 'profile-menu-item',
            link: 'profile-menu-link',
            toggle: 'profile-menu-toggle',
            active: 'active',
            current: 'current-page'
        },
    };


    React.useEffect(() => {
        AddActiveClassToElement(`.${menu.classes.link}`, menu.classes.active, menu.classes.main, menu.classes.item);
    }, [null])



    // todo: update the SidebarMenu loop when items in sidebar menu can be something other than an item or spacer
    return (
        <MenuList>
            {
                SidebarMenu.items.map((item, index) => (
                    item.type === 'item' ?
                        <MenuItem key={index}>
                            <MenuItemLink text={item.text} to={item.link}/>
                        </MenuItem>
                        :
                       item.type === 'logout' ?
                           <MenuItem key={index}>
                               <MenuLogoutButton text={item.text} action={logoutUser}/>
                           </MenuItem>
                            :
                            <MenuSpacer key={index} className={item.classNames}/>
                ))
            }
        </MenuList>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
