import Styled from 'styled-components';

const LoginPage = Styled.div`
    .login-carousel_mobile {
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
        }

        .login-carousel_container {
            .react-multi-carousel-dot-list {
                bottom: 190px;

                .react-multi-carousel-dot button {
                    background-color: #fff;
                    width: 12px;
                    height: 12px;
                    border: none;
                }

                .react-multi-carousel-dot--active button {
                    background-color: #2D5F62;
                    width: 12px;
                    height: 12px;
                    padding-bottom: 0px;
                    border: none;
                }
            }
        }

        .button-container {
            padding: 24px 16px 40px;


            .login-forgot_password {
                text-align: center;
                font-family: $primary-font-bold;
                font-size: 18px;
                color: #2D5F62;
                margin: 14px auto 6px;
            }

            .mobile-partner-link {
                font-size: 14px;
                display: inline-block;
                margin: 0 auto;
                position: relative;
                text-align: center;
                width: 100%;
                a {
                    color: #2D5F62;
                    font-family: $primary-font-bold;
                }
            }
        }
    }

    .login-mobile_view {
        @media screen and (max-width: 768px) {
            display: block;
        }
    }

`;

export {LoginPage}
