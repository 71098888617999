import React from 'react';
import Lottie from 'react-lottie';
import loaderJson from '../../@lottie/loader.json';
import PropTypes from 'prop-types';

const M365Loader = (props) => {
    const presentAnimation = {
        loop: true,
        autoplay: true,
        animationData: loaderJson,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Lottie options={presentAnimation}
                height={props.height ?? 60}
                width={props.width ?? 60}
        />
    )
}

M365Loader.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number,
}

export default M365Loader
