import React from 'react';

import './pop-quiz-button.scss';

const PopQuizButton = (props) => {

    return (
        <div id='pop-quiz_button' onClick={()=>{
            props.toggleQuiz();
            props.trackClick();
        }}></div>
    )
}

export default PopQuizButton;