/**
 * Template Name: Page Renderer
 *
 * Description: Page renderer to consume json objects to build pages
 * Author: Brandon Estrella
 * Version: 1.0
 * Text Domain: Page renderer to consume json objects to build pages
 *
 */
import React, {useEffect, useState} from "react"
import Components from "../Helpers/componentHelper"
import {getComponentData} from "../Helpers/backend-helper";
import {useParams} from "react-router-dom";
import "../Store"

/**
 * FOR DEVELOPMENT PURPOSES ONLY
 * --
 * Ensure the _ACTIVE_TEST matches the param at the end of the URL
 * ie: http://localhost:8000/checkout/b02399e1-756e-4d16-a9a2-0d65f0336ea6/registration-campaign-one
 * -
 * Update _DEVELOPMENT to true
 *
 * POINT THE TEMPLATE STRUCTURE TO THE STRUCTURE FILE IN DEVELOPMENT
 */

const _DEVELOPMENT = false;
const _ACTIVE_TEST = "page-renderer"
import TEMPLATE_STRUCTURE from "../../pages/Settings/renew-subscription-data.json"
/**
 * END DEVELOPMENT IMPORTS
 */



/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PageRenderer = (props) => {
    const [templateResponse, setTemplateResponse] = useState(null)
    // const {page_slug} = useParams()
    const [error, setError] = useState(null)

    useEffect(() => {

        const controller = new AbortController();
        // Load the data from the API
        LoadDataFromAPI();

        return () => controller.abort();

    }, [])

    const LoadDataFromAPI = async () => {
        if (_DEVELOPMENT) {
            setTemplateResponse(TEMPLATE_STRUCTURE);
        } else {
            await getComponentData(props.pageID).then(r => {
                setTemplateResponse(r.component)
            }).catch(er => {
                setError(er.message)
            })
        }
    }

    return (
        <React.Fragment>
            <>
                {
                    error &&
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <a href="https://marriage365.com" target="_self"><u>Return Home</u></a>
                            </div>
                        </div>
                    </div>
                }
            </>
            {
                templateResponse &&
                templateResponse.map(block => {
                    block.activeTest = _ACTIVE_TEST;
                    return Components(block)
                })
            }
        </React.Fragment>

    )
};

export default PageRenderer;
