/**
 * UnorderedList component should contain an array of items
 */
import React from "react"
import styled from "styled-components"
import {GetAttributes} from "../../HotFixes";
import {Link} from "react-router-dom";

const Img = styled.img`
        ${props => props.properties}
    `


export const Image = ({block, data}) => {
    // console.log('image data: ', data)
    return (
        <>
            {
                GetAttributes('link', data) ?
                    <a href={data.attributes.link.href} target={data.attributes.link.target}>
                        <Img src={data.src} {...data.attributes}
                             properties={data.properties}
                             id={GetAttributes('id', block)}
                             className={GetAttributes('class', block)}
                        />
                    </a>
                    :
                    <Img src={data.src} {...data.attributes}
                         properties={data.properties}
                         id={GetAttributes('id', block)}
                         className={GetAttributes('class', block)}
                    />
            }
        </>
    )
}




