import ActionType from './actionTypes';
const initialState = {
    error: '',
    isFetching: false,
};
const registerUserWithPartnerCode = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.REGISTER_USER_WITH_PARTNER_CODE:
            return { ...state, isFetching: true };
            break;
        case ActionType.REGISTER_USER_WITH_PARTNER_CODE_SUCCESSFUL:
            return { ...state, isFetching: false };
            break;
        case ActionType.REGISTER_USER_WITH_PARTNER_CODE_FAILED:
            return { ...state, error: action.payload, isFetching: false };
            break;
        default:
            return state;
            break;
    }
    return state;
};
export default registerUserWithPartnerCode;
