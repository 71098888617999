import React, { Fragment, useState } from 'react';
import { Collapse } from 'reactstrap';
import Chevron from '../../../assets/images/icons/svg/down-chevron.svg';
import PropTypes from 'prop-types';

const Accordion = (props) => {

    const [isCollapse, setIsCollapse] = useState(false);

    const toggle = () => {
        if (!isCollapse) {
            props.trackClick(props.question);
        }
        setIsCollapse(!isCollapse);
    }

    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="single-question">
                        <div className="question-box">
                            <div className="question-text" onClick={toggle}>
                                <div className="q-text mt-2 mb-2">{props.question}</div>
                                <div className="arrow-container">
                                    {!isCollapse && (
                                        <div className="arrowUp" style={{ backgroundImage: `url(${Chevron})`, height: '20px', width: '20px', backgroundSize: 'contain', transform: 'rotate(0deg)' }}></div>
                                    )}
                                    {isCollapse && (
                                        <div className="arrowDown" style={{ backgroundImage: `url(${Chevron})`, height: '20px', width: '20px', backgroundSize: 'contain', transform: 'rotate(180deg)' }}></div>
                                    )}
                                </div>
                            </div>
                            <div className="hide-me">
                                <div className="default-according" id="accordion2">
                                    <Collapse isOpen={isCollapse}>
                                        <div>
                                            <div onClick={toggle}>
                                                <div dangerouslySetInnerHTML={{ __html: props.answer }}
                                                    className="display-linebreak" style={{ whiteSpace: 'pre-line' }}>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

Accordion.propTypes = {
    answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    trackClick: PropTypes.func.isRequired,
}

export default Accordion;
