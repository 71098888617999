import React, { useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import PropTypes from 'prop-types';
import './audioOnly.scss';
import 'react-h5-audio-player/src/styles.scss'
import AudioSwitch from "../buttons/audioSwitch";

const AudioOnly = (props) => {

    const [isPlaybackSpeed, setIsPlaybackSpeed] = useState(1);

    let playIcon = <div className="play-audio_button" />;
    let forwardIcon = <div className="forward-audio_button" />;
    let rewindIcon = <div className="rewind-audio_button" />;

    const changePlaySpeed = () => {
        let audio = document.getElementById('audio-player');
        audio = audio.children[0].children[0];

        if (isPlaybackSpeed === 1) {
            audio.playbackRate = 1.5;
            setIsPlaybackSpeed(1.5);
        } else if (isPlaybackSpeed === 1.5) {
            audio.playbackRate = 2;
            setIsPlaybackSpeed(2);
        } else if (isPlaybackSpeed === 2) {
            audio.playbackRate = 1;
            setIsPlaybackSpeed(1);
        }
    }

    return (
        <div className="audio-only_container">
            <div className="audio-header">
                <div className="close-audio" onClick={props.toggleAudioOnly} />
            </div>
            <div className="audio-content_container">
                <h4 className="audio-title">{props.title}</h4>
                <div id="audio-player" className="audio-player_container">
                    <AudioPlayer
                        src={props.audio}
                        controls
                        showJumpControls={true}
                        progressJumpSteps={{ backward: 15000, forward: 15000 }}
                        autoPlay={false}
                        customIcons={{
                            play: playIcon,
                            rewind: rewindIcon,
                            forward: forwardIcon,
                        }}
                        customAdditionalControls={[]}
                        customVolumeControls={[]}
                    />
                </div>
            </div>
            <div className="additional-controls_container">
                <div className="playback-speed_container">
                    <div className="speed-pill" onClick={changePlaySpeed}>
                        <div className="speed-pill_text">
                            {isPlaybackSpeed}x
                        </div>
                    </div>
                </div>
                <div className="video-audio_switch__container" onClick={props.toggleAudioOnly}>
                    <AudioSwitch
                        isAudioSelected={props.isAudioSelected}
                        toggleAudioOnly={props.toggleAudioOnly}
                    />
                </div>
            </div>
        </div>
    )
}

AudioOnly.propTypes = {
    audio: PropTypes.string.isRequired,
    isAudioSelected: PropTypes.bool.isRequired,
    title: PropTypes.string,
    toggleAudioOnly: PropTypes.func.isRequired,
}

export default AudioOnly;
