import {Link} from "react-router-dom";
import GeneralAssetCard from "../../components/cards/generalAssetCard";
import React from "react";
import {getCardHeight__coreCategoryPage} from "../../utils/responsive-card-height";


export function CreateGeneralCards(videosArray, resourcesArray, coursesArray, settings, screenWidth) {

    let styledVideosArray = [];
    let styledResourcesArray = [];
    let styledCoursesArray = [];

    videosArray.forEach((e) => {
        let {title, duration, thumbnail, asset_id} = e;
        let cardHeight = screenWidth > 1168 ?  316 : getCardHeight__coreCategoryPage('VIDEOS', settings, screenWidth);
        let styledVideoCard =
            <Link className="link-container" to={`/watch/${asset_id}`} key={Math.random(100)}>
                <GeneralAssetCard
                    title={title}
                    duration={duration}
                    src={thumbnail}
                    assetType={"video"}
                    cardHeight={cardHeight}
                />
            </Link>
        styledVideosArray.push(styledVideoCard);

    });

    resourcesArray.forEach((e) => {
        let {title, thumbnail, asset_id} = e;
        let cardHeight = screenWidth > 1168 ?  316 : getCardHeight__coreCategoryPage('RESOURCES', settings, screenWidth);

        let styledResourceCard =
            <Link className="link-container" to={`/read/${asset_id}`} key={Math.random(100)}>
                <GeneralAssetCard
                    title={title}
                    src={thumbnail}
                    assetType={"resource"}
                    cardHeight={cardHeight}
                />
            </Link>
        styledResourcesArray.push(styledResourceCard);
    });

    coursesArray.forEach((e) => {
        let {title, thumbnail, asset_id} = e;

        let cardHeight = screenWidth > 1168 ?  316 : getCardHeight__coreCategoryPage('COURSES', settings, screenWidth);
        let styledCourseCard =
            <Link className="link-container" to={`/course/${asset_id}`} key={Math.random(100)}>
                <GeneralAssetCard
                    title={title}
                    assetType={"course"}
                    src={thumbnail}
                    cardHeight={cardHeight}
                />
            </Link>
        styledCoursesArray.push(styledCourseCard);
    });

    return {
        "videoCards": styledVideosArray,
        "resourceCards": styledResourcesArray,
        "courseCards": styledCoursesArray
    }


}
