import * as TYPES from "./actionTypes"

export const getInfoMessages = (location) => {
    return {
        type: TYPES.GET_INFO_MESSAGES,
        location: location
    }
}

export const setInfoMessages = (payload) => {
    return {
        type: TYPES.SET_INFO_MESSAGES,
        payload: payload
    }
}

export const getOverallStats = () => {
    return {
        type: TYPES.GET_OVERALL_STATS,
    }
}

export const setOverallStats = (payload) => {
    return {
        type: TYPES.SET_OVERALL_STATS,
        payload: payload
    }
}

export const getCategoryStats = (category_uuid) => {
    return {
        type: TYPES.GET_CATEGORY_STATS,
        category_uuid: category_uuid
    }
}

export const setCategoryStats = (payload) => {
    return {
        type: TYPES.SET_CATEGORY_STATS,
        payload: payload
    }
}


export const getPerformanceScores = (category_uuid) => {
    return {
        type: TYPES.GET_PERFORMANCE_SCORES,
        category_uuid: category_uuid
    }
}

export const setPerformanceScores = (payload) => {
    return {
        type: TYPES.SET_PERFORMANCE_SCORES,
        payload: payload
    }
}

export const getMyRecommendedAssets = (category_uuid) => {
    return {
        type: TYPES.GET_MY_RECOMMENDED_ASSETS,
        category_uuid: category_uuid
    }
}

export const setMyRecommendedAssets = (payload) => {
    return {
        type: TYPES.SET_MY_RECOMMENDED_ASSETS,
        payload: payload
    }
}

export const submitCheckupScores = (payload) => {
    return {
        type: TYPES.POST_CHECKUP,
        payload: payload
    }
}
