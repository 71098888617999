import React, {useState, useEffect, Fragment} from 'react';

import {useNavigate} from 'react-router-dom';

import algoliasearch from 'algoliasearch/lite';
import {InstantSearch, Index, Configure, connectSearchBox} from 'react-instantsearch-dom';

const VirtalSearchBox = connectSearchBox(() => null);
import AutoComplete from './autocomplete';

const searchClient = algoliasearch(
    'UV8941WIXZ',
    '7d375c1399d29b1b8830b5a8d26dc1ea'
);


const SearchHeader = () => {
    const history = useNavigate();

    const routeUserToAsset = (suggestion) => {
        if (suggestion.index_type === "video") {
            history('/watch/' + suggestion.uuid);
        } else if (suggestion.index_type === "course") {
            history('/course/' + suggestion.uuid);
        } else if (suggestion.index_type === "challenge") {
            history('/challenge/' + suggestion.uuid);
        } else if (suggestion.index_type === "podcast_episode") {
            history('/podcast');
        } else {
            history('/read/' + suggestion.uuid);
        }
    }


    return (
        <Fragment>
            <div className="search-form">
                <div className="form-group">
                    <InstantSearch
                        indexName="assets"
                        searchClient={searchClient}
                    >
                        <Configure hitsPerPage={10}/>
                        <AutoComplete
                            onSuggestionSelected={(event, {suggestion}) => {
                                routeUserToAsset(suggestion)
                            }}
                        />
                        <Index indexName="assets"/>
                    </InstantSearch>
                </div>
            </div>

        </Fragment>
    );
};

export default SearchHeader;
