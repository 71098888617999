import {
    isEmpty,
} from 'lodash';
import React, {useEffect, useState, Fragment} from 'react';
import axios from 'axios';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import LoadingScreen from '../../components/LoadingScreen';
import CheckupBar from '../../components/common/progress-bar/checkup-bar';
import QuizQuestion from '../../components/cards/quiz-question';
import GeneralButton from '../../components/buttons/generalButton';
import ScaleLoader from "react-spinners/ScaleLoader";
import './checkup.scss';
import {HandleRouteChange, Track} from "../../utils/Segment/segment-methods";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {submitCheckupScores} from "../MyMarriage/store/actions";
import {captureException} from "@sentry/react";
import QuestionTypeRadio from '../../onBoarding/quiz/questionTypeRadio';

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            submitCheckupScores
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    isUserFetching: appState.User.isFetching,
});

function Checkup({user, isUserFetching, submitCheckupScores}) {
    let location = useLocation()
    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Take Checkup', `${quiz_type} Checkup`)
        }
    }, [user, location])

    let navigate = useNavigate();
    let {quiz_type} = useParams();

    const [isQuizQuestions, setIsQuizQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isCurrentQuestionShown, setIsCurrentQuestionShown] = useState(0);
    const [isFormSent, setIsFormSent] = useState(false);
    const [isAnswerValue, setIsAnswerValue] = useState();
    const [isQuestionAnswered, setIsQuestionAnswered] = useState(false);
    const [isLastQuestion, setIsLastQuestion] = useState(false);
    const [quizType, setQuizType] = useState(null);

    const [isAnswers, setIsAnswers] = useState([]);

    // Fetch on boarding flow questions list
    const questionStepInfo = user?.onboarding_flow?.steps?.find((step) => step.type === 'marriage_quiz');
    const demographicQuestionList = questionStepInfo?.data?.questions?.filter((question) => question.question_type === 'preassessment');
    const isDemographicQuestionAlreadyInQuiz = !isEmpty(demographicQuestionList)
        && (isQuizQuestions.map((q) => q.question_uuid).includes(demographicQuestionList[0].uuid));

    // Check if preassesment question is already completed
    const isPreAssessmentComplete = user?.preassessment_complete ?? false;

    useEffect(() => {
        getQuizQuestions().then(questions => {
            organizeAndStyleQuestions(questions);
        })
    }, []);

    useEffect(() => {
        if (!isPreAssessmentComplete
            && !isEmpty(demographicQuestionList)
            && !isUserFetching
            && !isDemographicQuestionAlreadyInQuiz) {
            organizeAndStypePreAssessmentQuestions(demographicQuestionList);
        }
    }, [
        isDemographicQuestionAlreadyInQuiz,
        isUserFetching,
        demographicQuestionList,
        isPreAssessmentComplete,
    ]);

    const organizeAndStypePreAssessmentQuestions = () => {
        let preAssessmentQuestionArrJsx = [];

        if (!isPreAssessmentComplete && !isEmpty(demographicQuestionList)) {
            preAssessmentQuestionArrJsx = demographicQuestionList?.map((q) => ({
                position: q.position ?? 0,
                question_uuid: q.uuid,
                content: q.title.elements[0]?.content,
                type: q.type,
                question_type: q.question_type,
                category: null,
                category_uuid: null,
                styledElement: (
                    <div className="demographic-question">
                        <QuestionTypeRadio
                            answer={isAnswerValue}
                            title={q.title}
                            options={q.options}
                            onSelectAnswerOption={onSelectDemographicQuestion}
                        />
                    </div>
                ),
            }));
        }

        setIsQuizQuestions([
            ...preAssessmentQuestionArrJsx,
            ...isQuizQuestions,
        ]);
    };

    const getQuizQuestions = async () => {
        let baseURL

        if (quiz_type === "full") {
            setQuizType('full')
            baseURL = "/assessment-questions";
        } else {
            setQuizType('intro')
            baseURL = "/intro-questions";
        }

        return await axios.get(baseURL).then((resp) => {
            if (quiz_type === "full") {
                return resp.data.assessment
            } else {
                return resp.data.intro_questions
            }
        });
    }

    const onSelectDemographicQuestion = (answer) => {
        setIsQuestionAnswered(true);
        setIsAnswerValue(answer);
    };

    const organizeAndStyleQuestions = (array) => {
        let arrayCopy = array;
        let categoryArray = [];

        for (let i = 0; i < arrayCopy.length; i++) {
            if (!categoryArray.includes(arrayCopy[i].category.title)) {
                categoryArray.push(arrayCopy[i].category.title);
            }
        }
        categoryArray.sort((a, b) => a.localeCompare(b))

        // cat array is sorted alphabetically
        // loop through the array to make an array of category names
        // filter by each category, sort and store in temp array

        // use one array filter topic and one to store all,
        let finalStyledQuestions = [];
        // empty array and move on to next topic
        let questionArray = [];
        // use 2 loops one for category the other for questions
        let categoryTitle;

        arrayCopy.forEach((e) => {
            let question = {
                position: e.position,
                question_uuid: e.question_uuid,
                type: 'hearts',
                content: e.question, // added for Segment Access
                category: e.category, // added for Segment Access
                category_uuid: e.category_uuid,
                question_type: 'assessment',
                styledElement: (<QuizQuestion
                    icon={e.category.icon_svg}
                    category={e.category.title}
                    question={e.question}
                    setIsQuestionAnswered={setIsQuestionAnswered}
                    setIsAnswerValue={setIsAnswerValue}
                />)
            }
            questionArray.push(question);
        });
        for (let i = 0; i < categoryArray.length; i++) {
            let tempArray = [];
            categoryTitle = categoryArray[i];
            for (let j = 0; j < questionArray.length; j++) {
                if (questionArray[j].category.title === categoryTitle) {
                    tempArray.push(questionArray[j]);
                }
            }

            tempArray = tempArray.sort((a, b) => {
                return a.position - b.position;
            });
            finalStyledQuestions = [...finalStyledQuestions, ...tempArray];
        }
        setIsLoading(false);
        setIsQuizQuestions(finalStyledQuestions);
    };

    const nextQuestion = async () => {
        // needs to save the current value of the answer given in a obj
        // obj will be pushed into answer array
        // obj needs category uuid, question uuid, answer value
        let currentQuestionObj = isQuizQuestions[isCurrentQuestionShown];

        // Check if current question is demographic question type or not
        const isCurrentObjDemographicQuestion = currentQuestionObj.question_type === 'preassessment';

        let value;

        if (isCurrentObjDemographicQuestion) {
            value = isAnswerValue;
        } else {
            switch (isAnswerValue) {
                case "one":
                    value = 1;
                    break;
                case "two":
                    value = 2;
                    break;
                case "three":
                    value = 3;
                    break;
                case "four":
                    value = 4;
                    break;
                case "five":
                    value = 5;
                    break;
            }
        }

        let answerObj = {
            category: currentQuestionObj.category_uuid,
            question: currentQuestionObj.question_uuid,
            question_type: currentQuestionObj.question_type,
            value,
        };

        let currentAnswerArray = isAnswers;
        currentAnswerArray.push(answerObj);
        setIsAnswers(currentAnswerArray);

        if (isQuizQuestions.length === (isCurrentQuestionShown + 1)) {
            await finishQuiz();
            return window.location.replace('/checkup')
        }

        if (isQuizQuestions.length === (isCurrentQuestionShown + 2)) {
            setIsLastQuestion(true);
        }


        // Only send question to SEGMENT if its not demographic question

        if (!isCurrentObjDemographicQuestion) {
             //-SEND QUESTION TO SEGMENT
            let segmentData = {
                checkup_type: quizType,
                first_checkup: user.onboarding_complete === 0,
                question: currentQuestionObj.content,
                value: value,
                category: currentQuestionObj.category.title,
            }
        }


        // increment the people property in Segment
        if (quizType === 'full') {
            try {
                Track('checkup_step_completed_full')
            } catch (error) {
                captureException(error)
            }
        } else {
            try {
                Track('checkup_step_completed_intro')
            } catch (error) {
                captureException(error)
            }

        }

        // track the event in Segment
        try {
            Track('checkup_step_completed', segmentData);
        } catch (error) {
            captureException(error)
        }


        setIsCurrentQuestionShown(isCurrentQuestionShown + 1);

    }

    const finishQuiz = () => {
        setIsFormSent(true);
        let answers = isAnswers;
        let is_intro = false;

        if (answers.length === 7 || (answers.length === 9 && !isPreAssessmentComplete)) {
            is_intro = true;
        }

        const quizObj = {
            answers,
            is_intro,
        };

        submitCheckupScores(quizObj);
    }

    if (isLoading || isUserFetching) {
        return <LoadingScreen />
    } else {
        return (
            <div className="checkup-page">

                <div className="m365-logo"></div>
                {
                    isQuizQuestions.length > 0 && (
                        <div className="checkup-content">
                            <CheckupBar
                                currentQuestion={isCurrentQuestionShown + 1}
                                totalQuestions={isQuizQuestions.length}
                                percentage={((isCurrentQuestionShown + 1) / isQuizQuestions.length) * 100}
                            />
                            {isQuizQuestions[isCurrentQuestionShown].styledElement}
                        </div>
                    )
                }
                <div className="checkup-button_container">
                    {
                        isQuestionAnswered ? (
                            <div>
                                {
                                    isLastQuestion ? (
                                        <Fragment>
                                            {isFormSent ? (
                                                <div style={{textAlign: 'center'}}>
                                                    <ScaleLoader height="10px" color="#000"/>
                                                </div>

                                            ) : (
                                                <GeneralButton
                                                    onClick={nextQuestion}
                                                    backgroundColor={"#2D5F62"}
                                                    buttonText={"Finish"}
                                                />
                                            )}
                                        </Fragment>

                                    ) : (
                                        <GeneralButton
                                            onClick={nextQuestion}
                                            backgroundColor={"#2D5F62"}
                                            buttonText={"Next"}
                                        />
                                    )
                                }
                            </div>
                        ) : (
                            <div>
                                {
                                    isLastQuestion ? (
                                        <GeneralButton
                                            onClick={() => {
                                            }}
                                            backgroundColor={"#E3DCD3"}
                                            buttonText={"Finish"}
                                        />
                                    ) : (
                                        <GeneralButton
                                            onClick={() => {
                                            }}
                                            backgroundColor={"#E3DCD3"}
                                            buttonText={"Next"}
                                        />
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

Checkup.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkup)
