import React from 'react';
import CategoryPill from '../Pills/categoryPill';
import ProgressBar from '../ProgressBar/progress-bar';

import './trendingCard.scss';
import styled from "styled-components";
import PropTypes from 'prop-types';

const LargeChallengeCard = (props) => {

    const CourseItem = styled.div`
        height: ${props.cardHeight}px;
        background-image: url(${props.src})
    `

    return (
        <CourseItem className="course-card mx-1">
            <div className="flex-container">
                <h5>
                    {props.title}
                </h5>
                <ProgressBar progressPercentage={props.progress / 100}/>
                <div className="course-card_text">
                    <div className="progress-text">
                        Challenge Progress
                    </div>
                    <div className="percent-watched">
                        {`${Math.round(props.progress)}%`}
                    </div>
                </div>
            </div>
        </CourseItem>
    )
}

LargeChallengeCard.propTypes = {
    cardHeight: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired, 
}

export default LargeChallengeCard;
