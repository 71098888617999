import {call, put, takeEvery} from "redux-saga/effects"

import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
import {loadDashboardAssets } from "../../../helpers/backend-helper";
import {getCoreCategories} from "../../CoreCategory/store/actions";

function* loadDashboard() {
    try {
        const response = yield call(loadDashboardAssets);
        yield put(ACTIONS.setDashboard(response))
        yield put(getCoreCategories())
    } catch (error) {
        yield put(ACTIONS.dashboardError(error))
    }
}

function* dashboardError(error) {
    yield put(ACTIONS.dashboardError(error))
}

function* DashboardSaga() {
    yield takeEvery(TYPES.GET_DASHBOARD, loadDashboard);
    yield takeEvery(TYPES.LOAD_DASHBOARD_ERROR, dashboardError);
}

export default DashboardSaga
