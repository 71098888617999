import React from 'react';

import GeneralButton from '../buttons/generalButton';
import PropTypes from 'prop-types';
import './my-marriage-modal.scss';

const MyMarriageModal = (props) => {
    return (
        <div id='marriage-modal_container'>
            <div className='my-marriage_modal'>
                <div className="title-container">
                    <h2 className="modal-title">
                        My Marriage
                    </h2>
                    <div className="cross pointer" onClick={props.closeModal}/>
                </div>
                <div className="modal-text">
                    You can view your results here. This will help you focus on the best content for your marriage. You got this!
                </div>
                <GeneralButton
                    onClick={props.closeModal}
                    buttonText={"Got it"}
                    backgroundColor={"#2D5F62"}
                />
            </div>
        </div>

    )
}

MyMarriageModal.propTypes = {
    closeModal: PropTypes.func,
}

export default MyMarriageModal;
