import React from "react";
import Components from "../../../@splitty/Helpers/componentHelper";

const FormContainer = ({data, storeAnswers, stepBack, activeTest}) => {
    return (
        <React.Fragment>
            {
                data.map(block => {
                    block.activeTest = activeTest
                    // todo: only send the functions to the Components that need them opposed to all of them
                    block.functions = {store: storeAnswers, back: stepBack}
                    return Components(block)
                })
            }
        </React.Fragment>
    )
}

export default FormContainer
