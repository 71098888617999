import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";

import UserJourneyCard from '../cards/userJourneyCard';
import WorksheetCard from '../cards/worksheetCard';
import CustomLoader from '../CustomLoader';

import {Track} from '../../utils/Segment/segment-methods';
import styled from 'styled-components';
import "./user-journey.scss";
import {useNavigate} from "react-router-dom";


const UserJourneyContainer = styled.div`
    display: ${props => props.display ? 'show' : 'none'};
`


const UserJourney = (props) => {

    const [isSuggestedAction, setSuggestedAction] = useState(undefined);
    const [isShownCard, setShownCard] = useState();
    let navigate = useNavigate();
    let placeholder = useRef();

    let JourneyItem;
    let ResourceItem;
    let denominator;
    let resourceDenominator;

    useEffect(() => {
        setCardSize();
        getJourneyData();
    }, []);

    useEffect(() => {
        if (!!placeholder.current) {
            if (!!isSuggestedAction && isSuggestedAction.asset.type === "resource") {
                placeholder.current.style.height = `${props.resourceCardHeight}px`;
            } else {
                placeholder.current.style.height = `${props.cardHeight}px`;
            }
        }
    }, [isSuggestedAction]);

    useEffect(() => {
        setCardSize();
        if (!!isSuggestedAction) {
            determineCardToShow(isSuggestedAction);
        }
    }, [props]);

    const getJourneyData = () => {
        axios.get("/journey",{timeout:8000}).then((resp) => {
            determineCardToShow(resp.data);
            setSuggestedAction(resp.data);
        }).catch(()=>{
            window.location.href = "/login";
        })
    }

    const setCardSize = () => {
        if (props.screenWidth > 1200) {
            denominator = 3.168;
            resourceDenominator = 5.3;
        }
        if (props.screenWidth <= 1200 && props.screenWidth > 1023) {
            denominator = 3.24;
            resourceDenominator = 5.44;
        }
        if (props.screenWidth <= 1023) {
            denominator = 2.14;
            resourceDenominator = 3.24;
        }
        if (props.screenWidth <= 768) {
            denominator = 2.15;
            resourceDenominator = 3.24444;
        }
        if (props.screenWidth <= 480) {
            denominator = 1.09;
            JourneyItem = styled.div`
                height: ${props.cardHeight}px;
                width: ${props.screenWidth / denominator}px`

            ResourceItem = styled.div`
                height: ${props.resourceCardHeight}px;
                width: 100%;`

        } else {
            JourneyItem = styled.div`
                height: ${props.cardHeight}px;
                width: ${props.screenWidth / denominator}px;`

            ResourceItem = styled.div`
                height: ${props.resourceCardHeight}px;
                width: ${props.screenWidth / resourceDenominator}px;`
        }

        if (!!placeholder.current) {
            if (!!isSuggestedAction && isSuggestedAction.asset.type === "resource") {
                placeholder.current.style.height = `${props.resourceCardHeight}px`;
            } else {
                placeholder.current.style.height = `${props.cardHeight}px`;
            }
        }
    }


    const determineCardToShow = (data) => {
        let cardShown;

        if (data.checkup.retake) {
            cardShown = (
                <JourneyItem>
                    <div className="teal-checkup_card pointer" onClick={() => {
                        TrackClickedJourney(data.asset, data.uuid, true)
                    }}>
                        <div className="card-content">
                            <div className="checkup-title">{data.checkup.header}</div>
                            <div className="checkup-description">{data.checkup.content}</div>
                        </div>
                        <div className="right-arrow"></div>
                    </div>
                </JourneyItem>
            )
        } else {
            if (data.asset.type === "video") {
                cardShown = (
                    <div className="pointer" onClick={() => {
                        TrackClickedJourney(data.asset, data.asset.uuid, false)
                    }}>
                        <JourneyItem>
                            <UserJourneyCard
                                assetType={data.asset.type}
                                cardHeight={props.cardHeight}
                                category={data.category.title}
                                duration={data.asset.is_video.duration}
                                progressPercentage={data.asset.is_video.progress_percentage === null ? 0 : data.asset.is_video.progress_percentage}
                                showPlayButton={true}
                                src={data.asset.thumbnail}
                                title={data.asset.name}
                                showProgress={true}
                            />
                        </JourneyItem>
                    </div>
                )
            }

            if (data.asset.type === "worksheet" || data.asset.type === "resource") {
                cardShown = (
                    <div className="pointer" onClick={() => {
                        TrackClickedJourney(data.asset, data.asset.uuid, false)
                    }}>
                        <ResourceItem className="resource-item">
                            <WorksheetCard
                                assetType={data.asset.type}
                                cardHeight={props.cardHeight}
                                src={data.asset.thumbnail}
                                title={data.asset.name}
                            />
                        </ResourceItem>
                    </div>
                )
            }
        }
        setShownCard(cardShown);
    }

    function TrackClickedJourney(answerAsset, linkUuid, checkupBoolean) {
        if (checkupBoolean) {
            Track('Clicked User Journey Checkup').then(() => {
                navigate("/start-assessment")
            });
        } else {
            Track('Clicked User Journey Asset', {
                Title: answerAsset.name,
                Thumbnail: answerAsset.thumbnail
            }).then(() => {
                if (answerAsset.type === "video") {
                    navigate(`/watch/${linkUuid}`)
                } else if (answerAsset.type === "resource" || answerAsset.type === "worksheet") {
                    navigate(`/read/${linkUuid}`)
                }
            })

        }
    }


    return (
        <UserJourneyContainer className="user-journey_container"
                              display={isSuggestedAction !== undefined ? isSuggestedAction.display_section : true}>
            {

                isSuggestedAction === undefined ?
                    <CustomLoader/>
                    :
                    <div className="journey-content">

                        <div className="action-text_container">
                            <h3 className="slider-title">{isSuggestedAction.title}</h3>
                            <div className="action-description">{isSuggestedAction.description}</div>
                        </div>

                        <div id="placeholder" className="placeholder" ref={placeholder}></div>

                        <div className="journey-card_container">
                            {isShownCard}
                        </div>

                    </div>
            }
            <div className="two-circle_doodle">
                <svg width="302" height="277" viewBox="0 0 302 277" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M302 176.816C302 231.965 257.402 276.671 202.389 276.671C147.375 276.671 102.777 231.965 102.777 176.816C102.777 121.668 147.375 76.9614 202.389 76.9614C257.402 76.9614 302 121.668 302 176.816Z"
                        fill="#78A5A8"/>
                    <circle cx="102.534" cy="102.534" r="75.7436" stroke="#E9C06F" strokeWidth="53.5806"/>
                </svg>
            </div>
        </UserJourneyContainer>
    )
}

export default UserJourney;
