import React from "react"
import AppRoutes from "./routes"
import store from "./store";
import {Provider} from 'react-redux';
import {screenResize as setScreenSize} from "./utils/UserInterface/store/actions"
import './scss/app.scss'

window.addEventListener('resize', () => {
    store.dispatch(setScreenSize(window.innerWidth));
});

const App = () => {
    return (
        <Provider store={store}>
            <AppRoutes/>
        </Provider>
    )
}

export default App
