import { isEmpty } from 'lodash';
//-DEPENDENCIES
import React, {useEffect, Fragment, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
//-HELPERS
import {getCardHeight} from '../../utils/responsive-card-height'
import {UseSystemSettings} from "../../utils/SystemSettings/provider/system-settings";

//-ASSETS

//-COMPONENTS
import AllPill from '../../components/Pills/allPill';
import CategoryCard from '../../components/cards/categoryCard';
import CategoryDesktopCard from '../../components/cards/categoryDesktopCard';
import ChallengeCard from '../../components/cards/challengeCard';
import CourseCard from '../../components/cards/courseCard';
import DesktopNav from '../../components/common/nav/desktop-nav';
import GeneralAssetCard from '../../components/cards/generalAssetCard';
import HomeMenu from '../../components/common/home-menu/home-menu';
import LargeChallengeCard from '../../components/cards/largeChallengeCard';
import LoadingScreen from "../../components/LoadingScreen"
import MobileFooter from '../../components/common/footer-component/mobile-footer';
import NewCarousel from '../../components/Carousels/new-carousel';
import RecommendedCard from '../../components/cards/recommended-card';
import WorksheetCard from '../../components/cards/worksheetCard';
import HeaderPromoBanner from '../../components/HeaderPromoBanner';
import DesktopFooter from '../../components/common/footer-component/desktop-footer';
import PopQuiz2022 from '../../components/PopQuiz/pop-quiz-2022';
import PopQuizButton from '../../components/PopQuiz/pop-quiz-button';
import UserJourney from '../../components/UserJourney';

import BlackFriday2023Banner from '../../components/BlackFriday/black-friday_2023';

//-ACTIONS
import {getDashboard} from "./store/actions";
import { getSubscriptionPlanType } from '../Account/store/actions';

import { SUBSCRIPTION_TYPE_PLANS_INFO_PROPTYPES } from '../../global/propTypesConstants';

import {HandleRouteChange, Track, IncrementPeopleProperties} from "../../utils/Segment/segment-methods";
import NYNYBanner from "../../components/NewYear/banner";


//-EVENTS


//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getDashboard,
            getSubscriptionPlanType,
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    dashboardAssets: appState.Dashboard.assets,
    dashboardLoading: appState.Dashboard.loading,
    screenWidth: appState.UserInterface.screenWidth,
    subscriptionPlans: appState.AccountSettings.subscriptionPlans,
    // settings: appState.SystemSettings.settings
});


//-START FUNCTIONAL COMPONENT
const Dashboard = ({
                       user,
                       dashboardAssets,
                       dashboardLoading,
                       getDashboard,
                       getSubscriptionPlanType,
                       screenWidth,
                       subscriptionPlans,
                       // settings,
                   }) => {
    const {settings} = UseSystemSettings();
    let location = useLocation();
    let navigate = useNavigate();

    //-LOCAL STATE
    const [isCategoryCards, setIsCategoryCards] = useState([]);
    const [isStyledChallengeCards, setIsStyledChallengeCards] = useState([]);
    const [isStyledContinueCards, setIsStyledContinueCards] = useState([]);
    const [isStyledCourseCards, setIsStyledCourseCards] = useState([]);
    const [isStyledDesktopCategoryCards, setIsStyledDesktopCategoryCards] = useState([]);
    const [isStyledRecommendedCards, setIsStyledRecommendedCards] = useState([]);
    const [isStyledSmallChallengeCards, setIsStyledSmallChallengeCards] = useState([]);
    const [isStyledTrendingCards, setIsStyledTrendingCards] = useState([]);
    const [isStyledWatchListCards, setIsStyledWatchListCards] = useState([]);
    const [isStyledQuickieSectionCards, setIsStyledQuickieSectionCards] = useState([]);
    const [isStyledJustAddedCards, setIsStyledJustAddedCards] = useState([]);
    const [isStyledWorksheetCards, setIsStyledWorksheetCards] = useState([]);
    const [isStyledVideoCards, setIsStyledVideoCards] = useState([]);

    const [isShowQuiz, setIsShowQuiz] = useState(false);

    //-USE EFFECT
    /**
     * Prevent the dashboard from loading if the assets exist in redux.
     * This will prevent the dashboard from reloading when a user navigates back to the dashboard by closing a video
     */
    useEffect(() => {
        if (!dashboardAssets) {
            getDashboard();
        }
        window.scroll(0, 0);
    }, [])

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Dashboard')
        }
    }, [user, location])


    // Get all available subscription plans
    useEffect(() => {
        if (isEmpty(subscriptionPlans)) {
            debugger;
            getSubscriptionPlanType();
        }
        window.scroll(0, 0);
    }, [
        subscriptionPlans,
    ]);


    /**
     * Listen for updates to the dashboard assets
     */
    useEffect(() => {
        // todo: rewrite this garbage.
        if (dashboardAssets && settings) {
            // console.log(dashboardAssets)
            // console.log(settings)
            styleTrendingCards(dashboardAssets.TRENDING.assets, "trending");
            styleTrendingCards(dashboardAssets.JUST_ADDED.assets, "just added");
            styleCategoryCards(dashboardAssets.CORE_CATEGORIES);
            styleContinueCards(dashboardAssets.CONTINUE.assets);
            styleWatchListCards(dashboardAssets.WATCHLIST.assets);
            styleQuickieSectionCards(dashboardAssets.QUICKIE_SECTION.assets);
            styleMyCourses(dashboardAssets.MY_COURSES);
            styleMyChallenges(dashboardAssets.MY_CHALLENGES);
            styleRecommendedCourses(dashboardAssets.RECOMMENDED_COURSES);
            styleSmallChallengeCards(dashboardAssets.CHALLENGES);
            styleWorksheetCards(dashboardAssets.FEATURED_WORKSHEETS);
            styleDesktopCategoryCards(dashboardAssets.CORE_CATEGORIES);
            styleTrendingCards(dashboardAssets.VIDEOS.assets, "videos");
        }
    }, [dashboardAssets, screenWidth, settings])


    /**
     * puts all the data for each item into trending card component
     * @param {array} trending_array
     * @param displayLocation
     */
    const styleTrendingCards = (trending_array, displayLocation) => {
        let tempArray = [];
        let cardHeight;

        if (displayLocation === "trending") {
            //set trending slider height here
            cardHeight = getCardHeight('QUICKIE_SECTION', settings, screenWidth);
        } else if (displayLocation === "just added" || displayLocation === "videos") {
            // set quickie and video height here
            cardHeight = getCardHeight('QUICKIE_SECTION', settings, screenWidth);
        }

        trending_array.forEach((e, i) => {
            let link;
            let showPlay = false;
            if (displayLocation !== "videos") {
                switch (e.asset_type) {
                    case "video":
                        link = '/watch';
                        showPlay = true;
                        break;
                    case "resource":
                        link = '/read';
                        break;
                    case "course":
                        link = "/course";
                        break;
                    case "challenge":
                        link = "/challenge";
                        break;
                    case "podcast_episode":
                        link = "/podcast"
                }
            } else {
                link = "/watch";
                showPlay = true;
            }



            // the link needs to be inside the trending card component
            let trendingCard;

            if (displayLocation === "trending") {
                trendingCard = (
                    <div className={'pointer'} key={`trending-${i}`} onClick={
                        () => {
                            if (e.asset_type !== "podcast_episode") {
                                RouteUser(`${link}/${e.uuid}`, {
                                    Link: `${link}/${e.uuid}`,
                                    Slider: 'Trending Now',
                                    Thumbnail: e.poster,
                                    Title: e.title,
                                })
                            } else {
                                RouteUser(`${link}`, {
                                    Link: `${link}`,
                                    Slider: 'Trending Now',
                                    Thumbnail: e.poster,
                                    Title: e.title,
                                })
                            }
                        }
                    }>
                        <GeneralAssetCard
                            assetType={e.asset_type}
                            cardHeight={cardHeight}
                            category={Array.isArray(e.coreCategory) ? e.coreCategory[0] : e.coreCategory}
                            duration={e.duration}
                            showProgress={false}
                            src={e.poster}
                            title={e.title}
                            showPlayButton={showPlay}
                            newBanner={false}
                        />
                    </div>
                );
            } else if (displayLocation === "just added" || displayLocation === "videos") {
                trendingCard = (
                    <div className={'pointer'}
                         key={`trending-${i}`}
                         onClick={
                             () => {
                                 if (e.asset_type !== "podcast_episode") {
                                     RouteUser(`${link}/${e.uuid}`, {
                                         Link: `${link}/${e.uuid}`,
                                         Slider: 'Just Added',
                                         Thumbnail: e.poster,
                                         Title: e.title,
                                     })
                                 } else {
                                     RouteUser(`${link}`, {
                                         Link: `${link}`,
                                         Slider: 'Just Added',
                                         Thumbnail: e.poster,
                                         Title: e.title,
                                     })
                                 }
                             }}>
                        <GeneralAssetCard
                            assetType={displayLocation === "videos" ? e.asset_type : e.asset_type}
                            cardHeight={cardHeight}
                            category={Array.isArray(e.coreCategory) ? e.coreCategory[0] : e.coreCategory}
                            duration={e.duration}
                            showProgress={false}
                            src={e.poster}
                            title={e.title}
                        />
                    </div>);
            }

            tempArray.push(trendingCard);
        });
        if (displayLocation === "trending") {
            setIsStyledTrendingCards(tempArray);
        } else if (displayLocation === "just added") {
            setIsStyledJustAddedCards(tempArray);
        } else if (displayLocation === "videos") {
            setIsStyledVideoCards(tempArray);
        }

    }

    /**
     *
     * @param {String} link
     * @param {Object} properties
     */
    function RouteUser(link, properties) {
        Track(`Clicked Slide`, properties).then(() => {
            navigate(`${link}`)
        })
    }

    /**
     *
     * @param {array} category_array
     */
    const styleCategoryCards = (category_array) => {
        category_array = category_array.sort((a, b) => a.title !== b.title ? a.title < b.title ? -1 : 1 : 0);
        let tempArray = [];
        category_array.forEach((item, i) => {
            let styledCategoryCard = <CategoryCard icon={item.icon_svg} categoryTitle={item.title}
                                                   backgroundColor={item.meta.backgroundColor.hex} location={"dash"}
                                                   link={item.meta.slug} key={`category-cards-${i}`}/>
            tempArray.push(styledCategoryCard);
        })
        setIsCategoryCards(tempArray);
    }


    // TODO: the following 6 or so methods all pretty much do the same thing. Can we write a single method requiring
    //      params to set them apart? This feels a bit bloated.
    /**
     *
     * @param {array} continue_array
     */
    const styleContinueCards = (continue_array) => {

        let tempArray = [];
        let continueWatchingCard;
        const cardHeight = getCardHeight('CONTINUE', settings, screenWidth);


        continue_array.forEach((v, i) => {
            let link;

            switch (v.asset_type) {
                case "video":
                    link = '/watch';
                    break;
                case "course":
                    link = "/course";
                    break;
                case "challenge":
                    link = "/challenge";
                    break;
                default:
                    link = '/watch';
            }


            continueWatchingCard = (
                <div className={'pointer'}
                     key={`continue-${i}`}
                     onClick={() => RouteUser(`${link}/${v.uuid}`, {
                         Link: `${link}/${v.uuid}`,
                         Slider: 'Continue Watching',
                         Thumbnail: v.poster,
                         Title: v.title,
                     })}>
                    <GeneralAssetCard
                        assetType={v.asset_type}
                        cardHeight={cardHeight}
                        category={v.coreCategory[0]}
                        duration={v.duration}
                        progressPercentage={v.progress_percentage}
                        showProgress={v.asset_type === 'video'}
                        src={v.poster}
                        title={v.title}
                    />
                </div>);
            tempArray.push(continueWatchingCard);
        });
        setIsStyledContinueCards(tempArray);
    }

    /**
     *
     * @param {array} watchlist_array
     */
    const styleWatchListCards = (watchlist_array) => {
        let tempArray = [];
        let watchListCard;
        const cardHeight = getCardHeight('WATCHLIST', settings, screenWidth);

        watchlist_array.forEach((v, i) => {
            let link;

            switch (v.asset_type) {
                case "video":
                    link = '/watch';
                    break;
                case "course":
                    link = "/course";
                    break;
                case "challenge":
                    link = "/challenge";
                    break;
                default:
                    link = '/watch';
            }

            watchListCard = (
                <div className={'pointer'}
                     key={`watchlist-${i}`}
                     onClick={() => RouteUser(`${link}/${v.uuid}`, {
                         Link: `${link}/${v.uuid}`,
                         Slider: 'My Watchlist',
                         Thumbnail: v.poster,
                         Title: v.title,
                     })}>
                    <GeneralAssetCard
                        assetType={v.asset_type}
                        cardHeight={cardHeight}
                        category={v.coreCategory[0]}
                        duration={v.duration}
                        showProgress={false}
                        src={v.poster}
                        title={v.title}
                    />
                </div>);
            tempArray.push(watchListCard);
        });
        setIsStyledWatchListCards(tempArray);
    }


    /**
     *
     * @param {array} quickieSectionArray
     */
    const styleQuickieSectionCards = (quickieSectionArray) => {
        let tempArray = [];
        let quickieListCard;
        const cardHeight = getCardHeight('QUICKIE_SECTION', settings, screenWidth);

        quickieSectionArray.forEach((v, i) => {
            let link;
            switch (v.asset_type) {
                case "video":
                    link = '/watch';
                    break;
                case "resource":
                    link = '/read';
                    break;
                case "course":
                    link = "/course";
                    break;
                case "challenge":
                    link = "/challenge";
                    break;
                case "podcast_episode":
                    link = "/podcast"
            }


            quickieListCard = (
                <div className={'pointer'}
                     key={`quickies-${i}`}
                     onClick={() => {
                         if (v.asset_type !== "podcast_episode") {
                             RouteUser(`${link}/${v.uuid}`, {
                                 Link: `${link}/${v.uuid}`,
                                 Slider: 'Marriage Quickies',
                                 Thumbnail: v.poster,
                                 Title: v.title,
                             })
                         } else {
                             RouteUser(`${link}`, {
                                 Link: `${link}`,
                                 Slider: 'Marriage Quickies',
                                 Thumbnail: v.poster,
                                 Title: v.title,
                             })
                         }
                     }}>

                    <GeneralAssetCard
                        assetType={v.asset_type}
                        cardHeight={cardHeight}
                        category={v.coreCategory[0]}
                        duration={v.duration}
                        showProgress={false}
                        src={v.poster}
                        title={v.title}
                    />
                </div>);
            tempArray.push(quickieListCard);
        });
        setIsStyledQuickieSectionCards(tempArray);
    }

    /**
     *
     * @param {array} courses_array
     */
    const styleMyCourses = (courses_array) => {
        let tempArray = [];
        let courseCard;
        const cardHeight = getCardHeight('MY_COURSES', settings, screenWidth);

        courses_array.forEach((v, i) => {
            courseCard = (
                <div className={'pointer'}
                     onClick={() => RouteUser(`/course/${v.uuid}`, {
                         Link: `/course/${v.uuid}`,
                         Slider: 'My Courses',
                         Thumbnail: v.thumbnail,
                         Title: v.title,
                     })}
                     key={`courses-${i}`}>
                    <CourseCard
                        cardHeight={cardHeight}
                        category={v.categories[0]}
                        duration={v.videos}
                        progress={v.completed_percentage}
                        src={v.thumbnail}
                        title={v.title}
                    />
                </div>);
            tempArray.push(courseCard);
        });
        setIsStyledCourseCards(tempArray);
    }

    /**
     *
     * @param {array} challenge_array
     */
    const styleMyChallenges = (challenge_array) => {
        let tempArray = [];
        let challengeCard;
        const cardHeight = getCardHeight('MY_CHALLENGES', settings, screenWidth);

        challenge_array.forEach((v, i) => {
            challengeCard = (
                <div className={'pointer'}
                     onClick={() => RouteUser(`/challenge/${v.uuid}`, {
                         Link: `/challenge/${v.uuid}`,
                         Slider: 'My Challenges',
                         Thumbnail: v.thumbnail,
                         Title: v.title,
                     })}
                     key={`my-challenges-${i}`}>
                    <LargeChallengeCard
                        cardHeight={cardHeight}
                        progress={v.completed_percentage}
                        src={v.thumbnail}
                        title={v.title}
                    />
                </div>);
            tempArray.push(challengeCard);
        });
        setIsStyledChallengeCards(tempArray);
    }


    /**
     *
     * @param {array} recommended_array
     */
    const styleRecommendedCourses = (recommended_array) => {
        let tempArray = [];
        let recommendedCard;

        // const cardHeight = getCardHeight('RECOMMENDED_COURSES', settings, screenWidth);
        // const cardHeight = getCardHeight('MY_COURSES', settings, screenWidth);

        let cardHeight;

        if (screenWidth >= 590) {
            cardHeight = getCardHeight('FEATURED_WORKSHEETS', settings, screenWidth);
        } else {
            cardHeight = getCardHeight('MY_CHALLENGES', settings, screenWidth);
        }


        recommended_array.forEach((v, i) => {
            recommendedCard = (
                <div className={'pointer'}
                     onClick={() => RouteUser(`/course/${v.uuid}`, {
                         Link: `/course/${v.uuid}`,
                         Slider: 'Courses',
                         Thumbnail: v.thumbnail,
                         Title: v.title,
                     })}
                     key={`recommended-courses-${i}`}>
                    <RecommendedCard
                        assetType={v.asset_type}
                        cardHeight={cardHeight}
                        lessonNumber={v.lessons.length}
                        src={v.thumbnail}
                        title={v.title}
                    />
                </div>);
            tempArray.push(recommendedCard);

        });
        setIsStyledRecommendedCards(tempArray);
    }


    /**
     * this is the categories that appear as a list layout for mobile
     * @param {array} challenge_array
     */
    const styleSmallChallengeCards = (challenge_array) => {
        let tempArray = [];
        let finishedCards = [];
        let smallChallengeCard;

        const cardHeight = getCardHeight('CHALLENGES', settings, screenWidth);

        challenge_array.forEach((v, i) => {
            smallChallengeCard = (
                <div className={'pointer'}
                     onClick={() => RouteUser(`/challenge/${v.uuid}`, {
                         Link: `/challenge/${v.uuid}`,
                         Slider: 'Challenges',
                         Thumbnail: v.thumbnail,
                         Title: v.title,
                     })}
                     key={`sm-challenges-${i}`}>
                    <ChallengeCard
                        cardHeight={cardHeight}
                        numberOfDays={v.lessons.days.length}
                        numberOfLessons={v.lessons.days.length}
                        src={v.thumbnail}
                        title={v.title}
                    />
                </div>);
            tempArray.push(smallChallengeCard);

            if ((i + 1) % 3 === 0 || i === challenge_array.length - 1) {
                let card = <div className='challenge-card_container m-r-4' key={`challenge-card-${i}`}>{tempArray}</div>
                finishedCards.push(card);
                tempArray = [];
            }
        });
        setIsStyledSmallChallengeCards(finishedCards);
    }

    /**
     *
     * @param {array} worksheet_array
     */
    const styleWorksheetCards = (worksheet_array) => {
        let tempArray = [];
        let worksheetCard;
        // console.log("check screen width", screenWidth);
        let cardHeight;
        if (screenWidth >= 590) {
            cardHeight = getCardHeight('FEATURED_WORKSHEETS', settings, screenWidth);
        } else {
            cardHeight = getCardHeight('MY_CHALLENGES', settings, screenWidth);
        }


        worksheet_array.forEach((v, i) => {
            worksheetCard = (
                <div className={'pointer'}
                     onClick={() => RouteUser(`/read/${v.assets.uuid}`, {
                         Link: `/read/${v.assets.uuid}`,
                         Slider: 'Worksheets',
                         Thumbnail: v.thumbnail,
                         Title: v.title,
                     })}
                     key={`worksheets-${i}`}>
                    <WorksheetCard
                        assetType={v.assets.category}
                        cardHeight={cardHeight}
                        src={v.assets.portrait_thumbnail}
                        title={v.assets.title}
                    />
                </div>);
            tempArray.push(worksheetCard);
        });
        setIsStyledWorksheetCards(tempArray);
    }


    /**
     * display the category cards that will appear on the desktop layout
     * @param category_array
     */
    const styleDesktopCategoryCards = (category_array) => {
        category_array = category_array.sort((a, b) => a.title !== b.title ? a.title < b.title ? -1 : 1 : 0);
        let tempArray = [];
        let styledCategoryCard
        const cardHeight = getCardHeight('CORE_CATEGORIES', settings, screenWidth);

        category_array.forEach((item, i) => {
            styledCategoryCard = (
                <CategoryDesktopCard
                    backgroundColor={item.meta.backgroundColor.hex}
                    cardHeight={cardHeight}
                    categoryIcon={item.icon_svg}
                    categoryTitle={item.title}
                    key={`lg-category-${i}`}
                    slug={item.meta.slug}
                />
            )
            tempArray.push(styledCategoryCard);
        })
        setIsStyledDesktopCategoryCards(tempArray);
    }


    const buildDashboard = () => {
        Object.entries(dashboardAssets).forEach(([key, value]) => (
                console.log(key)
            )
        )
    }

    function TrackOpenedPopQuiz() {
        IncrementPeopleProperties('Opened pop quiz').then(() => {
            Track('Opened Pop Quiz').then(() => {

            })
        })
    }

    // console.log("dashboard assets", dashboardAssets)

    // todo: rewrite the dashboard. I don't want to have to manually enter each section into this. Let's
    //      iterate through an array or something. Just make this look sweeter, please

    return (
        dashboardLoading || dashboardLoading === undefined || settings === null || JSON.stringify(user) === '{}' ?
            <LoadingScreen/>
            :
            <Fragment>
                <HomeMenu user={user}/>
                <DesktopNav user={user}/>
                {/*<NYNYBanner />*/}
                {isShowQuiz && <PopQuiz2022
                    setIsShowQuiz={setIsShowQuiz}
                    settings={settings}
                    user={user}
                />}

                {
                    settings &&
                    <Fragment>
                        {
                            settings.SYSTEM_BANNER &&
                            <HeaderPromoBanner content={settings.SYSTEM_BANNER}/>
                        }
                        <UserJourney
                            cardHeight={getCardHeight('TRENDING', settings, screenWidth)}
                            resourceCardHeight={getCardHeight('TRENDING', settings, screenWidth)}
                            screenWidth={screenWidth}
                        />
                        <section id="dashboard" className="pb-md-4">
                            {
                                dashboardAssets.TRENDING.assets.length > 0 && settings.DASHBOARD_LAYOUT.TRENDING &&
                                <div className="col trending-slider">
                                    <div className="slider-container">
                                        <div className="trending-container">
                                            <h5 className="slider-title mb-2">{settings.DASHBOARD_LAYOUT.TRENDING.title || ''}</h5>
                                            <NewCarousel
                                                key={`slider-${Math.random(100)}`}
                                                cards={isStyledTrendingCards}
                                                config={settings.DASHBOARD_LAYOUT.QUICKIE_SECTION.slider ?? {}}
                                                partialVisible={false}
                                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                                showDots={true}
                                                source={"dashboard trending"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                dashboardAssets.QUICKIE_SECTION.assets.length > 0 && settings.DASHBOARD_LAYOUT.QUICKIE_SECTION &&
                                <div className="col">
                                    <div className="slider-container continue-container raised-chevron my-3">
                                        <div className="section-header_all mb-2">
                                            <h5 className="slider-title mb-0">{settings.DASHBOARD_LAYOUT.QUICKIE_SECTION.title}</h5>
                                            <AllPill link={"/tag?term=quickies"} slider={'Marriage Quickies'}/>
                                        </div>
                                        <NewCarousel
                                            cards={isStyledQuickieSectionCards}
                                            config={settings.DASHBOARD_LAYOUT.QUICKIE_SECTION.slider ?? {}}
                                            partialVisible={true}
                                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                            showDots={false}
                                            source={"dashboard quickies"}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                dashboardAssets.JUST_ADDED.assets.length > 0 && settings.DASHBOARD_LAYOUT.TRENDING &&
                                <div className="col">
                                    <div className="slider-container continue-container raised-chevron my-3">
                                        <div className="section-header_all mb-2">
                                            <h5 className="slider-title mb-0">{dashboardAssets.JUST_ADDED.meta.title}</h5>
                                        </div>
                                        <NewCarousel
                                            cards={isStyledJustAddedCards}
                                            config={settings.DASHBOARD_LAYOUT.QUICKIE_SECTION.slider ?? {}}
                                            partialVisible={true}
                                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                            showDots={false}
                                            source={"dashboard just added"}
                                        />
                                    </div>
                                </div>
                            }
                            {
                                dashboardAssets.VIDEOS.assets.length > 0 && settings.DASHBOARD_LAYOUT.TRENDING &&
                                <div className="col">
                                    <div className="slider-container continue-container raised-chevron my-3">
                                        <div className="section-header_all mb-2">
                                            <h5 className="slider-title mb-0">Videos</h5>
                                            <AllPill link={"/all-videos"} slider={'Marriage Quickies'}/>
                                        </div>
                                        <NewCarousel
                                            cards={isStyledVideoCards}
                                            config={settings.DASHBOARD_LAYOUT.QUICKIE_SECTION.slider ?? {}}
                                            partialVisible={true}
                                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                            showDots={false}
                                            source={"dashboard videos"}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                dashboardAssets.CONTINUE.assets.length > 0 && settings.DASHBOARD_LAYOUT.CONTINUE &&
                                <div className="col">
                                    <div className="slider-container continue-container raised-chevron my-3">
                                        <div className="section-header_all mt-sm-2 mb-2">
                                            <h5 className="slider-title mb-0">{settings.DASHBOARD_LAYOUT.CONTINUE.title || ''}</h5>
                                            <AllPill link={"/account/continue-watching"} slider={'Continue Watching'}/>
                                        </div>
                                        <NewCarousel
                                            cards={isStyledContinueCards}
                                            config={settings.DASHBOARD_LAYOUT.CONTINUE.slider ?? {}}
                                            partialVisible={true}
                                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                            showDots={false}
                                            source={"dashboard continue watching"}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                dashboardAssets.FEATURED_WORKSHEETS.length > 0 && settings.DASHBOARD_LAYOUT.FEATURED_WORKSHEETS &&
                                <div className="col">
                                    <div className="slider-container continue-container raised-chevron my-3">
                                        <div className="section-header_all mt-sm-2 mb-2">
                                            <h5 className="slider-title mb-2">{settings.DASHBOARD_LAYOUT.FEATURED_WORKSHEETS.title}</h5>
                                            <AllPill link={"/worksheets"} slider={'Worksheets'}/>
                                        </div>
                                        <NewCarousel
                                            cards={isStyledWorksheetCards}
                                            config={settings.DASHBOARD_LAYOUT.FEATURED_WORKSHEETS.slider}
                                            partialVisible={true}
                                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                            showDots={false}
                                            source={"dashboard worksheets"}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                dashboardAssets.WATCHLIST.assets.length > 0 && settings.DASHBOARD_LAYOUT.WATCHLIST &&
                                <div className="col">
                                    <div className="slider-container continue-container raised-chevron my-3">
                                        <div className="section-header_all mb-2">
                                            <h5 className="slider-title mb-0">{settings.DASHBOARD_LAYOUT.WATCHLIST.title}</h5>
                                            <AllPill link={"/account/watchlist"} slider={'My Watchlist'}/>
                                        </div>
                                        <NewCarousel
                                            cards={isStyledWatchListCards}
                                            config={settings.DASHBOARD_LAYOUT.WATCHLIST.slider ?? {}}
                                            partialVisible={true}
                                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                            showDots={false}
                                            source={"dashboard watchlist"}
                                        />
                                    </div>
                                </div>
                            }


                            {
                                dashboardAssets.RECOMMENDED_COURSES.length > 0 && settings.DASHBOARD_LAYOUT.RECOMMENDED_COURSES &&
                                <div className="col">
                                    <div className="slider-container continue-container my-3">
                                        <div className="section-header_all mb-2">
                                            <div>
                                                <h5 className="slider-title mb-0">{settings.DASHBOARD_LAYOUT.RECOMMENDED_COURSES.title}</h5>
                                                <h6 className="home-subtitle">{settings.DASHBOARD_LAYOUT.RECOMMENDED_COURSES.subtitle}</h6>
                                            </div>
                                            <AllPill link={"/all-courses"} slider={'Courses'}/>
                                        </div>
                                        <NewCarousel
                                            config={settings.DASHBOARD_LAYOUT.FEATURED_WORKSHEETS.slider}
                                            cards={isStyledRecommendedCards}
                                            showDots={false}
                                            removeArrowOnDeviceType={["tablet", "mobile"]}
                                            partialVisible={true}
                                            source={"dashboard recommended courses"}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                dashboardAssets.CHALLENGES.length > 0 && settings.DASHBOARD_LAYOUT.CHALLENGES &&
                                <div className="col">
                                    <div className="container-fluid continue-container my-3">
                                        <h5 className="slider-title mb-0">{settings.DASHBOARD_LAYOUT.CHALLENGES.title}</h5>
                                        <h6 className="home-subtitle">{settings.DASHBOARD_LAYOUT.CHALLENGES.subtitle}</h6>
                                        <NewCarousel
                                            config={settings.DASHBOARD_LAYOUT.CHALLENGES.slider}
                                            cards={isStyledSmallChallengeCards}
                                            showDots={false}
                                            removeArrowOnDeviceType={["tablet", "mobile"]}
                                            partialVisible={true}
                                            source={"dashboard challenges"}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                dashboardAssets.CORE_CATEGORIES.length > 0 && settings.DASHBOARD_LAYOUT.CORE_CATEGORIES &&
                                <div className="col">
                                    <div className="flex-two_column d-md-none">
                                        {isCategoryCards}
                                    </div>

                                    <div className="slider-container continue-container d-none d-md-block my-3">
                                        <h5 className="slider-title mb-2">{settings.DASHBOARD_LAYOUT.CORE_CATEGORIES ? settings.DASHBOARD_LAYOUT.CORE_CATEGORIES.title : ''}</h5>
                                        <NewCarousel
                                            config={settings.DASHBOARD_LAYOUT.CORE_CATEGORIES.slider}
                                            cards={isStyledDesktopCategoryCards}
                                            showDots={false}
                                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                                            partialVisible={true}
                                            source={"dashboard core categories"}
                                        />
                                    </div>
                                </div>
                            }
                        </section>
                    </Fragment>
                }
                <DesktopFooter/>
                <MobileFooter/>

                <PopQuizButton
                    toggleQuiz={() => {
                        setIsShowQuiz(!isShowQuiz)
                    }}
                    trackClick={TrackOpenedPopQuiz}

                />

            </Fragment>
    );
};

Dashboard.propTypes = {
    user: PropTypes.object,
    dashboardAssets: PropTypes.object,
    dashboardLoading: PropTypes.bool,
    getDashboard: PropTypes.func,
    getSubscriptionPlanType: PropTypes.func,
    subscriptionPlans: SUBSCRIPTION_TYPE_PLANS_INFO_PROPTYPES.isRequired,
    screenWidth: PropTypes.number,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
