import React, {useState, useRef, Fragment, useEffect} from 'react';

import GeneralButton from '../../../../../components/buttons/generalButton';

import CustomLoader from '../../../../../components/CustomLoader';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {updateUserPassword} from '../../../../../store/user/actions';

import PropTypes from 'prop-types';

import './account-password-12-2022.scss';
import {HandleRouteChange} from "../../../../../utils/Segment/segment-methods";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        updateUserPassword
    }, dispatch)
};

//-MAP PROPS
const mapStateToProps = appState => ({
    successMessage: appState.User.successMessage,
    userError: appState.User.error,
});

const AccountPassword2022 = ({updateUserPassword, successMessage, userError}) => {
    const pageTitle = 'Change Password'
    const [isError, setIsError] = useState();
    const [isPasswordKeyShown, setIsPasswordKeyShown] = useState(false);
    const [isCurrentInputIconsShown, setIsCurrentInputIconsShown] = useState(false);
    const [isNewInputIconsShown, setIsNewInputIconsShown] = useState(false);
    const [isConfirmInputIconsShown, setIsConfirmInputIconsShown] = useState(false);
    const [isUpdateButtonShown, setIsUpdateButtonShown] = useState();
    const [isLoaderButtonShown, setIsLoaderButtonShown] = useState(false);

    const currentPW = useRef();
    const newPW = useRef();
    const newPWConfirm = useRef();
    const keyContainer = useRef();

    const passwordNeedIcon1 = useRef();
    const passwordNeedIcon2 = useRef();
    const passwordNeedIcon3 = useRef();
    const passwordNeedIcon4 = useRef();
    const passwordNeedIcon5 = useRef();

    useEffect(() => {

        if (successMessage !== null) {
            resetComponent();
        }
        if (userError !== null) {
            tryPasswordAgain();
        }

    }, [successMessage, userError]);

    /**
     * Send page view to Mixpanel
     */
    React.useEffect(() => {
        HandleRouteChange({pathname: '/account/change-password'}, {}, 'page-view', pageTitle)
    }, [])

    const resetComponent = () => {
        setIsPasswordKeyShown(false);
        clearCurrent();
        clearNew();
        clearConfirm();
        setIsUpdateButtonShown(false);
        setIsLoaderButtonShown(false);
    }
    const tryPasswordAgain = () => {
        setIsLoaderButtonShown(false);
    }

    const clearCurrent = () => {
        currentPW.current.value = "";
    }

    const showCurrent = () => {
        if (currentPW.current.type === "password") {
            currentPW.current.type = "text";
        } else {
            currentPW.current.type = "password";
        }
    }

    const clearNew = () => {
        newPW.current.value = "";
    }

    const showNew = () => {
        if (newPW.current.type === "password") {
            newPW.current.type = "text";
        } else {
            newPW.current.type = "password";
        }
    }

    const clearConfirm = () => {
        newPWConfirm.current.value = "";
    }

    const showConfirm = () => {
        if (newPWConfirm.current.type === "password") {
            newPWConfirm.current.type = "text";
        } else {
            newPWConfirm.current.type = "password";
        }
    }

    const showCurrentIcons = () => {
        if (currentPW.current.value === '') {
            setIsCurrentInputIconsShown(false);
        } else {
            setIsCurrentInputIconsShown(true);
        }
    }

    const showNewIcons = () => {
        if (newPW.current.value === '') {
            setIsNewInputIconsShown(false);
            setIsPasswordKeyShown(false);
        } else {
            setIsPasswordKeyShown(true);
            setIsNewInputIconsShown(true);
        }
        checkRequirements()
    }

    const showConfirmIcons = () => {
        if (newPWConfirm.current.value === '') {
            setIsConfirmInputIconsShown(false);
            setIsPasswordKeyShown(false);
        } else {
            setIsPasswordKeyShown(true);
            setIsConfirmInputIconsShown(true);
        }
        checkRequirements();
    }

    const checkRequirements = () => {
        setIsError('');
        // regex check each need and set class if true
        let password = newPW.current.value;
        const eightOrMore = /^.{8,}$/;
        const capitalLetter = /^(?=.*[A-Z])/;
        const number = /^(?=.*\d)/;
        const specialCharacter = /^(?=.*[@$!%^*?&])/;

        let test1Passed = false;
        let test2Passed = false;
        let test3Passed = false;
        let test4Passed = false;
        let test5Passed = false;

        if (passwordNeedIcon1.current === undefined || passwordNeedIcon1.current === null) {
            return;
        }

        if (eightOrMore.test(password)) {
            passwordNeedIcon1.current.classList.remove('not-met');
            passwordNeedIcon1.current.classList.add('fulfilled');
            test1Passed = true;
        } else {

            if (passwordNeedIcon1.current.classList[1] !== "not-met") {
                passwordNeedIcon1.current.classList.remove('fulfilled');
                passwordNeedIcon1.current.classList.add('not-met');
                test1Passed = false;
            }
        }
        if (capitalLetter.test(password)) {
            passwordNeedIcon2.current.classList.remove('not-met');
            passwordNeedIcon2.current.classList.add('fulfilled');
            test2Passed = true;
        } else {
            if (passwordNeedIcon2.current.classList[1] !== "not-met") {
                passwordNeedIcon2.current.classList.remove('fulfilled');
                passwordNeedIcon2.current.classList.add('not-met');
                test2Passed = false;
            }
        }
        if (number.test(password)) {
            passwordNeedIcon3.current.classList.remove('not-met');
            passwordNeedIcon3.current.classList.add('fulfilled');
            test3Passed = true;
        } else {
            if (passwordNeedIcon3.current.classList[1] !== "not-met") {
                passwordNeedIcon3.current.classList.remove('fulfilled');
                passwordNeedIcon3.current.classList.add('not-met');
                test3Passed = false;
            }
        }
        if (specialCharacter.test(password)) {
            passwordNeedIcon4.current.classList.remove('not-met');
            passwordNeedIcon4.current.classList.add('fulfilled');
            test4Passed = true;
        } else {
            if (passwordNeedIcon4.current.classList[1] !== "not-met") {
                passwordNeedIcon4.current.classList.remove('fulfilled');
                passwordNeedIcon4.current.classList.add('not-met');
                test4Passed = false;
            }
        }
        if (password === newPWConfirm.current.value) {
            passwordNeedIcon5.current.classList.remove('not-met');
            passwordNeedIcon5.current.classList.add('fulfilled');
            test5Passed = true;
        } else {
            if (passwordNeedIcon5.current.classList[1] !== "not-met") {
                passwordNeedIcon5.current.classList.remove('fulfilled');
                passwordNeedIcon5.current.classList.add('not-met');
                test5Passed = false;
            }
        }

        if (test1Passed && test2Passed && test3Passed && test4Passed && test5Passed) {
            keyContainer.current.classList.remove('red-background');
            keyContainer.current.classList.add('green-background');
            setIsUpdateButtonShown(true);
        } else {
            if (keyContainer.current.classList[1] !== "red-background") {
                keyContainer.current.classList.remove('green-background');
                keyContainer.current.classList.add('red-background');
            }
            setIsUpdateButtonShown(false);
        }
    }

    const submitForm = () => {
        setIsLoaderButtonShown(true);
        let ncp, np, old;
        old = currentPW.current.value;
        np = newPW.current.value;
        ncp = newPWConfirm.current.value;

        let formObj = {
            old,
            np,
            ncp
        }

        updateUserPassword(formObj);
        // need to reset form and button


        // create flag
        // reset inputs if success, reset loader and hide button and requirements

        // if error
        // reset the loader
    }

    return (
        <div id="account-password_2022">
            <h3 className="font-bold">Change Password</h3>
            <div className='security-content'>
                <div className="change-password">
                    <div className="current-pw_container">
                        <input className="current-pw pw-input" ref={currentPW} placeholder={"Current Password"}
                               type="password" onChange={showCurrentIcons}></input>
                        {isCurrentInputIconsShown && (<Fragment>
                            <div className="clear-input" onClick={clearCurrent}></div>
                            <div className="show-input" onClick={showCurrent}></div>
                        </Fragment>)}
                    </div>
                    <div className="new-pw_container">
                        <input className="new-pw pw-input" ref={newPW} placeholder={"New Password"} type="password"
                               onChange={showNewIcons}></input>
                        {isNewInputIconsShown && (<Fragment>
                            <div className="clear-input" onClick={clearNew}></div>
                            <div className="show-input" onClick={showNew}></div>
                        </Fragment>)}
                    </div>
                    <div className="new-pw_confirm_container">
                        <input className="new-pw_confirm pw-input" ref={newPWConfirm}
                               placeholder={"Confirm New Password"} onChange={showConfirmIcons} type="password"></input>
                        {isConfirmInputIconsShown && (<Fragment>
                            <div className="clear-input" onClick={clearConfirm}></div>
                            <div className="show-input" onClick={showConfirm}></div>
                        </Fragment>)}
                    </div>
                </div>
            </div>
            {isUpdateButtonShown && (
                <Fragment>
                    {isLoaderButtonShown ? (
                        <div className='update-password_button_container'>
                            <GeneralButton
                                buttonText={<CustomLoader/>}
                                backgroundColor="rgba(0,0,0,0.08)"
                                textColor="#000"
                                onClick={() => {
                                    return
                                }}
                            />
                        </div>
                    ) : (
                        <div className='update-password_button_container'>
                            <GeneralButton
                                buttonText="Change Password"
                                backgroundColor="#2D5F62"
                                textColor="#fff"
                                onClick={submitForm}
                            />
                        </div>
                    )}
                </Fragment>
            )}
            {isPasswordKeyShown && (
                <div className="password-key_container red-background" ref={keyContainer}>
                    <div className='password-key_title_container'>
                        <div className="password-key_icon"></div>
                        <div className="password-key_title">Password Strength</div>
                    </div>
                    <div className="password-needs">
                        <div className="need-container">
                            <div className="need-icon not-met" ref={passwordNeedIcon1}></div>
                            <div className="need-text">8 or more characters</div>
                        </div>
                        <div className="need-container">
                            <div className="need-icon not-met" ref={passwordNeedIcon2}></div>
                            <div className="need-text">1 or more capital letters</div>
                        </div>
                        <div className="need-container">
                            <div className="need-icon not-met" ref={passwordNeedIcon3}></div>
                            <div className="need-text">1 or more numbers</div>
                        </div>
                        <div className="need-container">
                            <div className="need-icon not-met" ref={passwordNeedIcon4}></div>
                            <div className="need-text">1 or more special characters (!@#$%^&*)</div>
                        </div>
                        <div className="need-container">
                            <div className="need-icon not-met" ref={passwordNeedIcon5}></div>
                            <div className="need-text">Password must match</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

AccountPassword2022.propTypes = {
    updateUserPassword: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPassword2022);
