import React from 'react';
import {useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {HandleRouteChange} from "../../../utils/Segment/segment-methods";
import {bindActionCreators} from "redux";
import ProfileLayout from "../ProfileLayout";
import {getContinueWatching, removeVideoFromHistory} from "../store/actions";
import CustomLoader from "../../../components/CustomLoader";
import AssetCard from "../../../components/cards/watchlistCard2022";
import {ListContainer} from "./component-styles";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getContinueWatching,
            removeVideoFromHistory
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    continueWatching: appState.AccountSettings.continueWatching
});


const WatchHistoryScreen = ({user, continueWatching, getContinueWatching, removeVideoFromHistory}) => {
    const [isStyledHistory, setIsStyledHistory] = React.useState([]);
    let location = useLocation()
    const pageTitle = "Continue Watching"

    React.useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', pageTitle)
        }
    }, [user, location])

    React.useEffect(() => {
        !continueWatching && getContinueWatching();
        continueWatching && setIsStyledHistory(styleList())
    }, [location, continueWatching])


    const styleList = () => {
        let styledArray = [];
        let link;
        if (continueWatching.assets.length === 0) {
            let noHistory = <div className="no-history">Nothing to see here</div>
            styledArray.push(noHistory);
        }

        continueWatching.assets.forEach((e, i) => {
            switch (e.asset_type) {
                case "video":
                    link = '/watch';
                    break;
                case "course":
                    link = "/course";
                    break;
                case "challenge":
                    link = "/challenge";
                    break;
                default:
                    link = '/watch';
            }

            styledArray.push(
                <AssetCard
                    link={`${link}/${e.uuid}`}
                    uuid={e.uuid}
                    name={e.title}
                    subtitle={e.coreCategory[0]}
                    src={e.poster}
                    duration={e.duration}
                    key={Math.random(100)}
                    source={pageTitle}
                    styleCards={styleList}
                    watchlist={continueWatching}
                    position={i}
                    assetType={e.asset_type}
                    removeVideo={removeVideoFromHistory}
                />
            );
        });

        return styledArray
    }


    return (
        <ProfileLayout title={pageTitle} content="component-container">
            <ListContainer>
                <div className="back-link pointer">
                    <div className="back-button"/>
                </div>

                <h1 className="font-bold font-primary">{pageTitle}</h1>
                <div className="watchlist-container">

                    {
                        isStyledHistory.length === 0 ?
                            <div className="full-size_loader">
                                <div className="loader-container">
                                    <CustomLoader/>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                {
                                    isStyledHistory
                                }
                            </React.Fragment>
                    }
                </div>
            </ListContainer>
        </ProfileLayout>
    )
}

WatchHistoryScreen.propTypes = {
    user: PropTypes.object,
    continueWatching: PropTypes.array,
    getContinueWatching: PropTypes.func,
    removeVideoFromHistory: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchHistoryScreen)
