import React, {useState, useRef} from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BarGraph from "../common/bar-graph/index.jsx";
import "./marriage-pick-asset.scss";
import {Track} from "../../utils/Segment/segment-methods";
import PropTypes from 'prop-types';

const MarriagePickAsset = (props) => {

    const [isCurrentShown, setIsCurrentShown] = useState(true);
    const [isHistoryShown, setIsHistoryShown] = useState(false);

    const currentSection = useRef(null);
    const historySection = useRef(null);

    const currentBtn = useRef(null);
    const historyBtn = useRef(null);

    let {overallScore, history} = props;


    const toggleSelector = (e) => {
        let className = e.currentTarget.className;
        className = className.split(" ");
        let openElement = document.querySelector(".highlighted");
        let classToOpen = "";
        let openElementArray = [...openElement.classList];

        if (openElementArray.includes(className[0])) {
            return;
        }
        switch (className[0]) {
            case "current":
                classToOpen = currentBtn;
                setIsCurrentShown(true);
                setIsHistoryShown(false);
                currentSection.current.classList.remove("hide-section");
                historySection.current.classList.add("hide-section");
                break;
            case "history":
                classToOpen = historyBtn;
                setIsCurrentShown(false);
                setIsHistoryShown(true);
                currentSection.current.classList.add("hide-section");
                historySection.current.classList.remove("hide-section");
                break;
        }
        //set other two classes to closed
        openElement.classList.remove("highlighted");
        classToOpen.current.classList.add("highlighted");
    }
    const value = 0.66;

    function TrackHistoryClick(e) {
        toggleSelector(e)
        Track('my_marriage_history_viewed', null)
    }

    return (
        <div className="my-marriage_pick__asset">
            <div className="selector-container">
                <div className="selector">
                    <div className="current highlighted" ref={currentBtn} onClick={toggleSelector}>
                        Current
                    </div>
                    <div className="history" ref={historyBtn} onClick={(e) => TrackHistoryClick(e)}>
                        History
                    </div>
                </div>
                <div className="current-section" ref={currentSection}>
                    <CircularProgressbar value={(props.isCurrentScore / 100)} maxValue={1}
                                         text={`${props.isCurrentScore}`}/>
                    <div className="bar-subtitle">Overall Score</div>
                    <div className="more-info_button pointer" onClick={props.showDrawer}/>
                </div>
                <div className="history-section hide-section" ref={historySection}>
                    <BarGraph
                        isScoreHistory={props.isScoreHistory}
                    />
                </div>
            </div>
        </div>
    )
}

MarriagePickAsset.propTypes = {
    isCurrentScore: PropTypes.number,
    isScoreHistory: PropTypes.arrayOf(PropTypes.object),
    showDrawer: PropTypes.func,
    user: PropTypes.object,
}

export default MarriagePickAsset;
