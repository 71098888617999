import React from 'react';
import {Link} from 'react-router-dom';
import SearchHeader from '../header-component/searchHeader';
import PropTypes from 'prop-types';
import './desktop-nav.scss';
import styled from "styled-components";

const DesktopNav = ({user}) => {

    const ProfileImage = styled.div`
        background-image: url(${user.profile_image})
    `

    return (
        <div id="desktop-nav">
            <Link className="desktop-logo" to={'/browse'}/>
            <div className="nav-content">
                <div className="menu-links_container">
                    <Link to={'/browse'} className="menu-link">Home</Link>
                    <Link to={'/checkup'} className="menu-link">My Marriage</Link>
                    <Link to={'/account/watchlist'} className="menu-link">My Watchlist</Link>
                    <Link to={'/podcast'} className="menu-link">Podcast</Link>
                </div>
                <div className="search-user_container">
                    <div className='search-input_container'>
                        <div className="search-icon"/>
                        <SearchHeader className="search-input"/>
                    </div>

                    <Link to="/notifications" className="notifications-link">
                        <div className="notifications-icon"/>
                    </Link>
                    <Link to="/account/" className="account-link">
                        <ProfileImage className="account-image"/>
                    </Link>
                </div>
            </div>

        </div>
    )
}

DesktopNav.propTypes = {
    user: PropTypes.object.isRequired,
}

export default DesktopNav;
