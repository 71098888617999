import React from "react";
import {Hero} from "../Components/Hero";
import {Section} from "../Components/Section";
import {UnorderedList} from "../Components/List/unordered-list";
import {IconList} from "../Components/List/IconList";
import {Button} from "../Components/Button";
import {Column} from "../Components/Column";
import {Title} from "../Components/Title";
import {Div} from "../Components/Div";
import {VideoPlayer} from "../Components/Video/player";
import {PricingButton} from "../Components/PricingButtons/button";
import {SelectionButtonContainer} from "../Components/SelectionButtons";
import {SelectionButton} from "../Components/SelectionButtons/button";
import {BraintreeDropin} from "../Components/BraintreeDropin";
import {BraintreeDropin as BraintreeDropinExistingUser} from "../Components/BraintreeDropin/braintree.dropin";
import {Alert} from "../Components/Alert";
import {Image} from "../Components/Image";
import {RadioInput, RadioText, TextArea, FormButton} from "../../pages/Account/Components/FormElements";
import {HTag} from "../Components/HTag";
import Form from "../Components/Form";
/**
 * UPDATE THIS DURING DEVELOPMENT
 */
// import dummyData from "../../../../../../scratch.json";
import dummyData from "../Templates/Registration/SaleWithThirdButton/test-data.json";
import {BraintreeDropinReturningUser} from "../Components/BraintreeDropin/BraintreeDropinReturningUser";

/**
 * END DEVELOPMENT IMPORTS
 */

const Components = {
    Alert,
    BraintreeDropin,
    BraintreeDropinExistingUser,
    BraintreeDropinReturningUser,
    Button,
    Column,
    Div,
    Hero,
    IconList,
    Image,
    PricingButton,
    Section,
    SelectionButton,
    SelectionButtonContainer,
    Title,
    HTag,
    UnorderedList,
    VideoPlayer,
    Form,
    TextArea,
    RadioText,
    RadioInput,
    FormButton
};
/**
 *
 * @param block
 * @returns {React.FunctionComponentElement<{key: *}>|React.DetailedReactHTMLElement<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>}
 */
export default block => {
    // console.log('data: ', block.data_object)
    // console.log('block: ', block)
    // console.log('component: ', block.component)

    const _DEVELOPMENT = false;

    if (typeof Components[block.component] !== "undefined") {
        return React.createElement(Components[block.component], {
            key: block._uid,
            block: block,
            data: _DEVELOPMENT ? dummyData[block.data] : JSON.parse(block.data_object),
            attributes: block.attributes,
            activeTest: block.activeTest
        });
    }
    return React.createElement(
        () => <div>The component {block._uid} has not been created yet.</div>,
        {key: block._uid}
    );
};
