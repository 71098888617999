import {call, put, takeEvery} from "redux-saga/effects"
import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
import {getPopQuizData, postPopQuizAnswer} from "../../../helpers/backend-helper";

function* getPopQuiz() {
    try {
        const response = yield call(getPopQuizData);
        yield put(ACTIONS.setPopQuiz(response))
    } catch (error) {
        // !-- READING THIS ASSUMES ASSOCIATION WITH DASHBOARD ERROR
        // SHOULD CONSIDER PopQuizError OR SEND THIS ERROR TO dashboardError IN THE DASHBOARD SAGA
        yield put(ACTIONS.dashboardError(error))
    }
}

function* postQuizAnswer({payload}) {
    try {
        const response = yield call(postPopQuizAnswer, payload);
        yield put(ACTIONS.setPopQuiz(response))
    } catch (error) {
        // ! -- SEE SUGGESTION ABOVE.
        yield put(ACTIONS.dashboardError(error))
    }
}

function* PopQuizSaga() {
    yield takeEvery(TYPES.GET_POP_QUIZ, getPopQuiz);
    yield takeEvery(TYPES.POST_POP_QUIZ, postQuizAnswer);
}

export default PopQuizSaga
