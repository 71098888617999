/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isEmpty } from 'lodash';
import React, { useCallback, useState, } from 'react';
import styled from 'styled-components';
import { PrimaryColor, TextColor, } from '../../global/colors';
import { SofiaFont, } from '../../global/fonts';
import AlertNotification from '../../common/alertNotification';
import Card from '../../common/card';
// @ts-ignore
import Copy from '../../assets/icon/copy.svg';
import CustomButton from '../../common/customButton';
import Title from '../../common/title';
import SubTitle from '../../common/subTitle';
const CardTitle = styled(Title) `
    font-family: ${SofiaFont.Bold};
    font-size: 20px;
    line-height: 1.2;
    margin: 8px 0 18px 0;
`;
const CardContainer = styled.div `
    width: 100%;
`;
const CodeContainer = styled.div `
    align-items: center;
    display: flex;
    position: relative;
`;
const CodeTitle = styled.span `
    color: #9CA3AF;
    font-family: ${SofiaFont.Regular};
    font-size: 12px;
    line-height: 1.2;
    margin-right: 11px;
`;
const Code = styled.span `
    color: ${PrimaryColor.hover};
    font-family: ${SofiaFont.Regular};
    font-size: 16px;
    line-height: 1.2;
    margin-right: 12px;
`;
const Container = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px;
`;
const MainSubTitle = styled(SubTitle) `
    color: ${TextColor.subTitle};
    line-height: 1.65;
    margin: 20px 14px 30px 0;
`;
const MainTitle = styled(Title) `
    display: inline-flex;
    line-height: 1.15;
    span: {
        font-size: 28px !important;
    }
`;
const ShareInviteLink = styled(CustomButton) `
    margin: 44px 0 8px 0;
`;
const StyledCopyImg = styled.img `
    cursor: pointer;
`;
const StyledAlertNotificationWrapper = styled.div `
    > div {
        top: -56px;
        left: 0;
    }
`;
function PartnerPairingInviteTemplate({ data, onShareInviteButtonClick, pairingCode, }) {
    const [showCodeCopiedAlertMsg, setShowCodeCopiedAlertMsg] = useState(false);
    const onCopyPartnerCode = useCallback(() => {
        navigator.clipboard.writeText(pairingCode);
        setShowCodeCopiedAlertMsg(true);
        setTimeout(() => {
            setShowCodeCopiedAlertMsg(false);
        }, 3000);
    }, [
        pairingCode,
    ]);
    const onShareInviteLinkButtonClick = useCallback(() => {
        onShareInviteButtonClick();
    }, [
        onShareInviteButtonClick,
    ]);
    return (React.createElement(Container, null,
        data?.title && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        React.createElement(MainTitle
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        , { 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            elements: data?.title?.elements ?? [], style: data?.title?.style })),
        data?.subTitle && (React.createElement(MainSubTitle, { content: data.subTitle.content ?? '', style: data?.subTitle?.style ?? {} })),
        data?.cards && !isEmpty(data?.cards) && (React.createElement(CardContainer, null, !isEmpty(data?.cards[0]) && (React.createElement(Card, null,
            React.createElement(CardTitle
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            , { 
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                elements: data?.cards[0]?.title?.elements ?? [], style: data?.cards[0]?.title?.style ?? {} }),
            React.createElement(CodeContainer, null,
                React.createElement(CodeTitle, null, "My code:"),
                React.createElement(Code, null, pairingCode),
                React.createElement(StyledCopyImg, { alt: "alert", className: "alert-icon", src: Copy, onClick: onCopyPartnerCode })),
            React.createElement(ShareInviteLink, { onClick: onShareInviteLinkButtonClick }, data?.cards[0]?.button?.content))))),
        showCodeCopiedAlertMsg && (React.createElement(StyledAlertNotificationWrapper, null,
            React.createElement(AlertNotification, { title: "Code copied to clipboard!" })))));
}
export default PartnerPairingInviteTemplate;
