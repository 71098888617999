import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import {bindActionCreators} from "redux";
import {HandleRouteChange} from "../../utils/Segment/segment-methods";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {getAllWorksheets} from "./store/actions";
import AssetListItem from "../../components/AssetListItem/ListItem";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getAllWorksheets
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    worksheets: appState.Worksheet.all
});


const AllWorksheets = ({user, getAllWorksheets, worksheets}) => {
    let navigate = useNavigate();
    let location = useLocation()


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'All Worksheets')
        }
    }, [user, location])

    useEffect(() => {
        window.scroll(0, 0);
        if (!worksheets) {
            getAllWorksheets({skip: 0, limit: 100});
        }
    }, [])

    const goBack = () => {
        navigate(-1)
    }


    return (

        JSON.stringify(user) === '{}' || worksheets === null ?
            <LoadingScreen/>
            :
            <div id="account-watchlist_page">
                <div className="back-link pointer">
                    <div className="back-button" onClick={goBack}/>
                </div>

                <h1 className="pt-5 font-bold text-center mb-5">All Worksheets</h1>
                <div className="container">
                    {
                        worksheets.map((w, i) => (
                            <AssetListItem
                                link={`/read/${w.uuid}`}
                                name={w.title}
                                category={'Self-Discovery'}
                                src={w.thumbnail}
                                key={i}
                            />
                        ))
                    }
                </div>

            </div>

    )

}

AllWorksheets.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(AllWorksheets)
