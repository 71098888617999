import * as TYPES from './actionTypes'


export const setUser = (payload) => {
    return {
        type: TYPES.SET_USER,
        payload
    }
}

export const setAuthentication = (payload) => {
    return {
        type: TYPES.SET_AUTHENTICATION,
        payload: payload
    }
}
export const setFavorites = (payload) => {
    return {
        type: TYPES.SET_FAVORITES,
        payload
    }
};

export const updateSubscriptionData = (payload) => {
    return {
        type: TYPES.UPDATE_SUBSCRIPTION_DATA,
        payload
    }
}

export const removeFavorites = (payload) => {
    return {
        type: TYPES.REMOVE_FAVORITES,
        payload
    }
};

export const setContinueWatching = (payload) => {
    return {
        type: TYPES.SET_CONTINUE_WATCHING,
        payload
    }
};

export const setTutorialWatched = (payload) => {
    return {
        type: TYPES.SET_TUTORIAL_WATCHED,
        payload
    }
};

export const fetchUser = (navigate) => {
    return {
        type: TYPES.GET_USER,
        navigate: navigate
    }
}

export const getUserSuccess = (payload) => {
    return {
        type: TYPES.GET_USER_SUCCESS,
        payload: payload
    }
}

export const setUserSuccessMessage = (payload) => {
    return {
        type: TYPES.SET_USER_SUCCESS_MESSAGE,
        payload: payload
    }
}

export const setUserError = (payload) => {
    return {
        type: TYPES.SET_USER_ERROR,
        payload: payload
    }
}
export const clearUserMessage = (payload) => {
    return {
        type: TYPES.CLEAR_USER_MESSAGE,
        payload: payload
    }
}

export const updateUserProfile = (payload) => {
    return {
        type: TYPES.UPDATE_USER,
        payload
    }
}

export const updateProfileImage = (payload) => {
    return {
        type: TYPES.UPDATE_PROFILE_IMAGE,
        payload
    }
}

export const updateUserPassword = (payload) => {
    return {
        type: TYPES.UPDATE_USER_PASSWORD,
        payload
    }
}

export const addSecondaryUser = (payload) => {
    return {
        type: TYPES.ADD_SECONDARY_USER,
        payload
    }
}

export const removeSecondaryUser = (payload) => {
    return {
        type: TYPES.REMOVE_SECONDARY_USER,
        payload
    }
}


