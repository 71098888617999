import React from "react";
import {getCardHeight__videoPage} from "../../utils/responsive-card-height";
import GeneralAssetCard from "../../components/cards/generalAssetCard";
import NewCarousel from "../../components/Carousels/new-carousel";
import {Track} from "../../utils/Segment/segment-methods";


function StyleRelatedVideos(related_videos = [], settings, screenWidth, navigate) {
    let carousel;
    let mobileCardsArray = [];
    let carouselCardsArray = [];
    const cardHeight = getCardHeight__videoPage('RELATED', settings, screenWidth)

    const RouteUser = (link, properties)=> {
        Track(`Clicked Slide`, properties).then(() => {
            navigate(`${link}`)
        })
    }

    related_videos.forEach((v, i) => {
        let mobileCard;
        let carouselCard;
        if (i < settings.VIDEO_LAYOUT.mobile_display_quantity) {
            mobileCard = (
                <div className={'pointer'}
                     onClick={() => RouteUser(`/watch/${v.asset_id}`, {
                         Link: `/watch/${v.asset_id}`,
                         Slider: 'Related Videos',
                         Thumbnail: v.meta.thumbnail,
                         Title: v.meta.title,
                     })}
                     key={i}>
                    <GeneralAssetCard
                        assetType={"video"}
                        cardHeight={cardHeight}
                        category={v.meta.coreCategory[0]}
                        duration={v.meta.duration}
                        showProgress={false}
                        src={v.meta.thumbnail}
                        title={v.meta.title}
                    />
                </div>
            )
            mobileCardsArray.push(mobileCard);
        }

        carouselCard =
            (
                <div className={'pointer'}
                     onClick={() => RouteUser(`/watch/${v.asset_id}`, {
                         Link: `/watch/${v.asset_id}`,
                         Slider: 'Related Videos',
                         Thumbnail: v.meta.thumbnail,
                         Title: v.meta.title,
                     })}
                     key={i}>
                    <GeneralAssetCard
                        assetType={"video"}
                        cardHeight={cardHeight}
                        category={v.meta.coreCategory[0]}
                        duration={v.meta.duration}
                        showProgress={false}
                        src={v.meta.thumbnail}
                        title={v.meta.title}
                    />
                </div>);
        carouselCardsArray.push(carouselCard);
    });

    carousel = (
        <NewCarousel
            cards={carouselCardsArray}
            config={settings.VIDEO_LAYOUT.RELATED.slider ?? {}}
            partialVisible={true}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            showDots={false}
            source={"single video page - related videos carousel"}
        />);

    return {
        'desktop': carousel,
        'mobile': mobileCardsArray
    }
}


export default StyleRelatedVideos;
