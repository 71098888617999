import React from 'react';
import {Link} from 'react-router-dom';
import './home-menu.scss';
import styled from "styled-components";
import PropTypes from 'prop-types';

const HomeMenu = (props) => {

    const ProfileImage = styled.div`
        background-image: url(${props.user.profile_image})
    `

    let capitalizedFirstName = props.user.first_name;
    capitalizedFirstName = capitalizedFirstName.split("");
    capitalizedFirstName[0] = capitalizedFirstName[0].toUpperCase();
    capitalizedFirstName = capitalizedFirstName.join('');

    return (
        <div className="home-menu_container">
            <h1 className="user-name">
                Welcome, {capitalizedFirstName}
            </h1>
            <div className="icon-container">
                <Link className='notifications-link' to={'/notifications'}>
                    <div className="notifications"/>
                </Link>
                <Link to="/account" className="account-link">
                    <ProfileImage className="profile-image"/>
                </Link>
                
            </div>
        </div>
    )
}

HomeMenu.propTypes = {
    user: PropTypes.object.isRequired,
}

export default HomeMenu;
