import React from 'react';
import {useForm} from 'react-hook-form';
import './reset-password-page.scss';
import {Link} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScaleLoader from "react-spinners/ScaleLoader";

const ResetPassword = () => {
    const {register, handleSubmit, watch, formState: {isValid}} = useForm();
    const [buttonLoading, setButtonLoading] = React.useState(false);

    const onSubmit = (data) => {
        if (buttonLoading === true) {
            return
        }

        setButtonLoading(true);
        axios.post('/password/email', data).then((resp) => {
            toast(resp.data.message, {
                className: 'success--toast',
                bodyClassName: 'success--body',
                progressClassName: 'success--progress'
            });
            setButtonLoading(false);
        }).catch(error => {
            toast("There was an error processing your request. Please wait and try again.", {
                className: 'error--toast',
                bodyClassName: 'error--body',
                progressClassName: 'error--progress'
            });
            setButtonLoading(false)
        })

    }

    return (
        <div id="reset-password_page">
            <ToastContainer/>
            <div className="desktop-login_page">
                <div className="m365-desktop_logo"/>
                <div className="desktop-login_container">
                    <form className="login-content" onSubmit={handleSubmit(onSubmit)}>
                        <h1 className="login-title">Reset Password</h1>
                        <div className="full-length_container">
                            <div className='input-container'>
                                <label className="input-label" style={{display: "block"}}>Email</label>
                                <input {...register("email", {required: true})} type="email"
                                />
                            </div>
                        </div>
                        <button className="create-button_container">
                            <div className="create-account">
                                {
                                    buttonLoading ? <ScaleLoader height="10px" color="#fff"/> :
                                        <span>Reset Password</span>
                                }

                            </div>
                        </button>
                        <div className="register">
                            Return to <Link to={'/login'}>Login</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;
