import * as TYPES from "./actionTypes";
import {UPDATE_WATCHLIST_STATUS_COURSE} from "./actionTypes";


export const getSingleCourse = (uuid) => {
    return {
        type: TYPES.GET_SINGLE_COURSE,
        uuid: uuid
    }

}

export const setSingleCourse = payload => {
    return {
        type: TYPES.SET_SINGLE_COURSE,
        payload: payload
    }

}

export const setLastCourseUUID = (payload) => {
    return {
        type: TYPES.SET_LAST_LOADED_COURSE_UUID,
        payload: payload
    }
}


export const setCourseError = error => {
    return {
        type: TYPES.COURSE_ERROR,
        payload: error
    }
}

export const restartCourse = (payload) => {
    return {
        type: TYPES.RESTART_COURSE,
        payload: payload
    }
}

export const restartCourseError = error => {
    return {
        type: TYPES.RESTART_COURSE_ERROR,
        payload: error
    }
}

export const restartCourseSuccess = () => {
    return {
        type: TYPES.RESTART_COURSE_SUCCESS,
    }
}

export const getAllCourses = (payload) => {
    return {
        type: TYPES.GET_ALL_COURSES,
        payload: payload
    }
}

export const setAllCourses = payload => {
    return {
        type: TYPES.SET_ALL_COURSES,
        payload: payload
    }
}

export const updateWatchlistStatusCourse = payload => {

    return {
        type: TYPES.UPDATE_WATCHLIST_STATUS_COURSE,
        payload: payload
    }
}

