const CancellationModalData = {
    Form: {
        content: [
            {
                _uid: 'sjJdddFDSsh',
                data_object: JSON.stringify({
                    properties: {
                        "fontSize": "40px"
                    },
                    text: 'Let us know...',
                    type: 'h1'
                }),
                attributes: {
                    id: "",
                    class: ""
                },
                component: 'HTag',
            },
            {
                _uid: 'sjJmdddSSsh',
                data_object: JSON.stringify({
                    properties: {
                        "fontSize": "20px"
                    },
                    text: 'Please answer these 2 quick questions so we can learn from your responses.',
                }),
                attributes: {
                    id: "",
                    class: ""
                },
                component: 'Title',
            },
            {
                _uid: 'sjJdFDSsh',
                data_object: JSON.stringify({
                    properties: {},
                }),
                attributes: {
                    id: "cancellation-form",
                    class: "cancel-radio_container answer-container"
                },
                component: 'Form',
                children: [
                    {
                        _uid: 'sjJmdSFDSsh',

                        data_object: JSON.stringify({
                            properties: {},
                            text: 'Why are you canceling your membership?',
                        }),
                        attributes: {
                            id: "",
                            class: ""
                        },
                        component: 'Title',
                    },
                    {
                        _uid: "KaNNmAofre",
                        text: 'I fixed the issue I had and feel like I no longer need membership',
                        component: "RadioText",
                        data_object: JSON.stringify({
                            name: "reasonWhy",
                            text: 'I fixed the issue I had and feel like I no longer need membership',
                            properties: {},
                        }),
                        attributes: {
                            id: "no-longer-needed",
                            class: ""
                        }
                    },
                    {
                        _uid: "KaNNmvozEk",
                        text: 'I didn\'t make time to use my membership',
                        component: "RadioText",
                        data_object: JSON.stringify({
                            name: "reasonWhy",
                            text: 'I didn\'t make time to use my membership',
                            properties: {},
                        }),
                        attributes: {
                            id: "no-time",
                            class: ""
                        }
                    },
                    {
                        _uid: "KasmAidfd",
                        text: 'I found a different resource that works better for me',
                        component: "RadioText",
                        data_object: JSON.stringify({
                            name: "reasonWhy",
                            text: "I found a different resource that works better for me",
                            properties: {},
                        }),
                        attributes: {
                            id: "different-resource",
                            class: ""
                        }
                    },
                    {
                        _uid: "KasmAi2wd",
                        text: 'I realized we need coaching/therapy',
                        component: "RadioText",
                        data_object: JSON.stringify({
                            name: "reasonWhy",
                            text: "I realized we need coaching/therapy",
                            properties: {},
                        }),
                        attributes: {
                            id: "need-coaching",
                            class: ""
                        }
                    },
                    {
                        _uid: "KasmAisoj",
                        text: 'I waited too long to get help for my marriage',
                        component: "RadioText",
                        data_object: JSON.stringify({
                            name: "reasonWhy",
                            text: "I waited too long to get help for my marriage",
                            properties: {
                            },
                        }),
                        attributes: {
                            id: "waited-too-long",
                            class: ""
                        }
                    },
                    {
                        _uid: "KasmAifsd",
                        text: 'I\'m not going to work on my marriage until my spouse participates too',
                        component: "RadioText",
                        data_object: JSON.stringify({
                            name: "reasonWhy",
                            text: "I'm not going to work on my marriage until my spouse participates too",
                            properties: {},
                        }),
                        attributes: {
                            id: "waiting-for-spouse",
                            class: ""
                        }
                    },
                    {
                        _uid: "KaNNmA123s",
                        component: "Div",
                        data_object: JSON.stringify({
                            text: '',
                            properties: {},
                        }),
                        attributes: {
                            id: "",
                            class: ""
                        },
                        children: []
                    },
                    {
                        _uid: 'JhsnBqjdD3x',
                        component: "TextArea",
                        data_object: JSON.stringify({
                            text: 'Anything else you would like to share? We appreciate all feedback!',
                            properties: {},
                            name: 'additionalAnswer'
                        }),
                        attributes: {
                            id: "",
                            class: ""
                        }
                    },
                    {
                        _uid: "KaNNmAozhk",
                        component: "Title",
                        data_object: JSON.stringify({
                            text: 'Cancelling your membership will cause you to lose access to your Marriage365 Checkup result history. Refunds are not given on payments already collected.',
                            properties: {
                                "fontSize": "12px"
                            },
                        }),
                        attributes: {
                            id: "",
                            class: "mb-4"
                        },
                        children: []
                    },
                    {
                        _uid: 'JhsdfgdqjdD3x',
                        component: "Div",
                        data_object: JSON.stringify({
                            properties: {},
                        }),
                        attributes: {
                            id: "",
                            class: "account-button_container"
                        },
                        children:[
                            {
                                _uid: 'sjJSSsh',
                                data_object: JSON.stringify({
                                    text:"&#8592; Back",
                                    properties: {},
                                    functionName: 'back'
                                }),
                                attributes: {
                                    id: "",
                                    class: "account-button half-button"
                                },
                                component: 'FormButton',
                            },
                            {
                                _uid: 'sjJSSshddd',
                                data_object: JSON.stringify({
                                    text:"Confirm Cancellation",
                                    properties: {},
                                    functionName: 'store'
                                }),
                                attributes: {
                                    id: "",
                                    class: "account-button half-button red-button"
                                },
                                component: 'FormButton',
                            },
                        ]
                    }
                ]
            },

        ],
        cancellationOptions : [

        ]
    }
}

export default CancellationModalData
