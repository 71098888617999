/**
 * Template Name: Registration Alt One
 *
 * Description: Template containing a media component, list item and two buttons
 * Author: Kurt Weston
 * Version: 1.0
 * Text Domain: registration-alt-one
 *
 */
import React, {useEffect, useState} from "react"
import Components from "../Helpers/componentHelper"
import {getTemplateData} from "../Helpers/backend-helper";
import {useParams} from "react-router-dom";
import styled from "styled-components";
import "../Store"

/**
 * FOR DEVELOPMENT PURPOSES ONLY
 * --
 * Ensure the _ACTIVE_TEST matches the param at the end of the URL
 * ie: http://localhost:8000/checkout/b02399e1-756e-4d16-a9a2-0d65f0336ea6/registration-campaign-one
 * -
 * Update _DEVELOPMENT to true
 *
 * POINT THE TEMPLATE STRUCTURE TO THE STRUCTURE FILE IN DEVELOPMENT
 */

const _DEVELOPMENT = false;
const _ACTIVE_TEST = "development"
import TEMPLATE_STRUCTURE from "../Templates/Registration/SaleWithThirdButton/structure.json"


/**
 * END DEVELOPMENT IMPORTS
 */


const PageController = styled.div`
            background-color: #f1eae3;
            background-image: url('/images/Rings-pattern.png');
            background-size: cover;
            width: 100vw;
            min-height: 100vh;
            height: 100%;
            padding: 80px 0;
`


/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const TemplateRenderer = () => {
    const [templateResponse, setTemplateResponse] = useState(null)
    const {campaign_slug} = useParams()
    const [activeTest, setActiveTest] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {

        const controller = new AbortController();
        // Load the data from the API
        LoadDataFromAPI();

        return () => controller.abort();

    }, [])

    const LoadDataFromAPI = async () => {
        if (_DEVELOPMENT) {
            setTemplateResponse(TEMPLATE_STRUCTURE);
            setActiveTest(_ACTIVE_TEST)

        } else {
            await getTemplateData(campaign_slug).then(r => {
                setTemplateResponse(r.template)
                setActiveTest(r.activeTest)
            }).catch(er => {
                setError(er.message)
            })

        }

    }

    return (
        <PageController>
            <>
                {
                    error &&
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="alert alert-danger">
                                    {error}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <a href="https://marriage365.com" target="_self"><u>Return Home</u></a>
                            </div>
                        </div>
                    </div>
                }
            </>
            {
                templateResponse &&
                templateResponse.map(block => {
                    block.activeTest = activeTest;
                    return Components(block)
                })
            }
        </PageController>

    )
};

export default TemplateRenderer;
