import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    applyMemberCoupon,
    restartSubscription,
    setAccountSettingsMessage,
    setCoupon,
    swapMembership,
    validateCouponCode,
} from "../store/actions";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./accountConfirmation.scss";


const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            applyMemberCoupon,
            restartSubscription,
            setAccountSettingsMessage,
            setCoupon,
            swapMembership,
            validateCouponCode,
        },
        dispatch
    )
};
const mapStateToProps = appState => ({
    alert: {message: appState.AccountSettings.message, type: appState.AccountSettings.messageType},
    coupon: appState.AccountSettings.coupon,
    eventLoading: appState.AccountSettings.changeSubscriptionLoading,
    loading: appState.AccountSettings.loading,
    response: appState.AccountSettings.response,
    subscription: appState.User.data.subscription,
    user: appState.User.data,
});

const ConfirmationContainer = styled.div`
    border-color: ${props => props.borderColor};
`


const ApplyCouponModal = ({
                              alert,
                              applyMemberCoupon,
                              coupon,
                              eventLoading,
                              loading,
                              response,
                              restartSubscription,
                              setAccountSettingsMessage,
                              setCoupon,
                              subscription,
                              swapMembership,
                              validateCouponCode,
                              ...props
                          }) => {
    const couponInput = React.useRef();
    const navigate = useNavigate();

    const [disableCouponButton, setDisableCouponButton] = React.useState(true)


    function ResetCouponSettings() {
        setAccountSettingsMessage({message: null, type: null})
        setCoupon(null)
    }


    const confirmChange = () => {
        if (coupon !== null) {
            // send the request to the server
            applyMemberCoupon(coupon.code, 'profile-coupon')
        } else {
            setAccountSettingsMessage({
                message: 'Please enter a valid coupon code before confirming change.',
                type: 'danger'
            })
        }


    }

    const closeModal = () => {
        // Set any error or success messages from a previous input to null
        ResetCouponSettings()
        props.setIsCouponModalShown(false);
    }


    const unlockCoupon = () => {
        if (couponInput.current.value === "") {
            setDisableCouponButton(true)
        } else {
            setDisableCouponButton(false)
        }
    }

    const checkCouponValidity = () => {
        /**
         * Build the coupon object to send to the server.
         * @type {{coupon: *, is_swap: boolean}}
         * is_swap determines whether the user is trying to swap plans
         */
        let code = {
            coupon: couponInput.current.value,
            is_swap: false,
            swap_to: props.plan.name
        };

        // Set any error or success messages from a previous code to null
        ResetCouponSettings()

        // send the new code object to the server
        validateCouponCode(code);
    }


    return (
        <div className="overlay">
            <div className="account-confirmation">
                <ConfirmationContainer className="account-confirmation_modal"
                                       borderColor={coupon ? '#4AC86A' : '#F88A09'}>
                    <div className="modal-content_container">
                        <div className="close-modal_x" onClick={closeModal}></div>
                        <h1>{response ? response.title : 'Enter Coupon'}</h1>
                        <div className="subtitle bold-text">
                            {response ? response.content : 'Please enter the coupon and click apply'}
                        </div>
                        <div className="account-details">
                            {
                                alert.message &&
                                <div dangerouslySetInnerHTML={{__html: alert.message}}
                                     className={`mt-3 alert alert-${alert.type}`}/>
                            }
                            {
                                (response === null || response.status === 'error') &&
                                <div className="d-flex flex-row align-items-center mt-3 gap-7">
                                    <input ref={couponInput} type="text" onChange={unlockCoupon}
                                           placeholder={'Enter Coupon'}/>
                                    <button
                                        className={`btn ${disableCouponButton ? 'disabled-button' : 'active-button'} apply-coupon_button`}
                                        onClick={!disableCouponButton ? checkCouponValidity : () => {
                                        }}>
                                        {
                                            !loading ?
                                                <span className="apply-text">Apply</span>
                                                :
                                                <ScaleLoader height="10px" color="#fff"/>
                                        }
                                    </button>
                                </div>
                            }
                        </div>

                        {
                            response && response.status === 'success' &&
                            <div className="subtitle">
                                Your account will continue to renew on each payment date until you cancel.
                            </div>
                        }
                        {
                            response && response.status === 'success' ? (
                                <div className="account-button_container">
                                    <div className="account-button full-button" onClick={() => navigate(0)}>
                                        <div className="account-button_text">Close Window</div>
                                    </div>
                                </div>
                            ) : (
                                <div className="account-button_container">
                                    <div className="account-button back-button" onClick={closeModal}>
                                        <div className="account-button_text">Back</div>
                                    </div>
                                    <div className="account-button" onClick={confirmChange}>
                                        <div className="account-button_text">
                                            {
                                                eventLoading ?
                                                    <ScaleLoader height="10px" color="#fff"/>
                                                    :
                                                    <span>Confirm Change</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </ConfirmationContainer>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyCouponModal)
