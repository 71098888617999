import React, {Fragment} from 'react';
import PlayIcon from '../AssetButton/assets/play';
import TimePill from '../Pills/timePill';
import CustomProgressBar from '../ProgressBar/progress-bar';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import './trendingCard.scss';

const GeneralAssetCard = (props) => {

    const ItemMeta = styled.div`
        background-image: url(${props.src});
    `
    const GeneralContainer = styled.div`
        height: ${props.cardHeight}px;
    `

    return (
        <>
            <GeneralContainer className="general-asset_container">

                <ItemMeta className="general-asset_card">
                    {/* {props.category !== undefined && (
                    <CategoryPill category={props.category} position="top-right" />
                    )} */}
                    {
                        props.assetType === "video" && (<PlayIcon/>)

                    }
                    {
                        props.duration && (<TimePill duration={props.duration} position="bottom-right"/>)
                    }
                    {
                        props.showProgress && (<CustomProgressBar progressPercentage={props.progressPercentage}/>)
                    }
                </ItemMeta>
            </GeneralContainer>
            <div className="general-card_title">
                {props.title}
            </div>
            {
                props.category &&
                <div className="general-card_category">
                    {props.category}
                </div>

            }
        </>
    )
}

GeneralAssetCard.propTypes = {
    assetType: PropTypes.string.isRequired,
    category: PropTypes.string,
    cardHeight: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    showProgress: PropTypes.bool,
    src: PropTypes.string.isRequired,
    duration: PropTypes.string,
}


export default GeneralAssetCard;
