import React from "react";

export function CreatePerformanceScoreCards(performanceObj) {

    let {COURSES_COMPLETE, MINUTES_WATCHED, VIDEOS_WATCHED, WORKSHEETS_OPENED} = performanceObj;
    let cards = [];

    let minutesWatchedCard = (
        <div className="performance-card" style={{backgroundColor: `${MINUTES_WATCHED.backgroundColor.hex}`}}>
            <div className="stat-icon_container">
                <div className="stat-number">
                    {MINUTES_WATCHED.stat}
                </div>
                <div className="performance-icon"
                     style={{backgroundImage: `url(${MINUTES_WATCHED.icon.svg})`}}/>
            </div>
            <div className="performance-subtitle">Minutes Watched</div>
        </div>);


    let videosWatchedCard = (
        <div className="performance-card" style={{backgroundColor: `${VIDEOS_WATCHED.backgroundColor.hex}`}}>
            <div className="stat-icon_container">
                <div className="stat-number">
                    {VIDEOS_WATCHED.stat}
                </div>
                <div className="performance-icon"
                     style={{backgroundImage: `url(${VIDEOS_WATCHED.icon.svg})`}}/>
            </div>
            <div className="performance-subtitle">Videos Watched</div>
        </div>);


    let worksheetsOpenedCard = (
        <div className="performance-card" style={{backgroundColor: `${WORKSHEETS_OPENED.backgroundColor.hex}`}}>
            <div className="stat-icon_container">
                <div className="stat-number">
                    {WORKSHEETS_OPENED.stat}
                </div>
                <div className="performance-icon"
                     style={{backgroundImage: `url(${WORKSHEETS_OPENED.icon.svg})`}}/>
            </div>
            <div className="performance-subtitle">Worksheets Opened</div>
        </div>);

    let courseCompleteCard = (
        <div className="performance-card" style={{backgroundColor: `${COURSES_COMPLETE.backgroundColor.hex}`}}>
            <div className="stat-icon_container">
                <div className="stat-number">
                    {COURSES_COMPLETE.stat}
                </div>
                <div className="performance-icon"
                     style={{backgroundImage: `url(${COURSES_COMPLETE.icon.svg})`}}/>
            </div>
            <div className="performance-subtitle">Courses Complete</div>
        </div>);

    cards.push(minutesWatchedCard)
    cards.push(videosWatchedCard)
    cards.push(worksheetsOpenedCard)
    cards.push(courseCompleteCard)

    return cards;


}
