import React from 'react'
import Components from "../../Helpers/componentHelper"
import PropTypes from "prop-types";
import styled from "styled-components";


const ColumnContainer = styled.div`
        ${props => props.properties}
    `

export const Column = ({block, data}) => {
    // console.log('column block: ', block)
    // console.log('column data: ', data)
    return (
        <ColumnContainer properties={data.properties}>
            {
                (block.children || []).map(child => Components(child))
            }
        </ColumnContainer>
    )
}

Column.propTypes = {}
