import * as TYPES from "./actionTypes"

export const screenResize = () => {
    return {
        type: TYPES.SCREEN_RESIZE,
    };
}

export const screenResizeSuccess = (width) => {
    return {
        type: TYPES.SCREEN_RESIZE_SUCCESS,
        screenWidth: width
    }
}


