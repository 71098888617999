import styled from "styled-components";

const ListContainer = styled.div`
  position: relative;
  margin-left: 61px;
  max-width: 688px;
  padding-top: 112px;
  height: 100%;
  @media screen and (max-width: 480px) {
    padding-left: 29px;
    padding-right: 29px;
    margin-left: 0;
  }

  h1 {
    margin-bottom: 50px;
  }

`

export {ListContainer}
