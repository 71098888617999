var GenIcon = require('../../../@splitty/Icons/lib/iconBase').GenIcon


module.exports.LeftChevronCircle = function LeftChevronCircle(props) {
    return GenIcon({
        "tag": "svg",
        "attr": {"fill": "none", "viewBox": "0 0 32 32"},
        "child": [
            {
                "tag": "rect",
                "attr": {
                    "rx": "16",
                    "width": props.size,
                    "height": props.size,
                    "fill": props.primary,
                    "fillOpacity": props.opacity ?? 1
                },

            },
            {
                "tag": "path",
                "attr": {
                    "d": "M18.1657 10.6343C18.4781 10.9468 18.4781 11.4533 18.1657 11.7657L13.9314 16L18.1657 20.2343C18.4781 20.5468 18.4781 21.0533 18.1657 21.3657C17.8533 21.6781 17.3467 21.6781 17.0343 21.3657L12.2343 16.5657C11.9219 16.2533 11.9219 15.7468 12.2343 15.4343L17.0343 10.6343C17.3467 10.3219 17.8533 10.3219 18.1657 10.6343Z",
                    "fill": props.secondary,
                    "fillRule": "evenodd",
                    "clipRule": "evenodd",

                }
            }
        ]
    })(props);
};
