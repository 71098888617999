function RemoveClass(className) {
    var forEach = Array.prototype.forEach;
    forEach.call(document.querySelectorAll("." + className), function (node) {
        node.classList.remove(className);
    });
}



export {RemoveClass}
