/**
 * Braintree Dropin component presented during re-registration
 */
import React, {useState} from "react"
import dropin from "braintree-web-drop-in"
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import styled from "styled-components";
import * as STYLES from "../../Templates/Global/default-styles";


const BraintreeButton = styled.button`
    ${STYLES.BRAINTREE_FORM_BUTTON}
    &.inactive {
        background: #2D5F62;
        opacity: .5;
    }
`
const ButtonText = styled.div`
    ${STYLES.CREATE_ACCOUNT_BTN}
`

const BraintreeContainer = styled.div`
    max-width: 90%;
    margin: 0 auto;
`

/**
 * THIS IS THE DROPIN FOR AN EXISTING USER
 * @type {React.NamedExoticComponent<{readonly user?: *, readonly setBraintreeNonce?: *}>}
 */
export const BraintreeDropin = React.memo(({user, setBraintreeNonce}) => {
    const [paymentError, setPaymentError] = React.useState(null);
    const [braintreeInstance, setBraintreeInstance] = React.useState(undefined);
    const [braintreeClientToken, setBraintreeClientToken] = React.useState(null)

    const [paymentButtonLoader, setPaymentButtonLoader] = useState(true);
    const [activeButton, setActiveButton] = useState(false)
    const BTDropin = React.useRef();


    React.useEffect(() => {
        GetBraintreeClientToken();
    }, []);

    React.useEffect(() => {
        if (braintreeClientToken && JSON.stringify(user) !== '{}') {
            BTDropin.current.innerHTML = '';
            const initializeBraintree = () => dropin.create({
                authorization: braintreeClientToken,
                container: '#braintree-dropin-div',
                paypal: {
                    flow: 'vault'
                },
            }, function (error, instance) {
                if (error) {
                    setPaymentError(error)
                } else
                    setBraintreeInstance(instance);
            });

            if (braintreeInstance) {
                braintreeInstance.teardown().then(() => {
                    initializeBraintree();
                });
            } else {
                initializeBraintree();
            }
        }

    }, [braintreeClientToken, user])


    React.useEffect(() => {
        if (braintreeInstance) {
            braintreeInstance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                if (requestPaymentMethodErr) {
                    setPaymentError(requestPaymentMethodErr)
                } else {
                    // setPaymentNonce(payload.nonce)
                        setBraintreeNonce(payload.nonce)

                }
            });
        }
    }, [braintreeInstance])


    async function GetBraintreeClientToken() {
        await axios.get('/braintree-client-token').then(response => {
            setBraintreeClientToken(response.data.token)
        }).catch(() => {
            setPaymentError('We are having trouble getting your payment information')
        })
    }

    async function SetNewPaymentMethodAsDefault(nonce) {
        await axios.post('/set-default-payment-method', {
            payment_method_nonce: nonce
        }).then(r => {

        }).finally(() => {
            setPaymentButtonLoader(true)
            setActiveButton(false)
        })
    }


    return (
        <React.Fragment>
            {
                paymentError !== null &&
                <div className="alert-danger alert">
                    {paymentError.message}
                </div>
            }
            <BraintreeContainer>
                <div id="braintree-dropin-div" ref={BTDropin} onClick={() => setActiveButton(true)}></div>
                <BraintreeButton
                    type="primary"
                    disabled={!braintreeInstance || !activeButton}
                    className={activeButton ? 'active' : 'inactive'}
                    onClick={() => {
                        setPaymentButtonLoader(false)
                        setActiveButton(false)
                        if (braintreeInstance) {
                            braintreeInstance.requestPaymentMethod(
                                (requestPaymentMethodErr, payload) => {
                                    if (requestPaymentMethodErr) {
                                        setPaymentError(requestPaymentMethodErr.message)
                                    } else {
                                        setBraintreeNonce(payload.nonce)
                                        SetNewPaymentMethodAsDefault(payload.nonce)
                                    }
                                });
                        }
                    }}>
                    {
                        <ButtonText>
                            {
                                (paymentButtonLoader) ? <span>Continue</span> :
                                    <ScaleLoader height="10px" color="#fff"/>
                            }
                        </ButtonText>
                    }
                </BraintreeButton>
            </BraintreeContainer>
        </React.Fragment>

    )
});
