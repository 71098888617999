import mixpanel from 'mixpanel-browser';

// mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN, {debug: false});
mixpanel.init('43374a9d467c9386b29124061ad3f6ef', {debug: false});

export async function Identify() {
    if (!mixpanel) return;

    mixpanel.identify()
}

/**
 *
 * @param location
 * @param user
 * @param category
 * @param name
 * @param assetTitle
 * @param coreCategory
 */
export async function HandleRouteChange(location, user, category, name, assetTitle = null, coreCategory = null) {
    if (!mixpanel) return
    Identify().then(() => {
        mixpanel.track(
            "Loaded a Page",
            {
                "userId": user.email,
                "category": category,
                "pageName": name,
                "url": location.pathname,
                "title": assetTitle,
                "coreCategory": coreCategory
            }
        )
    })
}

/**
 *
 * @param {String} event
 * @param {Object} properties
 * @param {Object} options
 * @param {Function} callback
 * @returns {Promise<void>}
 * @constructor
 */
export async function Track(event, properties = null, options = null, callback = null) {
    if (!mixpanel) return;

    mixpanel.track(event, properties, options, callback)
}

/**
 *
 * @param {Object || String} properties // If a string, this is the name of the property. If an object, this is an associative array of names and values.
 * @returns {Promise<void>}
 * @constructor
 */
export async function SetPeopleProperties(properties) {
    if (!mixpanel) return;

    mixpanel.people.set(properties)
}

/**
 *
 * @param {String} prop
 * @param {Number} by
 * @returns {Promise<void>}
 * @constructor
 */
export async function IncrementPeopleProperties(prop, by = 1) {
    if (!mixpanel) return;

    // mixpanel.people.set_once(prop, by)

    mixpanel.people.increment(prop, by)
}

/**
 *
 * @param {String} alias
 * @param {String} original
 * @returns {Promise<void>}
 * @constructor
 */
export async function Alias(alias, original = null) {
    if (!mixpanel) return;

    mixpanel.alias(alias, original)
}

/**
 * Register a set of super properties, which are included with all events. This will overwrite previous super property values
 * @param {Object} properties An associative array of properties to store about the user
 * @param {Number || Object }days_or_options Options object or number of days since the user's last visit to store the super properties (only valid for persisted props)
 * @returns {Promise<void>}
 * @constructor
 */
export async function RegisterSuperProperties(properties, days_or_options = null) {
    if (!mixpanel) return;

    mixpanel.register(properties, days_or_options)
}

