import {GetBrowserType} from "../../utils/get-browser";
import {Track} from "../../utils/Segment/segment-methods";

export const onReady = (document) => {
    let closeButton = document.getElementById('close-button');
    closeButton.classList.remove('hide');
}

export const onPlay = (document) => {
    let closeButton = document.getElementById('close-button');
    closeButton.classList.add('hide');
};

export const onResume = (document) => {
    let closeButton = document.getElementById('close-button');
    closeButton.classList.add('hide');
}

export const onTenSeconds = (props) => {
   //TODO: send progress to server to send to Segment
}

/**
 * On video pause, show the close button and save the video progress
 * @param asset_id
 * @param document
 * @param current_position
 * @param saveProgress
 */
export const onPause = ({asset_id, document, current_position, saveProgress}) => {
    let closeButton = document.getElementById('close-button');
    closeButton.classList.remove('hide');
    return sendProgress({asset_id, current_position, saveProgress});
}


/**
 *
 * @param props
 * @param complete
 * @returns {{saveProgress: *, document: *, current_position: number, asset_id}}
 */
export const getVideoDataForEvent = (props, complete = null) => {

    if (complete) {
        // console.log('******VIDEO IS COMPLETE*******', props)
        // Track(props.user, 'video_completed', {
        //     "Title": props.video.title,
        //     "Category": props.video.core_categories.toString(),
        //
        // })
        let closeButton = props.document.getElementById('close-button');
        closeButton.classList.remove('hide');
    }


    return {
        'asset_id': props.video.asset_id,
        'document': props.document,
        'current_position': window.jwplayer(props.playerId).getPosition(),
        'saveProgress': props.saveProgress
    }
}

/**
 *
 * @param asset_id
 * @param current_position
 * @param saveProgress
 * @returns {Promise<void>}
 */
export const sendProgress = async ({asset_id, current_position, saveProgress}) => {
    // TODO: include a saga action to add video to continue watching in redux state.
    // TODO: if video is complete, initiate an action to remove from state.

    let currentBrowser = GetBrowserType();

    let data = {
        browser: currentBrowser,
        sec: current_position
    }

    saveProgress(asset_id, data)

}

/**
 *
 * @param video
 * @returns {[{default: boolean, file: string, kind: string, label: string}]|*[]}
 */
export const getVTT = (video) => {
    if (video.vtt) {
        return [{
            file: `${video.vtt}`,
            label: 'English',
            kind: 'captions',
            'default': false
        }]
    } else {
        return [];
    }

}
