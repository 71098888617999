/**
 * List Item component should contain
 *      - title*
 *      - subtitle
 *      - icon
 *      - ability to adjust color on all elements
 */
import React, {Fragment} from "react"
import styled from "styled-components";
import PropTypes from "prop-types";
import * as TEMPLATE_STYLES from "../../Templates/Global/default-styles";
import {SplittyCircleCheckMultiFill} from "../../Icons/splittyIcons/index"

const Title = styled.p`
        ${TEMPLATE_STYLES.PARAGRAPH_STYLE}
        ${props => props.properties}
    `

const Subtitle = styled.p`
        ${TEMPLATE_STYLES.PARAGRAPH_STYLE}
        ${props => props.properties}
    `
export const ListItem = ({icon, title, subtitle}) => {

    return (
        <Fragment>
            {/* TODO: update icon to be dynamic component */}
            <SplittyCircleCheckMultiFill size={40} primary={`#2D5F62`} secondary={`#BBD2D3`}/>
            <Title properties={title.properties}>{title.text}</Title>
            <Subtitle properties={subtitle.properties}>{subtitle.text}</Subtitle>
        </Fragment>

    )
}


ListItem.propTypes = {
    icon: PropTypes.shape({
        url: PropTypes.string,
        properties: PropTypes.object
    }),
    title: PropTypes.shape({
        text: PropTypes.string,
        properties: PropTypes.object
    }).isRequired,
    subtitle: PropTypes.shape({
        text: PropTypes.string,
        properties: PropTypes.object
    })
}
