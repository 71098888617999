import PropTypes from 'prop-types';
export const USER_SUBSCRIPTION_INFO_PROPTYPES = {
    account_status: PropTypes.string.isRequired,
    billing_period_end_date: PropTypes.string.isRequired,
    next_billing_amount: PropTypes.string.isRequired,
    next_billing_date: PropTypes.string.isRequired,
    plan_name: PropTypes.string.isRequired,
    paid_through_date: PropTypes.string.isRequired,
    subscription_id: PropTypes.number.isRequired,
};
export const SUBSCRIPTION_TYPE_PLAN_ITEM_PROPTYPES = {
    bestValue: PropTypes.bool.isRequired,
    cancellationOffer: PropTypes.shape({
        amount: PropTypes.string,
        coupon: PropTypes.string,
        duration: PropTypes.string,
    }).isRequired,
    component: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.shape({
        amount: PropTypes.string,
        symbol: PropTypes.string,
        currency: PropTypes.string,
        renewalDuration: PropTypes.string,
    }).isRequired,
    subText: PropTypes.string.isRequired,
};
export const SUBSCRIPTION_TYPE_PLANS_INFO_PROPTYPES = PropTypes.shape({
    extras: PropTypes.arrayOf(PropTypes.shape({})),
    items: PropTypes.arrayOf(PropTypes.shape(SUBSCRIPTION_TYPE_PLAN_ITEM_PROPTYPES)),
});
