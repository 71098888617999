import Moment from "react-moment";
import React from "react";

/**
 *
 * @param date
 * @returns {JSX.Element}
 * @constructor
 */
export function FormatLastCheckupDate(date) {
    if (date) {
        return (
            <Moment local format="MMMM D, YYYY" withTitle
                    add={{days: 0}}>{date}</Moment>
        )
    } else {
        return (<span>n/a</span>)
    }
}
