import React, {useState, useRef} from 'react';
import axios from 'axios';
import RegistrationCarousel from '../components/Carousels/registration-carousel';

import {useForm} from 'react-hook-form';

import {useNavigate} from 'react-router-dom';

import './redeem-page.scss';
import ScaleLoader from "react-spinners/ScaleLoader";

const RedeemPage = () => {

    const navigate = useNavigate();
    const [isTermsAgreed, setIsTermsAgreed] = useState(false);
    const [redeemErrors, setRedeemErrors] = useState(null);
    const [redeemButtonActive, setRedeemButtonActive] = useState(true)
    const {register, handleSubmit, watch, formState: {isValid}} = useForm();

    window.addEventListener('click', () => {
        setRedeemErrors(null)
    });


    const onSubmit = (data) => {
        setRedeemButtonActive(false)

        if (isTermsAgreed) {
            data.terms = "accepted"
        } else {
            return setRedeemErrors('You must agree to our terms and conditions.')
        }


        axios.post('/redeem-code', data).then((resp) => {
            navigate('/complete-registration');
        }).catch(error => {
            setRedeemButtonActive(false)
            setRedeemErrors(error.response.data.message)

        })

    }

    const toggleTerms = () => {
        setIsTermsAgreed(!isTermsAgreed);
    }

    let settle1 = (<div className="settle-item_container">
        <div className='settle-color_title'>You Need Clear Communication</div>
        <div className='settle-text'>Our practical tools help struggling couples learn to express themselves in healthy
            ways.
        </div>
    </div>);
    let settle2 = (<div className="settle-item_container">
        <div className='settle-color_title'>You Deserve Emotional Intimacy</div>
        <div className='settle-text'>Members who watch our videos and use our worksheets feel more known, understood,
            and heard.
        </div>
    </div>);
    let settle3 = (<div className="settle-item_container">
        <div className='settle-color_title'>You Can Create Healthy Boundaries</div>
        <div className='settle-text'>Gain the confidence you need to establish and maintain the boundaries necessary to
            grow.
        </div>
    </div>);
    let testimonial1 = (<div className='testimonial' id="testimonial-1">
        <div className="text-message">
            I assumed I would be underwhelmed with your membership and to my surprise, I'm
            humbled
            and so grateful to have found this.
            I just watched how to forgive your spouse and feel like I finally understand
            what to
            do
            and a huge weight has been lifted.
            I'm telling everyone I know to sign up!
            <div className="emoji-wink__container">
                <div className="emoji-wink"/>
            </div>
        </div>

        <div className="heart-icon__container">
            <div className="heart-icon"/>
        </div>
    </div>);
    let testimonial2 = (<div className='testimonial' id="testimonial-2">
        <div className="text-message">
            A loyal Marriage365 husband and member.
            I am who I am because of you guys and all that you've done for me.
            When I signed up, I was in a really bad place.
            You've given me the steps to take to earn back my wife's trust and more than
            anything,
            you gave me hope.

        </div>
        <div className="heart-icon__container">
            <div className="heart-icon"/>
        </div>
    </div>);
    let testimonial3 = (<div className='testimonial' id="testimonial-3">
        <div className="text-message">
            I just want to thank you guys for making me realize what marriage is really
            about.
            My wife and I were in a rough spot in our marriage.
            When I became a 365 member, it totally changed my mindset.
            Things have gotten better.
            Watching your videos made me realize what I wasn't bringing to the marriage.
            Thank you again!!

        </div>
        <div className="heart-icon__container">
            <div className="heart-icon"/>
        </div>
    </div>);

    return (
        <div id="first-registration_page">
            <div className="left-column">
                <div className='logo-container'/>
                <div className='registration-title'>
                    Get your account Started
                </div>
                <div className='registration-subtitle'>
                    Say <span>goodbye</span> to an average marriage!
                </div>
                <div className="form_container">
                    <form className="registration-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="full-length_container">
                            <div className='input-container'>
                                <label className="input-label">First Name</label>
                                <input {...register("first_name", {required: true})} />
                            </div>
                            <div className="input-container">
                                <label className="input-label">Last Name</label>
                                <input {...register("last_name", {required: true})} />
                            </div>

                            <div className='input-container'>
                                <label className="input-label">Email Address</label>
                                <input  {...register("email", {required: true})} type="email" autoComplete={false}/>
                            </div>
                            <div className='input-container'>
                                <label className="input-label">Phone</label>
                                <input {...register("mobile_phone")}/>
                            </div>
                            <div className='input-container'>
                                <label className="input-label">Redemption Code</label>
                                <input {...register("coupon", {required: true})} />
                            </div>
                        </div>
                        <div className="terms-container">
                            <div className={`custom-terms_dot ${isTermsAgreed ? 'active' : ''}`}
                                 onClick={toggleTerms}/>
                            <div className='terms-text'>I agree to the Marriage365 <a id="terms"
                                                                                      href="https://www.marriage365.com/mymarriage365-terms-conditions"
                                                                                      target="_blank">Terms and
                                Conditions</a></div>
                        </div>
                        {
                            redeemErrors &&
                            <div className="alert-danger alert mt-4">
                                {redeemErrors}
                            </div>
                        }
                        <button className="create-button_container">
                            <div className="create-account">
                                {
                                    (redeemButtonActive && !isValid) ?
                                        <span>Create Account</span>
                                        :
                                        <ScaleLoader height="10px" color="#fff"/>
                                }

                            </div>
                        </button>
                    </form>
                </div>
                <div className='copyright-container mt-2'>
                    © 2023 Marriage365
                </div>
            </div>
            <div className='right-column'>
                <div className='settle-container'>
                    <div className='settle-color_content'>
                        <div className="color-overlay"/>
                        <div className='settle-title'>Don't settle for an <br/>average marriage.
                            <div className='color-highlight'/>
                        </div>
                        <div className='settle-list'>
                            <div className='settle-color_title'>You Need Clear Communication</div>
                            <div className='settle-text'>Our practical tools help struggling couples learn to express
                                themselves in healthy ways.
                            </div>
                            <div className='settle-color_title'>You Deserve Emotional Intimacy</div>
                            <div className='settle-text'>Members who watch our videos and use our worksheets feel more
                                known, understood, and heard.
                            </div>
                            <div className='settle-color_title'>You Can Create Healthy Boundaries</div>
                            <div className='settle-text'>Gain the confidence you need to establish and maintain the
                                boundaries necessary to grow.
                            </div>
                        </div>
                        <div className="mobile-carousel_container">
                            <RegistrationCarousel
                                carouselItems={[settle1, settle2, settle3]}
                            />
                        </div>
                    </div>
                    <div className='settle-image'></div>
                    <div className='bottom-white_gradient'></div>
                </div>
                <div className='message-container'>
                    <div className='message-title'>Don't take it from us...</div>
                    <div className='message-subtitle'>These are just a few of the hundreds of messages we get on
                        Instagram from our Members
                    </div>
                    <div className='text-message_container'>

                        {testimonial1}
                        {testimonial2}
                        {testimonial3}


                    </div>
                    <div className="mobile-carousel_container">
                        <RegistrationCarousel
                            carouselItems={[testimonial1, testimonial2, testimonial3]}
                        />
                    </div>
                </div>
                <div className='membership-perks'>
                    <div className="perk-content">
                        <div className='perk-title'>Marriage365 Membership is the leading on-demand relationship support
                            resource to help you build a healthy marriage.
                        </div>
                        <div className="perk-color_title">Members get 24/7 access to...</div>
                        <ul className="perk-list">
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Over 200 videos that talk honestly about real life issues
                                    many marriages experience
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Over 75 downloadable worksheets with practical action steps
                                    to inspire change
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Connecting questions for every video that help you create
                                    meaningful discussion
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Interviews with experts who dive deeper into complex issues
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Topically based courses to give you a clear path of growth
                                    and insight
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">2 profiles for you and your spouse to engage with content
                                    that matters most to you
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>

        </div>)
}

export default RedeemPage;
