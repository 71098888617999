import React from 'react';
import TimePill from '../Pills/timePill';
import {Link, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import './newCourseStep.scss';

const NewCourseStep = (props) => {

    let navigate = useNavigate();
    let status = props.status;
    let link;
    if (status === 'disabled') {
        link = "#";
    } else {
        link = `/get-course-lesson/${props.course_uuid}/${props.lesson_uuid}`;
    }

    const changeLesson = () => {
        navigate(`/get-course-lesson/${props.course_uuid}/${props.lesson_uuid}`)
    }

    return (

        <div onClick={() => changeLesson()} className={`course-step_link pointer`} >
            <div className='course-step_container'>
                <div className={`status-dot ${status}`}/>
                <div className={`step-content_container ${props.status === "disabled" ? "" : props.status}`}>
                    <div className="step-title">
                        {props.name}
                    </div>
                    <div className="course-image" style={{backgroundImage: `url(${props.src})`}}>
                        <TimePill
                            duration={props.duration}
                            position={"bottom-right"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

NewCourseStep.propTypes = {
    course_uuid: PropTypes.string.isRequired,
    duration: PropTypes.string,
    lesson_uuid: PropTypes.string.isRequired,
    name: PropTypes.string,
    src: PropTypes.string,
    status: PropTypes.string,
}

export default NewCourseStep;
