import {
    del, get, post, put,
} from './api-helper.js';
import * as url from './url-helper.js';

/**
 * Declare all the route types as functions to be called in the saga files
 *
 */

// -SYSTEM SETTINGS
export const loadSystemSettings = () => get(url.LOAD_SYSTEM_SETTINGS_URL);

// -USER
export const getUser = () => get(url.GET_USER_URL);
export const updateUser = (data) => post(url.UPDATE_USER_PROFILE_URL, data);
export const updateProfileImage = (data) => post(url.UPDATE_PROFILE_IMAGE_URL, data, {
    headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
    },
});
export const updateUserPassword = (data) => post(url.UPDATE_USER_PASSWORD_URL, data);
export const addSecondaryUser = (data) => post(url.ADD_SECONDARY_USER_URL, data);
export const removeSecondaryUser = (data) => post(url.REMOVE_SECONDARY_USER_URL, data);

// -SUBSCRIPTION
export const cancelSubscription = (data) => post(url.CANCEL_SUBSCRIPTION_URL, data);
export const restartSubscription = (data) => post(url.RESTART_SUBSCRIPTION_URL, data);
export const changeMembershipFrequency = (data) => put(url.CHANGE_MEMBERSHIP_FREQUENCY, data);

// -AUTH
export const postLogin = (data) => post(url.POST_LOGIN_URL, data);
export const getSanctumCookie = () => get(url.SANCTUM_COOKIE_URL);
export const postLogout = () => post(url.POST_LOGOUT_URL);
export const postForgetPwd = (data) => post(url.POST_PASSWORD_FORGET_URL, data);
export const postProfile = (data) => post(url.POST_EDIT_PROFILE_URL, data);
export const postRegister = (data) => post(url.POST_REGISTER_URL, data);

// -CORE CATEGORIES
export const getCoreCategories = () => get(url.GET_CORE_CATEGORIES_URL);
export const getSingleCoreCategoryResources = (payload) => get(`${url.GET_SINGLE_CATEGORY_URL}resources/${payload.uuid}?skip=${payload.skip}&limit=${payload.limit}`);
export const getSingleCoreCategoryVideos = (payload) => get(`${url.GET_SINGLE_CATEGORY_URL}videos/${payload.uuid}?skip=${payload.skip}&limit=${payload.limit}`);
export const getSingleCoreCategoryTrending = (payload) => get(`${url.GET_SINGLE_CATEGORY_URL}trending/${payload.uuid}?skip=${payload.skip}&limit=${payload.limit}`);
export const getSingleCoreCategoryCourses = (payload) => get(`${url.GET_SINGLE_CATEGORY_URL}courses/${payload.uuid}?skip=${payload.skip}&limit=${payload.limit}`);

// -DASHBOARD
export const loadDashboardAssets = () => get(url.LOAD_DASHBOARD_URL);

// -ONBOARDING
export const getIntroSlider = () => get(url.GET_INTRO_SLIDER_URL);
export const getIntroQuestions = () => get(url.GET_INTRO_QUESTIONS_URL);
export const saveIntroQuestions = () => post(url.SAVE_INTRO_QUESTIONS_URL);
export const getWelcomeVideo = () => get(url.GET_WELCOME_VIDEO_URL);
export const hideWelcomeVideo = (uuid) => get(url.GET_WELCOME_VIDEO_URL + uuid);
export const resetWelcomeVideo = (uuid) => get(url.RESET_WELCOME_VIDEO_URL + uuid);
export const resetOnboarding = (uuid) => get(url.RESET_ONBOARDING_URL + uuid);

// -VIDEOS
export const getSingleVideo = (uuid) => get(url.SINGLE_VIDEO_URL + uuid);
export const getAllVideos = (payload) => get(`${url.GET_ALL_VIDEOS}?skip=${payload.skip}&limit=${payload.limit}`);

// -VIDEO EVENTS
// eslint-disable-next-line max-len
export const sendVideoEvent = (payload) => post(url.SEND_VIDEO_EVENT_URL + payload.uuid, payload.data);
export const getVideosByTag = (payload) => get(`${url.GET_VIDEOS_BY_TAG_URL}?tag=${payload.tag}&skip=${payload.skip}&limit=${payload.limit}`);

// -WATCHLIST
export const addToWatchlist = (payload) => post(`${url.ADD_TO_WATCHLIST_URL}${payload.assetType}/${payload.asset_id}`);

export const getWatchlist = () => get(url.GET_WATCHLIST_URL);
export const removeFromWatchlist = (payload) => del(`${url.REMOVE_FROM_WATCHLIST_URL}${payload.assetType}/${payload.asset_id}`);

// -HISTORY
export const getHistory = (payload) => get(`${url.GET_HISTORY_URL}?skip=${payload.skip}&limit=${payload.limit}&complete=${payload.complete}`);
export const removeFromHistory = (payload) => del(`${url.REMOVE_FROM_HISTORY_URL}${payload.assetType}/${payload.uuid}`);

// -CONTINUE
export const getContinueWatching = (payload) => get(`${url.GET_CONTINUE_WATCHING_URL}?skip=${payload.skip}&limit=${payload.limit}&complete=${payload.complete}`);

// -WORKSHEETS
export const getSingleWorksheet = (uuid) => get(url.GET_SINGLE_WORKSHEET_URL + uuid);
export const getAllWorksheets = (payload) => get(`${url.GET_ALL_WORKSHEETS}?skip=${payload.skip}&limit=${payload.limit}`);
export const markWorksheetRead = (uuid) => post(url.POST_SINGLE_WORKSHEET_URL + uuid);

// -NOTIFICATION PREFERENCES
export const getNotificationPreferences = () => get(url.GET_NOTIFICATION_PREFERENCES_URL);
export const updateNotificationPreferences = (payload) => post(
    url.UPDATE_NOTIFICATION_PREFERENCES_URL,
    payload,
);

// -MEMBERSHIP PERKS
export const getMembershipPerks = () => get(url.GET_MEMBERSHIP_PERKS_URL);

// -EXTRAS
export const getExtras = (payload) => get(url.GET_EXTRAS_URL + payload.type);

// -FAQ
export const getFAQ = () => get(url.GET_FAQ_URL);

// -MY MARRIAGE
export const getInfoMessages = (location) => get(url.GET_INFO_MESSAGES_URL + location);
export const getOverallStats = () => get(url.GET_STATS_URL);
export const getCategoryStats = (category_uuid) => get(url.GET_STATS_URL + category_uuid);
// eslint-disable-next-line max-len
export const getPerformanceScores = (category_uuid) => get(url.GET_PERFORMANCE_SCORES_URL + category_uuid);
// eslint-disable-next-line max-len
export const getRecommendedAssets = (category_uuid) => get(url.GET_MY_RECOMMENDED_ASSETS_URL + category_uuid);
export const postCheckup = (data) => post(url.POST_CHECKUP_URL, data);

// -COURSES
export const getSingleCourse = (uuid) => get(url.GET_SINGLE_COURSE_URL + uuid);
export const saveCourseProgress = (payload) => post(url.SAVE_COURSE_PROGRESS_URL, payload.data);
export const restartSingleCourse = (payload) => post(url.RESTART_COURSE_URL, payload);
export const getAllCourses = (payload) => get(`${url.GET_ALL_COURSES}?skip=${payload.skip}&limit=${payload.limit}`);

// -COUPON
export const checkCouponCode = (payload) => post(url.POST_COUPON_CODE_URL, payload);
export const applyCouponCode = (payload) => post(url.ACCEPT_MEMBER_COUPON_URL, payload);
// -Second User
export const getInfoDrawer = () => get(url.GET_INFO_DRAWER_URL);

// -CANCELLATION
export const cancellationOffer = (payload) => get(url.GET_CANCELLATION_OFFERS_URL + payload);

// -POP QUIZ
export const getPopQuizData = () => get(url.GET_POP_QUIZ_URL);
export const postPopQuizAnswer = (payload) => post(url.POST_POP_QUIZ_ANSWER_URL, payload);

// --DELETE ACCOUNT FOR USER
export const deleteAccount = (payload) => post(url.POST_DELETE_ACCOUNT_URL, payload);

export const partnerPairCode = (payload) => post(url.PARTNER_PAIR_CODE_URL, payload);

// --REDEEM PARTNER CODE FOR INVITED USER
export const registerWithPartnerCode = (payload) => post(
    url.POST_REEDEEM_PARTNER_CODE_URL,
    payload,
);

// --GET AVAILABLE SUBSCRIPTION PLAN TYPE FOR USER
export const getSubscriptionPlanType = (offeringId) => get(`${url.GET_SUSBSCRIPTION_PLAN_TYPE}${offeringId}`);
