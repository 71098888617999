import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import { bindActionCreators } from "redux";
import { HandleRouteChange } from "../../utils/Segment/segment-methods";
import { connect } from "react-redux";
import PropTypes from 'prop-types';

import { getAllCourses } from './store/actions';
import AssetListItem from "../../components/AssetListItem/ListItem";
import LazyLoad from 'react-lazyload';
import { forceCheck } from 'react-lazyload';

import "../Video/all-videos.scss";

// -DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getAllCourses
        },
        dispatch
    )
};

// -MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    courses: appState.Course.all,
});


const AllCourses = ({ user, getAllCourses, courses }) => {
    let navigate = useNavigate();
    let location = useLocation()

    const [isShownCourses, setIsShownCourses] = useState(undefined);
    const [isSkip, setIsSkip] = useState(0);
    const [isFlag, setIsFlag] = useState(false);
    const [isScrollBoxHeight, setIsScrollBoxHeight] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'All Courses')
        }
    }, [user, location])

    useEffect(() => {
        if (courses === null) {
            window.scroll(0, 0);
            getAllCourses({ skip: 0, limit: 20 });
        }
        if (isFlag) {
            incrementSkip(isSkip);
            setIsFlag(false);
        }
        styleCourses();
    }, [courses]);

    useEffect(() => {
        //get 90% of the screen height 
        let boxHeight = (window.innerHeight * .90);
        setIsScrollBoxHeight(boxHeight);
    }, [window.innerHeight, window.innerWidth]);

    const styleCourses = () => {
        if (isSkip === 0 && courses !== null) {
            setIsSkip(courses.length);
            setIsShownCourses(courses);
        }
    }

    const getMoreCourses = (e) => {
        forceCheck();

        let bottom;

        let scrollDifference = (e.target.scrollHeight - e.target.scrollTop) - e.target.clientHeight;

        if (scrollDifference < 10 && scrollDifference > -10) {
            bottom = true;
        } else {
            bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        }

        if (bottom && isLoading === false) {
            setIsLoading(true)
            setIsFlag(true);
            getAllCourses({ skip: isSkip, limit: 20 });
            setTimeout(()=>{setIsLoading(false)},500)
        }
    }

    const incrementSkip = (skip) => {
        if (courses === null || courses.length === 0) {
            return;
        }
        let currentCourses = [...courses]
        let nextSkip = currentCourses.length;
        setIsShownCourses(currentCourses);
        setIsSkip(nextSkip);
        forceCheck();
    }

    const goBack = () => {
        navigate(-1)
    }
    // console.log("Courses", courses);
    // console.log("Shown Courses", isShownCourses);

    return (

        isShownCourses === undefined || isShownCourses === null ?
            <LoadingScreen />
            :
            <div id="account-watchlist_page"  >
                <div className="back-link pointer">
                    <div className="back-button" onClick={goBack} />
                </div>

                <h1 className="pt-5 font-bold text-center mb-5">All Courses</h1>
                <div className="container lazy-load_container" style={{ overflowY: "scroll", height: "800px" }} onScroll={getMoreCourses}>

                    {
                        isShownCourses.map((w, i) => (

                            <LazyLoad key={i} overflow offset={600} height={353} scroll>
                                <AssetListItem
                                    link={`/course/${w.uuid}`}
                                    name={w.title}
                                    category={w.categories[0]}
                                    src={w.thumbnail}
                                    key={i}
                                    once={true} id={i} count={i + 1}
                                />
                            </LazyLoad>
                        ))
                    }
                </div>
            </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AllCourses)