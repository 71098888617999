import React, {useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import LoadingScreen from '../../components/LoadingScreen';
import {bindActionCreators} from "redux";
import {HandleRouteChange} from "../../utils/Segment/segment-methods";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import queryString from "query-string"
import AssetListItem from "../../components/AssetListItem/ListItem";
import {getAVideosByTag} from "../Video/store/actions";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getAVideosByTag
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    assets: appState.Video.taggedVideos
});


const ListMarriageQuickies = ({user, getAVideosByTag, assets}) => {
    let navigate = useNavigate();
    let location = useLocation()
    let params = queryString.parse(location.search)


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Marriage Quickies')
        }
    }, [user, location])

    useEffect(() => {
        window.scroll(0, 0);
        if (!assets) {
            getAVideosByTag({tag: params.term, skip: 0, limit: 100});
        }
    }, [])

    const goBack = () => {
        navigate(-1)
    }


    return (

        JSON.stringify(user) === '{}' || assets === null ?
            <LoadingScreen/>
            :
            <div id="account-watchlist_page">
                <div className="back-link pointer">
                    <div className="back-button" onClick={goBack}/>
                </div>

                <h1 className="pt-5 font-bold text-center mb-5">Marriage Quickies</h1>
                <div className="container">
                    {
                        assets.length > 0 ?
                            assets.map((w, i) => {
                                let link;
                                switch (w.asset_type) {
                                    case "video":
                                        link = '/watch';
                                        break;
                                    case "course":
                                        link = "/course";
                                        break;
                                    case "challenge":
                                        link = "/challenge";
                                        break;
                                    default:
                                        link = '/watch';
                                }
                                return <AssetListItem
                                    link={`${link}/${w.uuid}`}
                                    name={w.title}
                                    category={'Self-Discovery'}
                                    src={w.poster}
                                    key={i}
                                />
                            })
                            :
                            <div className="alert alert-danger">We are unable to find any resources that match your
                                search.</div>
                    }
                </div>

            </div>

    )

}

ListMarriageQuickies.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(ListMarriageQuickies)
