import React from 'react';
import styled from 'styled-components';
import { CommonColor, PrimaryColor, } from '../global/colors';
import { SofiaFont, } from '../global/fonts';
const defaultProps = {
    className: undefined,
    disabled: false,
    styles: {},
};
// Define styled button component
const StyledButton = styled.button `
    border-radius: 60px;
    border: 0.5px solid ${PrimaryColor.border};
    font-size: 18px;
    padding: 14px 32px 16px 32px;
    font-family: ${SofiaFont.Bold};
    transition: background-color 0.3s, color 0.3s;
    background-color: ${({ styles }) => styles?.active?.backgroundColor || PrimaryColor.main};
    color: ${({ styles }) => styles?.active?.color || CommonColor.white};
    &:active {
        background-color: ${PrimaryColor.pressed};
    }
    &:hover {
        background-color: ${({ styles }) => styles?.hover?.backgroundColor || PrimaryColor.hover};
        color: ${({ styles }) => styles?.hover?.color || styles?.active?.color};
    }
    &:disabled {
        background-color: ${({ styles }) => styles?.inactive?.backgroundColor || PrimaryColor.border};
        color: ${({ styles }) => styles?.inactive?.color || styles?.active?.color};
    }
    box-shadow: 0px 3px 12px 0px rgba(57, 57, 57, 0.08), 0px 1px 2px 0px rgba(57, 57, 57, 0.12);
    width: 100%;
`;
// Custom button component
function CustomButton({ className, disabled, styles, onClick, children, }) {
    return (React.createElement(StyledButton, { className: className, disabled: disabled, styles: styles, onClick: onClick }, children));
}
CustomButton.defaultProps = defaultProps;
export default CustomButton;
