import React, {useState, useEffect} from 'react';
import SwitchOption from '../../../../../components/AccountOptions/switchOption';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {updateNotificationPreferences, getNotificationPreferences} from "../../../store/actions";

import PropTypes from 'prop-types';

import './styles.scss';
import {HandleRouteChange} from "../../../../../utils/Segment/segment-methods";

const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        updateNotificationPreferences,
        getNotificationPreferences
    }, dispatch)
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    notificationPreferences: appState.AccountSettings.notificationPreferences,
});

const NotificationSettings2022 = ({
                                      notificationPreferences,
                                      getNotificationPreferences,
                                      updateNotificationPreferences
                                  }) => {

    const [isGeneral, setIsGeneral] = useState();
    const [isGeneralEmail, setIsGeneralEmail] = useState();
    const [isMyProgress, setIsMyProgress] = useState();
    const [isMyProgressEmail, setIsMyProgressEmail] = useState();
    const [isSuggested, setIsSuggested] = useState();
    const [isSuggestedEmail, setIsSuggestedEmail] = useState();
    const [isContinue, setIsContinue] = useState();
    const [isContinueEmail, setIsContinueEmail] = useState();
    const [isNotification, setIsNotification] = useState();
    const [isNotificationEmail, setIsNotificationEmail] = useState();

    const [isSwitches, setIsSwitches] = useState([]);

    useEffect(() => {
        if (notificationPreferences !== null) {
            setNotificationData();
        } else {
            getNotificationPreferences()
        }
    }, [notificationPreferences]);

    /**
     * Send page view to Mixpanel
     */
    React.useEffect(() => {
        HandleRouteChange({pathname: '/account/notification-preferences'}, {email: null}, 'page-view', 'Notification Preferences')
    }, [])

    const setNotificationData = () => {
        setIsSwitches(notificationPreferences);
        setSwitches(notificationPreferences);
    }

    const setSwitches = (array) => {
        if (array === null) {
            return;
        }
        array.forEach((e) => {
            switch (e.id) {
                case 1:
                    if (e.push_enabled == 1) {
                        setIsGeneral(true);
                    } else {
                        setIsGeneral(false);
                    }
                    if (e.email_enabled !== undefined && e.email_enabled == 1) {
                        setIsGeneralEmail(true);
                    } else {
                        setIsGeneralEmail(false);
                    }
                    break;
                case 2:
                    if (e.push_enabled == 1) {
                        setIsMyProgress(true);
                    } else {
                        setIsMyProgress(false);
                    }
                    if (e.email_enabled !== undefined && e.email_enabled == 1) {
                        setIsMyProgressEmail(true);
                    } else {
                        setIsMyProgressEmail(false);
                    }
                    break;
                case 3:
                    if (e.push_enabled == 1) {
                        setIsSuggested(true);
                    } else {
                        setIsSuggested(false);
                    }
                    if (e.email_enabled !== undefined && e.email_enabled == 1) {
                        setIsSuggestedEmail(true);
                    } else {
                        setIsSuggestedEmail(false);
                    }
                    break;
                case 4:
                    if (e.push_enabled == 1) {
                        setIsContinue(true);
                    } else {
                        setIsContinue(false);
                    }
                    if (e.email_enabled !== undefined && e.email_enabled == 1) {
                        setIsContinueEmail(true);
                    } else {
                        setIsContinueEmail(false);
                    }
                    break;
                case 5:
                    if (e.push_enabled == 1) {
                        setIsNotification(true);
                    } else {
                        setIsNotification(false);
                    }
                    if (e.email_enabled !== undefined && e.email_enabled == 1) {
                        setIsNotificationEmail(true);
                    } else {
                        setIsNotificationEmail(false);
                    }
                    break;
            }
        })
    }

    const updateNotificationSettings = (updateObj) => {
        updateNotificationPreferences(updateObj)
    }


    return (
        <div id="settings-notifications_2022">
            <h3 className="font-bold">Notifications</h3>
            <div className="settings-notification_content__container">
                {isSwitches.map((e) => {
                    let state, setState, emailState, setEmailState;
                    switch (e.id) {
                        case 1:
                            state = isGeneral;
                            setState = setIsGeneral;
                            emailState = isGeneralEmail;
                            setEmailState = setIsGeneralEmail;
                            break;
                        case 2:
                            state = isMyProgress;
                            setState = setIsMyProgress;
                            emailState = isMyProgressEmail;
                            setEmailState = setIsMyProgressEmail;
                            break;
                        case 3:
                            state = isSuggested;
                            setState = setIsSuggested;
                            emailState = isSuggestedEmail;
                            setEmailState = setIsSuggestedEmail;
                            break;
                        case 4:
                            state = isContinue;
                            setState = setIsContinue;
                            emailState = isContinueEmail;
                            setEmailState = setIsContinueEmail;
                            break;
                        case 5:
                            state = isNotification;
                            setState = setIsNotification;
                            emailState = isNotificationEmail;
                            setEmailState = setIsNotificationEmail;
                            break;
                    }
                    if (e.email_enabled !== undefined) {
                        return (
                            <div className="notification-content_email" key={Math.random(100)}>
                                <div className="notification-title">{e.title}</div>
                                <div className='notification-description'>{e.description}</div>

                                <SwitchOption
                                    id={e.id}
                                    title={'Notification'}
                                    subtitle={''}
                                    switchState={state}
                                    setSwitchState={setState}
                                    updateNotificationSettings={updateNotificationSettings}
                                />
                                <SwitchOption
                                    id={e.id}
                                    title={'Email'}
                                    subtitle={''}
                                    switchState={emailState}
                                    setSwitchState={setEmailState}
                                    updateNotificationSettings={updateNotificationSettings}
                                    isEmail={true}
                                />

                            </div>
                        )
                    }
                    return (
                        <div className="notification-content" key={Math.random(100)}>
                            <SwitchOption
                                id={e.id}
                                title={e.title}
                                subtitle={e.description}
                                switchState={state}
                                setSwitchState={setState}
                                updateNotificationSettings={updateNotificationSettings}
                            />
                        </div>
                    )
                })}
            </div>
        </div>)
}

NotificationSettings2022.propTypes = {
    notificationPreferences: PropTypes.array,
    updateNotificationPreferences: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettings2022)
