import React, {useState, useEffect, useRef, Fragment} from 'react';
import NewCourseStep from '../../components/CourseStep/newCourseStep';
import CourseHeaderDesktop from '../../components/common/header-component/course-header-desktop';
import CourseHeader from '../../components/common/header-component/course-header';
import CoursePickAsset from '../../components/CoursePickAsset';
import WhitePlayButton from '../../assets/images/white-play-button-hollow.svg';
import './single-course.scss';
import {useLocation, useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {getSingleCourse, restartCourse} from "./store/actions";
import {UseSystemSettings} from "../../utils/SystemSettings/provider/system-settings";
import {connect} from "react-redux";
import LoadingScreen from "../../components/LoadingScreen";
import DesktopNav from "../../components/common/nav/desktop-nav";
import {HandleRouteChange} from "../../utils/Segment/segment-methods";
import PropTypes from 'prop-types';

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getSingleCourse,
            restartCourse
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    course: appState.Course.data,
    courseLoading: appState.Course.courseLoading,
    screenWidth: appState.UserInterface.screenWidth,
    lastLoadedUUID: appState.Course.lastLoadedUUID,
    user: appState.User.data,
    restartingCourse: appState.Course.restartingCourse,
});

//TODO: BREAK THIS FILE UP INTO SMALLER FILES.
const SingleCourse = ({
                          course,
                          courseLoading,
                          screenWidth,
                          getSingleCourse,
                          lastLoadedUUID,
                          restartCourse,
                          restartingCourse,
                          user
                      }) => {

    let {course_uuid} = useParams();
    let location = useLocation()
    const {settings} = UseSystemSettings();

    const [isCourseArray, setIsCourseArray] = useState();
    const [isCourseSteps, setIsCourseSteps] = useState();

    const [isHeader, setIsHeader] = useState();
    const [isButtonText, setIsButtonText] = useState();

    const [isLearnedItems, setIsLearnedItems] = useState([]);

    const [isConfetti, setIsConfetti] = useState();
    const [isCourseResponse, setIsCourseResponse] = useState(null);
    const [isCurrentStepNumber, setIsCurrentStepNumber] = useState();
    const [isCourseComplete, setIsCourseComplete] = useState(false);
    const [isActiveVideo, setIsActiveVideo] = useState();
    const [isRestart, setIsRestart] = useState(false);
    const [isOnWatchlist, setIsOnWatchlist] = useState(false);


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location && course && course_uuid !== lastLoadedUUID) {
            HandleRouteChange(location, user, 'page-view', 'Single Course', course.title, course.core_category)
        }
    }, [user, location, course, course_uuid])

    useEffect(() => {
        window.scrollTo(0, 0)
        // if (!course || course_uuid !== lastLoadedUUID) {
        getSingleCourse(course_uuid)
        // }

    }, [course_uuid, isRestart]);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (restartingCourse) {
            setIsCourseComplete(false);
            setIsRestart(true);
        }

    }, [restartingCourse]);

    useEffect(() => {
        if (course && settings) {
            setIsCourseSteps(undefined);
            setIsCourseArray(course);
            createHeader(course)
            createCourseSteps(course.lessons, course.progress);
            setIsCourseResponse(course);

        }

        // console.log('course: ', course)

    }, [course, settings, screenWidth])


    const createHeader = (course) => {
        // console.log('course header: ', course)
        let {title, thumbnail, intro, core_category} = course;
        setIsLearnedItems(course.learn_items);
        let headerObj = {
            title,
            thumbnail,
            intro,
            core_category
        }
        setIsHeader(headerObj);
    }

    const createCourseSteps = (lessonArray, currentProgress) => {

        if (lessonArray) {
            let courseSteps = lessonArray;
            courseSteps.sort((a, b) => (a.position > b.position) ? 1 : -1);
            let sortedStyledSteps = courseSteps.map((e, i) => {
                // progress with be null if there is no progress
                // need to merge for changes
                if (currentProgress.length === 0 && e.position === 1) {
                    e.status = 'active';
                    setIsActiveVideo(e.uuid);
                } else {
                    e.status = "";
                }
                // will be null if no progress
                if (currentProgress.length !== 0) {
                    setIsButtonText("Resume Course");

                    for (let j = 0; j < currentProgress.lessons_completed.length; j++) {

                        if (currentProgress.lessons_completed[j].lesson_uuid === e.uuid) {
                            e.status = "complete";
                            if (e.position === currentProgress.lessons_completed.length) {
                                setIsCurrentStepNumber(currentProgress.lessons_completed.length);
                            }

                            if (e.position === currentProgress.lessons_completed.length - 1) {
                                setIsCurrentStepNumber(currentProgress.lessons_completed.length - 1)
                            }
                            break;
                        } else if (currentProgress.lessons_completed.length === 0 && e.position === 1) {
                            e.status = "active";
                            setIsCurrentStepNumber(e.position);
                            setIsActiveVideo(e.uuid);
                            break;
                        } else if (e.position > 1 && (currentProgress.lessons_completed.length + 1) === e.position) {
                            e.status = "active";
                            setIsCurrentStepNumber(e.position);
                            setIsActiveVideo(e.uuid);
                            break;
                        } else {
                            e.status = "";
                        }
                    }
                } else {
                    setIsButtonText("Begin Course");
                    setIsCurrentStepNumber(1);
                }

                return (

                    <NewCourseStep
                        src={e.thumbnail}
                        status={e.status}
                        name={e.name}
                        duration={e.duration}
                        key={i}
                        course_uuid={course_uuid}
                        lesson_uuid={e.uuid}
                    />
                )
            });

            if (currentProgress.lessons_completed !== undefined && currentProgress.lessons_completed.length === sortedStyledSteps.length) {

                // let mobileProgressElement = document.getElementById('mobile-course_progress');
                // mobileProgressElement = mobileProgressElement.children[0].children[0];
                // let desktopProgressElement = document.getElementById('desktop-course_progress');
                // desktopProgressElement = desktopProgressElement.children[0].children[0];
                // mobileProgressElement.classList.add('completed');
                // desktopProgressElement.classList.add('completed');
                setIsCourseComplete(true);
            }
            setIsCourseSteps(sortedStyledSteps);
        }
    }

    const restartSingleCourse = () => {

        let restartObj = {uuid: course_uuid}
        restartCourse(restartObj);
    }


    return (
        courseLoading || courseLoading === undefined || settings === null || JSON.stringify(user) === '{}' ?
            <LoadingScreen/>
            :
            <React.Fragment>
                <DesktopNav user={user}/>
                <div id="single-course_page">
                    {
                        isHeader && isCourseArray &&
                        <Fragment>
                            <div className="mobile-course_header__container">
                                <CourseHeader
                                    backgroundColor={"main-primary"}
                                    buttonText={isButtonText}
                                    category={isHeader.core_category[0]}
                                    icon={WhitePlayButton}
                                    onClick={() => {
                                        return
                                    }}
                                    position={""}
                                    src={isHeader.thumbnail}
                                    textColor={""}
                                    title={isHeader.title}
                                    lesson_uuid={isActiveVideo}
                                    course_uuid={course_uuid}
                                    numerator={isCourseArray.progress.lessons_completed === undefined ? 0 : isCourseArray.progress.lessons_completed.length}
                                    totalLessons={isCourseArray.lessons.length}
                                    isCourseComplete={isCourseComplete}
                                    restartCourse={restartSingleCourse}
                                />
                            </div>
                            <div className="desktop-course_header__container">
                                <CourseHeaderDesktop
                                    backgroundColor={"main-primary"}
                                    buttonText={isButtonText}
                                    category={isHeader.core_category[0]}
                                    icon={WhitePlayButton}
                                    onClick={() => {
                                        return
                                    }}
                                    position={""}
                                    src={isHeader.thumbnail}
                                    textColor={""}
                                    title={isHeader.title}
                                    intro={isHeader.intro}
                                    lesson_uuid={isActiveVideo}
                                    course_uuid={course_uuid}
                                    numerator={isCourseArray.progress.lessons_completed === undefined ? 0 : isCourseArray.progress.lessons_completed.length}
                                    totalLessons={isCourseArray.lessons.length}
                                    isCourseComplete={isCourseComplete}
                                    restartCourse={restartSingleCourse}
                                    onWatchlist={course.on_watchlist}
                                />
                            </div>
                        </Fragment>
                    }
                    {
                        isCourseSteps !== undefined &&
                        <Fragment>
                            <div className="course-progress_container">
                                <div className="course-subtitle">COURSE</div>
                                <div className="course-progress">{isCurrentStepNumber}/{isCourseSteps.length}</div>

                            </div>
                            <div className="single-course_title">{isHeader.title}</div>
                        </Fragment>
                    }

                    {
                        isCourseSteps !== undefined &&
                        <div className="course-steps">
                            <CoursePickAsset
                                intro={isHeader.intro}
                                learned_items={isLearnedItems}
                                courseSteps={isCourseSteps}
                            />

                        </div>

                    }

                    {isConfetti}


                </div>
            </React.Fragment>
    )
}

SingleCourse.propTypes = {
    course: PropTypes.object,
    courseLoading: PropTypes.bool,
    screenWidth: PropTypes.number,
    getSingleCourse: PropTypes.func,
    lastLoadedUUID: PropTypes.string,
    restartCourse: PropTypes.func,
    user: PropTypes.object
}


export default connect(mapStateToProps, mapDispatchToProps)(SingleCourse)
