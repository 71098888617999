import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

import MyMarriageCategoryHeader from "./my-marriage-category-header";

import "./category-header.scss";
import styled from "styled-components";

const CategoryHeader = (props) => {
    let navigate = useNavigate();

    const CategoryContainer = styled.div`
        height: ${props.screenWidth * .25}px;
        background-color: ${props.category.color.hex};
    `
    const CategoryIcon = styled.div`
        background-image: url(${props.category.icon.svg});
        height: ${props.screenWidth * .25}px;
    `
    const CategoryIconContainer = styled.div`
        height: ${props.screenWidth * .25}px;
        width: ${props.screenWidth * .25}px;
    `

    return (
        <CategoryContainer className="category-header_container">
            <CategoryIconContainer className="category-header_icon__container">
                <CategoryIcon className="category-header_icon" />
            </CategoryIconContainer>

            <MyMarriageCategoryHeader
                isCurrentScore={props.categoryStats.current.score}
                isScoreHistory={props.categoryStats.history}
                category={props.category}
                showDrawer={props.showDrawer}
                user={props.user}

            />
    
            {!props.myPerformanceScoresLoading &&
                <div className="performance-card_container">
                    {props.isStyledPerformance}
                </div>
            }
        </CategoryContainer>
    )
}

CategoryHeader.propTypes = {
    screenWidth: PropTypes.number.isRequired,
    category: PropTypes.object.isRequired,
}

export default CategoryHeader;
