import * as TYPES from "./actionTypes"

export const getDashboard = () => {
    return {
        type: TYPES.GET_DASHBOARD,
    }
}

export const setDashboard = payload => {
    return {
        type: TYPES.SET_DASHBOARD,
        payload: payload
    }
}

export const dashboardError = error => {
    return {
        type: TYPES.LOAD_DASHBOARD_ERROR,
        payload: error
    }
}
