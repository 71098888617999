import * as TYPES from './actionTypes'

const initialState = {
    slides:[]

}

const IntroSlider = (state = initialState, action) => {
    switch (action.type) {

        case TYPES.GET_INTRO_SLIDER_CARDS:
            return state

        case TYPES.GET_INTRO_SLIDER_CARDS_SUCCESS:
            return {...state, slides: action.payload.slides}


        default:
            return state;

    }

}

export default IntroSlider
