import React, {useState, useRef} from "react";
import axios from "axios";
import RegistrationCarousel from "../../../components/Carousels/registration-carousel";
import ReCAPTCHA from "react-google-recaptcha";
import "./registration-page.scss";
import {Link, useNavigate} from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";

const FirstRegistrationPage = () => {
    const navigate = useNavigate();

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [loginError, setLoginError] = useState(null)
    const [registerButtonActive, setRegisterButtonActive] = useState(true)
    const userEmail = useRef();
    const utmSource = useRef();
    const utmMedium = useRef();
    const utmCampaign = useRef();
    const utmContent = useRef();
    const recaptchaRef = useRef();

    const query = new URLSearchParams(window.location.search)
    const utm_source = query.get('utm_source') || '';
    const utm_medium = query.get('utm_medium') || '';
    const utm_campaign = query.get('utm_campaign') || '';
    const utm_content = query.get('utm_content') || '';
    const coupon = query.get('coupon') || '';

    const sitekey = process.env.MIX_RECAPTCHA_SITE_KEY;

    const checkInput = () => {
        setLoginError(null)
        let email = userEmail.current.value;
        if (email === "") {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    }

    const sendUserData = async () => {
        setRegisterButtonActive(false)
        const recaptcha_token = await recaptchaRef.current.executeAsync();

        if (isButtonDisabled) {
            return;
        }
        let email = userEmail.current.value;
        let origin = window.location.pathname;
        let utm_source = utmSource.current.value;
        let utm_medium = utmMedium.current.value;
        let utm_campaign = utmCampaign.current.value;
        let utm_content = utmContent.current.value;

        let userObj = {email, recaptcha_token, origin, utm_medium, utm_source, utm_campaign, utm_content};

        await axios.post("/event/pr/website", userObj).then((response) => {

            if (response.data.status === 'success') {
                // send user to step 2
                // TODO: how do we remove the hardcoded campaign slug? we need the slug here to redirect users from the
                //  old registration page to a split testing page. we want to continue passing the coupon query
                if (coupon) {
                    return navigate(`/checkout/${response.data.uuid}/registration-campaign-two?coupon=${coupon}`)
                } else {
                    return navigate(`/checkout/${response.data.uuid}/registration-campaign-two`)
                }

            } else {
                // display error
                // todo: send to sentry
                setLoginError('There was a login error.')
            }
        }).catch((error) => {
            // todo: send to sentry
            setRegisterButtonActive(true)
            let er = Object.values(error.response.data.message);
            er = er.flat();
            setLoginError(er)
        })
    }

    let settle1 = (<div className="settle-item_container" key={Math.random(100)}>
        <div className="settle-color_title">You Need Clear Communication</div>
        <div className="settle-text">Our practical tools help struggling couples learn to express themselves in healthy
            ways.
        </div>
    </div>);
    let settle2 = (<div className="settle-item_container" key={Math.random(100)}>
        <div className="settle-color_title">You Deserve Emotional Intimacy</div>
        <div className="settle-text">Members who watch our videos and use our worksheets feel more known, understood,
            and heard.
        </div>
    </div>);
    let settle3 = (<div className="settle-item_container" key={Math.random(100)}>
        <div className="settle-color_title">You Can Create Healthy Boundaries</div>
        <div className="settle-text">Gain the confidence you need to establish and maintain the boundaries necessary to
            grow.
        </div>
    </div>);
    let testimonial1 = (<div className="testimonial" id="testimonial-1" key={Math.random(100)}>
        <div className="text-message">
            I assumed I would be underwhelmed with your membership and to my surprise, I'm
            humbled
            and so grateful to have found this.
            I just watched how to forgive your spouse and feel like I finally understand
            what to
            do
            and a huge weight has been lifted.
            I'm telling everyone I know to sign up!
            <div className="emoji-wink__container">
                <div className="emoji-wink"></div>
            </div>
        </div>

        <div className="heart-icon__container">
            <div className="heart-icon"></div>
        </div>
    </div>);
    let testimonial2 = (<div className="testimonial" id="testimonial-2" key={Math.random(100)}>
        <div className="text-message">
            A loyal Marriage365 husband and member.
            I am who I am because of you guys and all that you've done for me.
            When I signed up, I was in a really bad place.
            You've given me the steps to take to earn back my wife's trust and more than
            anything,
            you gave me hope.

        </div>
        <div className="heart-icon__container">
            <div className="heart-icon"></div>
        </div>
    </div>);
    let testimonial3 = (<div className="testimonial" id="testimonial-3" key={Math.random(100)}>
        <div className="text-message">
            I just want to thank you guys for making me realize what marriage is really
            about.
            My wife and I were in a rough spot in our marriage.
            When I became a 365 member, it totally changed my mindset.
            Things have gotten better.
            Watching your videos made me realize what I wasn't bringing to the marriage.
            Thank you again!!

        </div>
        <div className="heart-icon__container">
            <div className="heart-icon"></div>
        </div>
    </div>);

    return (
        <div id="first-registration_page">
            <div className="left-column">
                <div className="logo-container">

                </div>
                <div className="registration-title">
                    Get your account Started
                </div>
                <div className="registration-subtitle">
                    Say <span>goodbye</span> to an average marriage!
                </div>
                <div className="input-container">
                    {
                        loginError &&
                        <div className="alert-danger alert">
                            {loginError}
                        </div>
                    }

                    <div className="step-account_container">
                        <div className="step">Step 1 of 2</div>
                        <div className="have-account">Already have an account? <Link to={'/login'}>Click here</Link>
                        </div>
                    </div>
                    <input type="hidden" ref={utmSource} name="utm_source" value={utm_source}/>
                    <input type="hidden" ref={utmMedium} name="utm_medium" value={utm_medium}/>
                    <input type="hidden" ref={utmCampaign} name="utm_campaign" value={utm_campaign}/>
                    <input type="hidden" ref={utmContent} name="utm_content" value={utm_content}/>
                    <input className="email-input" ref={userEmail} placeholder="Email Address*" onChange={checkInput}/>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={sitekey}
                    />
                    <div className="button-container d-block">
                        <div className={`next-button ${!isButtonDisabled ? "active pointer" : "disabled"}`}
                             onClick={sendUserData}>
                            <div className="next-text">
                                {
                                    registerButtonActive ?
                                        <span>Next</span>
                                        :
                                        <ScaleLoader height="10px" color="#fff"/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-container">
                    © 2023 Marriage365
                </div>
            </div>
            <div className="right-column">
                <div className="settle-container">
                    <div className="settle-color_content">
                        <div className="color-overlay"></div>
                        <div className="settle-title">Don't settle for an <br/>average marriage.
                            <div className="color-highlight"></div>
                        </div>
                        <div className="settle-list">
                            <div className="settle-color_title">You Need Clear Communication</div>
                            <div className="settle-text">Our practical tools help struggling couples learn to express
                                themselves in healthy ways.
                            </div>
                            <div className="settle-color_title">You Deserve Emotional Intimacy</div>
                            <div className="settle-text">Members who watch our videos and use our worksheets feel more
                                known, understood, and heard.
                            </div>
                            <div className="settle-color_title">You Can Create Healthy Boundaries</div>
                            <div className="settle-text">Gain the confidence you need to establish and maintain the
                                boundaries necessary to grow.
                            </div>
                        </div>
                        <div className="mobile-carousel_container">
                            <RegistrationCarousel
                                carouselItems={[settle1, settle2, settle3]}
                            />
                        </div>
                    </div>
                    <div className="settle-image"></div>
                    <div className="bottom-white_gradient"></div>
                </div>
                <div className="message-container">
                    <div className="message-title">Don't take it from us...</div>
                    <div className="message-subtitle">These are just a few of the hundreds of messages we get on
                        Instagram from our Members
                    </div>
                    <div className="text-message_container">

                        {testimonial1}
                        {testimonial2}
                        {testimonial3}


                    </div>
                    <div className="mobile-carousel_container">
                        <RegistrationCarousel
                            carouselItems={[testimonial1, testimonial2, testimonial3]}
                        />
                    </div>
                </div>
                <div className="membership-perks">
                    <div className="perk-content">
                        <div className="perk-title">Marriage365 Membership is the leading on-demand relationship support
                            resource to help you build a healthy marriage.
                        </div>
                        <div className="perk-color_title">Members get 24/7 access to...</div>
                        <ul className="perk-list">
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Over 200 videos that talk honestly about real life issues
                                    many marriages experience
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Over 75 downloadable worksheets with practical action steps
                                    to inspire change
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Connecting questions for every video that help you create
                                    meaningful discussion
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Interviews with experts who dive deeper into complex issues
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">Topically based courses to give you a clear path of growth
                                    and insight
                                </div>
                            </li>
                            <li className="perk-container">
                                <div className="perk-check"></div>
                                <div className="perk-text">2 profiles for you and your spouse to engage with content
                                    that matters most to you
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>

        </div>)
}

export default FirstRegistrationPage;
