/**
 * Button component should contain
 *      - text*
 *      - properties
 *      - icon
 *      - ability to adjust color on all elements
 */
import React, {useState, useEffect} from "react"
import styled from "styled-components"
import PropTypes from "prop-types";
import {useStateMachine} from "little-state-machine";
import updateAction from "../../Actions/updateAction";

const DurationText = styled.span`
 font-family: Sofia Pro Bold;
 font-size: 18px;
 line-height: 125%;
 text-align: center;
 letter-spacing: 0.02em;
 text-transform: uppercase;
 color: #A7B2B8;
 display:block;
     `
const SubtitleText = styled.span`
font-family: Sofia Pro;
font-size: 16px;
line-height: 20px;
text-align: center;
letter-spacing: 0.02em;
color: #A7B2B8;
height: 60px;
margin-bottom: 7px;
padding: 0px 8px;
display:block;
width:100%;
     `
const PricingText = styled.span`
font-size: 24px;
font-family: Sofia Pro Bold;
display:block;
line-height: 125%;
text-align: center;
letter-spacing: 0.02em;
color: #299DA0;
margin-bottom: 4px;
     `
const DueText = styled.span`
font-family: Sofia Pro;
font-size: 16px;
line-height: 125%;
text-align: center;
letter-spacing: 0.02em;
text-transform: uppercase;
color: #54989C;
display:block;
margin-top:16px;
     `
const PricingNowText = styled.span`
font-family: Sofia Pro Bold;
font-size: 28px;
line-height: 43px;
text-align: center;
letter-spacing: 0.03em;
color: #299DA0;
display:block;
     `

const TagText = styled.div`
background-color: #A7B2B8;
border-radius: 8px 8px 0px 0px;
padding: 8px 0px 10px;
text-align: center;
font-family: $primary-font;
font-size: 16px;
line-height: 125%;
color: #FFFFFF;
width: 100%;
     `


const ButtonTag = styled.div`
         ${props => props.properties}
     `

const ButtonContainer = styled.div`
         ${props => props.properties}
     `

const ButtonElement = styled.div`
         ${props => props.properties}

     `

/**
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export const PricingButton = ({data}) => {

    // console.log('button data: ', data)
    const {actions} = useStateMachine({updateAction});
    const [activeButtonId, setActiveButtonId] = useState(null)
    const [buttonIDs, setButtonIDs] = useState([])
    let idArray = []

    /**
     * Store all button IDs as state array to reference during button state assignment
     */
    useEffect(() => {

        (data.buttons || []).map(btn => {
            idArray.push(btn.attributes.id)
        })
        setButtonIDs(idArray)

    }, [data])

    /**
     * Determine the state of the selection button
     * @param attributes
     * TODO: there must be a more elegant way of handling this @kurtweston
     */
    function assignButton(attributes) {
        // console.log(attributes)
        if (attributes.id === activeButtonId) return;

        actions.updateAction({selectedPlan: attributes.id});
        if (activeButtonId === null) {
            buttonIDs.forEach(btnID => {
                if (btnID !== attributes.id) {

                    document.getElementById(btnID).classList.add('inactive-background')
                }
            })
            document.getElementById(attributes.id).classList.remove('inactive-background')
            document.getElementById(attributes.id).classList.add('active')
        } else {
            document.getElementById(attributes.id).classList.add('active')
            document.getElementById(attributes.id).classList.remove('inactive-background')
            document.getElementById(activeButtonId).classList.add('inactive-background')
        }
        setActiveButtonId(attributes.id)

        // check to see if these should activate a disabled button
        if (!!attributes.activateButton) {

            actions.updateAction({buttonState: {startMembershipButton: false}});

            // document.getElementById(attributes.activateButton).removeAttribute('disabled')
            document.getElementById(attributes.activateButton).classList.add('active')
        }


    }

    return (
        (data.buttons || []).map((item, i) => (
            <ButtonContainer properties={data.properties} key={i}>
                <ButtonElement id={item.attributes.id} properties={item.properties}
                               onClick={() => assignButton(item.attributes)}>
                    <DurationText className="grey-text">
                        {item.text}
                    </DurationText>
                    <SubtitleText className="grey-text">
                        {item.subtitle}
                    </SubtitleText>
                    <PricingText className="teal-text">
                        {item.pricingMonth}
                    </PricingText>
                    <PricingText className="teal-text">
                        {item.pricingYear}
                    </PricingText>
                    <DueText className="teal-text">
                        DUE TODAY
                    </DueText>
                    <PricingNowText className="teal-text">
                        {item.pricingNow}
                    </PricingNowText>
                    <TagText className="tag">
                        {item.tagText}
                    </TagText>
                    {
                        item.buttonTag &&
                        <ButtonTag properties={item.buttonTag.properties}>
                            {item.buttonTag.text}
                        </ButtonTag>
                    }
                </ButtonElement>
            </ButtonContainer>
        ))
    )
}

PricingButton.propTypes = {
    data: PropTypes.shape({
        properties: PropTypes.any.isRequired,
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                buttonTag: PropTypes.oneOfType([
                    PropTypes.object,
                    PropTypes.array
                ]),
                attributes: PropTypes.shape({
                    class: PropTypes.string,
                    id: PropTypes.string.isRequired
                }).isRequired,
            })
        ).isRequired
    }).isRequired
}
