import React from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';

const GeneralButton = (props) => {
    // background color, text color, icon, text, onClick function

    const Button = styled.button`
        font-size: 18px;
        background-color:${props.backgroundColor}
    `
    const ButtonText = styled.div`
        color:${props.textColor} !important
    `
    const ButtonIcon = styled.div`
        color:${props.textColor};
        background-image: url(${props.icon})
    `

    return (
        <Button className={`general-button_container ${props.className}`} onClick={props.onClick}
                disabled={props.disabled}>
            <ButtonText className="button-title">
                {props.buttonText}
            </ButtonText>
            {
                props.icon && (
                    <ButtonIcon className="button-icon"/>
                )
            }
        </Button>
    )
}

GeneralButton.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    // buttonText: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    textColor: PropTypes.string,
}

export default GeneralButton;
