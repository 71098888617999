import * as TYPES from './actionTypes'

const initialState = {
    categories: null,
    lastLoadedUUID: null,
    category: null,
    trending: null,
    videos: null,
    resources: null,
    courses: null,
    categoryLoading: true,
    error: ""
}

const coreCategories = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.SET_CORE_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case TYPES.GET_SINGLE_CATEGORY:
            return {
                ...state,
                categoryLoading: true
            }

        case TYPES.SET_LAST_LOADED_UUID:
            return {
                ...state,
                lastLoadedUUID: action.payload
            }

        case TYPES.SET_SINGLE_CATEGORY:
            return {
                ...state,
                category: action.payload,
                categoryLoading: false
            }
        case TYPES.SINGLE_CATEGORY_VIDEOS:
            return {
                ...state,
                videos: action.payload,
            }
        case TYPES.SINGLE_CATEGORY_TRENDING:
            return {
                ...state,
                trending: action.payload,

            }
        case TYPES.SINGLE_CATEGORY_RESOURCES:
            return {
                ...state,
                resources: action.payload,

            }
        case TYPES.SINGLE_CATEGORY_COURSES:
            return {
                ...state,
                courses: action.payload,

            }
        case TYPES.SINGLE_CATEGORY_ERROR:
            return {
                ...state,
                error: action.payload,
                categoryLoading: false
            }


        default:
            return state;
    }
}

export default coreCategories
