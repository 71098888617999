/**
 * These are functions that the user can apply to some component. These are not functions needed to run the framework.
 */

import React from 'react';
import {checkCouponCode} from "../Helpers/backend-helper";

async function AutoApplyCoupon(params) {
    // console.log('params: ', params)

    // enter coupon in the coupon box and execute coupon submission to server
    const response = await CheckCoupon(params.code, params.planName)

    return {
        ...response,
        updateState: true
    }

}


async function CheckCoupon(promoCode, selectedPlan) {
    let errorMessage, couponResponse, status, amountToCharge;


    if (promoCode) {
        await checkCouponCode({
            coupon: promoCode,
            selected: selectedPlan
        }).then(response => {
            status = response.status
            if (response.status !== 200) {
                errorMessage = response.msg
            } else {
                couponResponse = response.msg
                amountToCharge = response.atc
            }
        })
    }

    return {
        errorMessage,
        buttonState: true,
        couponResponse,
        couponRedeemed: promoCode,
        amountToCharge,
        status
    }

}


export const AllowedFunctions = {
    AutoApplyCoupon: AutoApplyCoupon,
    CheckCoupon: CheckCoupon
}
