import React, {useLayoutEffect} from 'react'

import ProfileContent from '../ProfileContent'
import ProfileMain from "../ProfileMain";
import ProfileRoot from '../ProfileRoot'
import ProfileWrap from "../ProfileWrap";

import Sidebar from '../Sidebar'
import LayoutProvider from '../LayoutProvider'
import ToggleSidebar from "../Sidebar/Components/toggle-sidebar";
import ToastCard from '../../../components/cards/toastCard';

function ProfileLayout({title, content, ...props}) {

    useLayoutEffect(() => {
        document.title = `${title} - Marriage365 Profile`;
    });

    return (
        <LayoutProvider>
            <ProfileRoot>
                <ProfileMain>
                    <Sidebar/>
                    <ProfileWrap>
                        <ProfileContent content={content}>
                            <ToastCard />
                            <ToggleSidebar />
                            {props.children}
                        </ProfileContent>
                    </ProfileWrap>
                </ProfileMain>
            </ProfileRoot>
        </LayoutProvider>
    )
}

export default ProfileLayout
