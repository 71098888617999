import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import ScaleLoader from 'react-spinners/ScaleLoader';
import './pick-asset.scss';
import LoadingScreen from "../LoadingScreen";

import {Track} from "../../utils/Segment/segment-methods";

const PickAsset = (props) => {
   
    const [isVideoShown, setIsVideoShown] = useState(true);
    const [isResourceShown, setIsResourceShown] = useState(false);
    const [isCourseShown, setIsCourseShown] = useState(false);

    let videoResourcePicked = true;

    const videoSection = useRef(null);
    const resourceSection = useRef(null);
    const courseSection = useRef(null);
    const videoBtn = useRef(null);
    const resourceBtn = useRef(null);
    const courseBtn = useRef(null);
    // get two arrays
    let {videos, resources, courses} = props;

    // generate pictures off array items

    // have a switch that displays one set of images or the other
    // hide show possible animation


    const toggleSelector = (e) => {
        let className = e.currentTarget.className;
        className = className.split(' ');
        let openElement = document.querySelector('.pick-highlighted');
        let classToOpen = '';
        let openElementArray = [...openElement.classList];

        if (openElementArray.includes(className[0])) {
            return;
        }
        let properties = {
            Title: className[0],
            Location:`${props.category} Category - Assets Section`,
        }
        switch (className[0]) {
            case 'videos':
                classToOpen = videoBtn;
                setIsVideoShown(true);
                setIsResourceShown(false);
                setIsCourseShown(false);
                videoSection.current.classList.remove('hide-section');
                resourceSection.current.classList.add('hide-section');
                courseSection.current.classList.add('hide-section');
                
                break;
            case 'resources':
                classToOpen = resourceBtn;
                setIsVideoShown(false);
                setIsResourceShown(true);
                setIsCourseShown(false);
                videoSection.current.classList.add('hide-section');
                resourceSection.current.classList.remove('hide-section');
                courseSection.current.classList.add('hide-section');
              
                break;
            case 'courses':
                classToOpen = courseBtn;
                setIsVideoShown(false);
                setIsResourceShown(false);
                setIsCourseShown(true);
                videoSection.current.classList.add('hide-section');
                resourceSection.current.classList.add('hide-section');
                courseSection.current.classList.remove('hide-section');
                
        }
        //set other two classes to closed
        openElement.classList.remove('pick-highlighted');
        classToOpen.current.classList.add('pick-highlighted');
        if(props.source === "Category-Page"){
            Track("navigation_pill_clicked", properties);
        }
    }

    return (
        <>
            <div className="selector-menu">
                <div className="selector">
                    <div className="videos pick-highlighted" ref={videoBtn} onClick={toggleSelector}>
                        Videos
                    </div>
                    <div className="resources" ref={resourceBtn} onClick={toggleSelector}>
                        Worksheets
                    </div>
                    <div className="courses" ref={courseBtn} onClick={toggleSelector}>
                        Courses
                    </div>
                </div>
            </div>

            <div className="pick-asset-container">
                <div className="selector-container">
                    {
                        videos.length > 0 && (
                            <div className="video-section" ref={videoSection}>
                                {/* <h4>Trending Now</h4>
                                <div className="trending-category_container">
                                    {props.trendingVideo}
                                </div> */}
                                <div className="flex-two_column">
                                    {props.videos}
                                </div>

                            </div>
                        )}
                    {
                        resources.length > 0 && (
                            <div className="resource-section hide-section" ref={resourceSection}>
                                <div className="flex-two_column">
                                    {props.resources}
                                </div>
                            </div>
                        )}
                    {
                        courses.length > 0 && (
                            <div className="course-section hide-section" ref={courseSection}>
                                <div className="flex-two_column">
                                    {props.courses}
                                </div>
                            </div>
                        )}
                    {
                        videos.length === 0 && (
                            <div className="trending-height">
                                <div className="trending-loader-container">
                                    <LoadingScreen/>
                                </div>
                            </div>
                        )}
                </div>
            </div>
        </>
    )
}

PickAsset.propTypes = {
    courses: PropTypes.arrayOf(PropTypes.element),
    resources: PropTypes.arrayOf(PropTypes.element),
    trendingVideo: PropTypes.element,
    videos: PropTypes.arrayOf(PropTypes.element),
}

export default PickAsset;
