export const GET_INFO_MESSAGES = "GET_INFO_MESSAGES"
export const GET_OVERALL_STATS = "GET_OVERALL_STATS"
export const SET_OVERALL_STATS = "SET_OVERALL_STATS"
export const GET_CATEGORY_STATS = "GET_CATEGORY_STATS"
export const SET_CATEGORY_STATS = "SET_CATEGORY_STATS"
export const GET_PERFORMANCE_SCORES = "GET_PERFORMANCE_SCORES"
export const SET_INFO_MESSAGES = "SET_INFO_MESSAGES"
export const SET_PERFORMANCE_SCORES = "SET_PERFORMANCE_SCORES"
export const GET_MY_RECOMMENDED_ASSETS = "GET_MY_RECOMMENDED_ASSETS"
export const SET_MY_RECOMMENDED_ASSETS = "SET_MY_RECOMMENDED_ASSETS"
export const POST_CHECKUP = "POST_CHECKUP"
