import axios from "axios"

//apply base url for axios
// const API_URL = "http://localhost:8000"
// const API_URL = "https://my.marriage365.com"
const API_URL = "/"

const axiosApi = axios.create({
    baseURL: API_URL,
    headers: {
        'content-type': 'application/json',
        'accept': 'application/json'
    },
})


function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

const token = getCookie('XSRF-TOKEN');
axiosApi.defaults.headers.common["X-XSRF-TOKEN"] = decodeURIComponent(token)
// axiosApi.defaults.withCredentials = true

axiosApi.interceptors.response.use(function (response) {
        return response
    }, function (error) {
        if (error.response.status === 401) {
            if (error.config && error.config.url) {
                const requestUrl = error.config.url;
                console.log('*****unauthorized*****')

                // Redirect to login page only if request url is not login url
                if (requestUrl !== '/login') {
                    window.location.replace("/login")
                }
            }
        }

        if (error.response.status === 500) {
            console.log(error.response.data.message)
        }

        return Promise.reject(error.response.data)
    }
)

export async function get(url, config = {}) {
    return await axiosApi.get(url, {...config}).then(response => response.data)
}

export async function post(url, data, config = {}) {
    return axiosApi
        .post(url, {...data}, {...config})
        .then(response => response.data)
}

export async function put(url, data, config = {}) {
    return axiosApi
        .put(url, {...data}, {...config})
        .then(response => response.data)
}

export async function del(url, config = {}) {
    return await axiosApi
        .delete(url, {...config})
        .then(response => response.data)
}
