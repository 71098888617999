export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const RESTART_SUBSCRIPTION = 'RESTART_SUBSCRIPTION';
export const CHANGE_SUBSCRIPTION_SUCCESS = 'CHANGE_SUBSCRIPTION_SUCCESS';
export const CHANGE_SUBSCRIPTION_RESPONSE = 'CHANGE_SUBSCRIPTION_RESPONSE';
export const CHANGE_SUBSCRIPTION_FAIL = 'CHANGE_SUBSCRIPTION_FAIL';
export const UPDATE_ACCOUNT_MESSAGE = 'UPDATE_ACCOUNT_MESSAGE';
export const SWAP_MEMBERSHIP = 'SWAP_MEMBERSHIP';

export const GET_HISTORY = 'GET_HISTORY';
export const SET_HISTORY = 'SET_HISTORY';
export const REMOVE_VIDEO_FROM_HISTORY = 'REMOVE_VIDEO_FROM_HISTORY';

export const GET_CONTINUE_WATCHING = 'GET_CONTINUE_WATCHING';
export const SET_CONTINUE_WATCHING = 'SET_CONTINUE_WATCHING';

export const GET_NOTIFICATION_PREFERENCES = 'GET_NOTIFICATION_PREFERENCES';
export const SET_NOTIFICATION_PREFERENCES = 'SET_NOTIFICATION_PREFERENCES';
export const UPDATE_NOTIFICATION_PREFERENCES = 'UPDATE_NOTIFICATION_PREFERENCES';

export const GET_MEMBERSHIP_PERKS = 'GET_MEMBERSHIP_PERKS';
export const SET_MEMBERSHIP_PERKS = 'SET_MEMBERSHIP_PERKS';

export const GET_FAQ = 'GET_FAQ';
export const SET_FAQ = 'SET_FAQ';

export const GET_NAKED_CONVERSATIONS = 'GET_NAKED_CONVERSATIONS';
export const SET_NAKED_CONVERSATIONS = 'SET_NAKED_CONVERSATIONS';

export const GET_MEMBER_STORIES = 'GET_MEMBER_STORIES';
export const SET_MEMBER_STORIES = 'SET_MEMBER_STORIES';

export const POST_COUPON_CODE = 'POST_COUPON_CODE';
export const APPLY_MEMBER_COUPON = 'APPLY_MEMBER_COUPON';

export const SET_COUPON = 'SET_COUPON';

export const GET_INFO_DRAWER = 'GET_INFO_DRAWER';
export const SET_INFO_DRAWER = 'SET_INFO_DRAWER';

export const SET_ACTIVE_PROFILE_PAGE = 'SET_ACTIVE_PROFILE_PAGE';

export const SET_ACCOUNT_SETTINGS_MESSAGE = 'SET_ACCOUNT_SETTINGS_MESSAGE';

export const GET_CANCELLATION_OFFER = 'GET_CANCELLATION_OFFER';
export const SET_CANCELLATION_OFFER = 'SET_CANCELLATION_OFFER';

export const GET_SUSBSCRIPTION_PLAN_TYPE = 'GET_SUSBSCRIPTION_PLAN_TYPE';
export const SET_SUSBSCRIPTION_PLAN_TYPE = 'SET_SUSBSCRIPTION_PLAN_TYPE';

