
import React from "react";

import GeneralButton from "../buttons/generalButton";
import PropTypes from 'prop-types';

import './marriage-info-drawer.scss';

const MarriageInfoDrawer = (props) => {

    return (
        <div className="drawer-container">
            <div className="close-button pointer" onClick={props.closeModal}>
                <div className="cross"/>
            </div>
            <div className="drawer-title">
                {props.header}
            </div>

            <div className="drawer-text" dangerouslySetInnerHTML={{__html: props.body}}>

            </div>
            {props.button && (
                <div className="drawer-button_container">
                    <GeneralButton
                        backgroundColor={"#D93B31"}
                        buttonText={props.buttonText ? props.buttonText : 'Remove'}
                        textColor={'#fff'}
                        onClick={props.onClick}
                     />
                </div>
            )}
        </div>
    )

}

MarriageInfoDrawer.propTypes = {
    closeModal: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    button: PropTypes.bool,
    buttonText:PropTypes.string,
    onClick: PropTypes.func,

}

export default MarriageInfoDrawer;
