import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import "./myMarriageCard.scss";
import PropTypes from 'prop-types';

const MyMarriageCard = (props) => {

    return (
        <div className="my-marriage_card">
            <div className="title-content">
                <div className="icon" style={{ backgroundImage: `url(${props.icon})` }}/>
                <div className="card-title">
                    {props.cardTitle}
                </div>
            </div>

            <div className="radial-container">
                <CircularProgressbar value={props.radialScore} maxValue={1} text={`${Math.round(props.radialScore * 100)}`} />
            </div>
            <div className="chevron-right"/>
        </div>
    )
}

MyMarriageCard.propTypes = {
    cardTitle: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    radialScore: PropTypes.number.isRequired,
}

export default MyMarriageCard;
