import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import GeneralButton from '../buttons/generalButton';
import SkipVideoIcon from '../../assets/images/skip-forward.svg';
import LoadingScreen from '../LoadingScreen';
import ReactPlayer from "react-player";
import './desktop-login.scss';

const DesktopLoginVideo = () => {

    let dummyData = {
        "uuid": "5f85bb12-d838-4adf-a31f-33004f35c4a8",
        "url": "https://d1sw85km49u66l.cloudfront.net/assets/Member-Tutorial-Update-2022-new/Member-Tutorial-Update-2022-3.mp4"
    }

    const [isVideo, setIsVideo] = useState();
    const [isVideoPaused, setIsVideoPaused] = useState(true);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isVideoOver, setIsVideoOver] = useState(false);

    useEffect(() => {
        getWelcomeVideo();
    }, [])

    const getWelcomeVideo = () => {

        axios.get('/welcome-video').then((resp) => {
            setIsVideo(resp.data.url);
        })
    }

    const playVideo = () => {
        setIsVideoPaused(false);
        let video = document.getElementById('welcome-video_desktop');
        video = video.children[0];
        video.play();
    }

    const replayVideo = () => {
        setIsVideoOver(false);
        let video = document.getElementById('welcome-video_desktop');
        video = video.children[0];
        video.play();
    }

    const videoOver = () => {
        setIsVideoOver(true);
    }

    if (!isVideo) {
        return <LoadingScreen />
    } else {


        return (
            <div className="desktop-video_page">
                <div className='m365-desktop_logo'>

                </div>
                <div className="desktop-video_container">
                    {isVideoPaused && (
                        <div className="play-button" onClick={playVideo}></div>
                    )}
                    {isVideoOver && (
                        <div className='replay-video_button'>
                            <div className="replay-button" onClick={replayVideo}>
                                <div className="button-text">
                                    Replay Video
                                </div>
                                <div className="replay-icon"></div>
                            </div>
                        </div>
                    )}
                    <ReactPlayer
                        url={isVideo}
                        className='react-player'
                        id='welcome-video_desktop'
                        width="336px"
                        height="604.33px"
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload',
                                    autoPlay: true,
                                    onContextMenu: e => e.preventDefault()
                                },
                                forceVideo: true,
                            }
                        }}
                        onEnded={videoOver}
                    />
                </div>
                <div className="desktop-button_container">
                    <Link to={"/welcome"}>
                        {isVideoOver ? (
                            <GeneralButton
                                backgroundColor={"#2D5F62"}
                                buttonText={"Continue"}
                                textColor={"#fff"}
                                icon={""}
                                onClick={() => { return }}
                            />
                        ) : (
                            <GeneralButton
                                backgroundColor={"#2D5F62"}
                                buttonText={"Skip"}
                                textColor={"#fff"}
                                icon={SkipVideoIcon}
                                onClick={() => { return }}
                            />
                        )}
                    </Link>
                </div>

            </div>
        )
    }
}

export default DesktopLoginVideo;