import { isEmpty, } from 'lodash';
import React, { useCallback, useState, } from 'react';
import styled from 'styled-components';
import QuestionOptionRadioItem from './questionOptionRadioItem';
import Title from '../common/title';
const StyledQuestionContainer = styled.div `
    flex: 1;
`;
const StyledTitle = styled(Title) `
    display: inline-flex;
    line-height: 1.15;
    margin: 36px 0 30px 0;
    white-space: unset;
`;
function QuestionTypeRadio({ answer, title, options, onSelectAnswerOption, }) {
    const [questionAnswer, setQuestionAnswer] = useState(answer);
    const onChangeAnswerOption = useCallback((value) => {
        setQuestionAnswer(value);
        onSelectAnswerOption(value);
    }, [
        onSelectAnswerOption,
    ]);
    return (React.createElement(StyledQuestionContainer, null,
        React.createElement(StyledTitle
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        , { 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            elements: title?.elements ?? [], style: title?.style ?? {} }),
        React.createElement("form", null, options?.map((option) => {
            const isChecked = !isEmpty(questionAnswer)
                && (questionAnswer === option.value);
            return (React.createElement(QuestionOptionRadioItem, { key: `question-option-${option?.value}`, checked: isChecked, onSelectAnswerOption: onChangeAnswerOption, title: option?.label, value: option?.value }));
        }))));
}
export default QuestionTypeRadio;
