/**
 * HTag component should contain
 *      - text*
 *      - properties
 *      - icon
 *      - ability to adjust color on all elements
 */
import React from "react"
import PropTypes from "prop-types";
import classNames from "classnames";

export const HTag = ({data, block}) => {
    // console.log('htag data: ', data)
    // console.log('htag block: ', block)

    const compClass = classNames({
        'header': true,
        [block.attributes.class]: block.attributes.class
    });

    // todo: attempting to pass custom styles into the styledComponents is not working

    return React.createElement(data.type, {
        key: block._uid,
        className: compClass,

    }, data.text)


}


HTag.propTypes = {
    data: PropTypes.shape({
        text: PropTypes.string.isRequired
    }).isRequired

}
