import {call, put, takeEvery} from "redux-saga/effects"

import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"

function* SetScreenSize() {
    try {
        yield put(ACTIONS.screenResizeSuccess(window.innerWidth))
    } catch (error) {
        console.log(error)
        // TODO: connect sentry
    }
}


function* UserInterfaceSaga() {
    yield takeEvery(TYPES.SCREEN_RESIZE, SetScreenSize)
}

export default UserInterfaceSaga
