import React, { useState, useEffect, useRef, Fragment } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { clearUserMessage } from "../../store/user/actions";

import PropTypes from "prop-types";

import "./toastCards.scss";

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            clearUserMessage,
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    successMessage: appState.User.successMessage,
    userError: appState.User.error,
});

const ToastCard = ({ clearUserMessage, successMessage, userError }) => {

    let [showToast, setShowToast] = useState(false);
    let toastCard = useRef();

    useEffect(() => {
        setShowToast(true);
        if (userError !== null || successMessage !== null) {
            setTimeout(() => {
                clearUserMessage(null);
                setShowToast(false);
            }, 3000)
           
        } else {
            setShowToast(false);
        }
    }, [userError, successMessage])

    return (
        <Fragment>
            {showToast ? (
                <div className="toast-settings_container">
                    <div className={`toast-container ${userError !== null ? "error" : ""}`} >
                        <div className="check-mark" />
                        <div className="toast-text">
                            {successMessage} {userError}
                        </div>
                    </div>
                </div>
            ) : <div></div>}
        </Fragment>
    )
}

ToastCard.propTypes = {
    clearUserMessage : PropTypes.func,
    successMessage : PropTypes.string,
    userError : PropTypes.string,
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastCard);
