import React, {Fragment} from 'react'
import Components from "../../Helpers/componentHelper"
import PropTypes from "prop-types";
import styled from "styled-components";


const DivElement = styled.div`
        ${props => props.properties}
    `
/**
 *
 * @param block
 * @param data
 * @param activeTest
 * @param reference
 * @returns {JSX.Element}
 * @constructor
 */
export const Div = ({block, data, activeTest}) => {
    // console.log('DIV data: ', data)
    // console.log('DIV block: ', block)

    return (
        <DivElement id={block.attributes.id ?? null} properties={data.properties}
                    className={block.attributes.class ?? null}>
            {
                (block.children || []).map(child => {
                        child.activeTest = activeTest
                        child.functions = block.functions ?? null
                        return <Fragment key={Math.random()}>{Components(child)}</Fragment>;
                    }
                )
            }
        </DivElement>
    )
}

Div.propTypes = {
    block: PropTypes.shape({
        children: PropTypes.arrayOf(
            PropTypes.shape({
                component: PropTypes.string.isRequired,
                data: PropTypes.any,
            })
        )
    }).isRequired
}
