import {call, put, takeEvery} from "redux-saga/effects"

import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"

import {getIntroSlider} from "../../../../helpers/backend-helper";


function* LoadIntroCards() {
    try {

        const response = yield call(getIntroSlider)
        yield put(ACTIONS.getIntroSliderCardsSuccess(response))

    } catch (error) {
        console.log(error)
        // TODO: connect sentry
    }
}


function* IntroSliderSaga() {
    yield takeEvery(TYPES.GET_INTRO_SLIDER_CARDS, LoadIntroCards)
}

export default IntroSliderSaga
