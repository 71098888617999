import React, { Fragment } from 'react';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import HomeMenu from '../../components/common/home-menu/home-menu';
import DesktopNav from '../../components/common/nav/desktop-nav';
import DesktopFooter from '../../components/common/footer-component/desktop-footer';
import MobileFooter from '../../components/common/footer-component/mobile-footer';
import LoadingScreen from '../../components/LoadingScreen';

import "./podcast-landing.scss";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {

        },
        dispatch
    )
};

//-MAP PROPS1
const mapStateToProps = appState => ({
    user: appState.User.data,
});

const PodcastLandingPage = ({
    user
}) => {


    return (

        <Fragment>
            {JSON.stringify(user) === '{}' ?
                (<LoadingScreen />)
                :
                (<div>
                    <HomeMenu user={user}/>
                    <DesktopNav user={user} />
                    <div id="podcast-landing">
                        <div className="picture-column">
                            <img src="/images/podcast/podcast-phone.png" alt="Marriage365 Podcast"/>
                        </div>
                        <div className="content-column">
                            <div className="content-container">
                                <div className="podcast-title"></div>
                                <p className="podcast-description">
                                    The Marriage365 Podcast is a feature exclusively available in our mobile app.
                                    <br/> <br/>
                                    If you haven't already, download the app today for immediate access to the podcast.
                                </p>
                                <div className="podcast-button_container">
                                    <a className="podcast-button" href="https://apps.apple.com/us/app/marriage365/id1628739510" target="_blank"><div className="apple-store"></div></a>
                                    <a className="podcast-button" href="https://play.google.com/store/apps/details?id=com.marriage365.app" target="_blank"><div className="google-play"></div></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <DesktopFooter />
                    <MobileFooter />
                </div>)
            }

        </Fragment>

    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PodcastLandingPage);

