
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import './bar-graph.scss';

const BarGraph = (props) => {
    let dummyArray = props.isScoreHistory;

    useEffect(()=>{

        dummyArray = dummyArray.reverse();
    },[])
    // console.log('bar graph', props);


    // console.log('bar props' , dummyArray);

    return (
        <div className="bar-graph_container">
            <div className='overall-score'>Overall Score</div>
            <div className="graph-content_container">
                <div className="graph-container">
                    <div className="graph-bar"/>
                    <div className="graph-bar"/>
                    <div className="graph-bar"/>
                    <div className="graph-bar"/>
                    <div className="graph-bar"/>
                    <div className="graph-bar"/>
                </div>
                <div className="result-container">
                {dummyArray.map(e => {
                    let color;
                    if(e.score >= 70){
                        color = "#54989C";
                    } else if (e.score < 70 && e.score >= 50) {
                        color = "#E8B44F";
                    } else {
                        color = "#EF967E";
                    }

                    return (
                    <div className="score" key={Math.random(100)} style={{height:`${e.score}%`, backgroundColor:`${color}`}} >
                        <div className="score-number">{Math.round(e.score)}</div>
                    </div>)
                })}
                </div>
            </div>
            <div className="month-container">
                {dummyArray.map(e => {
                    return (<div className="month" key={Math.random(100) }>{e.month}</div>)
                })}
            </div>
        </div>
    )

}

BarGraph.propTypes = {
     isScoreHistory: PropTypes.arrayOf(PropTypes.object),
}

export default BarGraph;
