import {call, put, takeEvery} from "redux-saga/effects"


// Login Redux States - user saga specific
import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
// additional saga req
import {setAuthentication, setUser} from "../../../../store/user/actions"

import {
    getSanctumCookie,
    postLogin,
    postLogout
} from "../../../../helpers/backend-helper";

import * as MIXPANEL from "../../../../utils/Segment/segment-methods";


function* loginUser({payload: {user, navigate}}) {

    try {
        yield put(ACTIONS.loginError(null))
        yield call(getSanctumCookie);

        const response = yield call(postLogin, {
            email: user.email,
            password: user.password,
            utm_source: user.utm_source,
            utm_medium: user.utm_medium,
            utm_campaign: user.utm_campaign,
            utm_content: user.utm_content,
            utm_term: user.utm_term,
        })

        yield put(ACTIONS.loginSuccess(response, navigate, {
            utm_source: user.utm_source,
            utm_medium: user.utm_medium,
            utm_campaign: user.utm_campaign,
            utm_content: user.utm_content,
            utm_term: user.utm_term,
        }))


    } catch (error) {
        yield put(ACTIONS.loginError(error?.message));
    }


}

function* successfulLogin({payload: {user, navigate, utm}}) {
    try {
        yield put(setAuthentication(true))
        yield put(setUser(user.data))

        //- SET THE SUPER PROPERTIES FOR MIXPANEL
        // MIXPANEL.Alias(user.data.email).then(() => {
        //     MIXPANEL.RegisterSuperProperties({
        //         "utm_source": utm.utm_source,
        //         "utm_medium": utm.utm_medium,
        //         "utm_campaign": utm.utm_campaign,
        //         "utm_term": utm.utm_term
        //     }, {days: 1, persistent: true}).then(() => {})
        // })


        if (!user.data.allow_access) {
            navigate("/renew-subscription")
        } else if (user.data.reset_password) {
            navigate("/complete-registration", {replace: true})
        } else if (user.data.onboarding_complete) {

            // if exists, redirect user to the intended URL
            if (user.data.intended_url) {
                navigate(user.data.intended_url, {replace: true})
            } else {
                // otherwise send them to the dashboard
                navigate("/browse", {replace: true})
            }

        } else {
            navigate("/complete-registration", {replace: true})
        }


    } catch (error) {
        yield put(ACTIONS.loginError(error))
    }
}


function* logoutUser() {
    try {
        yield call(postLogout)
        yield put(setAuthentication(false))

        MIXPANEL.Identify()

        window.location.replace("/login")
    } catch (error) {
        yield put(ACTIONS.loginError(error))
    }
}


function* AuthSaga() {
    yield takeEvery(TYPES.LOGIN_USER, loginUser)
    yield takeEvery(TYPES.LOGOUT_USER, logoutUser)
    yield takeEvery(TYPES.LOGIN_SUCCESS, successfulLogin)
}

export default AuthSaga
