import * as TYPES from './actionTypes'

const initialState = {
    error: "",
    loading: false,
    data: null,
    watchlistButtonLoading: false,
}

const Watchlist = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_WATCHLIST:
            return {
                ...state,
                loading: true
            }

        case TYPES.SET_WATCHLIST:
            return {
                ...state,
                data: action.payload,
                loading: false
            }

        case TYPES.ADD_TO_WATCHLIST:
            return {
                ...state,
                watchlistButtonLoading: true
            }

        case TYPES.REMOVE_FROM_WATCHLIST:
            return {
                ...state,
                watchlistButtonLoading: true
            }

        case TYPES.ADD_TO_WATCHLIST_COMPLETE:
            return {
                ...state,
                watchlistButtonLoading: false
            }

        default:
            return state;
    }
}

export default Watchlist
