import React, {useState, useEffect} from 'react';

import LoginTextCard from '../../cards/loginTextCard';
import LoginVideoCard from '../../cards/loginVideoCard';

import NewCarousel from '../../Carousels/new-carousel';
import {useDispatch, useSelector} from "react-redux";
import {getIntroSliderCards as getSlider} from "./store/actions";

const LoginCarousel = () => {
    const dispatch = useDispatch();
    const [introSliderCards, setIntroSliderCards] = useState([]);
    const {sliderCards} = useSelector(state => ({
        sliderCards: state.IntroSlider.slides,
    }))

    useEffect(() => {
        dispatch(getSlider())
    }, [])


    useEffect(() => {
        if (sliderCards.length > 0) {
            styleCards(sliderCards)
        }
    }, [sliderCards])

    const styleCards = (slides_array) => {
        // check the type, put in the proper card and push into new array
        let carouselSlide;
        let styledArray = [];

        slides_array.forEach((e) => {
            if (e.background_type === "image") {
                carouselSlide =
                    <LoginTextCard
                        title={e.slide_title}
                        text={e.slide_text}
                        backgroundImage={e.background_src}
                        key={Math.random(100)}
                    />
            } else {
                carouselSlide =
                    <LoginVideoCard
                        title={e.slide_title}
                        text={e.slide_text}
                        backgroundVideo={e.background_src}
                        key={Math.random(100)}
                    />
            }
            styledArray.push(carouselSlide);
        });
        setIntroSliderCards(styledArray);
    }

    return (
        <div className="login-carousel_container">
            <NewCarousel
                cards={introSliderCards}
                showDots={true}
                removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                partialVisible={false}
                source={"Login page carousel"}
                config={{
                    'all': {'breakpoint': {min: 0, max: 6000}, 'items': 1}
                }}
            />
        </div>
    )
}

export default LoginCarousel;
