exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/images/video-black.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../assets/images/video-white.svg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../assets/images/headphones-black.svg"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../assets/images/headphones-white.svg"));

// Module
exports.push([module.id, ".video-audio_switch__container{border:1px solid #fff;box-shadow:0 14px 5px rgba(0,0,0,.01),0 8px 5px rgba(0,0,0,.05),0 3px 3px rgba(0,0,0,.09),0 1px 2px rgba(0,0,0,.1),0 0 0 rgba(0,0,0,.1)}.video-audio_switch__container .switch-container{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.video-audio_switch__container .switch-container .video-switch{position:relative;width:48px;height:40px}.video-audio_switch__container .switch-container .video-switch .video-icon{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);background-image:url(" + ___CSS_LOADER_URL___0___ + ");background-repeat:no-repeat;background-size:contain;width:24px;height:24px}.video-audio_switch__container .switch-container .video-switch.active{background-color:#2d5f62;border-radius:100%;width:48px;height:48px;border:1px solid #fff}.video-audio_switch__container .switch-container .video-switch.active .video-icon{background-image:url(" + ___CSS_LOADER_URL___1___ + ")}.video-audio_switch__container .switch-container .audio-switch{width:48px;height:40px;position:relative}.video-audio_switch__container .switch-container .audio-switch .audio-icon{background-image:url(" + ___CSS_LOADER_URL___2___ + ");background-repeat:no-repeat;background-size:contain;width:24px;height:24px;position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}.video-audio_switch__container .switch-container .audio-switch.active{background-color:#2d5f62;border-radius:100%;width:48px;height:48px;border:1px solid #fff}.video-audio_switch__container .switch-container .audio-switch.active .audio-icon{background-image:url(" + ___CSS_LOADER_URL___3___ + ")}", ""]);

