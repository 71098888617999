import React from 'react';

const PlayIcon = (props) => {
    return (
        <svg preserveAspectRatio="xMidYMid slice" width={props.width} height={props.height} viewBox={`0 0 ${props.width} ${props.height}`} xmlns="http://www.w3.org/2000/svg">
            <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="black" fillOpacity="0.7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.3472 9.4149C12.5613 9.29804 12.822 9.30738 13.0272 9.43926L22.3605 15.4393C22.5513 15.5619 22.6667 15.7732 22.6667 16C22.6667 16.2269 22.5513 16.4382 22.3605 16.5608L13.0272 22.5608C12.822 22.6927 12.5613 22.702 12.3472 22.5852C12.1332 22.4683 12 22.2439 12 22V10C12 9.75617 12.1332 9.53176 12.3472 9.4149ZM13.3333 11.2212V20.7789L20.7672 16L13.3333 11.2212Z" fill={props.color}/>
        </svg>
    )
}

PlayIcon.defaultProps = {
    width: 32,
    height: 32,
    color: '#fff'
}


export default PlayIcon;
