import React from 'react';
import {Track} from "../../utils/Segment/segment-methods";

const CustomArrowRight = (props) => {

    const trackClick = () => {
        Track("clicked_carousel_forward", {Placement:props.source});
    }

    return (
        <button
            aria-label="Go to next slide"
            className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
            onClick={()=>{
                trackClick()
                props.onClick();
            }}
            type="button"
            >
        </button>
    )

}

export default CustomArrowRight;