import React, {useState, useEffect, useContext} from "react";

import store from "./store";
import {fetchUser} from "./store/user/actions";
import {useHistory, useNavigate} from "react-router-dom";


// END IMPORTS
export const AuthenticationContext = React.createContext();
export const useAuth = () => useContext(AuthenticationContext);

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState();

    // const user = JSON.parse(localStorage.getItem('user'));
    let navigate = useNavigate()


    useEffect(() => {

        const initAuth = async () => {
            // check if user exists in state. If not, set state.
            const userInformation = await store.getState().User

            if (!userInformation.authenticated) {
                store.dispatch(fetchUser(navigate))
            } else {
                setIsAuthenticated(true)
            }
        };
        initAuth();


    }, []);




    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};
