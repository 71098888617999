/**
 * Button component should contain
 *      - text*
 *      - properties
 *      - icon
 *      - ability to adjust color on all elements
 */
import React from "react"
import styled from "styled-components"
import Components from "../../Helpers/componentHelper";

const SelectionContainer = styled.div`
  flex-wrap: wrap;
  ${props => props.properties}
`

/**
 *
 * @param block
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectionButtonContainer = ({block, data}) => {
    // console.log('selection data: ', data)
    return (
        <SelectionContainer id={block.attributes.id} properties={data.properties}>
            {
                (block.children || []).map(child => Components(child))
            }
        </SelectionContainer>
    )
}
