import * as TYPES from "./actionTypes"

export const dashboardError = error => {
    return {
        type: TYPES.LOAD_DASHBOARD_ERROR,
        payload: error
    }
}

export const getPopQuiz = () => {
    return {
        type: TYPES.GET_POP_QUIZ,
    }
}

export const setPopQuiz = payload => {
    return {
        type: TYPES.SET_POP_QUIZ,
        payload: payload
    }
}

export const postPopQuiz = payload => {
    return {
        type: TYPES.POST_POP_QUIZ,
        payload: payload
    }
}
