export const GET_CORE_CATEGORIES = "GET_CORE_CATEGORIES"
export const SET_CORE_CATEGORIES = "SET_CORE_CATEGORIES"
export const GET_SINGLE_CATEGORY = "GET_SINGLE_CATEGORY"
export const SET_SINGLE_CATEGORY = "SET_SINGLE_CATEGORY"
export const SINGLE_CATEGORY_ERROR = "SINGLE_CATEGORY_ERROR"
export const SINGLE_CATEGORY_TRENDING = "SINGLE_CATEGORY_TRENDING"
export const SINGLE_CATEGORY_VIDEOS = "SINGLE_CATEGORY_VIDEOS"
export const SINGLE_CATEGORY_RESOURCES= "SINGLE_CATEGORY_RESOURCES"
export const SINGLE_CATEGORY_COURSES = "SINGLE_CATEGORY_COURSES"
export const SET_LAST_LOADED_UUID = "SET_LAST_LOADED_UUID"
