
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import './quiz-question.scss';

const QuizQuestion = (props) => {

    const heartOne = useRef();
    const heartTwo = useRef();
    const heartThree = useRef();
    const heartFour = useRef();
    const heartFive = useRef();

    useEffect(() => {
        resetHearts();
    }, [props]);

    const resetHearts = () => {
        heartOne.current.classList.remove('active');
        heartTwo.current.classList.remove('active');
        heartThree.current.classList.remove('active');
        heartFour.current.classList.remove('active');
        heartFive.current.classList.remove('active');
        props.setIsQuestionAnswered(false);
    }

    const fillHearts = (e) => {
        // on click find heart number and add active to every one prior
        // this should set the current questions score and unlock the button
        // to go to the next question

        let heartPicked = e.currentTarget.classList[1];

        switch (heartPicked) {
            case "one":
                heartOne.current.classList.add('active');
                heartTwo.current.classList.remove('active');
                heartThree.current.classList.remove('active');
                heartFour.current.classList.remove('active');
                heartFive.current.classList.remove('active');
                break;
            case "two":
                heartOne.current.classList.add('active');
                heartTwo.current.classList.add('active');
                heartThree.current.classList.remove('active');
                heartFour.current.classList.remove('active');
                heartFive.current.classList.remove('active');
                break;
            case "three":
                heartOne.current.classList.add('active');
                heartTwo.current.classList.add('active');
                heartThree.current.classList.add('active');
                heartFour.current.classList.remove('active');
                heartFive.current.classList.remove('active');
                break;
            case "four":
                heartOne.current.classList.add('active');
                heartTwo.current.classList.add('active');
                heartThree.current.classList.add('active');
                heartFour.current.classList.add('active');
                heartFive.current.classList.remove('active');
                break;
            case "five":
                heartOne.current.classList.add('active');
                heartTwo.current.classList.add('active');
                heartThree.current.classList.add('active');
                heartFour.current.classList.add('active');
                heartFive.current.classList.add('active');
                break;
        }
        props.setIsAnswerValue(heartPicked);
        props.setIsQuestionAnswered(true);
    }

    return (
        <div className="quiz-question_container">
            <div className="category-container">
                <div className="category-icon" style={{ backgroundImage: `url(${props.icon})` }}/>
                <div className="category-title">
                    {props.category}
                </div>
            </div>
            <div className="question-text">
                {props.question}
            </div>
            <div className='quiz-heart_container'>
                <div className="heart-icon one" ref={heartOne} onClick={fillHearts}/>
                <div className="heart-icon two" ref={heartTwo} onClick={fillHearts}/>
                <div className="heart-icon three" ref={heartThree} onClick={fillHearts}/>
                <div className="heart-icon four" ref={heartFour} onClick={fillHearts}/>
                <div className="heart-icon five" ref={heartFive} onClick={fillHearts}/>
            </div>
            <div className="heart-text_container">
                <div className="score-title text-center">Strongly<br/>Disagree</div>
                <div className="score-title neutral text-center">Neutral</div>
                <div className="score-title text-center">Strongly<br/>Agree</div>
            </div>

        </div>
    )
}
// TODO: add default prop types

QuizQuestion.propTypes = {
    category: PropTypes.string.isRequired,
    icon:PropTypes.string.isRequired,
    question:PropTypes.string.isRequired,
    // setIsQuestionAnswered: '',
    // setIsAnswerValue:'',
}

export default QuizQuestion;
