import React, {useEffect} from 'react';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import './registration-carousel.scss';

const RegistrationCarousel = (props) => {

    const login = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
           
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        }
    };


    return (
        <div className="registration-carousel">
            <Carousel 
        showDots={true}
        responsive={login}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        renderDotsOutside={true}
        partialVisible={false}
        itemClass="carousel-item-padding-8-px"
        >
            {props.carouselItems}
        </Carousel>
        </div>
        
    )
}

export default RegistrationCarousel;