import React from 'react';
import styled from 'styled-components';
import GeneralButton from '../../../../../components/buttons/generalButton.jsx';
const Container = styled.div `
    position: relative;
    padding: 0 16px;
    height: 100vh;
    margin: 0 auto;
    max-width: 666.5px;
`;
const StyledTitle = styled.div `
    font-family: Sofia Pro Bold;
    font-size: 28px;
    text-align: left;
    line-height: 1.2;
    margin-bottom: 8px;
`;
const StyledSubTitle = styled.div `
    font-size: 14px;
    color: #47505F;
    text-align: left;
    margin-bottom: 20px;
`;
const ProfileImageContainer = styled.div `
    width: fit-content;
    padding-top: 30px;
    margin: 22px auto;
`;
const ProfileImage = styled.div `
    background-size: contain;
    border-radius: 1000px;
    background-repeat: no-repeat;
    width: 140px;
    height: 140px;
    margin: 0px auto 36px;
    position: relative;
`;
const ProfileEmail = styled.input `
    padding: 8px 0px;
    width: 183px;
    margin: 0 auto;
    font-family: $primary-font;
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
    display: block;
    border: none;
    border-bottom: 1px solid #E3DCD3;
    background-color: transparent;
`;
const ProfileNameContainer = styled.div `
    width: 183px;
    border-bottom: 1px solid #E3DCD3;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: nowrap;
    gap: 4px;
`;
const ProfileName = styled.div `
    width: inherit;
    padding-bottom: 8px;
    margin: 0 auto;
    font-family: $primary-font-bold;
    font-size: 20px;
    white-space: nowrap;
    text-align: center;
    display: inline;
    border: none;
    text-align: center;
`;
const RemoveButtonContainer = styled.div `
    .general-button_container {
        position: relative;
        background: #F2F2F2;
        border: 1px solid #D93B31;
        border-radius: 10px;
        width: 128px;
        height: 23px;
        margin: 0 auto 39px;

        .button-title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #D93B31;
            font-size: 12px;
            white-space: nowrap;
        }
    }
`;
function PartnerAccountInfo({ onClickShowRemovePartnerModal, partnerEmail, partnerFirstName, partnerProfileImageUrl, }) {
    return (React.createElement(Container, null,
        React.createElement(StyledTitle, null, "Partner Pairing"),
        React.createElement(StyledSubTitle, null, "The following profile is associated with your account."),
        React.createElement(ProfileImageContainer, null,
            React.createElement(ProfileImage, { style: { backgroundImage: `url(${partnerProfileImageUrl})` } },
                React.createElement("div", { className: "camera-icon" })),
            React.createElement(ProfileNameContainer, null,
                React.createElement(ProfileName, null, partnerFirstName)),
            React.createElement(ProfileEmail, { className: "profile-email", placeholder: partnerEmail })),
        React.createElement(RemoveButtonContainer, null,
            React.createElement(GeneralButton, { buttonText: "Remove User", backgroundColor: "#FFFFFF", textColor: "#D93B31", onClick: onClickShowRemovePartnerModal }))));
}
export default PartnerAccountInfo;
