import {takeEvery, put, call, all, fork} from "redux-saga/effects"

import * as TYPES from "./actionTypes"

import * as ACTIONS from "./actions"

//Include Both Helper File with needed methods
import {
    getUser,
    updateUser,
    updateProfileImage,
    updateUserPassword,
    addSecondaryUser,
    removeSecondaryUser
} from "../../helpers/backend-helper"

function* fetchUser({navigate}) {
    try {
        const response = yield call(getUser)
        yield put(ACTIONS.getUserSuccess(response))

        if (!response.user.allow_access) {
            navigate('/renew-subscription')
        }

        if (response.user.reset_password) {
            navigate("/complete-registration")
        }

        if (response.user.onboarding_complete === 0 && window.location.pathname !== '/complete-registration') {
            navigate('/pick-quiz')
        }


    } catch (error) {
        yield put(ACTIONS.setUserError(error))
    }
}

function* successfulFetchUser({payload: {user}}) {

    try {
        yield put(ACTIONS.setAuthentication(true))
        yield put(ACTIONS.setUser(user))
    } catch (error) {
        // todo: connect to sentry
        // console.log('SENTRY: *****************', error)
    }
}

function* postUserProfile({payload}) {

    try {

        const response = yield call(updateUser, payload);

        // update mixpanel with data

        yield put(ACTIONS.setUserSuccessMessage(response.message));
    } catch (e) {
        yield put(ACTIONS.setUserError(e.message))
        yield put(ACTIONS.setUser(response.user));
        // console.log('to sentry: ', e)
    }
}

function* postUserProfileImage({payload}) {
    try {
        // console.log("check payload", payload);
        const response = yield call(updateProfileImage, payload);

        yield put(ACTIONS.setUserSuccessMessage(response.message));
    } catch (e) {
        yield put(ACTIONS.setUserError(e.message))
        // yield put(setUser(response.user));
        // console.log('to sentry: ', e)
    }
}

function* postUserPassword({payload}) {

    try {
        const response = yield call(updateUserPassword, payload);
        // console.log("POST USER PASSWORD RESPONSE", response);
        yield put(ACTIONS.setUserSuccessMessage(response.message));
        // yield put(ACTIONS.setUserPassword());
    } catch (e) {
        // console.log('to sentry: ', e)
        yield put(ACTIONS.setUserError(e.message))
    }
}

function* addSecondaryUserProfile({payload}) {

    try {
        const response = yield call(addSecondaryUser, payload);
        // console.log("check response for a complete second user obj", response);
        yield put(ACTIONS.setUserSuccessMessage(response.message));

        const user = yield call(getUser)
        yield put(ACTIONS.getUserSuccess(user))

    } catch (e) {
        // console.log('to sentry: ', e);
        yield put(ACTIONS.setUserError(e.message));
    }
}

function* removeSecondaryUserProfile({payload}) {

    try {
        const response = yield call(removeSecondaryUser, payload);
        // console.log("check response for remove secondary", response);
        yield put(ACTIONS.setUserSuccessMessage(response.message))

        const user = yield call(getUser)
        yield put(ACTIONS.getUserSuccess(user))
    } catch (e) {
        // console.log('to sentry: ', e);
        yield put(ACTIONS.setUserError(e.message));
    }
}

function* UserSaga() {
    yield takeEvery(TYPES.ADD_SECONDARY_USER, addSecondaryUserProfile);
    yield takeEvery(TYPES.GET_USER, fetchUser);
    yield takeEvery(TYPES.GET_USER_SUCCESS, successfulFetchUser);
    yield takeEvery(TYPES.REMOVE_SECONDARY_USER, removeSecondaryUserProfile);
    yield takeEvery(TYPES.UPDATE_PROFILE_IMAGE, postUserProfileImage);
    yield takeEvery(TYPES.UPDATE_USER, postUserProfile);
    yield takeEvery(TYPES.UPDATE_USER_PASSWORD, postUserPassword);
}

export default UserSaga
