import React from 'react';
import PropTypes from 'prop-types';
import './audioSwitch.scss';

const AudioSwitch = (props) => {

    return (
        <div className='switch-container'>
            <div className={`pointer video-switch ${props.isAudioSelected ? "" : "active"}`} onClick={props.toggleAudioOnly}>
                <div className='video-icon'>

                </div>
            </div>
            <div className={`pointer audio-switch ${props.isAudioSelected ? "active" : ""}`} onClick={props.toggleAudioOnly}>
                <div className='audio-icon'>

                </div>
            </div>
        </div>
    )
}

AudioSwitch.propTypes = {
   isAudioSelected: PropTypes.bool,
   toggleAudioOnly: PropTypes.func,
}

export default AudioSwitch;
