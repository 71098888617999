
import React from 'react';
import { Link } from 'react-router-dom';
import GeneralButton from '../../buttons/generalButton';
import WhiteWorksheet from '../../../assets/images/white-worksheet.svg';
import PropTypes from 'prop-types';
import './video-header-desktop.scss';

const ChallengeHeaderDesktop = (props) => {

    return (
        <div className="challenge-header_desktop__container">
            <div className="video-content_container" >
                <div className='content'>
                    <div className="category">
                        {props.category}
                    </div>
                    <div className="video-title">
                        {props.title}
                    </div>
                    <div className="video-text" dangerouslySetInnerHTML={{ __html: props.intro }}>

                    </div>
                </div>

                <div className="action-container">
                    <div className="play-container">
                        <div className='play-button_container'>
                            <div className="play-button">

                            </div>
                        </div>

                        <div className='play-text'>
                            Play Video
                        </div>
                    </div>
                    <div className='button-container'>
                        <div className="watchlist-container">
                            <div className="watchlist"></div>
                        </div>

                        <div className="headphones-container">
                            <div className="headphones">

                            </div>
                        </div>
                    </div>
                </div>
                <div className='content'>
                    <Link to={props.link}>
                        <GeneralButton
                            backgroundColor={"#2D5F62"}
                            buttonText={"Connecting Questions"}
                            icon={WhiteWorksheet}
                            onClick={''}
                        />
                    </Link>
                </div>

            </div>
            <div className="large-banner_image" style={{ backgroundImage: `url(${props.src})` }}>

            </div>
        </div>
    )

}

ChallengeHeaderDesktop.propTypes = {
    category: PropTypes.object.isRequired,
    intro: PropTypes.string,
    link: PropTypes.link,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default ChallengeHeaderDesktop;