import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import TimePill from '../Pills/timePill';
import WorksheetCard from './worksheetCard';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import './searchResultCard.scss';

const SearchResultCard = (props) => {

    const history = useNavigate();

    const goToAsset = () => {

        switch (props.hit.index_type) {
            case "video":
                history('/watch/' + props.hit.uuid);

                break;
            case "worksheet":
                history('/read/' + props.hit.uuid);

                break;
            case "challenge":
                history('/challenge/' + props.hit.uuid);

                break;
            case "course":
                history('/course/' + props.hit.uuid);

        }
    }
    // console.log('search props', props);

    return (
        <div className="search-result_card" onClick={goToAsset}>
            <div className="result-title_container">
                <div className="result-title">{props.hit.title}</div>
                <div className="result-subtitle">
                    <div className="result-type">{props.hit.index_type}</div>
                    <div>&#8226;</div>
                    <div>{props.hit.core_category_title[0]}</div>
                </div>
            </div>
            {props.hit.index_type === "worksheet" ? (
                <div className={`result-image ${props.hit.index_type}`} >
                    <WorksheetCard
                        title={props.hit.title}
                        src={props.hit.portrait_poster}
                        assetType={props.hit.index_type}
                        cardHeight={"70"}
                    />
                    {/* {props.hit.duration !== undefined && (<TimePill duration={props.hit.duration} position={'bottom-right'} />)}
                    {props.hit.index_type === "worksheet" && (
                        <Fragment>
                            <div className="worksheet-title"></div>
                            <div className="worksheet-tab"></div>
                        </Fragment>

                    )} */}
                </div>
            ) : (
                <div className={`result-image ${props.hit.index_type}`} style={{ backgroundImage: `url(${props.hit.index_type === "worksheet" ? props.hit.portrait_poster : props.hit.poster})` }}>

                    {props.hit.duration !== undefined && (<TimePill duration={props.hit.duration} position={'bottom-right'} />)}
                    {props.hit.index_type === "worksheet" && (
                        <Fragment>
                            <div className="worksheet-title"></div>
                            <div className="worksheet-tab"></div>
                        </Fragment>

                    )}
                </div>
            )}

        </div>
    )

}

SearchResultCard.propTypes = {
    hit: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

export default SearchResultCard;
