import styled from "styled-components";

const PaymentInfoLine = styled.div`
    display: flex;
    gap: 4px;

    > span {
        font-family: 'Sofia Pro Bold', sans-serif;
    }

`

export {PaymentInfoLine}
