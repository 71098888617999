import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import GeneralButton from '../../buttons/generalButton';
import WhitePlayButton from '../../../assets/images/white-play-icon.svg';
import RestartButton from '../../../assets/images/refresh-ccw.svg';
import ProgressBar from '../progress-bar';
import PropTypes from 'prop-types';
import './challenge-header.scss';
import WatchlistButton from "../../buttons/watchlistButton";
import {UseSystemSettings} from "../../../utils/SystemSettings/provider/system-settings";



const ChallengeHeader = ({...props}) => {
    const {settings} = UseSystemSettings();


    /**
     * This is a temporary fix until we use redux to store the challenge.
     * todo: use redux to store the challenge
     * @returns {Promise<void>}
     */
    const toggleWatchlist = async () => {
        props.setIsChallengeWatchlist(!props.onWatchlist)
    }

    return (
        <div className="challenge-header_container">
            <div className="header-image" style={{backgroundImage: `url(${props.src})`}}>
                <Link to={'/browse'} className="back-link">
                    <div className='back-button'></div>
                </Link>
            </div>
            <div className="challenge-progress_container">
                <div className='title-container'>
                    <div className="challenge-title">{props.title}</div>
                    <div className='duration-container'>
                        <div className="videos">
                            {props.videos} videos
                        </div>
                        <div>
                            &#183;
                        </div>
                        <div className="days">
                            {props.days} days
                        </div>
                        <div>
                            &#183;
                        </div>
                        <div className="time">
                            {props.duration}
                        </div>
                    </div>
                </div>

                <div className='challenge-button_container'>
                    <div className='settings-button_container'>
                        <div className="button-container mb-4" onClick={() => toggleWatchlist()}>
                            <WatchlistButton onWatchlist={props.onWatchlist}
                                             icons={{
                                                 checkmark: settings?.ICONS.shapes.checkmark.svg,
                                                 plus: settings?.ICONS.shapes.plus.svg
                                             }}
                                             loader={{
                                                 color: '#fff',
                                                 width: '8px'
                                             }}
                                             buttonType={`circle`}
                                             assetID={props.challenge_uuid}
                                             assetType={`challenge`}
                            />
                        </div>
                        <Link to={'/account?tab=notification'}>
                            <div className='settings-button'>
                                <div className="bell-icon"></div>
                            </div>
                        </Link>

                        <div className='settings-text'>Settings</div>
                    </div>

                    {props.isChallengeStarted ? (
                        <div className='restart-button_container' onClick={props.resetChallenge}>

                            <div className='restart-button'>
                                <div className="restart-icon"></div>
                            </div>


                            <div className='restart-text'>Restart</div>
                        </div>
                    ) : (
                        <Link className='start-button_container'
                              to={`/get-challenge-lesson/${props.challenge_uuid}/${props.lesson_uuid}`}>

                            <div className='start-button'>
                                <div className="play-icon"></div>
                            </div>


                            <div className='start-text'>Start</div>
                        </Link>
                    )}

                    {props.isChallengeStarted ? (
                        <div className="restart-button_desktop">
                            <div>
                                <GeneralButton
                                    buttonText={"Restart Challenge"}
                                    icon={RestartButton}
                                    textColor={"#000"}
                                    backgroundColor={'rgba(0, 0, 0, 0.08)'}
                                    onClick={props.resetChallenge}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="start-button_desktop">
                            <Link to={`/get-challenge-lesson/${props.challenge_uuid}/${props.lesson_uuid}`}>
                                <GeneralButton
                                    buttonText={"Start Challenge"}
                                    icon={WhitePlayButton}
                                    textColor={"#fff"}
                                    backgroundColor={'#2D5F62'}
                                    onClick={() => {
                                        return
                                    }}
                                />
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <div className='challenge-progress_bar'>
                <ProgressBar numerator={props.numerator} denominator={props.days}/>
            </div>
            <div className='progress-text'>
                <div className="challenge-progress">
                    {props.isChallengeComplete ? (
                        <b>Challenge Complete</b>
                    ) : (
                        'Challenge Progress'
                    )}
                </div>
                {props.isChallengeComplete ? (
                    <div className="progress-complete"></div>
                ) : (
                    <div className="progress-percent">
                        {props.numerator === 0 ? 0 : Math.round((props.numerator / props.days) * 100)}%
                    </div>
                )}

            </div>
        </div>
    )

}

ChallengeHeader.propTypes = {
    challenge_uuid: PropTypes.string.isRequired,
    days: PropTypes.number.isRequired,
    duration: PropTypes.string.isRequired,
    isChallengeComplete: PropTypes.bool.isRequired,
    isChallengeStarted: PropTypes.bool.isRequired,
    lesson_uuid: PropTypes.string,
    numerator: PropTypes.number.isRequired,
    resetChallenge: PropTypes.func.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    videos: PropTypes.number.isRequired,
}


export default ChallengeHeader
