import React from 'react'
import classNames from "classnames"

function ProfileWrap({className,...props}) {
    const compClass = classNames({
        "profile-wrap": true,
        [`${className}`]: className,
    });
    return (
        <div className={compClass}>
            {props.children}
        </div>
    )
}

export default ProfileWrap
