import React, {useEffect, useState} from "react"

import styled from "styled-components"
import * as STYLES from "../../Templates/Global/default-styles"
import {Alert} from "../Alert";
import {useStateMachine} from "little-state-machine";
import ScaleLoader from "react-spinners/ScaleLoader";
import {AllowedFunctions} from "../../Functions/buttons";
import updateAction from "../../Actions/updateAction";

const InputGroup = styled.div`
    ${STYLES.INPUT_GROUP}
    margin-bottom: 1em;
`
const CheckCouponButton = styled.button`
    ${STYLES.BTN}
`

export const CouponField = ({register, getValues, setValue}) => {
    const {state, actions} = useStateMachine({updateAction});
    const query = new URLSearchParams(window.location.search);


    const [coupon, setCoupon] = useState(query.get('coupon') || '');


    useEffect(() => {
        if (!!state.data.coupon.couponRedeemed) {
            setCoupon(state.data.coupon.couponRedeemed)
            setValue('promo_code', state.data.coupon.couponRedeemed)
        }
    }, [state.data.coupon])

    useEffect(() => {
        setCoupon(null)
        let promoCode = getValues("promo_code");

        // if plan has been changed, but the coupon in the field still equals what it did before the new selection, clear it
        if (state.data.coupon.couponRedeemed && (state.data.coupon.couponRedeemed === promoCode)) {
            setValue('promo_code', null)
        }

        // reset the coupon if the plan is changed
        actions.updateAction({
            coupon: {
                couponRedeemed: null,
                buttonState: true,
                errorMessage: null,
                status: null,
                couponResponse: null,
                amountToCharge: null
            }
        })

    }, [state.data.selectedPlan])

    const checkCoupon = (e = null) => {
        if (e) {
            e.preventDefault()
        }

        actions.updateAction({
            coupon: {
                buttonState: false
            }
        })


        let promoCode = getValues("promo_code");


        AllowedFunctions.CheckCoupon(promoCode, state.data.selectedPlan).then(response => {

                if (response.status !== 200) {
                    // setPaymentError(response.errorMessage)
                    actions.updateAction({
                        coupon: {
                            errorMessage: response.errorMessage,
                            buttonState: response.buttonState,
                            amountToCharge: null
                        }
                    })
                } else {
                    actions.updateAction({
                        coupon: {
                            couponRedeemed: response.couponRedeemed,
                            buttonState: response.buttonState,
                            couponResponse: response.couponResponse,
                            amountToCharge: response.amountToCharge
                        }
                    })

                }
            }
        )
    }

    return (
        <>
            {
                state.data.coupon.couponResponse &&
                <Alert data={{text: state.data.coupon.couponResponse, properties: {}, className: "alert-success"}}/>
            }
            <label>Promo Code</label>
            <InputGroup>
                <input defaultValue={coupon} {...register("promo_code")}/>
                <div className="append">
                    <CheckCouponButton onClick={(e) => checkCoupon(e)}>
                        {
                            state.data.coupon.buttonState ? <span>Apply</span> :
                                <ScaleLoader height="10px" color="#2D5F62"/>
                        }
                    </CheckCouponButton>
                </div>
            </InputGroup>
        </>
    )
}
