/**
 * Button component should contain
 *      - text*
 *      - properties
 *      - icon
 *      - ability to adjust color on all elements
 */
import React from "react"
import styled from "styled-components"
import * as STYLES from "../../Templates/Global/default-styles"
import {useStateMachine} from "little-state-machine";

const ButtonText = styled.span`
        ${STYLES.BUTTON_TEXT}
        ${props => props.properties}

    `

const ButtonElement = styled.button`
        ${STYLES.BUTTON_CONTAINER}
        ${props => props.properties}
    `

function RouteButtonClick(onCLickProperties) {
    // display a section on the page by the ID
    if (onCLickProperties.activateId !== null) {
        document.getElementById("braintree-form").style.display = 'block'
    }

    // onCLickProperties.external ? window.push(onCLickProperties.href) : onCLickProperties.href.current?.scrollIntoView({behavior: 'smooth'})
}

/**
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export const Button = ({data}) => {
    const {state} = useStateMachine();
    // console.log('btn state: ', state)
    // console.log('btn data: ', data)
    return (
        //TODO - The buttonState object needs to be dynamic. it should come from the server and will change
        <ButtonElement id={data.attributes.id} disabled={state.data.buttonState.startMembershipButton}
                       onClick={() => RouteButtonClick(data._onClick)} properties={data.properties}>
            <ButtonText>
                {data.text}
            </ButtonText>
        </ButtonElement>

    )
}
