import SubscriptionCard from '../components/cards/subscriptionCard.jsx';
export const SUBSCRIPTION_SECTION_INFO = {
    page: {
        title: 'Subscription',
        subTitle: 'Your subscription will automatically renew on [_BILLING_DATE]. Your next payment amount is $[_BILLING_AMOUNT].',
        buttons: {
            cancel: 'Cancel Membership',
        },
    },
    items: [],
};
export const SUBSCRIPTION_COMPONENT_MAPPING = {
    SubscriptionCard,
};
