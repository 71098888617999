import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import GeneralButton from "../../components/buttons/generalButton";
import styled from "styled-components";
import './style.scss';
import {Track} from "../../utils/Segment/segment-methods";

const AppSelection = () => {
    const query = new URLSearchParams(window.location.search);
    const [title, setTitle] = useState(null);
    const [webLink, setWebLink] = useState(null);
    const [appLink, setAppLink] = useState(null);

    const navigate = useNavigate();
    const utm_source = query.get('utm_source') || '';
    const utm_medium = query.get('utm_medium') || '';
    const utm_campaign = query.get('utm_campaign') || '';
    const utm_content = query.get('utm_content') || '';

    useEffect(() => {
        axios.get(`/_a/data/${query.get('_t')}/${query.get('_u')}`).then(r => {
            setTitle(r.data.title)
            setWebLink(r.data.link.web)
            setAppLink(r.data.link.app)
        })
    },[])
    const openApp = () => {
        Track('Selected App', {title: title, type: 'Asset'}).then(() => {})
        window.location.replace(appLink)
    }

    const openBrowser = () => {
        Track('Selected Web', {title: title, type: 'Asset'}).then(() => {})
        window.location.replace(webLink)
    }

    const SelectionContainer = styled.div`
        width: 640px;
        max-width: 96%;
    `

    return (
        <SelectionContainer className="container d-flex flex-column align-items-center justify-content-center vh-100">
            <div className="mb-4">
                <h2>{title}</h2>
            </div>
            <div className="container d-flex flex-row justify-content-between align-items-center"
                 style={{backgroundColor: '#eee', padding: '8px'}}>
                <div className="d-flex p-relative"
                     style={{
                         backgroundColor: '#78A5A8',
                         borderRadius: '20px',
                         height: '92px',
                         width: '92px',
                         padding: '12px'
                     }}>
                    <img src="/images/white-m365-logo.svg" alt=""/>
                </div>
                <div style={{maxWidth: "200px", width: "50%"}}>
                    <GeneralButton textColor={'#fff'} disabled={false} backgroundColor={"#2D5F62"}
                                   onClick={() => openApp()} buttonText={'Open App'}></GeneralButton>
                </div>
            </div>
            <div className="container-fluid">
                <GeneralButton className={'mt-4'} textColor={'#fff'} disabled={false} backgroundColor={"#2D5F62"}
                               onClick={() => openBrowser()} buttonText={'Open in Browser'}></GeneralButton>
            </div>
        </SelectionContainer>

    )
}

export default AppSelection;
