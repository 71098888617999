import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { getFormattedStyle } from '../global/utils';
const defaultProps = {
    className: undefined,
};
const StyledText = styled.span `
    ${(props) => props.styles && `
    ${Object.keys(props.styles).map((key) => `${key}: ${props.styles[key]};`).join('\n')}
`}
`;
// eslint-disable-next-line react/prop-types
function Text({ className, children, styles, }) {
    const formattedStyle = !isEmpty(styles)
        ? getFormattedStyle(styles) : {};
    return (React.createElement(StyledText, { className: className, styles: formattedStyle }, children));
}
Text.defaultProps = defaultProps;
export default Text;
