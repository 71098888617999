import React from 'react';
import './trendingCard.scss';
import styled from "styled-components";
import PropTypes from 'prop-types';

const ChallengeCard = (props) => {

    const ChallengeContainer = styled.div`
        height: ${props.cardHeight}px;
    `

    const ChallengeItem = styled.div`
     height: ${props.cardHeight}px;
        background-image: url(${props.src})
    `

    return (
        <ChallengeContainer className="challenge-card">
            <ChallengeItem className="challenge-image"/>
            <div className="challenge-text_container">
                <h4 className="font-semibold">{props.title}</h4>
                <div className="duration-container">
                    <h6>
                        {props.numberOfLessons} videos
                    </h6>
                    &#8226;
                    <h6>
                        {props.numberOfDays} days
                    </h6>
                </div>
            </div>
        </ChallengeContainer>
    )
}

ChallengeCard.propTypes = {
    cardHeight: PropTypes.number.isRequired,
    categoryIcon: PropTypes.string,
    numberOfDays: PropTypes.number.isRequired,
    numberOfLessons: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired, 
}

export default ChallengeCard;
