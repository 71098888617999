/**
 * Title component should contain
 *      - text*
 *      - properties
 *      - icon
 *      - ability to adjust color on all elements
 */
import React from "react"
import styled from "styled-components"
import * as STYLES from "../../Templates/Global/default-styles"
import PropTypes from "prop-types";
import {GetAttributes} from "../../HotFixes";

const TitleTag = styled.div`
  ${STYLES.PARAGRAPH_STYLE}
  ${props => props.properties}
  position: relative;
  z-index: 9;

  ::before {
    ${props => props.highlight}
    content: "";
    position: absolute;
    display: inline-block;
    z-index: -1;
  }

`

export const Title = ({data, block = null}) => {
    // console.log('Title block: ', block)
    return (
        <TitleTag id={GetAttributes('id', block)}
                  className={GetAttributes('class', block)}
                  properties={data.properties}
                  highlight={data.highlight ? data.highlight.properties : null}
                  dangerouslySetInnerHTML={{__html: data.text}}
        />

    )
}


Title.propTypes = {
    data: PropTypes.shape({
        text: PropTypes.string.isRequired
    }).isRequired

}
