//-DEPENDENCIES
import React, {useState, useEffect} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
//-HELPERS
import {FormatLastCheckupDate} from "./helpers";
import {UseSystemSettings} from "../../utils/SystemSettings/provider/system-settings";

//-ASSETS

//-COMPONENTS
import {CreateCategories} from "./create-categories";
import LoadingScreen from "../../components/LoadingScreen";
import MarriageInfoDrawer from "../../components/Modals/marriage-info-drawer";
import MyMarriageHeader from "../../components/common/header-component/my-marriage-header";
import MyMarriageModal from "../../components/Modals/my-marriage-modal";

//-ACTIONS
import {
    getInfoMessages,
    getOverallStats,

} from "./store/actions";

import "./my-marriage.scss";
import MobileFooter from "../../components/common/footer-component/mobile-footer";
import DesktopNav from "../../components/common/nav/desktop-nav";
import {HandleRouteChange} from "../../utils/Segment/segment-methods";


//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getInfoMessages,
            getOverallStats,
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    infoMessages: appState.MyMarriage.info,
    myStats: appState.MyMarriage.allStats,
    myStatsLoading: appState.MyMarriage.allStatsLoading,
    screenWidth: appState.UserInterface.screenWidth,
    user: appState.User.data,
});

//-START FUNCTIONAL COMPONENT
const MyMarriage = ({
                        getInfoMessages,
                        getOverallStats,
                        myStats,
                        myStatsLoading,
                        infoMessages,
                        screenWidth,
                        user,
                    }) => {

    const {settings} = UseSystemSettings();
    let navigate = useNavigate();
    let location = useLocation()

    //-LOCAL STATE
    const [isDrawerShown, setIsDrawerShown] = useState(false);
    const [isLookingGoodCards, setIsLookingGoodCards] = useState([]);
    const [isModalShown, setIsModalShown] = useState(false);
    const [isNeedsWorkCards, setIsNeedsWorkCards] = useState([]);
    const [isPriorityCards, setIsPriorityCards] = useState([]);

    //-USE EFFECTS
    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Marriage Checkup Score')
        }
    }, [user, location])

    useEffect(() => {
        if (!myStats) {
            getOverallStats()
            getInfoMessages("my-marriage-overall-score")
        }
        window.scroll(0, 0)
    }, []);

    useEffect(() => {
        if (myStats && settings) {
            const cards = CreateCategories([...myStats.priority.stats, ...myStats.needs_work.stats, ...myStats.looking_good.stats])
            setIsPriorityCards(cards.priority);
            setIsNeedsWorkCards(cards.needsWork);
            setIsLookingGoodCards(cards.lookingGood);
        }
    }, [myStats, settings])


    const buttonFunction = () => {
        // todo: send to segment
        navigate("/start-assessment");
    }

    const showDrawer = () => {
        setIsDrawerShown(true);
    }


    return (
        myStatsLoading || myStatsLoading === undefined || settings === null || JSON.stringify(user) === '{}' ?
            <LoadingScreen/>
            :
            <>
                <DesktopNav user={user}/>
                <div className="my-marriage_page">
                    <MyMarriageHeader
                        buttonFunction={buttonFunction}
                        isCurrentScore={Math.round(myStats.current.score)}
                        isScoreHistory={myStats.history}
                        lastTaken={FormatLastCheckupDate(myStats.current.date)}
                        showDrawer={showDrawer}
                        user={user}
                    />
                    <div className="my-marriage_score__container">
                        {
                            isPriorityCards.length > 0 && (
                                <div className="priority-container my-marriage_container">
                                    <div className="priority-title my-marriage_title">
                                        <h3>Priority</h3>
                                        <div className="priority-icon"/>
                                    </div>
                                    {isPriorityCards}
                                </div>
                            )}
                        {
                            isNeedsWorkCards.length > 0 && (
                                <div className="needs-work_container my-marriage_container">
                                    <div className="needs-title my-marriage_title">
                                        <h3>Needs Work</h3>
                                        <div className="needs-icon"/>
                                    </div>
                                    {isNeedsWorkCards}
                                </div>
                            )}
                        {
                            isLookingGoodCards.length > 0 && (
                                <div className="looking-good_container my-marriage_container">
                                    <div className="good-title my-marriage_title">
                                        <h3>Looking Good</h3>
                                        <div className="good-icon"/>
                                    </div>
                                    {isLookingGoodCards}
                                </div>
                            )}
                    </div>
                    {
                        isModalShown && (
                            <MyMarriageModal
                                closeModal={() => {
                                    setIsModalShown(false);
                                }}
                            />
                        )
                    }
                    {
                        isDrawerShown && (
                            <div className="drawer-container">
                                <MarriageInfoDrawer
                                    header={infoMessages.message.header}
                                    body={infoMessages.message.body}
                                    closeModal={() => {
                                        setIsDrawerShown(false);
                                    }}
                                />
                            </div>

                        )
                    }
                </div>
                <MobileFooter/>
            </>
    )

}

MyMarriage.propTypes = {
    getInfoMessages: PropTypes.func,
    getOverallStats: PropTypes.func,
    myStats: PropTypes.object,
    myStatsLoading: PropTypes.bool,
    infoMessages: PropTypes.object,
    screenWidth: PropTypes.number,
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMarriage);
