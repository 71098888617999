import React, { useState, useEffect } from 'react';
import {Link, useLocation} from 'react-router-dom';
import GeneralButton from '../components/buttons/generalButton';
import PropTypes from 'prop-types';

import './welcome-page.scss';
import {connect} from "react-redux";
import {HandleRouteChange} from "../utils/Segment/segment-methods";
import {bindActionCreators} from "redux";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data
});

const WelcomePage = ({user}) => {
    let location = useLocation()

    const [isProfileName, setIsProfileName] = useState();
    const [isProfileImage, setIsProfileImage] = useState();

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Welcome Page')
        }
    }, [user, location])

    return (
        <div className="welcome-page">
            <div className='dark-blue-dot'></div>
            <div className='yellow-ring'></div>
            <div className='coral-dot'>
                <div className="welcome-logo_container">
                    <div className='m365-logo_white'></div>
                </div>

            </div>
            <div className='light-blue-ring'></div>
            <div className='white-dot'></div>
            <div className="profile-logo_container">
                <div className='desktop-content_profile'>
                    <div className='desktop-profile_layout'>
                        <div className="profile-image">
                            <div className="camera-icon">

                            </div>
                        </div>
                        <div className="profile-welcome">
                            {isProfileName ? (
                                <div className="profile-name">
                                    {`Welcome, ${isProfileName}`}

                                </div>
                            ) : (
                                <div className="profile-name">
                                    {`Welcome`}
                                </div>
                            )}

                        </div>
                        <div className="welcome-button_desktop__container">
                            <Link to={'/pick-quiz'}>
                                <GeneralButton
                                    backgroundColor={"#2D5F62"}
                                    buttonText={`Get Started >`}

                                    textColor={"#fff"}
                                    onClick={() => { return }}
                                />
                            </Link>
                        </div>
                    </div>
                </div>

                <div className='mobile-profile_layout'>
                    <div className="profile-image">
                        <div className="camera-icon">

                        </div>
                    </div>
                    <div className="profile-welcome">
                        {isProfileName ? (
                            <div className="profile-name">
                                {`Welcome, ${isProfileName}`}

                            </div>
                        ) : (
                            <div className="profile-name">
                                {`Welcome`}
                            </div>
                        )}

                    </div>
                </div>

            </div>
            <div className="welcome-button_container">
                <Link to={'/pick-quiz'}>
                    <GeneralButton
                        backgroundColor={"#2D5F62"}
                        buttonText={"Get Started"}
                        textColor={"#fff"}
                        onClick={() => { return }}
                    />
                </Link>
            </div>
        </div>
    )
}


WelcomePage.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage)
