import React from "react";
import {Link} from "react-router-dom";

import "../Checkup/pick-quiz.scss";

const SelectEntry = () => {

    return (
        <div className="pick-quiz_page">
            <div className="m365-logo"/>
            <div className="pick-quiz_content">
                <h1>How would you like to access membership?</h1>
                <div className="pick-card_container">
                    <a href="https://marriage365app.page.link/iGuj">
                        <div className="quick-quiz_container choice-container">
                            <div className="quick-icon quiz-icon"/>
                            <h4>
                                Open the mobile app
                            </h4>
                        </div>
                    </a>
                    <Link to={"/login"}>
                        <div className="full-quiz_container choice-container">
                            <div className="full-icon quiz-icon"/>
                            <h4>
                                Proceed to website
                            </h4>
                        </div>
                    </Link>

                </div>

            </div>
        </div>
    )

}

// todo: create a scss file that is only used for this file.

export default SelectEntry;

