import React, { useRef } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CustomProgressBar from '../ProgressBar/progress-bar';
import PropTypes from 'prop-types';

import './watchlistCard2022.scss';
import {Track} from "../../utils/Segment/segment-methods";

const AssetCard = (props) => {
    let navigate = useNavigate();
    let watchlistCard = useRef();
    let videoSection = useRef();
    let deleteButton = useRef();
    let videoImage = useRef();

    const removeItem = () => {
        // need uuid to remove
        let currentWatchlist;
        if (props.source === "Watchlist") {
            currentWatchlist = props.watchlist.data;
        } else {
            currentWatchlist = props.watchlist.assets;
        }
        currentWatchlist.splice(props.position, 1);

        let watchlistObj;
        if (props.source === "Watchlist") {
            watchlistObj = { data: currentWatchlist };
        }
        props.removeVideo(props.uuid, props.assetType);

        switch (props.source) {
            case "Watchlist":
                props.styleCards(watchlistObj, "Watchlist");
                break;
            case "Continue Watching":
                props.styleCards(currentWatchlist, "Continue Watching");
                break;
            case "History":
                props.styleCards(currentWatchlist, "History");
                break;
        }

        watchlistCard.current.style.display = "none";
    }

    const slideLeft = () => {
        // wait one second before activating
        // make sure it is not sliding right
        videoImage.current.classList.remove("slide-right");
        deleteButton.current.classList.remove("hide-delete");

        videoImage.current.classList.add("slide-left");
        deleteButton.current.classList.add("reveal");
        videoImage.current.classList.add("active");
        setTimeout(() => {
            videoImage.current?.classList.remove("active");
        }, 2000)
    }

    const slideRight = () => {
        // if it is sliding right prevent slide left
        let tempArray = Array.from(videoImage.current.classList)
        if (tempArray.includes('active')) {
            setTimeout(() => {
                videoImage.current?.classList.add("slide-right");
                deleteButton.current?.classList.add("hide-delete");

                videoImage.current?.classList.remove("slide-left");
                deleteButton.current?.classList.remove("reveal");
            }, 2000);
        } else {
            videoImage.current.classList.add("slide-right");
            deleteButton.current.classList.add("hide-delete");

            videoImage.current.classList.remove("slide-left");
            deleteButton.current.classList.remove("reveal");
        }
    }

    function RouteUser(link, properties) {
        Track(`Clicked Item From Watchlist Page`, properties).then(() => {
            navigate(`${link}`)
        })
    }

    return (
        <div className="watchlist-card" ref={watchlistCard} onMouseEnter={slideLeft} onMouseLeave={slideRight} key={Math.random(100)}>
            <div className="video-delete_container">
                <div className="link-container" ref={videoSection}>
                    <div onClick={() => RouteUser(props.link,{
                        Link: props.link,
                        Thumbnail: props.src,
                        Title: props.name,
                    } )}  className="watchlist-video_container pointer">
                        <div className="watchlist-text_container">
                            <h2 className="font-bold">{props.name}</h2>
                            {props.subtitle}
                        </div>
                        <div className="watchlist-image_container" ref={videoImage}>
                            <div className="watchlist-image" style={{ backgroundImage: `url(${props.src})` }}>
                                {props.showProgress && (<CustomProgressBar progressPercentage={props.progress} />)}
                                {
                                    (props.assetType === "video") &&   (
                                            <div className="time-pill_container">
                                                <div className="time-pill">
                                                    <div className="duration-text">{props.duration}</div>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="remove-item_container" ref={deleteButton} onClick={removeItem}>
                    <div className="remove-content">
                        <div className="remove-icon"></div>
                        <div className="remove-text">Remove</div>
                    </div>

                </div>
            </div>


        </div>
    )
}

AssetCard.propTypes = {
    category: PropTypes.string,
    link: PropTypes.string.isRequired,
    name: PropTypes.string,
    progress: PropTypes.number,
    showProgress: PropTypes.bool,
    src: PropTypes.string.isRequired,
}

export default AssetCard;
