import React from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux';
import CustomLoader from '../../components/CustomLoader/index';
import WatchlistButton from '../buttons/watchlistButton';
import {getPopQuiz, postPopQuiz} from "./store/actions";
import {getVideo as getSingleVideo} from "../../pages/Video/store/actions";

import { IncrementPeopleProperties, Track } from '../../utils/Segment/segment-methods';

import './pop-quiz-2022.scss';

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getPopQuiz,
            getSingleVideo,
            postPopQuiz
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    popQuiz: appState.PopQuiz.data,
    settings: appState.SystemSettings.settings,
    user: appState.User.data,
    video: appState.Video.data
});

const PopQuiz2022 = ({
                         addVideoToWatchlist,
                         getPopQuiz,
                         getSingleVideo,
                         popQuiz,
                         postPopQuiz,
                         settings,
                         video,
                         ...props
                     }) => {

    const [isAnswersElements, setIsAnswersElements] = React.useState([]);
    const [isResultsElements, setIsResultsElements] = React.useState([]);
    const [isAssetSuggestions, setIsAssetSuggestions] = React.useState();

    React.useEffect(() => {
        // ! -- CHANGE COMPARISON TO == WHICH WILL CHECK FOR NULL AND UNDEFINED
        // check to see if pop quiz exists
        if (popQuiz == null) {
            getPopQuiz()
        } else {
            // check to see if the pop quiz has been answered
            if (popQuiz.complete) {
                // loop through results to find an asset or send undefined
                let answerAsset;

                popQuiz.results.forEach((e) => {
                    if (e.data.answer === popQuiz.answer) {
                        if (!!e.data.asset) {
                            answerAsset = e.data.asset;

                            // !-- TODO: POSSIBLE POSITION TO UPDATE WHEN ADDITIONAL ASSETS CAN BE ADDED TO WATCHLIST
                            // if video get the watchlist status
                            if (e.data.asset.type === "video") {
                                return getSingleVideo(e.data.asset.uuid);
                            }
                        }
                    }
                })

                generateResults(popQuiz.results, popQuiz.answer, answerAsset);
            } else {
                generateAnswers();
            }
        }
    }, [popQuiz]);


    React.useEffect(() => {
        if (video != null) {
            let answerAsset;
            popQuiz.results.forEach((e) => {
                if (e.data.answer === popQuiz.answer) {
                    if (e.data.asset) {
                        answerAsset = e.data.asset;
                    }
                }
            });
            generateResults(popQuiz.results, popQuiz.answer, answerAsset);
        }
    }, [video]);


    const generateAnswers = () => {
        let tempAnswersArray = [];
        let answerElement;

        popQuiz.options.forEach((e, i) => {
            answerElement = (
                <div className="pop-quiz_answer__container"
                     onClick={() => answerPopQuiz(e.answer)}
                     key={`answer-${i}`}>
                    <div className="pop-quiz_text">{e.answer}</div>
                </div>);
            tempAnswersArray.push(answerElement);
        });

        setIsAnswersElements(tempAnswersArray);
    }


    const answerPopQuiz = (answerText) => {
        setIsAnswersElements([])
        // to post need answer text and question ID
        let postObj = {
            qid: popQuiz.qid,
            answer: answerText
        }
        // check if there is an asset to pass on
        let answerAsset;

        for (let i = 0; i < popQuiz.results.length; i++) {
            if (popQuiz.results[i].data.answer === answerText) {
                if (!!popQuiz.results[i].data.asset) {
                    answerAsset = popQuiz.results[i].data.asset;
                }
            }
        }
        postPopQuiz(postObj);
    }

    const generateResults = (array, answerText, answerAsset) => {
        // video needs to be populated to make the watchlist button work
        let resultsArray = array;
        let tempResultsArray = [];
        let resultsElement;

        resultsArray.forEach((e, i) => {
            resultsElement = (
                <div className={`pop-quiz_result__container ${e.data.answer === answerText ? 'selected-answer' : ''}`}
                     key={`result-${i}`}>
                    <div className="percentage-background" style={{width: `${e.percentage}%`}}></div>
                    <div className="percentage-number">{e.percentage}%</div>
                    <div className="pop-quiz_text">{e.data.answer}</div>
                </div>);
            tempResultsArray.push(resultsElement);
        });

        let assetElement;
        let assetLink;

        if (!!answerAsset) {
            switch (answerAsset.type) {
                case 'video':
                    assetLink = `/watch/${answerAsset.uuid}`;
                    break;
                case 'course':
                    assetLink = `/course/${answerAsset.uuid}`;
                    break;
                case 'challenge':
                    assetLink = `/challenge/${answerAsset.uuid}`;
                    break;
                case 'resource':
                    assetLink = `/read/${answerAsset.uuid}`;
                    break;
            }

            assetElement = (
                <React.Fragment>
                    <div className="result-asset_container">
                        <div className="result-asset_title">Thank you for participating! Based on your answer, we think
                            you would like this {answerAsset.type}.
                        </div>

                        <div onClick={()=>{TrackClickedPopQuizSuggestion(answerAsset, assetLink)}} >
                            <div className="image-container" style={{backgroundImage: `url(${answerAsset.thumbnail})`}}>
                                <div className="play-button"></div>
                                <div className="image-title">{answerAsset.name}</div>
                            </div>
                        </div>

                        <div className="asset-button_container">
                            <div onClick={()=>{TrackClickedPopQuizSuggestion(answerAsset, assetLink)}} className="play-container">
                                <div className="play-button"></div>
                                <div className="play-text">Play Now</div>
                            </div>
                            {
                                answerAsset.type === "video" && video !== null &&
                                <div className="watchlist-container">
                                    <WatchlistButton onWatchlist={video.on_watchlist}
                                                     icons={{
                                                         checkmark: settings.ICONS.shapes.checkmark.svg,
                                                         plus: settings.ICONS.shapes.plus.svg
                                                     }}
                                                     loader={{color: '#2D5F62', width: '2px'}}
                                                     buttonType={`circle`}
                                                     assetID={answerAsset.uuid}
                                    />
                                    <div className="watchlist-text">Watchlist</div>
                                </div>
                            }
                        </div>

                        <div className="minimize-modal_container" onClick={closeModal}>
                            <div className="minimize-x"></div>
                            <div className="minimize-text">Minimize</div>
                        </div>
                    </div>

                    <div className="mobile-asset_container">
                        <div className="mobile-asset_title">Thank you for participating! Based on your answer, we think
                            you would like&#160;<div onClick={()=>{TrackClickedPopQuizSuggestion(answerAsset, assetLink)}}>this {answerAsset.type}</div>.
                        </div>
                    </div>
                </React.Fragment>
            )
        } else {
            assetElement = (
                <div className="no-asset_container">
                    <div className="desktop-asset_title">Thank you for participating!</div>
                    <div className="minimize-modal_container" onClick={closeModal}>
                        <div className="minimize-x"></div>
                        <div className="minimize-text">Minimize</div>
                    </div>
                </div>
            )
        }

        // style the bottom desktop section
        setIsAssetSuggestions(assetElement)
        setIsResultsElements(tempResultsArray);
    }

    const closeModal = () => {
        props.setIsShowQuiz(false);
    }

    function TrackClickedPopQuizSuggestion(answerAsset, link) {
        IncrementPeopleProperties('Clicked Pop Quiz Suggestion').then(() => {
            Track('Clicked Pop Quiz Suggestion', {title:answerAsset.name, thumbnail:answerAsset.thumbnail}).then(() => {
                window.location = link;
            })
        })
    }

    return (
        <div id="pop-quiz_overlay">
            <div className="pop-quiz_container">
                <div className="close-quiz" onClick={closeModal}></div>
                <div className="pop-quiz_title">Pop Quiz!</div>
                {
                    popQuiz ?
                        <React.Fragment>
                            <div className="pop-quiz_question">{popQuiz.question}</div>
                            {
                                popQuiz.results.length ?
                                    <React.Fragment>
                                        <div className="results-container">
                                            {
                                                !isResultsElements.length ?
                                                    <div className="loader-box">
                                                        <CustomLoader/>
                                                    </div>
                                                    :
                                                    isResultsElements
                                            }
                                        </div>
                                        <div className="result-finished_container">
                                            {isAssetSuggestions}
                                        </div>
                                    </React.Fragment>
                                    :
                                    <div className="answer-container">
                                        {
                                            !isAnswersElements.length ?
                                                <div className="loader-box">
                                                    <CustomLoader/>
                                                </div>
                                                :
                                                isAnswersElements
                                        }
                                    </div>
                            }
                        </React.Fragment>
                        :
                        <div className="loader-box">
                            <CustomLoader/>
                        </div>
                }
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(PopQuiz2022);
