/**
 * UnorderedList component should contain an array of items
 */
import React from "react"
import styled from "styled-components"


const Video = styled.video`
        ${props => props.properties}
    `


export const VideoPlayer = ({data}) => {
    // console.log('video: ', data)
    return (
        <Video controls properties={data.properties} {...data.attributes}>
            <source src={data.src} type="video/mp4"/>
        </Video>
    )
}



