import classNames from "classnames";
import {NavLink} from "react-router-dom";
import React from "react";

/**
 * REQUIRED // FRAMEWORK V2
 * required functions require explicit declaration
 * @param text
 * @param action
 * @returns {JSX.Element}
 * @constructor
 */
const MenuLogoutButton = React.memo(({text, action}) => {
    return (
        <div className="option-container pointer">
            <div className="option-content">
                <div className="logout-button" onClick={action}>
                    <MenuItemTemplate text={text}/>
                </div>
            </div>
        </div>
    )
})


/**
 * REQUIRED // FRAMEWORK V2 COMPONENTS
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const MenuSpacer = ({className}) => {
    const compClass = classNames({
        'profile-menu-item': true,
        [className]: className
    });
    return (
        <li className={compClass}></li>
    )
}

/**
 * REQUIRED // FRAMEWORK V2 COMPONENTS
 * @param text
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItemTemplate = ({text}) => {
    return (
        <>
            {text && <span className="menu-text option-title">{text}</span>}
        </>
    )
}

/**
 * REQUIRED // FRAMEWORK V2 COMPONENTS
 * @param text
 * @param to
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItemLink = ({text, to}) => {
    return (
        <NavLink className="profile-menu-link" to={to}>
            <div className="option-container pointer">
                <div className="option-content">
                    <MenuItemTemplate text={text}/>
                    <div className="chevron-right"/>
                </div>
            </div>
        </NavLink>
    )
}


/**
 * REQUIRED // FRAMEWORK V2 COMPONENTS
 * components declared as constants
 * @param className
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MenuItem = ({className, ...props}) => {
    const compClass = classNames({
        'profile-menu-item': true,
        [className]: className
    });
    return (
        <li className={compClass}>
            {props.children}
        </li>
    )
}

/**
 * REQUIRED // FRAMEWORK V2 COMPONENTS
 * @param className
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MenuList = ({className, ...props}) => {
    const compClass = classNames({
        'profile-menu': true,
        [className]: className
    });
    return (
        <ul className={compClass}>
            {props.children}
        </ul>
    )
}

export {MenuSpacer, MenuItemLink, MenuItem, MenuList, MenuLogoutButton}
