import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import './progress-bar.scss';

const ProgressBar = (props) => {

    let progressBarElement = useRef(null); 
    let widthPercent;

    const setProgress = (numerator,denominator) => {
       if(numerator === 0 || denominator === 0 ){
            widthPercent = `0%`;
        } else {
            widthPercent = ((numerator/denominator) * 100);
            widthPercent = widthPercent+`%`;
        }
        progressBarElement.current.style.width = widthPercent;
    }
    useEffect(()=>{
        setProgress(props.numerator, props.denominator);
    }, [props.numerator]);

    return (
        <div id="progress-bar-container">
            <div id="progress" ref={progressBarElement}>

            </div>
        </div>
    )
}

ProgressBar.propTypes = {
    denominator: PropTypes.number.isRequired,
    numerator: PropTypes.number.isRequired,  
}

export default ProgressBar;