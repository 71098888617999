import React, {Fragment, useEffect, useRef, useState} from 'react'
import {useNavigate} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    updateUserProfile,
    setUserSuccessMessage, setUserError,
} from "../../../../store/user/actions";
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import styled, {css} from "styled-components";
import {LeftChevronCircle} from '../../../../assets/images/icons/index'
import {Track} from "../../../../utils/Segment/segment-methods";

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            updateUserProfile,
            setUserSuccessMessage,
            setUserError
        },
        dispatch
    )
};
const mapStateToProps = appState => ({
    user: appState.User.data,
});


const SharedStyle = css`
    padding: 8px 0;
    width: 183px;
    margin: 0 auto;
    font-size: 14px;
    white-space: nowrap;
    text-align: center;
    display: block;
    border: none;
    border-bottom: 1px solid #E3DCD3;
    background-color: transparent;

    &::placeholder {
        color: #000;
    }

    &:focus {
        outline-color: transparent !important;
        outline-width: 0;
    }
`
const EditInput = styled.input`
    ${SharedStyle}
`
const EditSelect = styled.select`
    ${SharedStyle}
`
const BackButton = styled.div`
    cursor: pointer;
    position: absolute;
    top: 8px;
    left: 8px;
`


function UserProfile({user, updateUserProfile, setUserSuccessMessage, setUserError}) {
    const navigate = useNavigate();
    const [isEditShown, setIsEditShown] = useState(false);
    const [userProfileImage, setUserProfileImage] = useState(null);
    const [userFirstName, setUserFirstName] = useState(null);
    const [userLastName, setUserLastName] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [userPhone, setUserPhone] = useState(null);
    const [userGender, setIsUserGender] = useState(null);
    const [userAnniversary, setIsUserAnniversary] = useState(null);
    const [isFormattedAnniversary, setIsFormattedAnniversary] = useState(null);
    const profileImage = useRef();
    const firstName = useRef();
    const lastName = useRef();
    const email = useRef();
    const phone = useRef();
    const gender = useRef();
    const anniversary = useRef();


    useEffect(() => {
        user && populateSidebar();
    }, [user])


    const setProfilePic = () => {
        setUserProfileImage(`${URL.createObjectURL(profileImage.current.files[0])}`)
        updateAvatar()
    }

    const populateSidebar = () => {
        // console.log(sidebarReady)
        setUserProfileImage(`${user.profile_image}?${Math.random().toString(36).slice(2, 7)}`)
        setUserFirstName(user.first_name);
        setUserLastName(user.last_name);
        setUserEmail(user.email);
        setUserPhone(user.phone);
        setIsUserGender(user.gender);
        let formattedAnniversary = null;
        // format anniversary to YYYY-MM-DD
        // if (user.anniversary !== null) {
        //     formattedAnniversary = user.anniversary.split("-");
        //     formattedAnniversary.unshift(formattedAnniversary[2]);
        //     formattedAnniversary.pop();
        //     formattedAnniversary = formattedAnniversary.join("-");
        // }
        setIsFormattedAnniversary(formattedAnniversary);
        setIsUserAnniversary(user.anniversary);


    }

    const updateProfile = async () => {

        let firstNameChange = true;
        let lastNameChange = true;
        let emailChange = true;
        let phoneChange = true;
        let genderChange = true;
        let anniversaryChange = true;

        let firstNameInput = firstName.current.value;
        let lastNameInput = lastName.current.value;
        let emailInput = email.current.value;
        let phoneInput = phone.current.value;
        let genderInput = gender.current.value;
        let anniversaryInput = anniversary.current.value;

        let formattedAnniversary = anniversaryInput.split('-');
        let year = formattedAnniversary[0];
        formattedAnniversary.shift();
        formattedAnniversary.push(year);
        formattedAnniversary = formattedAnniversary.join('-');

        if (firstNameInput === "") {
            firstNameInput = firstName.current.placeholder;
            firstNameChange = false;
        }
        if (lastNameInput === "") {
            lastNameInput = lastName.current.placeholder;
            lastNameChange = false;
        }

        if (emailInput === "") {
            emailInput = email.current.placeholder;
            emailChange = false;
        }

        if (phoneInput === "") {
            if (phone.current.placeholder == "Mobile Phone") {
                phoneInput = "";
            } else {
                phoneInput = phone.current.placeholder;
            }
            phoneChange = false;
        }
        // gender change is same as current user gender
        if (genderChange === "") {
            // current user gender
            genderInput = '';
            genderChange = false;
        }
        if (anniversaryInput === "") {
            // current user anniversary
            formattedAnniversary = '';
            anniversaryChange = false;
        }

        if (!firstNameChange && !lastNameChange && !emailChange && !phoneChange && !genderChange && !anniversaryChange) {
            return;
        }

        setUserFirstName(firstNameInput)
        setUserLastName(lastNameInput)
        setUserPhone(phoneInput)
        setUserEmail(emailInput)

        let updateObj = {
            first_name: firstNameInput,
            last_name: lastNameInput,
            email: emailInput,
            phone: phoneInput,
            anniversary: formattedAnniversary,
            gender: genderInput

        }
        // console.log('update obj', updateObj);

        updateUserProfile(updateObj);
        setUserFirstName(firstNameInput);
        setUserLastName(lastNameInput);
        setUserEmail(emailInput);
        setUserPhone(phoneInput);
        setIsUserGender(genderInput);


        Track('Saved Profile Information').then(() => {})


        let yymmddAnniversary = null;
        // format anniversary to YYYY-MM-DD
        if (formattedAnniversary !== null) {
            yymmddAnniversary = formattedAnniversary.split("-");
            yymmddAnniversary.unshift(yymmddAnniversary[2]);
            yymmddAnniversary.pop();
            yymmddAnniversary = yymmddAnniversary.join("-");
        }
        setIsFormattedAnniversary(yymmddAnniversary);
        setIsUserAnniversary(formattedAnniversary);

        setIsEditShown(false);
    }

    const updateAvatar = () => {
        var formData = new FormData();
        formData.append("image", profileImage.current.files[0]);
        axios.post("/update-profile-image", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Access-Control-Allow-Origin": "*",
                }
            }
        ).then((resp) => {
            Track('Saved Profile Image').then(() => {
                // todo: nothing is happening with a success or error messages. These should be displayed.
                setUserSuccessMessage(resp.data.message)
            })


        }).catch(er => {
            setUserError(er.data.message)
        })
    }

    const openEditProfile = () => {
        Track('Opened Profile Edit').then(() => {
            setIsEditShown(true);
        })
    }

    return (
        <div className="profile-sidebar-user-edit">
            <BackButton onClick={() => navigate(-1)}>
                <LeftChevronCircle size={32} primary={'#fff'} secondary={'#021026'} opacity={1}/>
            </BackButton>
            <div className="content">
                {
                    JSON.stringify(user) !== '{}' ?
                        <>
                            <div id="profile-avatar" className="profile-sidebar-avatar"
                                 style={{backgroundImage: `url(${userProfileImage})`}}>
                                <div className="camera-icon pointer" onClick={() => {
                                    document.getElementById('profile-input').click();
                                }}/>
                                <input type="file" id="profile-input" name="image" ref={profileImage}
                                       onChange={setProfilePic}/>
                            </div>
                            {
                                isEditShown && (
                                    <Fragment>
                                        <div className="profile-name_container">
                                            <EditInput className="profile-name" ref={firstName}
                                                       placeholder={userFirstName}
                                                       defaultValue={userFirstName}
                                            />
                                            <EditInput className="profile-name" ref={lastName}
                                                       placeholder={userLastName}
                                                       defaultValue={userLastName}
                                            />
                                        </div>

                                        <EditInput className="profile-email"
                                                   ref={email}
                                                   placeholder={userEmail}
                                                   defaultValue={userEmail}
                                        />
                                        <label htmlFor="mobile_phone">
                                            <EditInput id="phone" className="profile-phone" ref={phone}
                                                       placeholder={userPhone === null ? "Mobile Phone" : userPhone}
                                                       defaultValue={userPhone === null ? "" : userPhone}
                                            />
                                        </label>

                                        {/* Make custom dropdown */}
                                        <EditSelect className="gender-select edit-form_input profile-info" ref={gender}
                                                    defaultValue={userGender === null ? "not specified" : userGender}>
                                            <option value="not specified">Not Specified</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>

                                        </EditSelect>
                                        {/* default value is user anniversary */}
                                        <EditInput type="date" className="anniversary-input edit-form_input profile-info"
                                                   placeholder={"Anniversary"}
                                                   defaultValue={isFormattedAnniversary}
                                                   ref={anniversary}/>
                                    </Fragment>
                                )}
                            {
                                !isEditShown && (
                                    <Fragment>
                                        <div className="profile-name profile-info">{userFirstName} {userLastName}</div>
                                        <div className="profile-email profile-info">{userEmail}</div>
                                        {userPhone !== null && (
                                            <div className="profile-phone profile-info">{userPhone}</div>
                                        )}
                                        {/* {userGender !== null && (
                                            <div className="profile-gender profile-info">{userGender}</div>
                                        )} */}
                                        {/* {userAnniversary !== null && (
                                            <div className="profile-anniversary profile-info">{userAnniversary}</div>
                                        )
                                        } */}

                                    </Fragment>

                                )}


                            {
                                !isEditShown && (
                                    <div className="update-button pointer" name="edit-profile" onClick={openEditProfile}>
                                        <span>Edit</span>
                                    </div>
                                )}

                            {
                                isEditShown && (
                                    <div className="update-button pointer" name="save-profile" onClick={updateProfile}>
                                        <span>Save</span>
                                    </div>
                                )}
                        </>

                        :
                        <SkeletonTheme baseColor="#e1dedc" highlightColor="#cdcdcd">
                            <Skeleton circle height={156} width={156}/>
                            <Skeleton count={4} height={16}/>
                        </SkeletonTheme>
                }


            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
