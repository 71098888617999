import {combineReducers} from "redux"

import Login from "../pages/Auth/Login/store/reducer"
import Account from "./auth/register/reducer"
import RegisterWithPartnerCode from "./auth/registerWithPartnerCode/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import User from "./user/reducer"
import CoreCategories from "../pages/CoreCategory/store/reducers"
import Dashboard from "../pages/Dashboard/store/reducers"
import UserInterface from "../utils/UserInterface/store/reducers";
import IntroSlider from "../components/Login/LoginCarousel/store/reducer";
import SystemSettings from "../utils/SystemSettings/store/reducer";
import Watchlist from "../pages/Watchlist/store/reducer";
import Worksheet from "../pages/Worksheet/store/reducer";
import Video from "../pages/Video/store/reducer";
import MyMarriage from "../pages/MyMarriage/store/reducer";
import Course from "../pages/Courses/store/reducer";
import AccountSettings from "../pages/Account/store/reducer";
import PopQuiz from "../components/PopQuiz/store/reducer";
// eslint-disable-next-line max-len
// The implementation of this new onboarding feature is on hold for now. This can be brought back in the future.
// import onboarding from '../onBoarding/onboarding.reducers';

const rootReducer = combineReducers({
    RegisterWithPartnerCode,
    Login,
    UserInterface,
    Account,
    ForgetPassword,
    Profile,
    User,
    CoreCategories,
    Dashboard,
    IntroSlider,
    SystemSettings,
    Video,
    Watchlist,
    PopQuiz,
    Worksheet,
    MyMarriage,
    Course,
    AccountSettings,
    // The implementation of this new onboarding feature is on hold for now.
    // onboarding,
});

export default rootReducer;
