/**
 * Retrieve the user's browser
 * @returns {string}
 */
export function GetBrowserType() {
    var browser = '';
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
        browser = 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
        browser = 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
        browser = 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
        browser = 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') !== -1) {
        browser = 'IE';
    } else {
        browser = 'unknown';
    }
    return browser;
}
