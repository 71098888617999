import React from "react";
import {useNavigate} from 'react-router-dom';
import "./trendingCard.scss";
import styled from "styled-components";
import PropTypes from 'prop-types';
import {Track} from "../../utils/Segment/segment-methods";


const CoreCategoryItem = styled.div`
    height: ${props => props.cardHeight}px;
    background-color: ${props => props.backgroundColor}
`

const CoreCategoryIcon = styled.div`
    background-image: url(${props => props.categoryIcon})
`


const CategoryDesktopCard = (props) => {

    let navigate = useNavigate()

    function RouteUser() {
        Track(`Clicked Slide`,
            {
                Layout: 'Desktop',
                Link: `/category/${props.slug}`,
                Slider: 'Categories',
                Thumbnail: props.categoryIcon,
                Title: props.categoryTitle,
            }).then(() => {
            navigate(`/category/${props.slug}`)
        })

    }


    return (
        <div className="pointer" onClick={RouteUser}>
            <CoreCategoryItem cardHeight={props.cardHeight} backgroundColor={props.backgroundColor}
                              className="category-desktop_card">
                <CoreCategoryIcon categoryIcon={props.categoryIcon} className="category-icon"/>
                <h5 className="category-title">
                    {props.categoryTitle}
                </h5>
            </CoreCategoryItem>
        </div>
    )

}

CategoryDesktopCard.propTypes = {
    cardHeight: PropTypes.number.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    categoryIcon: PropTypes.string.isRequired,
    categoryTitle: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
}

export default CategoryDesktopCard;
