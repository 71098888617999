/**
 * TODO: This component needs to be rewritten. We need to better separate the responsibility of the server vs client
 *      there is too much server specific logic here. Make more dynamic
 * Braintree Dropin component
 */
import React, {useEffect, useRef, useState} from "react"
import dropin from "braintree-web-drop-in"
import styled from "styled-components"
import * as STYLES from "../../Templates/Global/default-styles"
import {useForm} from "react-hook-form";
import {Alert} from "../Alert";
import {useStateMachine} from "little-state-machine";
import ScaleLoader from "react-spinners/ScaleLoader";
import {useNavigate, useParams} from "react-router-dom";
import {CouponField} from "./coupon-field";


const FormContainer = styled.div`
    ${STYLES.BRAINTREE_FORM_CONTAINER}
    ${props => props.properties}
`
const NameContainer = styled.div`
    ${STYLES.BRAINTREE_FORM_NAME_CONTAINER}
`
const BraintreeButton = styled.button`
    ${STYLES.BRAINTREE_FORM_BUTTON}
`
const NameElement = styled.div`
    ${STYLES.BRAINTREE_FORM_NAME_ELEMENT}
`
const TermsContainer = styled.div`
    ${STYLES.TERMS_CONTAINER}
`
const TermsSelection = styled.div`
    ${STYLES.TERMS_SELECTION}
`
const TermsText = styled.div`
    ${STYLES.TERMS_TEXT}
`
const ButtonText = styled.div`
    ${STYLES.CREATE_ACCOUNT_BTN}
`


export const BraintreeDropin = ({data, activeTest}) => {
    // console.log('bt data: ', data)
    const paymentForm = useRef(null);
    const registrationForm = useRef(null);
    const {state} = useStateMachine();
    let {campaign_slug} = useParams();

    const query = new URLSearchParams(window.location.search);

    const navigate = useNavigate();
    const utm_source = query.get('utm_source') || '';
    const utm_medium = query.get('utm_medium') || '';
    const utm_campaign = query.get('utm_campaign') || '';
    const utm_content = query.get('utm_content') || '';
    const coupon = query.get('coupon') || '';

    const {register, handleSubmit, setValue, getValues, formState: {isValid}} = useForm({mode: 'onChange'});
    const [paymentButtonActive, setPaymentButtonActive] = useState(true);
    const [paymentError, setPaymentError] = useState(null);
    const [isTermsAgreed, setIsTermsAgreed] = useState(false);
    const [returningUser, setReturningUser] = useState(false)
    // const [paymentNonce, setPaymentNonce] = useState(null);
    const [braintreeInstance, setBraintreeInstance] = useState(undefined);

    useEffect(() => {
        console.log('dbt-', process.env.MIX_BRAINTREE_CLIENT.slice(0, 12))
        setReturningUser(data.attributes._returningUser)


        const initializeBraintree = () => dropin.create({
            authorization: process.env.MIX_BRAINTREE_CLIENT,
            container: "#braintree-drop-in-div",
            paypal: {
                flow: 'vault'
            },
            // applePay: {
            //     displayName: 'Marriage365 Subscription',
            //     paymentRequest: {
            //         total: {
            //             label: "Marriage365 Subscription",
            //             amount:  state.data.subscription.price ? state.data.subscription.price.toString() : "",
            //         },
            //
            //         requiredBillingContactFields: ["postalAddress"]
            //     }
            // },
            googlePay: {
                googlePayVersion: 2,
                merchantId: "BCR2DN4TW2LIRKCN",
                transactionInfo: {
                    totalPriceStatus: 'FINAL',
                    totalPrice: state.data.subscription.price ? state.data.subscription.price.toString() : "",
                    currencyCode: 'USD'
                },
                allowedPaymentMethods: [{
                    type: 'CARD',
                    parameters: {
                        billingAddressRequired: true,
                        billingAddressParameters: {
                            format: 'FULL'
                        }
                    }
                }]
            }
        }, function (error, instance) {
            if (error)
                console.error('ERROR: ', error)
                // console.log('building')
            else
                setBraintreeInstance(instance);
        });

        if (braintreeInstance && state.data.subscription.price) {
            braintreeInstance
                .teardown()
                .then(() => {
                    state.data.subscription.price && initializeBraintree();
                });
        } else {
            state.data.subscription.price && initializeBraintree();
        }


    }, [state.data.subscription.price])


    // window.addEventListener('click', () => {
    //     setPaymentError(null)
    // });


    const onSubmit = async (data) => {
        if (isTermsAgreed) {
            data.terms = "accepted"
        } else {
            return setPaymentError('You must agree to our terms and conditions.')
        }

        if (!data.payment_method_nonce) {
            return setPaymentError('We were unable to connect using this payment.')
        }

        //TODO: This should NOT be the logic fo the widget. Handle the checkoutID on the server. send pathname and split
        if (returningUser === false) {
            const PathArray = window.location.pathname.split('/');
            const ci = PathArray.indexOf('checkout');
            if (ci !== -1) {
                data.uuid = PathArray[ci + 1]
            } else {
                return setPaymentError('There was an error with the checkout ID of this transaction.')
            }
        } else {
            data.uuid = 'returning-user'
        }


        //TODO: not all split testing pages will contain this vars. Make dynamic from server data object
        if (data.promo_code) {
            data.coupon = data.promo_code;
        } else if (coupon) {
            data.coupon = coupon
        }

        if (utm_source) {
            data.utm_source = utm_source
        }
        if (utm_medium) {
            data.utm_medium = utm_medium
        }
        if (utm_campaign) {
            data.utm_campaign = utm_campaign
        }
        if (utm_content) {
            data.utm_campaign = utm_content
        }

        data.subscription = state.data.subscription;
        data.pathname = window.location.pathname;
        data.source = 'api';

        // console.log(data)
        // return;

        //TODO: The URL should be dynamic from server data object
        // const response = await fetch("/register",
        //     {
        //         method: "POST",
        //         headers: {
        //             "Accept": "application/json",
        //             "Content-Type": "application/json",
        //             "X-XSRF-TOKEN": decodeURIComponent(token)
        //         },
        //         body: JSON.stringify(data)
        //     });
        //
        //
        //
        // response.json().then((r) => {
        //     console.log('respeonse: ', r)
        //     // nothing happens here. The server should handle the redirect
        //     if (r.status === 'success') {
        //        navigate('/complete-registration')
        //     }
        //
        //     setPaymentButtonActive(true)
        //     setPaymentError(r.message)
        //
        // }).catch((error) => {
        //     setPaymentButtonActive(true)
        //     setPaymentError(error.response.data.message)
        // })

        axios.post("/register", data).then((response) => {
            if (response.data.status === "success") {

                // window._fbq = window._fbq || [];
                window._fbq.push('trackCustom', "SubscriptionStarted", {
                    value: state.data.coupon.amountToCharge ?? state.data.subscription.price,
                    currency: 'USD',
                    contents: [{
                        id: data.uuid,
                        quantity: 1,
                        item_price: state.data.subscription.price
                    }],
                    content_name: state.data.subscription.btPid,
                    source: 'membership',
                });

                if (returningUser) {
                    window.location.replace("/browse")
                } else {
                    navigate('/complete-registration', {replace: true})
                }

            }

        }).catch((error) => {
            setPaymentButtonActive(true)
            setPaymentError(error.response.data.message)
        })
    }

    const onPaymentCompleted = (nonce) => {
        setValue('payment_method_nonce', nonce)
        setPaymentButtonActive(false)
        registrationForm.current.dispatchEvent(new Event("submit", {cancelable: true, bubbles: true}))
    }

    const toggleTerms = () => {
        setPaymentButtonActive(true)
        setIsTermsAgreed(!isTermsAgreed);
    }

    const checkInput = () => {
        setPaymentButtonActive(true)
    }

    useEffect(() => {
        setPaymentError(state.data.coupon.errorMessage)
    }, [state.data.coupon])

    return (
        <FormContainer id={data.attributes.id} ref={paymentForm} properties={data.properties}>
            <form ref={registrationForm} onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register('activeTest')} defaultValue={activeTest}/>
                <input type="hidden" {...register('campaignName')} defaultValue={campaign_slug}/>
                <input type="hidden" {...register("payment_method_nonce")}/>

                {
                    paymentError &&
                    <Alert data={{text: paymentError, properties: {}, className: "alert-danger"}}/>
                }

                {
                    data.attributes._displayCouponField &&
                    <CouponField
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                    />
                }

                <NameContainer>
                    <NameElement>
                        <label>First Name</label>
                        <input {...register("first_name", {required: true})}
                               onChange={checkInput}/>
                    </NameElement>
                    <NameElement>
                        <label>Last Name</label>
                        <input {...register("last_name", {required: true})}
                               onChange={checkInput}/>
                    </NameElement>
                </NameContainer>

                <div id="braintree-drop-in-div"></div>
                <TermsContainer>
                    <TermsSelection className={isTermsAgreed ? "active" : ""}
                                    onClick={toggleTerms}/>
                    {/*TODO: move the text and URL to the server data object*/}
                    <TermsText>
                        I agree to the Marriage365 <a href='https://www.marriage365.com/mymarriage365-terms-conditions'
                                                      target='_blank'>Terms and Conditions</a>
                    </TermsText>
                </TermsContainer>
            </form>

            <BraintreeButton
                type="primary"
                disabled={!braintreeInstance || !paymentButtonActive}
                onClick={() => {
                    if (braintreeInstance) {
                        braintreeInstance.requestPaymentMethod(
                            (requestPaymentMethodErr, payload) => {
                                if (requestPaymentMethodErr) {
                                    // requestPaymentMethodErr.message
                                    // console.log('BT error: ', requestPaymentMethodErr)
                                    setPaymentError(requestPaymentMethodErr.message)
                                } else {
                                    // setPaymentNonce(payload.nonce)
                                    onPaymentCompleted(payload.nonce)
                                }
                            });
                    }
                }}>
                {
                    <ButtonText>
                        {
                            (paymentButtonActive && !isValid) ? <span>Create Account</span> :
                                <ScaleLoader height="10px" color="#fff"/>
                        }
                    </ButtonText>
                }
            </BraintreeButton>
            {/*TODO: move the text to the server data object*/}


        </FormContainer>

    )
}
