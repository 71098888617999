import {call, put, takeEvery} from "redux-saga/effects"

import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
import {getSingleVideo, sendVideoEvent, getVideosByTag, getAllVideos} from "../../../helpers/backend-helper";

function* loadVideo({uuid}) {
    try {
        const response = yield call(getSingleVideo, [uuid]);
        yield put(ACTIONS.setVideo(response))

    } catch (error) {
        yield put(ACTIONS.videoError(error))
    }
}

function* videoError(error) {
    yield put(ACTIONS.videoError(error))
}

function* sendVideoProgress({payload}) {
    try {
        yield call(sendVideoEvent, payload)
    } catch (e) {
        // TODO: send to sentry
        console.log('to sentry: ', e)
    }
}

function* retrieveVideosByTag({payload}) {

    try {
        const response = yield call(getVideosByTag, payload)
        yield put(ACTIONS.storeTaggedVideos(response))
    } catch (e) {
        console.log('to sentry', e)
    }
}

function* retrieveAllVideos({payload}) {
    try {
        const response = yield call(getAllVideos, payload)
        yield put(ACTIONS.setAllVideos(response))
    } catch (error) {
        yield put(ACTIONS.videoError(error))
    }
}

function* VideoSaga() {
    yield takeEvery(TYPES.GET_VIDEO, loadVideo)
    yield takeEvery(TYPES.VIDEO_ERROR, videoError)
    yield takeEvery(TYPES.SEND_VIDEO_PROGRESS, sendVideoProgress)
    yield takeEvery(TYPES.GET_VIDEO_BY_TAG, retrieveVideosByTag)
    yield takeEvery(TYPES.GET_ALL_VIDEOS, retrieveAllVideos)
}

export default VideoSaga
