/**
 * HOTFIX FOR COMPONENT RENDERER VS TEMPLATE RENDERER.
 * IN FUTURE RELEASES, REQUIRE CLASS AND ID IN ATTRIBUTES OBJECT
 */
export function GetAttributes(name, block) {
    if (!!block && !!block.attributes) {
        // found in the DIV, TITLE Components
        if (name === 'id') {
            return block.attributes.id
        }

        if (name === 'class') {
            return block.attributes.class
        }

        // found in the IMAGE component
        if (name === 'link') {
            return block.attributes.link
        }

    } else {
        return null
    }
}
