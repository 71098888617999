import React, {useEffect, useState, useRef} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import CategoryPill from '../Pills/categoryPill';
import PlayIcon from '../AssetButton/assets/play';
import TimePill from '../Pills/timePill';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DesktopHoverCard from './desktop-hover-card';

import './trendingCard.scss';

const TrendingCard = (props) => {

    const [isHoverShown, setIsHoverShown] = useState();
    const hoverOverlay = useRef();

    const history = useNavigate();

    const TrendingItem = styled.div`
        height: ${props.cardHeight}px;
        background-image: url(${props.src})
    `
    const showHoverCard = () => {
        setIsHoverShown(true);
    }

    const showOverlay = () => {
        // console.log('show overlay ran');
        hoverOverlay.current.style.display = "block";
    }

    const hideOverlay = () => {
        // console.log('hide overlay ran');
        hoverOverlay.current.style.display = "none";
    }

    const routeToAsset = () => {
        history(`${props.link}/${props.uuid}`);
    }

    return (
        // <TrendingItem className="trending-card" onMouseOver={showOverlay} onMouseOut={hideOverlay}>
        <TrendingItem className="trending-card">
            {props.newBanner && (
            <div className="new-banner_container">
                <div className="new-banner"></div>
            </div>)}
            {/* {isHoverShown && <DesktopHoverCard />}
            <div className='hover-overlay' ref={hoverOverlay}>
                <div className='hover-play_icon' onClick={routeToAsset}></div>
                <div className='show-action_container' onClick={showHoverCard}></div>
            </div> */}
            {/* <div className="trending-card_content" onClick={routeToAsset}> */}
            {
                props.category !== undefined && (
                    <CategoryPill category={props.category} position="top-right"/>
                )
            }
            {
                props.showPlayButton && (
                    <PlayIcon/>
                )
            }
            <div className="trending-card_title">
                {props.title}
            </div>
            {
                props.duration && (
                    <TimePill duration={props.duration}
                              position={`${props.topRight === true ? "top-right" : "bottom-right"}`}/>
                )
            }
            {/* </div> */}
        </TrendingItem>
    )

}

TrendingCard.propTypes = {
    cardHeight: PropTypes.number.isRequired,
    category:PropTypes.string,
    duration: PropTypes.string,
    link: PropTypes.string,
    src: PropTypes.string.isRequired,
    title:PropTypes.string,
    topRight: PropTypes.bool,
    uuid: PropTypes.string,
}

export default TrendingCard;
