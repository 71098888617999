import React, {useState, useRef} from 'react';

import ScaleLoader from 'react-spinners/ScaleLoader';
import PropTypes from 'prop-types';
import './coursePickAsset.scss';

const CoursePickAsset = (props) => {

    const [isOverviewShown, setIsOverviewShown] = useState(true);
    const [isDescriptionShown, setIsDescriptionShown] = useState(false);

    let videoResourcePicked = true;

    const overviewSection = useRef(null);
    const descriptionSection = useRef(null);

    const overviewBtn = useRef(null);
    const descriptionBtn = useRef(null);

    let {courseSteps, intro, learned_items} = props;

    const toggleSelector = (e) => {
        let className = e.currentTarget.className;
        className = className.split(' ');
        let openElement = document.querySelector('.highlighted');
        let classToOpen = '';
        let openElementArray = [...openElement.classList];

        if (openElementArray.includes(className[0])) {
            return;
        }
        switch (className[0]) {
            case 'overview':
                classToOpen = overviewBtn;
                setIsOverviewShown(true);
                setIsDescriptionShown(false);
                overviewSection.current.classList.remove('hide-section');
                descriptionSection.current.classList.add('hide-section');
                break;
            case 'description':
                classToOpen = descriptionBtn;
                setIsOverviewShown(false);
                setIsDescriptionShown(true);
                overviewSection.current.classList.add('hide-section');
                descriptionSection.current.classList.remove('hide-section');
                break;
        }
        //set other two classes to closed
        openElement.classList.remove('highlighted');
        classToOpen.current.classList.add('highlighted');
    }

    return (
        <div className="course-pick-asset-container">
            <div className="selector-container">
                <div className="selector">
                    <div className="overview highlighted" ref={overviewBtn} onClick={toggleSelector}>
                        Overview
                    </div>
                    <div className="description" ref={descriptionBtn} onClick={toggleSelector}>
                        Description
                    </div>
                </div>

                {courseSteps.length > 0 && (
                    <div className="overview-section" ref={overviewSection}>
                        {courseSteps}
                    </div>
                )}
                {intro != undefined && (
                    <div className="description-section hide-section" ref={descriptionSection}>
                        <div className="description-text">{props.intro}</div>
                        <h4 className="description-subtitle">You'll Learn:</h4>
                        <div className="description-flex_container">
                            <div className="blue-tile tile">
                                <div className="tile-text">
                                    {props.learned_items[0]}
                                </div>

                            </div>
                            <div className="yellow-tile tile">
                                <div className="tile-text">
                                    {props.learned_items[1]}
                                </div>

                            </div>
                            <div className="white-tile tile">
                                <div className="tile-text">
                                    {props.learned_items[2]}
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                {/* {videos.length === 0 && (
                    <div className="trending-height">
                        <div className="trending-loader-container">
                            <ScaleLoader height="10px" color="#000"/>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    )
}

CoursePickAsset.propTypes = {
    courseSteps: PropTypes.arrayOf(PropTypes.element).isRequired,
    intro: PropTypes.string,
    learned_items: PropTypes.arrayOf(PropTypes.string),
}

export default CoursePickAsset;
