import {call, put, takeEvery} from "redux-saga/effects"
import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
import {getAllWorksheets, getSingleWorksheet, markWorksheetRead} from "../../../helpers/backend-helper";


function* loadWorksheet({uuid}) {
    try {
        const response = yield call(getSingleWorksheet, [uuid]);
        yield put(ACTIONS.setWorksheet(response))

    } catch (error) {
        yield put(ACTIONS.worksheetError(error))
    }
}

function* worksheetError(error) {
    yield put(ACTIONS.worksheetError(error))
}

function* retrieveAllWorksheets({payload}) {
    try {
        const response = yield call(getAllWorksheets, payload)
        yield put(ACTIONS.setAllWorksheets(response))
    } catch (error) {
        yield put(ACTIONS.worksheetError(error))
    }
}

function* trackedViewedWorksheet({uuid}) {
    try {
        yield call(markWorksheetRead, [uuid])
    } catch (error) {
        yield put(ACTIONS.worksheetError(error))
    }
}

function* WorksheetSaga() {
    yield takeEvery(TYPES.GET_SINGLE_WORKSHEET, loadWorksheet)
    yield takeEvery(TYPES.WORKSHEET_ERROR, worksheetError)
    yield takeEvery(TYPES.GET_ALL_WORKSHEETS, retrieveAllWorksheets)
    yield takeEvery(TYPES.WORKSHEET_VIEWED, trackedViewedWorksheet)
}

export default WorksheetSaga
