
/**
 * UnorderedList component should contain an array of items
 */
import React from "react"
import styled from "styled-components"
import PropTypes from 'prop-types'
import {IconListItem} from './list-item'


const List = styled.div`

    `
/**
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export const IconList = ({data}) => {
    return (
        <List>
            {
                (data || []).map((item, i) => {
                    return <IconListItem key={i} icon={item.icon} title={item.title} subtitle={item.subtitle}/>
                })
            }
        </List>
    )
}

IconList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.shape({
            url: PropTypes.string,
            properties: PropTypes.object
        }),
        title: PropTypes.shape({
            text: PropTypes.string,
            properties: PropTypes.object
        }).isRequired,
        subtitle: PropTypes.shape({
            text: PropTypes.string,
            properties: PropTypes.object
        })
    })).isRequired
}

