import { call, put, takeEvery, } from 'redux-saga/effects';
import ActionType from './actionTypes';
import { registerUserSuccessfulWithPartnerCode, registerUserFailedWithPartnerCode, } from './actions';
import { setAuthentication, setUser } from '../../user/actions.js';
import { getSanctumCookie, registerWithPartnerCode as postRegisterWithPartnerCode, } from '../../../helpers/backend-helper.js';
function* registerUser({ payload: { user, navigate } }) {
    try {
        yield put(registerUserFailedWithPartnerCode(null));
        yield call(getSanctumCookie);
        const response = yield call(postRegisterWithPartnerCode, {
            ...user,
        });
        yield put(registerUserSuccessfulWithPartnerCode(response?.user, navigate));
    }
    catch (error) {
        // @ts-expect-error: TypeScript error message need to be typed
        yield put(registerUserFailedWithPartnerCode(error?.message));
    }
}
function* successfulRegisterWithPartnerCode({ payload: { user, navigate } }) {
    try {
        yield put(setAuthentication(true));
        yield put(setUser(user));
        if (user?.reset_password) {
            navigate('/complete-registration', { replace: true });
        }
        else if (user?.onboarding_complete) {
            // if exists, redirect user to the intended URL
            if (user?.intended_url) {
                navigate(user?.intended_url, { replace: true });
            }
            else {
                // otherwise send them to the dashboard
                navigate('/browse', { replace: true });
            }
        }
        else {
            navigate('/pick-quiz', { replace: true });
            // bypassing the complete-registration page since we now obtain the password on the registration page
            // navigate('/complete-registration', { replace: true });
        }
    }
    catch (error) {
        // @ts-expect-error: TypeScript error message need to be typed
        yield put(registerUserFailedWithPartnerCode(error?.message));
        // eslint-disable-next-line no-console
        console.log('error', error);
    }
}
function* RegisterWithPartnerCodeSaga() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield takeEvery(ActionType.REGISTER_USER_WITH_PARTNER_CODE, registerUser);
    yield takeEvery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ActionType.REGISTER_USER_WITH_PARTNER_CODE_SUCCESSFUL, successfulRegisterWithPartnerCode);
}
export default RegisterWithPartnerCodeSaga;
