//-DEPENDENCIES
import React, { useState, useEffect, Fragment } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from 'prop-types';
//-HELPERS
import { GetUuidFromSlug } from "./helpers";

//-ACTIONS
import { getSingleCoreCategory as getCategory, getCoreCategories as getAllCategories } from "./store/actions";
import { getPerformanceScores, getCategoryStats, getInfoMessages } from '../MyMarriage/store/actions';


//-COMPONENTS
import CategoryHeader from '../../components/common/header-component/category-header';
import { CreateTrending } from "./catergory-trending";
import { CreateGeneralCards } from "./category-asset-cards";
import LoadingScreen from "../../components/LoadingScreen";
import PickAsset from '../../components/PickAsset';
import { CreatePerformanceScoreCards } from '../MyMarriage/my-marriage-performance-score';
import MarriageInfoDrawer from '../../components/Modals/marriage-info-drawer';

import './category.scss';
import DesktopNav from "../../components/common/nav/desktop-nav";
import MobileFooter from "../../components/common/footer-component/mobile-footer";
import { HandleRouteChange } from "../../utils/Segment/segment-methods";



//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getCategory,
            getAllCategories,
            getCategoryStats,
            getInfoMessages,
            getPerformanceScores
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    category: appState.CoreCategories.category,
    categoryLoading: appState.CoreCategories.categoryLoading,
    categoryStats: appState.MyMarriage.categoryStats,
    coreCategories: appState.CoreCategories.categories,
    courses: appState.CoreCategories.courses,
    infoMessages: appState.MyMarriage.info,
    myPerformanceScores: appState.MyMarriage.scores,
    myPerformanceScoresLoading: appState.MyMarriage.scoresLoading,
    resources: appState.CoreCategories.resources,
    screenWidth: appState.UserInterface.screenWidth,
    settings: appState.SystemSettings.settings,
    trending: appState.CoreCategories.trending,
    user: appState.User.data,
    videos: appState.CoreCategories.videos,
});


//-START FUNCTIONAL COMPONENT
const Category = ({
    category,
    categoryLoading,
    categoryStats,
    coreCategories,
    courses,
    getAllCategories,
    getCategory,
    getCategoryStats,
    getInfoMessages,
    getPerformanceScores,
    infoMessages,
    myPerformanceScores,
    myPerformanceScoresLoading,
    resources,
    screenWidth,
    settings,
    trending,
    user,
    videos,
}) => {
    let { slug } = useParams();
    let location = useLocation()

    //-LOCAL STATE
    const [isVideos, setIsVideos] = useState([]);
    const [isResources, setIsResources] = useState([]);
    const [isCourses, setIsCourses] = useState([]);
    const [isVideoTrendingCard, setIsVideoTrendingCard] = useState();
    const [isStyledPerformance, setIsStyledPerformance] = useState([]);
    const [isDrawerShown, setIsDrawerShown] = useState(false);

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location && slug) {
            HandleRouteChange(location, user, 'page-view', 'Single Category', slug)
        }
    }, [user, location, slug])

    //-USE EFFECT
    useEffect(() => {
        //TODO: check to see if the slug matches the one in Redux. If so, load from redux.
        !coreCategories ? getAllCategories() : getCategory(GetUuidFromSlug(coreCategories, slug), 0, 100)
        window.scroll(0, 0);
    }, [slug, coreCategories]);

    useEffect(() => {

        if (category) {
            getPerformanceScores(category.uuid);
            getCategoryStats(category.uuid);
        }

    }, [category]);

    useEffect(() => {

        if (!infoMessages) {
            getInfoMessages("my-marriage-overall-score");
        }

    }, [infoMessages]);

    useEffect(() => {
        if (myPerformanceScores) {
            const cards = CreatePerformanceScoreCards(myPerformanceScores);
            setIsStyledPerformance(cards);
        }

    }, [myPerformanceScores])

    useEffect(() => {
        if (trending && settings) {
            const cards = CreateTrending(trending, settings, screenWidth);
            setIsVideoTrendingCard(cards.videos)
        }
    }, [trending, settings, screenWidth])

    useEffect(() => {
        if (videos && resources && courses && settings) {
            const cards = CreateGeneralCards(videos, resources, courses, settings, screenWidth);
            setIsVideos(cards.videoCards);
            setIsResources(cards.resourceCards);
            setIsCourses(cards.courseCards);
        }
    }, [videos, resources, courses, settings, screenWidth])

    const showDrawer = () => {
        setIsDrawerShown(true);
    }

    return (
        <>
            {
                settings === null || myPerformanceScores === null || categoryStats === null || categoryLoading === undefined || categoryLoading || JSON.stringify(user) === '{}' ?
                    <LoadingScreen />
                    :
                    <Fragment>
                        <DesktopNav user={user} />
                        <div className="category-page">
                            {categoryStats && (
                                <CategoryHeader
                                    screenWidth={screenWidth}
                                    category={category}
                                    title={category.title}
                                    backgroundColor={category.color.hex}
                                    icon={category.icon.svg}
                                    isStyledPerformance={isStyledPerformance}
                                    myPerformanceScoresLoading={myPerformanceScoresLoading}
                                    categoryStats={categoryStats}
                                    showDrawer={showDrawer}
                                    user={user}

                                />
                            )}
                            <PickAsset
                                trendingVideo={isVideoTrendingCard}
                                videos={isVideos}
                                resources={isResources}
                                courses={isCourses}
                                source={"Category-Page"}
                                category={category.title}
                                />
                        </div>
                        {isDrawerShown && (
                            <MarriageInfoDrawer
                                header={infoMessages.message.header}
                                body={infoMessages.message.body}
                                closeModal={() => {
                                    setIsDrawerShown(false);
                                }}
                            />
                        )
                        }
                        <MobileFooter />
                    </Fragment>
            }
        </>
    )
}



Category.propTypes = {
    category: PropTypes.object,
    categoryLoading: PropTypes.bool,
    coreCategories: PropTypes.array,
    courses: PropTypes.array,
    resources: PropTypes.array,
    screenWidth: PropTypes.number,
    settings: PropTypes.object,
    trending: PropTypes.object,
    user: PropTypes.object,
    videos: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(Category)
