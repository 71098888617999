import React, {useEffect} from 'react';
import MobileLoginVideo from '../components/Login/mobile-login-video';
import DesktopLoginVideo from '../components/Login/desktop-login-video';
import PropTypes from 'prop-types';
import './welcome-video.scss';
import {useLocation} from "react-router-dom";
import {HandleRouteChange} from "../utils/Segment/segment-methods";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data
});


const WelcomeVideoPage = ({user}) => {
    let location = useLocation()
    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Welcome Video')
        }
    }, [user, location])

    return (
        <div className="welcome-page">
            <div className='desktop-layout'>
                <DesktopLoginVideo/>
            </div>
            <div className='mobile-layout'>
                <MobileLoginVideo/>
            </div>

        </div>

    )
}

WelcomeVideoPage.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeVideoPage);
