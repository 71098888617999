import * as TYPES from './actionTypes'
import {UPDATE_WATCHLIST_STATUS_COURSE} from "./actionTypes";

const initialState = {
    data: null,
    lastLoadedUUID: null,
    courseLoading: true,
    restartingCourse: false,
    restartingCourseError: null,
    error: null,
    all: null,
}

const Course = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_SINGLE_COURSE:
            return {
                ...state,
                courseLoading: true
            }

        case TYPES.SET_SINGLE_COURSE:
            return {
                ...state,
                data: action.payload,
                courseLoading: false
            }

        case TYPES.SET_LAST_LOADED_COURSE_UUID:
            return {
                ...state,
                lastLoadedUUID: action.payload
            }

        case TYPES.COURSE_ERROR:
            return {
                ...state,
                error: action.payload,
                courseLoading: false
            }

        case TYPES.RESTART_COURSE:
            return {
                ...state,
                restartingCourse: true
            }

        case TYPES.RESTART_COURSE_SUCCESS:
            return {
                ...state,
                restartingCourse: false
            }

        case TYPES.RESTART_COURSE_ERROR:
            return {
                ...state,
                restartingCourseError: action.payload,
                restartingCourse: false
            }
        case TYPES.GET_ALL_COURSES:
            return {
                ...state,
                loading: true
            }
        case TYPES.SET_ALL_COURSES:
            return {
                ...state,
                all: state.all === null ? action.payload : state.all.concat(action.payload),
                loading: false
            }
        case TYPES.UPDATE_WATCHLIST_STATUS_COURSE:
            return {
                ...state,
                data: {...state.data, on_watchlist: action.payload}
            }

        default:
            return state;
    }
}

export default Course
