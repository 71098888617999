exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/images/white-circle-primary-color-plus.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../assets/images/white-circle-primary-color-audio.svg"));
var ___CSS_LOADER_URL___2___ = urlEscape(require("../../assets/images/white-circle-primary-color-download.svg"));
var ___CSS_LOADER_URL___3___ = urlEscape(require("../../assets/images/white-circle-primary-color-share.svg"));
var ___CSS_LOADER_URL___4___ = urlEscape(require("../../assets/images/white-circle-primary-color-cq.svg"));

// Module
exports.push([module.id, ".desktop-hover_card{background-color:#e3dcd3;padding:12px 15px;border-radius:4px;position:absolute;top:33px;right:-160px;z-index:20;width:200px}.desktop-hover_card .video-title_header{display:flex;flex-direction:row;justify-content:space-between;align-items:center;padding-bottom:10px}.desktop-hover_card .video-title_header .video-container{width:92px;height:50px}.desktop-hover_card .video-title_header .video-title{font-family:Sofia Pro Bold;font-size:12px;line-height:115%;letter-spacing:.02em;color:#021026}.desktop-hover_card .desktop-action_container{background-color:#e2dcd4;padding-top:10px}.desktop-hover_card .desktop-action_container .action-item{margin-bottom:8px}.desktop-hover_card .desktop-action_container .action-item .icon-container{display:flex;flex-direction:row;justify-content:flex-start;align-items:center;flex-wrap:nowrap;gap:8px}.desktop-hover_card .desktop-action_container .action-item .icon-container .action-icon{width:25px;height:25px;background-size:contain;background-repeat:no-repeat}.desktop-hover_card .desktop-action_container .action-item .icon-container .watchlist{background-image:url(" + ___CSS_LOADER_URL___0___ + ")}.desktop-hover_card .desktop-action_container .action-item .icon-container .audio{background-image:url(" + ___CSS_LOADER_URL___1___ + ")}.desktop-hover_card .desktop-action_container .action-item .icon-container .download{background-image:url(" + ___CSS_LOADER_URL___2___ + ")}.desktop-hover_card .desktop-action_container .action-item .icon-container .share{background-image:url(" + ___CSS_LOADER_URL___3___ + ")}.desktop-hover_card .desktop-action_container .action-item .icon-container .cq{background-image:url(" + ___CSS_LOADER_URL___4___ + ")}.desktop-hover_card .desktop-action_container .action-item .icon-container .action-title{font-family:Sofia Pro Bold;font-size:12px;line-height:120%;color:#2d5f62}", ""]);

