import {call, put, takeEvery} from "redux-saga/effects"
import * as TYPES from "./actionTypes"
import * as ACTIONS from "./actions"
import {
    getInfoMessages,
    getOverallStats,
    getPerformanceScores,
    getRecommendedAssets,
    getCategoryStats, postCheckup
} from "../../../helpers/backend-helper";
import {captureException} from "@sentry/react";

function* GetInfoMessages({location}) {
    try {
        const response = yield call(getInfoMessages, location);
        yield put(ACTIONS.setInfoMessages(response))

    } catch (e) {
        captureException(e)
    }
}

function* PostCheckup({payload}) {
    try {
        yield call(postCheckup, payload)
        yield put(ACTIONS.getOverallStats())
    } catch (e) {
        captureException(e)
    }
}

function* GetOverallStats() {
    try {
        const response = yield call(getOverallStats)
        yield put(ACTIONS.setOverallStats(response))

    } catch (e) {
        captureException(e)
    }
}

function* GetCategoryStats({category_uuid}) {
    try {
        const response = yield call(getCategoryStats, category_uuid)
        yield put(ACTIONS.setCategoryStats(response))
    } catch (e) {
        captureException(e)
    }
}

function* GetPerformanceScores({category_uuid}) {

    try {
        const response = yield call(getPerformanceScores, category_uuid);
        yield put(ACTIONS.setPerformanceScores(response))
    } catch (e) {
        captureException(e)
    }
}

function* GetMyRecommendedAssets({category_uuid}) {
    try {
        const response = yield call(getRecommendedAssets, category_uuid)
        yield put(ACTIONS.setMyRecommendedAssets(response))
    } catch (e) {
        captureException(e)
    }
}

function* MyMarriageSaga() {
    yield takeEvery(TYPES.GET_INFO_MESSAGES, GetInfoMessages)
    yield takeEvery(TYPES.GET_MY_RECOMMENDED_ASSETS, GetMyRecommendedAssets)
    yield takeEvery(TYPES.GET_OVERALL_STATS, GetOverallStats)
    yield takeEvery(TYPES.GET_CATEGORY_STATS, GetCategoryStats)
    yield takeEvery(TYPES.GET_PERFORMANCE_SCORES, GetPerformanceScores)
    yield takeEvery(TYPES.POST_CHECKUP, PostCheckup)
}

export default MyMarriageSaga
