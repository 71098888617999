import React from 'react';
import { Link } from 'react-router-dom';

import NewChallengeStep from '../ChallengeStep/new-challenge-step';
import PropTypes from 'prop-types';
import './challengeDetails.scss';

const ChallengeDetails = (props) => {

    // console.log('props challenge detail', props)
    return (
        <div className='challenge-video_details'>
            <div className='current-challenge_content'>
                <div className="challenge-day">
                    {props.isBonus ? (
                        `Bonus Day ${props.currentDay}`
                    ) : (
                        ` Day ${props.currentDay}`
                    )}

                </div>
                {props.isBonus ? (
                    <div className='challenge-description' >
                        <p>{props.currentName}</p>
                    </div>
                ) : (
                    <div className='challenge-description' dangerouslySetInnerHTML={{ __html: props.challengeDescription }}>

                    </div>
                )}
            </div>

            {props.uuid !== "" && (
                <div className="next-challenge_container">
                <div className='next-challenge_section_title'> UP NEXT</div>
                <div className='next-challenge' ref={props.lockedChallenge}>
                    <NewChallengeStep
                        uuid={props.uuid}
                        src={props.src}
                        stepNumber={props.position}
                        status={props.status}
                        name={props.nextName}
                        type={props.type}
                        duration={props.duration}
                        description={props.description}
                        //  totalSteps={challengeSteps.length}
                        videoURL={props.video_url}
                        day={props.day}
                        //  key={props.key}
                        onEnded={props.onEnded}
                        setVideo={props.setVideo}
                        challenge_uuid={props.challenge_uuid}
                        lesson_uuid={props.lesson_uuid}
                        bonus={props.isBonus}
                    />
                </div>


            </div>
            )}

        </div>
    )

}

ChallengeDetails.propTypes = {
    challengeDescription: PropTypes.string,
    challenge_uuid: PropTypes.string,
    currentDay: PropTypes.number.isRequired,
    currentName: PropTypes.string,
    // day: PropTypes.number,
    description: PropTypes.string,
    duration: PropTypes.string,
    isBonus: PropTypes.bool.isRequired,
    lesson_uuid: PropTypes.string,
    lockedChallenge: PropTypes.object,
    nextName: PropTypes.string,
    onEnded: PropTypes.func,
    src: PropTypes.string,
    status: PropTypes.string,
    stepNumber: PropTypes.number,
    type: PropTypes.string,
    uuid: PropTypes.string,  
}


export default ChallengeDetails;
