import * as TYPES from './actionTypes'

const initialState = {
    settings: localStorage.getItem('settings')
}

const SystemSettings = (state = initialState, action) => {
    switch (action.type) {

        case TYPES.LOAD_SYSTEM_SETTINGS_SUCCESS:
            return {...state, settings: action.payload}

        default:
            return state;

    }

}

export default SystemSettings
