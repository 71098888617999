import {Link} from "react-router-dom";
import TrendingCard from "../../components/cards/trendingCard";
import React from "react";
import {getCardHeight__coreCategoryPage} from "../../utils/responsive-card-height";


export function CreateTrending(obj, settings, screenWidth) {

    let trendingVideo = obj.video;
    let cardHeight = screenWidth > 1168 ? 648 : getCardHeight__coreCategoryPage('TRENDING', settings, screenWidth);

    let trendingVideoCard = (
        <Link to={`/watch/${trendingVideo.asset_id}`} key={Math.random(100)}>
            <TrendingCard
                title={trendingVideo.title}
                src={trendingVideo.thumbnail}
                category={undefined}
                duration={trendingVideo.duration}
                topRight={true}
                categoryPage={true}
                showPlayButton={true}
                cardHeight={cardHeight}
            />
        </Link>
    );

    return {
        "videos": trendingVideoCard,
    };
}
