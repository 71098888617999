/**
 * List Item component should contain
 *      - title*
 *      - subtitle
 *      - icon
 *      - ability to adjust color on all elements
 */
import React from "react"
import styled from "styled-components";
import PropTypes from "prop-types";
import * as TEMPLATE_STYLES from "../../../Templates/Global/default-styles";
import {SplittyCircleCheckMultiFill} from "../../../Icons/splittyIcons/index"
import {Title} from "../../Title";


const Subtitle = styled.div`
        ${TEMPLATE_STYLES.PARAGRAPH_STYLE}
        ${props => props.properties}
    `

const ItemContainer = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 22px;
`

const IconTitle = styled.div`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 22px;
`

/**
 *
 * @param icon
 * @param title
 * @param subtitle
 * @returns {JSX.Element}
 * @constructor
 */
export const IconListItem = ({icon, title, subtitle}) => {
    return (
        <ItemContainer>
            <IconTitle>
                {
                    icon &&
                    <div>
                        <SplittyCircleCheckMultiFill size={36} secondary={icon.properties.backgroundColor.secondary} primary={icon.properties.backgroundColor.primary}/>
                    </div>

                }
                <Title data={title}/>
            </IconTitle>

            <Subtitle properties={subtitle.properties}>{subtitle.text}</Subtitle>
        </ItemContainer>
    )
}


IconListItem.propTypes = {
    icon: PropTypes.shape({
        url: PropTypes.string,
        properties: PropTypes.object
    }),
    title: PropTypes.shape({
        text: PropTypes.string,
        properties: PropTypes.object
    }).isRequired,
    subtitle: PropTypes.shape({
        text: PropTypes.string,
        properties: PropTypes.object
    })
}
