
import React from 'react';
import TimePill from '../Pills/timePill';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './new-challenge-step.scss';

const NewChallengeStep = (props) => {


    let status = props.status;
    let link;
    if (status === 'disabled'){
        link = "#";
    } else {
        link = `/get-challenge-lesson/${props.challenge_uuid}/${props.lesson_uuid}`;
    }

    // console.log('new step props', props);

    return (

                <a href={link} className={`challenge-step_link`}>
                    <div className='challenge-step_container' >
                        <div className={`status-dot ${status}`}></div>
                        <div className={`step-content_container ${status}`}>
                            <div className="step-title">
                                <div className="day-number">
                                    {props.bonus ?  (
                                        <div> Bonus Day {props.day}</div>
                                    ): (
                                       <div> Day {props.day}</div>
                                    )}

                                </div>
                                {props.bonus ? (
                                    <div className="day-description">{props.name}</div>
                                ): (
                                    <div className="day-description" dangerouslySetInnerHTML={{ __html: props.description }}></div>
                                )}


                            </div>

                            <div className="course-image" style={{ backgroundImage: `url(${props.src})` }}>
                                <TimePill
                                    duration={props.duration}
                                    position={"bottom-right"}
                                />

                            </div>

                        </div>
                    </div>
                </a>

    )
}

NewChallengeStep.propTypes = {
  bonus: PropTypes.bool.isRequired,
  challenge_uuid: PropTypes.string.isRequired,
//   day: PropTypes.number.isRequired,
  description: PropTypes.string,
  duration: PropTypes.string.isRequired,
  lesson_uuid: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,

}

export default NewChallengeStep;
