import React from 'react'
import Components from "../../Helpers/componentHelper"
import PropTypes from "prop-types";
import styled from "styled-components";


const SectionContainer = styled.section`
        ${props => props.properties}
    `

export const Section = ({block, data}) => {
    return (
        <SectionContainer id={block.attributes.id} properties={data.properties}>
            {
                (block.children || []).map(child => Components(child))
            }
        </SectionContainer>
    )
}

Section.propTypes = {
    block: PropTypes.shape({
        children: PropTypes.arrayOf(
            PropTypes.shape({
                component: PropTypes.string.isRequired,
                data: PropTypes.string.isRequired,
            })
        ).isRequired
    }).isRequired
}
