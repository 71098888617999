import React from 'react';
import Carousel from 'react-multi-carousel';
import PropTypes from 'prop-types';
import CustomArrowLeft from '../SliderArrows/custom-arrow-left';
import CustomArrowRight from '../SliderArrows/custom-arrow-right';

import 'react-multi-carousel/lib/styles.css';
import './new-carousel.scss';

const NewCarousel = (props) => {

    return (
        <Carousel
            showDots={props.showDots}
            draggable={false}
            responsive={props.config}
            removeArrowOnDeviceType={props.removeArrowOnDeviceType}
            renderDotsOutside={true}
            partialVisible={props.partialVisible}
            itemClass="carousel-item-padding-8-px"
            customLeftArrow={<CustomArrowLeft source={props.source}/>}
            customRightArrow={<CustomArrowRight source={props.source}/>}
            slidesToSlide={1}
        >
            {props.cards}
        </Carousel>

    )
}

NewCarousel.propTypes = {
    showDots: PropTypes.bool.isRequired,
    draggable: PropTypes.bool,
    responsive: PropTypes.string,
    removeArrowOnDeviceType: PropTypes.arrayOf(PropTypes.string).isRequired,
    renderDotsOutside: PropTypes.bool,
    partialVisible: PropTypes.bool.isRequired,
    itemClass: PropTypes.string,
}

export default NewCarousel;
