import React, { useCallback, } from 'react';
import styled from 'styled-components';
import { PrimaryColor, TextColor, } from '../global/colors';
const CheckboxContainer = styled.label `
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 18px;
`;
const CheckboxInput = styled.input.attrs({ type: 'radio' }) `
    accent-color: ${PrimaryColor.main};
    border: 2px solid ${PrimaryColor.main};
    border-radius: 50%;
    cursor: pointer;
    height: 24px;
    margin-right: 16px;
    width: 24px;
    &:hover {
        accent-color: ${PrimaryColor.main};
    }
`;
const Title = styled.span `
    color: ${TextColor.primary};
    font-size: 18px;
    line-height: 1.65;
`;
function QuestionOptionRadioItem({ title, checked, onSelectAnswerOption, value, }) {
    const onChangeAnswerOption = useCallback(() => {
        onSelectAnswerOption(value);
    }, [
        onSelectAnswerOption,
        value,
    ]);
    return (React.createElement(CheckboxContainer, null,
        React.createElement(CheckboxInput, { checked: checked, name: `answer-${value}`, onChange: onChangeAnswerOption, type: "radio" }),
        React.createElement(Title, null, title)));
}
export default QuestionOptionRadioItem;
