import * as TYPES from './actionTypes'

// !- LOADING DOESN'T SEEM TO BE USED ANYWHERE
const initialState = {
    data: null,
    error: ""
}

const PopQuiz = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_POP_QUIZ:
            return {
                ...state,
            }
        case TYPES.SET_POP_QUIZ:
            return {
                ...state,
                data: action.payload,
            }
        case TYPES.LOAD_DASHBOARD_ERROR:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }
}

export default PopQuiz
