
import React from 'react';
import TimePill from '../Pills/timePill';
import { Link } from 'react-router-dom';

import './notificationCard.scss';
import PropTypes from 'prop-types';

const NotificationCard = (props) => {

    return (
        <div className='notification-content_container mb-3' >
            <div className={`status-dot ${props.status}`}></div>
            <div className="notification-card_container">
                <div className="notification-title_container">
                    <div className='notification-icon' style={{backgroundImage:`url(${props.icon})`}}></div>
                    <div className='notification-title'>{props.title}</div>
                </div>
                <div className="notification-description" dangerouslySetInnerHTML={{ __html: props.message }}></div>

                {/* <div className="course-image" style={{ backgroundImage: `url(${props.src})` }}>
                                <TimePill
                                    duration={props.duration}
                                    position={"bottom-right"}
                                />

                            </div> */}

            </div>
        </div>
    )
}

NotificationCard.propTypes = {
    icon: PropTypes.string.isRequired,
    message:PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    title:PropTypes.string.isRequired,
}

export default NotificationCard;