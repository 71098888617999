import {Link} from "react-router-dom";
import MyMarriageCard from "../../components/cards/myMarriageCard";
import React from "react";

export function CreateCategories(category_array) {
    // console.log("Categories look here", category_array);

    // create cards with title, score, and icon, link to the category page
    let styledPriority = [];
    let styledNeedsWork = [];
    let styledLooksGood = [];

    category_array.forEach((e, i) => {
        let styledCard = (
            <Link to={`/category/${e.category_slug}`} key={Math.random(100)}>
                <MyMarriageCard
                    icon={e.category_icon}
                    cardTitle={e.category}
                    radialScore={(e.category_score / 100)}
                />
            </Link>
        );

        if (e.priority_status === "Looking Good") {
            styledLooksGood.push(styledCard);
        } else if (e.priority_status === "Needs Work") {
            styledNeedsWork.push(styledCard);
        } else {
            styledPriority.push(styledCard);
        }
    });

    return {
        "priority": styledPriority,
        "needsWork": styledNeedsWork,
        "lookingGood": styledLooksGood
    }

}
