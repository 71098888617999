import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import LoadingScreen from "../../components/LoadingScreen";
import {HandleRouteChange} from "../../utils/Segment/segment-methods";
import ScaleLoader from "react-spinners/ScaleLoader";
import dropin from "braintree-web-drop-in";
import axios from "axios";
import {useForm} from "react-hook-form";
import ToastCard from "../../components/cards/toastCard";



import SubscriptionSectionInfo from '../Account/Sidebar/Submenu/AccountSubscription/subscriptionSectionInfo';

import "./settings-subscription.scss";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
});

const RenewSubscription = ({user}) => {
    let navigate = useNavigate();
    let location = useLocation()
    const [paymentError, setPaymentError] = useState(null)
    const [braintreeClientToken, setBraintreeClientToken] = useState(null)
    const {register, handleSubmit, getValues, formState: {isValid}} = useForm({mode: 'onChange'});
    const [toastMessage, setToastMessage] = useState(null)
    const [isShowToast, setIsShowToast] = useState(false);
    const [braintreeInstance, setBraintreeInstance] = useState(undefined)

    window.addEventListener('click', () => {
        setPaymentError(null)
    });

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            if (user.allow_access) {
                navigate('/browse', {replace: true})
            }
            HandleRouteChange(location, user, 'page-view', 'Renew Subscription')
        }
    }, [user, location])

    useEffect(() => {
        GetBraintreeClientToken();
    }, []);

    useEffect(() => {
        if (braintreeClientToken && JSON.stringify(user) !== '{}') {
            const initializeBraintree = () => dropin.create({
                authorization: braintreeClientToken,
                container: "#braintree-drop-in-div",
                paypal: {
                    flow: 'vault'
                },
            }, function (error, instance) {
                if (error)
                    console.error(error)
                else
                    setBraintreeInstance(instance);
            });

            if (braintreeInstance) {
                braintreeInstance
                    .teardown()
                    .then(() => {
                        initializeBraintree();
                    });
            } else {
                initializeBraintree();
            }

        }

    }, [braintreeClientToken, user])


    async function GetBraintreeClientToken() {
        await axios.get('/braintree-client-token').then(response => {
            setBraintreeClientToken(response.data.token)
        }).catch(() => {
            setToastMessage('We are having trouble getting your payment information')
            showToast()
        })
    }


    const showToast = () => {
        setTimeout(() => {
            setIsShowToast(true);
            setTimeout(() => {
                setIsShowToast(false);
            }, 3000);
        }, 2000)
    }

    return (
        JSON.stringify(user) === '{}' || braintreeClientToken === null ?
            <LoadingScreen/>
            :
            <div id="second-registration_page">
                <div className="second-registration_container">
                <SubscriptionSectionInfo />
                </div>
            </div>

    )
}

RenewSubscription.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(RenewSubscription)
