import React, {useState, useEffect, useRef} from "react";
import CustomLoader from "../../../../components/CustomLoader";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {getNakedConversations, getMemberStories} from "../../store/actions";
import PropTypes from 'prop-types';

import "./naked-conversations.scss";
import {HandleRouteChange, Track} from "../../../../utils/Segment/segment-methods";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getNakedConversations,
            getMemberStories,
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    nakedConversations: appState.AccountSettings.nakedConversations,
    memberStories: appState.AccountSettings.memberStories,
});

const NakedConversations = ({getNakedConversations, nakedConversations, getMemberStories, memberStories}) => {

    // This component displays both Naked Conversations and Member Stories

    const [isTitle, setIsTitle] = useState(undefined);
    const [isDescription, setIsDescription] = useState(undefined);
    const [isStyledElements, setIsStyledElements] = useState(undefined);
    const displayedPage = useRef();
    const [activeTab, setActiveTab] = useState(undefined)

    // naked convo and member stories endpoint are returning the same data set

    useEffect(() => {
        // find the current page by looking at the parent like subscription
        checkPage();
    }, [nakedConversations, memberStories]);


    const checkPage = () => {
        let page = displayedPage.current.parentElement;
        page = page.dataset.name;
        // activeTab is used to send to Mixpanel
        setActiveTab(page)

        switch (page) {
            case "nakedConversations":
                if (nakedConversations === null) {
                    getNakedConversations({type: "naked-conversations"});
                }
                if (nakedConversations === null) {
                    return;
                }
                setIsTitle(nakedConversations.meta.title);
                setIsTitle("Naked Conversations");
                setIsDescription(nakedConversations.meta.description);
                sortResponse(nakedConversations.assets);
                break;
            case "memberStories":
                if (memberStories === null) {
                    getMemberStories({type: "member-stories"})
                }
                if (memberStories === null) {
                    return;
                }
                setIsTitle(memberStories.meta.title);
                setIsDescription(memberStories.meta.description);
                sortResponse(memberStories.assets);
                break;
        }
    }

    function OpenDocument(doc){
        Track('Opened Extras Resource', {title: `${doc.title}`, url: `/account/extras/${activeTab}`}).then(() => {
            window.open(doc.url)
        })
    }

    const sortResponse = (object) => {
        if (object === null) {
            return;
        }

        // use a for in loop
        // for get the key, for each key make a new container element

        let styledYear;
        let allYears = [];

        for (const years in object) {
            let extraLink;
            let extraArray = [];
            let yearArray = object[years];
            yearArray.forEach((doc) => {
                extraLink = (
                <div className="extra-link_container" key={Math.random(100)}>
                    <div className="arrow-right"></div>
                    <div className="extra-link pointer" onClick={() => OpenDocument(doc)}>{doc.title}</div>
                </div>);
                extraArray.push(extraLink);
            })
            styledYear = (
                <div className="year-container" key={Math.random(100)}>
                    <div className="year">{years}</div>
                    <div className="all-links_container">
                        {extraArray}
                    </div>
                </div>);
            allYears.push(styledYear);
        }
        allYears = allYears.reverse();
        setIsStyledElements(allYears);
    }


    return (
        <div className="naked-convo_container" ref={displayedPage}>
            <h3 className="teal-title">{isTitle}</h3>
            <div className="subtitle">
                {isDescription}
            </div>
            <hr className="grey-separator"/>
            <div className="naked-convo_content">
                {isStyledElements ? (
                    <div className="content-container">
                        {isStyledElements}
                    </div>
                ) : (
                    <div className="full-size_loader" style={{marginTop: "30px"}}>
                        <div className="loader-container">
                            <CustomLoader/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

NakedConversations.propTypes = {
    getMemberStories: PropTypes.func,
    getNakedConversations: PropTypes.func,
    memberStories: PropTypes.object,
    nakedConversations: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(NakedConversations);
