/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import styled from 'styled-components';
import { BrandOceanColor, CommonColor, } from '../global/colors';
import { SofiaFont, } from '../global/fonts';
// @ts-ignore
import CheckSquareIcon from '../assets/icon/check-square.svg';
const defaultProps = {
    className: undefined,
    showIcon: true,
};
const Container = styled.div `
    background-color: ${BrandOceanColor.main};
    padding: 10px 0;
    position: absolute;
    text-align: center;
    top: 24px;
    width: 100%;
`;
const StyledTitle = styled.span `
    font-family: ${SofiaFont.Regular};
    font-size: 14px;
    line-height: 1.2;
    color: ${CommonColor.white};
`;
const StyledImg = styled.img `
    margin-right: 8px;
`;
// eslint-disable-next-line react/prop-types
function AlertNotification({ className, title, showIcon, }) {
    return (React.createElement(Container, { className: className },
        showIcon && (React.createElement(StyledImg, { alt: "alert", className: "check-square-icon", src: CheckSquareIcon })),
        React.createElement(StyledTitle, null, title)));
}
AlertNotification.defaultProps = defaultProps;
export default AlertNotification;
