import { findIndex } from 'lodash';
import { useEffect, useRef, } from 'react';
import { SofiaFont, } from './fonts';
import { ROUTE_URL, STEP_UUID_LIST, } from './constants';
/**
 * Hook to store the previous value of a prop or state.
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 */
export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};
export function getFormattedStyle(style) {
    if (!style) {
        return {};
    }
    const kebabCaseStyle = {};
    Object.keys(style).forEach((key) => {
        const kebabKey = key.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
        if (style[key] !== null) {
            kebabCaseStyle[kebabKey] = style[key];
            if (kebabKey === 'font-size') {
                kebabCaseStyle[kebabKey] = `${style[key]}px`;
            }
            if (kebabKey === 'font-family') {
                if (style[key] === 'SofiaPro-Regular') {
                    kebabCaseStyle[kebabKey] = SofiaFont.Regular;
                }
                else if (style[key] === 'SofiaPro-Bold') {
                    kebabCaseStyle[kebabKey] = SofiaFont.Bold;
                }
            }
        }
    });
    return kebabCaseStyle;
}
export function getWhitespaceConvertedText(text) {
    if (!text) {
        return text;
    }
    if (text.endsWith(' ')) {
        return `${text.slice(0, -1)}\u00A0`; // '\u00A0' represents '&nbsp;'
    }
    return text;
}
export function validatePasswordRequirement(password) {
    // Check for at least 6 characters, 1 uppercase letter, and 1 number
    const regex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
    return regex.test(password);
}
// Remove notification data from step, not needed for web version
export function getFilteredStepWithoutNotificationData(user) {
    const filteredSteps = user?.onboarding_flow?.steps.filter((step) => (step.uuid !== STEP_UUID_LIST.NotificationIntro));
    const filteredUser = {
        ...user,
        onboarding_flow: {
            ...user?.onboarding_flow,
            steps: filteredSteps,
        },
    };
    return filteredUser;
}
// This function borrowed from: https://stackoverflow.com/a/54834635
export function addOrReplaceBy(arr = [], predicate, getItem) {
    const index = findIndex(arr, predicate);
    return index === -1
        ? [...arr, getItem()]
        : [
            ...arr.slice(0, index),
            getItem(arr[index]),
            ...arr.slice(index + 1),
        ];
}
// Navigate mapper based on incomplete status
export function navigateByUuid(uuid) {
    if (STEP_UUID_LIST.PartnerPairingIntro === uuid) {
        return ROUTE_URL.PartnerPairingIntro;
    }
    if (STEP_UUID_LIST.PartnerPairing === uuid) {
        return ROUTE_URL.PartnerPairing;
    }
    if (STEP_UUID_LIST.QuizWelcomeIntro === uuid) {
        return ROUTE_URL.TakeQuiz;
    }
    if (STEP_UUID_LIST.QuizQuestions === uuid) {
        return ROUTE_URL.QuizQuestions;
    }
    return null;
}
