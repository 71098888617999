import * as TYPES from './actionTypes';

const initialState = {
    FAQ: null,
    History: null,
    MembershipPerks: null,
    activeProfilePage: null,
    cancellationOffer: null,
    changeSubscriptionLoading: false,
    continueWatching: null,
    coupon: null,
    error: null,
    infoDrawer: null,
    loading: false,
    memberStories: null,
    message: null,
    messageType: null,
    nakedConversations: null,
    notificationPreferences: null,
    response: null,
    subscriptionPlans: {},
};

const AccountSettings = (state = initialState, action) => {
    switch (action.type) {
    case TYPES.CANCEL_SUBSCRIPTION:
        return {
            ...state,
            navigate: action.navigate,
            changeSubscriptionLoading: true,
        };
    case TYPES.RESTART_SUBSCRIPTION:
        return {
            ...state,
            navigate: action.navigate,
            route: action.route,
            changeSubscriptionLoading: true,
        };
    case TYPES.CHANGE_SUBSCRIPTION_RESPONSE:
        return {
            ...state,
            changeSubscriptionLoading: false,
            response: action.payload,
        };
    case TYPES.CHANGE_SUBSCRIPTION_SUCCESS:
        return {
            ...state,
            changeSubscriptionLoading: false,
            message: action.payload,
            messageType: 'success',

        };
    case TYPES.CHANGE_SUBSCRIPTION_FAIL:
        return {
            ...state,
            changeSubscriptionLoading: false,
            message: action.payload,
            messageType: 'error',
        };
    case TYPES.SET_ACCOUNT_SETTINGS_MESSAGE:
        return {
            ...state,
            message: action.payload.message,
            messageType: action.payload.type,
            loading: false,
        };

    case TYPES.UPDATE_ACCOUNT_MESSAGE:
        return {
            ...state,
            message: action.payload,
        };
    case TYPES.SWAP_MEMBERSHIP:
        return {
            ...state,
            changeSubscriptionLoading: true,
        };
    case TYPES.GET_HISTORY:
        return {
            ...state,
            loading: true,
        };
    case TYPES.SET_HISTORY:
        return {
            ...state,
            History: action.payload,
            loading: false,
        };
    case TYPES.REMOVE_VIDEO_FROM_HISTORY:
        return {
            ...state,
            // watchlistButtonLoading: true
        };
    case TYPES.GET_CONTINUE_WATCHING:
        return {
            ...state,
            loading: true,
        };
    case TYPES.SET_CONTINUE_WATCHING:
        return {
            ...state,
            continueWatching: action.payload,
            loading: false,
        };
    case TYPES.GET_NOTIFICATION_PREFERENCES:
        return {
            ...state,
            loading: true,
        };
    case TYPES.SET_NOTIFICATION_PREFERENCES:
        return {
            ...state,
            notificationPreferences: action.payload,
            loading: false,
        };

    case TYPES.UPDATE_NOTIFICATION_PREFERENCES:
        return {
            ...state,
            loading: false,
        };

    case TYPES.GET_MEMBERSHIP_PERKS:
        return {
            ...state,
            loading: true,
        };
    case TYPES.SET_MEMBERSHIP_PERKS:
        return {
            ...state,
            MembershipPerks: action.payload,
            loading: false,
        };
    case TYPES.GET_FAQ:
        return {
            ...state,
            loading: true,
        };
    case TYPES.SET_FAQ:
        return {
            ...state,
            FAQ: action.payload,
            loading: false,
        };
    case TYPES.GET_NAKED_CONVERSATIONS:
        return {
            ...state,
            loading: true,
        };
    case TYPES.SET_NAKED_CONVERSATIONS:
        return {
            ...state,
            nakedConversations: action.payload,
            loading: false,
        };
    case TYPES.GET_MEMBER_STORIES:
        return {
            ...state,
            loading: true,
        };
    case TYPES.SET_MEMBER_STORIES:
        return {
            ...state,
            memberStories: action.payload,
            loading: false,
        };
    case TYPES.POST_COUPON_CODE:
        return {
            ...state,
            loading: true,
        };
    case TYPES.SET_COUPON:
        return {
            ...state,
            coupon: action.payload,
            loading: false,
        };
    case TYPES.APPLY_MEMBER_COUPON:
        return {
            ...state,
            changeSubscriptionLoading: true,
        };
    case TYPES.GET_INFO_DRAWER:
        return {
            ...state,
            loading: false,
        };
    case TYPES.SET_INFO_DRAWER:
        return {
            ...state,
            infoDrawer: action.payload,
            loading: false,
        };
    case TYPES.SET_ACTIVE_PROFILE_PAGE:
        return {
            ...state,
            activeProfilePage: action.payload,
            loading: false,
        };
    case TYPES.SET_CANCELLATION_OFFER:
        return {
            ...state,
            cancellationOffer: action.payload,
        };
    case TYPES.SET_SUSBSCRIPTION_PLAN_TYPE:
        return {
            ...state,
            subscriptionPlans: { ...action.payload },
        };
    default:
        return state;
    }
};

export default AccountSettings;
