import React, {useState, useEffect} from 'react';
import ChallengeCard from '../cards/challengeCard';
import ConfettiAnimation from '../Confetti/index.js';
import GeneralButton from '../buttons/generalButton';
import ReplayIcon from '../../assets/images/refresh-white.svg';
import PropTypes from 'prop-types';

import './challenge-complete.scss';

const ChallengeComplete = (props) => {

    const [isConfetti, setIsConfetti] = useState();

    useEffect(()=>{
        partyTime();
    },[]);

    const partyTime = () => {
        // console.log('party time')
        setIsConfetti(<div></div>);
        setTimeout(()=>{
            setIsConfetti(<ConfettiAnimation />)
        },2000)
    }

    return (
        <div id="challenge-complete" className="">
            <div className="close-confetti" onClick={props.closeConfetti}></div>
            <div className="confetti-container">
            {isConfetti}
            </div>
            <div className="content-container">
                <div className="complete-title">Congrats! You Finished</div>
                {props.challenge ? (
                    <ChallengeCard
                    src={props.challengeImage}
                    title={props.title}
                    numberOfLessons={props.days}
                    numberOfDays={props.days}
                    />
                ): (
                    <div id="course-complete_card">
                        <div className="complete-image" style={{backgroundImage:`url(${props.courseImage})`}}></div>
                        <div className="complete-content">
                            <div className="complete-type">COURSE</div>
                            <div className="complete-title">{props.title}</div>
                            <div className="complete-videos">{props.totalVideos} Videos</div>
                        </div>
                    </div>
                )}
            </div>
            <div className='replay-animation_container'>
                <GeneralButton
                    buttonText={"Replay Animation"}
                    icon={ReplayIcon}
                    backgroundColor={"#2D5F62"}
                    textColor={"#fff"}
                    onClick={partyTime}
                />
            </div>

        </div>
    )
}

ChallengeComplete.propTypes = {
    closeConfetti: PropTypes.func.isRequired,
    challenge: PropTypes.bool,
    challengeImage: PropTypes.string,
    courseImage: PropTypes.string,
    days: PropTypes.number,
    title: PropTypes.string.isRequired,
    totalVideos: PropTypes.number,
}

export default ChallengeComplete;
