// create a helper method to filter/iterate through objects below
Object.filter = (obj, callback) =>
    Object.keys(obj)
        .filter(item => callback(obj[item]));


/**
 * Determine responsive Card Height
 * @param index_key
 * @param settings
 * @param screenWidth
 * @returns {number}
 */
export function getCardHeight(index_key, settings, screenWidth) {
    let cardHeight = 0;
    if (settings.DASHBOARD_LAYOUT[index_key]) {
        let configKey = Object.filter(settings.DASHBOARD_LAYOUT[index_key].slider,
            (val) => val.breakpoint.min <= screenWidth && val.breakpoint.max >= screenWidth
        )
        // set the height of the item to proper ratio
        cardHeight = (screenWidth / settings.DASHBOARD_LAYOUT[index_key].slider[configKey].items) / settings.DASHBOARD_LAYOUT[index_key].ratio
    }
    return cardHeight;
}

/**
 * Merge this function with the one above eventually. Keeping here until the system settings response is final
 * the only difference is the LAYOUT_KEY. ie. DASHBOARD_LAYOUT, VIDEO_LAYOUT
 * @param index_key
 * @param settings
 * @param screenWidth
 * @returns {number}
 */
export function getCardHeight__videoPage(index_key, settings, screenWidth) {
    let cardHeight = 0;
    if (settings.VIDEO_LAYOUT[index_key]) {
        let configKey = Object.filter(settings.VIDEO_LAYOUT[index_key].slider,
            (val) => val.breakpoint.min <= screenWidth && val.breakpoint.max >= screenWidth
        )
        // set the height of the item to proper ratio
        cardHeight = (screenWidth / settings.VIDEO_LAYOUT[index_key].slider[configKey].items) / settings.VIDEO_LAYOUT[index_key].ratio
    }
    return cardHeight;
}

/**
 * Merge this function with the one above eventually. Keeping here until the system settings response is final
 * the only difference is the WORKSHEET_LAYOUT. ie. DASHBOARD_LAYOUT, VIDEO_LAYOUT, WORKSHEET_LAYOUT
 * @param index_key
 * @param settings
 * @param screenWidth
 * @returns {number}
 */
export function getCardHeight__worksheetPage(index_key, settings, screenWidth) {
    let cardHeight = 0;
    if (settings.WORKSHEET_LAYOUT[index_key]) {
        let configKey = Object.filter(settings.WORKSHEET_LAYOUT[index_key].slider,
            (val) => val.breakpoint.min <= screenWidth && val.breakpoint.max >= screenWidth
        )
        // set the height of the item to proper ratio
        cardHeight = (screenWidth / settings.WORKSHEET_LAYOUT[index_key].slider[configKey].items) / settings.WORKSHEET_LAYOUT[index_key].ratio
    }
    return cardHeight;
}

/**
 *
 * @param index_key
 * @param settings
 * @param screenWidth
 * @returns {number}
 */
export function getCardHeight__coreCategoryPage(index_key, settings, screenWidth) {
    let cardHeight = 0;
    if (settings.CORE_CATEGORY_LAYOUT[index_key]) {
        let configKey = Object.filter(settings.CORE_CATEGORY_LAYOUT[index_key].slider,
            (val) => val.breakpoint.min <= screenWidth && val.breakpoint.max >= screenWidth
        )
        // set the height of the item to proper ratio
        cardHeight = (screenWidth / settings.CORE_CATEGORY_LAYOUT[index_key].slider[configKey].items) / settings.CORE_CATEGORY_LAYOUT[index_key].ratio
    }
    return cardHeight;
}


/**
 *
 * @param LAYOUT_KEY
 * @param INDEX_KEY
 * @param settings
 * @param screenWidth
 * @returns {number}
 */
export function getCardHeight__universal(LAYOUT_KEY, INDEX_KEY, settings, screenWidth) {
    let cardHeight = 0;
    if (settings[LAYOUT_KEY][INDEX_KEY]) {
        let configKey = Object.filter(settings[LAYOUT_KEY][INDEX_KEY].slider,
            (val) => val.breakpoint.min <= screenWidth && val.breakpoint.max >= screenWidth
        )
        // set the height of the item to proper ratio
        cardHeight = (screenWidth / settings[LAYOUT_KEY][INDEX_KEY].slider[configKey].items) / settings[LAYOUT_KEY][INDEX_KEY].ratio
    }
    return cardHeight;
}
