import React, { useCallback, } from 'react';
import './accountConfirmation.scss';
import styled from 'styled-components';
const InnerContainer = styled.div `
    border-width: 5px;
    border-style: solid;
    border-color: #F88A09;
`;
const StyledTitle = styled.div `
    margin-bottom: 22px;
`;
function DeleteAccountModal({ onClose, onDeleteAccount, }) {
    const onClickCancel = () => {
        onClose();
    };
    const onClickDelete = useCallback(() => {
        onDeleteAccount();
    }, [
        onDeleteAccount,
    ]);
    const onCloseModal = useCallback(() => {
        onClose();
    }, [
        onClose,
    ]);
    return (React.createElement("div", { className: "overlay" },
        React.createElement(InnerContainer, { className: "account-cancel_modal position-relative" },
            React.createElement("div", { className: "modal-content_container" },
                React.createElement("div", { className: "close-modal_x", onClick: onCloseModal }),
                React.createElement(StyledTitle, null, "Are you sure you want to delete your account?"),
                React.createElement("div", { className: "account-button_container" },
                    React.createElement("div", { className: "account-button short-red_button small-text_button", onClick: onClickDelete },
                        React.createElement("div", { className: "account-button_text" },
                            React.createElement("span", null, "Delete Account"))),
                    React.createElement("div", { className: "account-button long-teal_button small-text_button", onClick: onClickCancel },
                        React.createElement("div", { className: "account-button_text" },
                            React.createElement("span", null, "Cancel"))))))));
}
export default DeleteAccountModal;
