//-DEPENDENCIES
import React, {useState, useEffect, Fragment} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {bindActionCreators} from "redux"
import {connect} from "react-redux";
import PropTypes from 'prop-types';
//-HELPERS
import {UseSystemSettings} from "../../utils/SystemSettings/provider/system-settings";

//-ASSETS
import Worksheet from "../../assets/images/worksheet.svg";

//-COMPONENTS
import DesktopNav from "../../components/common/nav/desktop-nav";
import GeneralButton from "../../components/buttons/generalButton.jsx";
import LoadingScreen from "../../components/LoadingScreen";
import StyleRelatedWorksheets from "./related-worksheets";
import WorksheetHeader from "../../components/common/header-component/worksheet-header.jsx";

//-ACTIONS
import {getWorksheet as getSingleWorksheet} from "./store/actions";
import MobileFooter from "../../components/common/footer-component/mobile-footer";
import {HandleRouteChange, Track} from "../../utils/Segment/segment-methods";
import {worksheetViewed as markWorksheetRead} from "./store/actions";


const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getSingleWorksheet,
            markWorksheetRead
        },
        dispatch
    )
};

const mapStateToProps = appState => ({
    screenWidth: appState.UserInterface.screenWidth,
    user: appState.User.data,
    worksheet: appState.Worksheet.data,
    worksheetLoading: appState.Worksheet.loading,
});

//-START FUNCTIONAL COMPONENT
const SingleWorksheet = ({getSingleWorksheet, markWorksheetRead, worksheet, worksheetLoading, screenWidth, user}) => {
    let {asset_id} = useParams();
    let location = useLocation()
    const {settings} = UseSystemSettings();
    const [isWorksheetCarousel, setIsWorksheetCarousel] = useState();

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location && worksheet && asset_id === worksheet.uuid) {
            HandleRouteChange(location, user, 'page-view', 'Single Worksheet', worksheet.title)
        }
    }, [user, location, worksheet])


    //-USE EFFECT
    useEffect(() => {
        getSingleWorksheet(asset_id);
        window.scroll(0, 0);
    }, [asset_id]);

    useEffect(() => {
        if (worksheet && settings) {
            const related = StyleRelatedWorksheets(worksheet.related_worksheets, settings, screenWidth);
            setIsWorksheetCarousel(related);
        }
    }, [worksheet, screenWidth, settings])

    function openWorksheet(url) {
        markWorksheetRead(worksheet.uuid)
        Track('worksheet_viewed', {title: worksheet.title, category: worksheet.coreCategory})
        window.open(url)

    }

    return (
        worksheet === null || worksheetLoading || worksheetLoading === undefined || settings === null || JSON.stringify(user) === '{}' ?
            <LoadingScreen/>
            :
            <Fragment>
                <DesktopNav user={user}/>
                <div className="single-worksheet_page">
                    <WorksheetHeader
                        screenWidth={screenWidth}
                        clickAction={() => openWorksheet(worksheet.url)}
                        worksheet={worksheet}
                    />

                    <div className="worksheet-content_container">
                        <div className="worksheet-title">{worksheet.title}</div>
                        <div className="card-description">
                            {worksheet.description}
                        </div>
                        <GeneralButton
                            backgroundColor={"#2D5F62"}
                            buttonText={"Open Worksheet"}
                            className={"max-300"}
                            icon={Worksheet}
                            disabled={false}
                            onClick={() => openWorksheet(worksheet.url)}
                        />
                    </div>

                    <div className="related-worksheet_container">
                        <h3 className="related-worksheet_title">{settings.WORKSHEET_LAYOUT.RELATED.title ?? ''}</h3>
                        <div className="worksheet-carousel">
                            {isWorksheetCarousel}
                        </div>
                    </div>
                </div>
                <MobileFooter/>
            </Fragment>
    )
}

SingleWorksheet.propTypes = {
    getSingleWorksheet: PropTypes.func,
    markWorksheetRead: PropTypes.func,
    screenWidth: PropTypes.number,
    user: PropTypes.object,
    worksheet: PropTypes.object,
    worksheetLoading: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleWorksheet);
