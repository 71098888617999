import React from 'react'
import {Link} from "react-router-dom";
import CustomProgressBar from "../../components/ProgressBar/progress-bar";
import styled from "styled-components";


const CategoryName = styled.div`
    font-size: 24px;
    color: #021026;

    @media screen and (max-width:768px) {
        font-size: 14px;
         margin-bottom: 1.25em;
    }
`
const ThumbnailContainer = styled.div`
    @media screen and (min-width:576px) {
        max-width: 320px;
        max-height: 200px;
    }
`


const AssetListItem = (props) => {

    return (
        <>
            <div className="row d-flex flex-column flex-sm-row align-items-center align-items-sm-start px-1">
                <div className="d-flex col flex-column justify-content-center pr-4">
                    <Link to={props.link}>
                        <h2 className="font-bold">{props.name}</h2>
                        <CategoryName>{props.category}</CategoryName>
                    </Link>
                </div>
                <ThumbnailContainer className="d-flex position-relative col px-0 mb-3">
                    <Link to={props.link}>
                        <img className="img-fluid rounded" src={props.src} alt="asset thumbnail"/>
                    </Link>
                    {
                        props.showProgress && (<CustomProgressBar progressPercentage={props.progress}/>)
                    }
                </ThumbnailContainer>
                <hr/>
            </div>

        </>
    )
}

export default AssetListItem

