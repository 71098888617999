//-DEPENDENCIES
import React, {Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import styled from "styled-components";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';
//-HELPERS

//-ASSETS

//-COMPONENTS
import CustomLoader from "../CustomLoader";


//-ACTIONS
import {
    addToWatchlist,
    removeFromWatchlist
} from "../../pages/Watchlist/store/actions";

//-EVENTS
import {adjustWatchlist} from "../../pages/Watchlist/events";


//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            addToWatchlist,
            removeFromWatchlist
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    watchlistButtonLoading: appState.Watchlist.watchlistButtonLoading,
});


//-START FUNCTIONAL COMPONENT
const WatchlistButton = ({
                             addToWatchlist,
                             removeFromWatchlist,
                             watchlistButtonLoading,
                             ...props
                         }) => {

    //-STYLED COMPONENTS
    const PlusIcon = styled.div`
        background-image: url(${props.icons.plus})
    `
    const CheckmarkIcon = styled.div`
        background-image: url(${props.icons.checkmark})
    `

    const [assetType, setAssetType] = useState('video')

    useEffect(() => {
        if(!!props.assetType){
            setAssetType(props.assetType)
        }
    },[props])

    return (
        <div className="watchlist-container pointer"
             onClick={() => adjustWatchlist(props.assetID, props.onWatchlist, addToWatchlist, removeFromWatchlist, assetType)}>
            {
                watchlistButtonLoading ?
                    props.buttonType === 'normal' ?
                        <CustomLoader color={props.loader.color} width={props.loader.width} type={`scale`}/>
                        :
                        <CustomLoader color={props.loader.color} width={props.loader.width} type={`bounce`}/>
                    :
                    <Fragment>
                        {
                            props.buttonType === 'normal' ?
                                <div className="watchlist-button">
                                    {
                                        props.onWatchlist ?
                                            <CheckmarkIcon className="watchlist-button__icon"/>
                                            :
                                            <PlusIcon className="watchlist-button__icon"/>
                                    }
                                    <div className="watchlist-text">
                                        Watchlist
                                    </div>
                                </div>
                                :
                                props.onWatchlist ?
                                    <CheckmarkIcon className="watchlist-button__icon"/>
                                    :
                                    <PlusIcon className="watchlist-button__icon"/>
                        }
                    </Fragment>
            }
        </div>
    )
}

WatchlistButton.propTypes = {
    assetID: PropTypes.string,
    buttonType: PropTypes.string,
    icons: PropTypes.object,
    loader: PropTypes.object,
    onWatchlist: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistButton)
