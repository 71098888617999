import React from 'react';

import './loginCards.scss';

const LoginVideoCard = (props) => {

    // needs to be full screen video

    // auto play?


    return (
    <div className='login-video_card' >
        <video autoPlay loop muted className='login-video'>
            <source src={props.backgroundVideo} type="video/mp4" />
        </video>
    </div>)
}

export default LoginVideoCard;