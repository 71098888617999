/**
 * Button component should contain
 *      - text*
 *      - properties
 *      - icon
 *      - ability to adjust color on all elements
 */
import React, {useState, useEffect} from "react"
import styled from "styled-components"
import PropTypes from "prop-types";
import {useStateMachine} from "little-state-machine";
import updateAction from "../../Actions/updateAction";
import {AllowedFunctions} from "../../Functions/buttons";

const ButtonText = styled.span`
    ${props => props.properties}
    display: flex;
    line-height: 1;
    align-items: center;
`

const ButtonTag = styled.div`
    ${props => props.properties}
`

const ButtonContainer = styled.div`
    ${props => props.properties}
`

const ButtonTextContainer = styled.div`
  ${props => props.properties ?? defaultButtonTextContainerProps}
`
const defaultButtonTextContainerProps = `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  `
const ButtonElement = styled.div`
    ${props => props.properties}
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

/**
 *
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectionButton = ({data}) => {
    // console.log('SelectionButton data: ', data)
    const {actions} = useStateMachine({updateAction});
    const [activeButtonId, setActiveButtonId] = useState(null)
    const [buttonIDs, setButtonIDs] = useState([])
    let idArray = []

    /**
     * Store all button IDs as state array to reference during button state assignment
     */
    useEffect(() => {

        (data.buttons || []).map(btn => {
            idArray.push(btn.attributes.id)
            if(btn.attributes.isActive){
                assignButton(btn.attributes)
            }
        })
        setButtonIDs(idArray)

    }, [data])

    /**
     * Determine the state of the selection button
     * @param attributes
     * TODO: there must be a more elegant way of handling this @kurtweston
     */
    function assignButton(attributes) {
        // console.log('attr: ', attributes)
        if (attributes.id === activeButtonId) return;

        actions.updateAction({
            selectedPlan: attributes.planName,
            subscription: {
                name: attributes.planName,
                btPid: attributes.subscription.braintree_plan_id,
                price: attributes.subscription.price
            }
        });

        if (activeButtonId === null) {
            buttonIDs.forEach(btnID => {
                if (btnID !== attributes.id) {
                    document.getElementById(btnID).classList.add('inactive-background')
                }
            })
            document.getElementById(attributes.id).classList.remove('inactive-background')
            document.getElementById(attributes.id).classList.add('active')
        } else {
            document.getElementById(attributes.id).classList.add('active')
            document.getElementById(attributes.id).classList.remove('inactive-background')
            document.getElementById(activeButtonId).classList.add('inactive-background')
        }
        setActiveButtonId(attributes.id)

        // check to see if these should activate a disabled button
        if (!!attributes.activateButton) {
            actions.updateAction({buttonState: {startMembershipButton: false}});
            // document.getElementById(attributes.activateButton).removeAttribute('disabled')
            console.log('button: ' , attributes.activateButton)
            document.getElementById(attributes.activateButton).classList.add('active')
        }
    }


    const FunctionIterator = async (item) => {
        if (item.type === 'function') {
            if (typeof AllowedFunctions[item.name] === 'function') {

                return await (AllowedFunctions[item.name])(item.parameters)


            } else {
                //TODO: HANDLE ERROR
                console.log('***PASSING NON-EXISTENT FUNCTION***')
            }
        }
    }


    async function ExecuteButtonFunctions(blockAttributes) {
        assignButton(blockAttributes);
        // check to see if functions exist on button
        if (!Array.isArray(blockAttributes._onClick)) {
            return;
        }
        const unresolvedFunctions = (blockAttributes._onClick || []).map(FunctionIterator);

        const response = await Promise.all(unresolvedFunctions);


        if (response && Array.isArray(response)) {
            if (!!response[0].updateState && response[0].updateState) {
                actions.updateAction({
                    coupon: {
                        ...response[0]
                    }
                })
            }
        }
    }

    return (
        (data.buttons || []).map((item, i) => (
            <ButtonContainer properties={data.properties} key={i}>
                <ButtonElement id={item.attributes.id}
                               properties={item.properties}
                               onClick={() => ExecuteButtonFunctions(item.attributes)}>
                    <ButtonTextContainer className={item.attributes.class} properties={item.buttonTextContainer ? item.buttonTextContainer.properties : null}>
                        <ButtonText properties={item.buttonText.properties}>
                            <div dangerouslySetInnerHTML={{__html: item.buttonText.text}}></div>
                        </ButtonText>
                        {
                            item.subtext &&
                            <ButtonText className={item.subtext.class} properties={item.subtext.properties}>
                                <span dangerouslySetInnerHTML={{__html: item.subtext.text}}></span>
                            </ButtonText>
                        }
                    </ButtonTextContainer>
                    {
                        item.buttonTag &&
                        <ButtonTag properties={item.buttonTag.properties}>
                            <ButtonText>
                                {item.buttonTag.text}
                            </ButtonText>
                        </ButtonTag>
                    }
                </ButtonElement>
            </ButtonContainer>
        ))
    )
}

SelectionButton.propTypes = {
    data: PropTypes.shape({
        properties: PropTypes.any.isRequired,
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                buttonText: PropTypes.shape({
                    text: PropTypes.string.isRequired,
                }),
                buttonTag: PropTypes.oneOfType([
                    PropTypes.object,
                    PropTypes.array
                ]),
                attributes: PropTypes.shape({
                    class: PropTypes.string,
                    id: PropTypes.string.isRequired
                }).isRequired,
            })
        ).isRequired
    }).isRequired
}
