import React from 'react'
import M365Loader from "../Loader";
import "./loading-screen.scss"

const LoadingScreen = () => {
    return (
        <div id="loading_screen">
            <M365Loader width={120} height={120}/>
        </div>
    )
}

export default LoadingScreen
