import React from 'react';
import PropTypes from 'prop-types';
import './trendingCard.scss';
import styled from "styled-components";
import {UseSystemSettings} from "../../utils/SystemSettings/provider/system-settings";

const WorksheetCard = (props) => {
    const {settings} = UseSystemSettings();

    //-STYLED COMPONENTS
    const WorksheetItem = styled.div`
        height: ${props.cardHeight}px;
        background-image: url(${props.src})
    `

    return (

        <WorksheetItem className="worksheet-card">
            <h6 className="worksheet-title">
                {props.title}
            </h6>

            <div className="tab-container">
                <div className="grey-tab">
                    <div className="tab-icon" style={{backgroundImage: `url(${settings.ICONS.worksheet.svg})`}}/>
                    <div className="tab-title">{props.assetType}</div>
                </div>
            </div>
        </WorksheetItem>
    )
}

WorksheetCard.propTypes = {
    assetType: PropTypes.string.isRequired,
    cardHeight: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default WorksheetCard;

