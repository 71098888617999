import React from 'react';
import {useNavigate} from 'react-router-dom';
import './trendingCard.scss';
import {Track} from "../../utils/Segment/segment-methods";
import PropTypes from 'prop-types';

const CategoryCard = (props) => {
    let navigate = useNavigate()

    // track when a user clicks the category from the search page
    function RouteUser() {
        if (props.location === "menu") {
            Track('search_category', {category: props.categoryTitle})
        }
        Track(`Clicked Slide`,
            {
                Layout: 'Mobile',
                Link: `/category/${props.slug}`,
                Slider: 'Categories',
                Thumbnail: props.icon,
                Title: props.categoryTitle,
            }).then(() => {
            navigate(`/category/${props.link}`)
        })
    }

    return (
        <div onClick={RouteUser} className={`pointer category-link ${props.location === 'dash' ? '' : 'menu'}`}>
            <div className='category-card' style={{backgroundColor: `${props.backgroundColor}`}}>
                <div className="card-icon" style={{backgroundImage: `url(${props.icon})`}}/>
                <div className='card-title'>{props.categoryTitle}</div>
                <div className='chevron-right'/>
            </div>
        </div>
    )


}

CategoryCard.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    categoryTitle: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    icon: PropTypes.string,
}

export default CategoryCard;
