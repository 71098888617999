export const COURSE_ERROR = "COURSE_ERROR"
export const GET_SINGLE_COURSE = "GET_SINGLE_COURSE"
export const GET_ALL_COURSES = "GET_ALL_COURSES"
export const SET_ALL_COURSES = "SET_ALL_COURSES"
export const RESTART_COURSE = "RESTART_COURSE"
export const RESTART_COURSE_ERROR = "RESTART_COURSE_ERROR"
export const RESTART_COURSE_SUCCESS = "RESTART_COURSE_SUCCESS"
export const SAVE_COURSE_PROGRESS = "SAVE_COURSE_PROGRESS"
export const SET_LAST_LOADED_COURSE_UUID = "SET_LAST_LOADED_COURSE_UUID"
export const SET_SINGLE_COURSE = "SET_SINGLE_COURSE"

export const UPDATE_WATCHLIST_STATUS_COURSE = "UPDATE_WATCHLIST_STATUS_COURSE"
