import React from "react";
import styled from "styled-components";
import classNames from "classnames";
import {RemoveClass} from "../../../utils/StyleUtilities";
import {useFormContext} from 'react-hook-form';

const DotContainer = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 1000px;
    background-color: #fff;
    border: 1px solid #A7B2B8;

    &:hover {
        cursor: pointer;
    }
`
const InnerDot = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    transform: translate(-50%, -50%);
    border-radius: 1000px;
    background-color: transparent;

    &.active {
        background-color: #3CBCC3;
    }

`
const Answer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    label {
        line-height: 1.25rem;
    }
`

const Input = styled.input`
    width: 100%;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #A7B2B8;
`

const TextAreaInput = styled.textarea`
    width: 602px;
    height: 120px;
    border-radius: 4px;
    background: #FFFFFF;
    border: 1px solid #A7B2B8;
    @media screen and (max-width: 480px) {
        width: 100%;
    }
`

const TextAreaContainer = styled.div`
    margin-top: 27px;
    margin-bottom: 8px;
`


function AssignActiveClass(e, name) {
    RemoveClass('active')
    document.querySelector(`input[name=${name}]:checked`).checked = false;
    // document.getElementById(id).checked = true
    let el = e.currentTarget.querySelector('.fill');
    if (el) {
        el.classList.add('active');
    }
}


const RadioText = React.memo(({data, block}) => {

    const compClass = classNames({
        'option': true,
        [block.attributes.class]: block.attributes.class
    });

    const resetFormCheckNew = (e) =>{
        let clickedElement = e.currentTarget;
        let formElement = document.forms[0];
        // let formElement = document.getElementById(clickedElement.parentElement.parentElement.id);
        formElement.reset();
        clickedElement.checked = true;
    }

    // add on click to check form for checked, remove check, and add check to clicked

    return (
        <Answer className={compClass}>
            <input id={block.attributes.id} type="radio" {...block.register(data.name)} value={data.text} onClick={resetFormCheckNew} />
            <label htmlFor={block.attributes.id}>{data.text}</label>
        </Answer>

        // <Answer className={compClass} onClick={(e) => AssignActiveClass(e, data.name)}>
        //     <DotContainer className={`dot`}>
        //         <InnerDot className={`fill`}/>
        //     </DotContainer>
        //     <input value={data.text} id={block.attributes.id} type="radio" name={data.name}/>
        //     <label htmlFor={block.attributes.id}>{data.text}</label>
        // </Answer>
    )
})

// #2 & #3 check variables, try to identify when option is selected and normal radio is selected

const RadioInput = React.memo(({data, block}) => {
    // const [check, setCheck] = React.useState(false)
    const compClass = classNames({
        'option': true,
        [block.attributes.class]: block.attributes.class
    });

    const changeSelect = (e) => {
        // only occurs when input is clicked
        // check === false && setCheck(true)

        let otherOption;
        if(e.currentTarget.type === "text"){
            otherOption = e.currentTarget.parentElement.parentElement.children[0];
        } else {
            otherOption = e.currentTarget;
        }
        otherOption.checked = true;
    }

    // #1 on input click activate other

    return (
        <Answer className={compClass}>
            <input {...block.register(data.name)} id={block.attributes.id} type="radio" value={`other`}
                //    checked={check}
                   onClick={changeSelect}/>
            <label htmlFor={block.attributes.id}>
                <Input {...block.register('other')} type="text" placeholder="Other" onSelect={changeSelect}/>
            </label>
        </Answer>


        // <Answer className={compClass} onClick={(e) => AssignActiveClass(e, block.attributes.id)}>
        //     <DotContainer className={`dot`}>
        //         <InnerDot className={`fill`}/>
        //     </DotContainer>
        //     <Input name={data.name} type="text" placeholder="Other"/>
        // </Answer>
    )
});

const TextArea = React.memo(({block, data}) => {
    const compClass = classNames({
        'textarea': true,
        [block.attributes.class]: block.attributes.class
    });

    return (
        <TextAreaContainer className={compClass}>
            <div>{data.text}</div>
            <TextAreaInput {...block.register(data.name)}/>
        </TextAreaContainer>
    )
});

function FormButton({data, block}) {
    const compClass = classNames({
        'btn': true,
        [block.attributes.class]: block.attributes.class
    });
    const {getValues} = useFormContext()

    const executeClick = () => {
        block.functions[data.functionName](getValues())
    }

    return (
        <div className={compClass} onClick={() => executeClick()}>
            <div className="account-button_text__container">
                <div dangerouslySetInnerHTML={{__html: data.text}}
                     className="account-button_text"/>
            </div>
        </div>
    )
}

export {TextArea, RadioText, RadioInput, FormButton}
