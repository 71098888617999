import {takeEvery, fork, put, all, call} from "redux-saga/effects"

//Account Redux states
import {REGISTER_USER} from "./actionTypes"
import {registerUserSuccessful, registerUserFailed} from "./actions"
import {postRegister} from "../../../helpers/backend-helper";

// Is user register successful then direct plot user in redux.
function* registerUser({payload: {user}}) {
    // console.log('this is the register saga')
    try {

        const response = yield call(postRegister, user)
        yield put(registerUserSuccessful(response))

    } catch (error) {
        yield put(registerUserFailed(error))
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser)
}

function* RegisterSaga() {
    yield all([fork(watchUserRegister)])
}

export default RegisterSaga
