import React from 'react'
import classNames from "classnames"

function ContentInner({...props}) {
    return (
        <div className="profile-content-inner">
            <div className="profile-content-body">
                {props.children}
            </div>
        </div>
    )
}

function ProfileContent({content, className, ...props}) {
    const compClass = classNames({
        "profile-content": true,
        "profile-content-full": content === 'full-screen',
        "profile-content-stretch": content === 'stretch',
        [`${className}`]: className,
    });
    return (
        <div className={compClass}>
            {content === 'full-screen' && <ContentInner>{props.children}</ContentInner>}
            {content === 'stretch' ? <ContentInner>{props.children}</ContentInner> :
                content !== 'full-screen' && <div className={content || 'container-fluid'}>
                    <ContentInner>{props.children}</ContentInner>
                </div>
            }
        </div>
    )
}

export default ProfileContent
