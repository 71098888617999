import * as TYPES from './actionTypes';

export const cancelSubscription = (payload, navigate) => ({
    type: TYPES.CANCEL_SUBSCRIPTION,
    payload: { payload, navigate },
});

export const changeSubscriptionSuccess = (payload) => ({
    type: TYPES.CHANGE_SUBSCRIPTION_SUCCESS,
    payload,
});

export const changeSubscriptionFail = (payload) => ({
    type: TYPES.CHANGE_SUBSCRIPTION_FAIL,
    payload,
});

export const updateAccountMessage = (payload) => ({
    type: TYPES.UPDATE_ACCOUNT_MESSAGE,
    payload,
});

export const restartSubscription = (payload) => ({
    type: TYPES.RESTART_SUBSCRIPTION,
    payload,
});
export const swapMembership = (payload) => ({
    type: TYPES.SWAP_MEMBERSHIP,
    payload,
});

export const updateSubscriptionServerResponse = (payload) => ({
    type: TYPES.CHANGE_SUBSCRIPTION_RESPONSE,
    payload,
});

export const getHistory = (skip = 0, limit = 100, complete = 1) => ({
    type: TYPES.GET_HISTORY,
    payload: { skip, limit, complete },
});

export const setHistory = (payload) => ({
    type: TYPES.SET_HISTORY,
    payload,
});

export const removeVideoFromHistory = (uuid, assetType) => ({
    type: TYPES.REMOVE_VIDEO_FROM_HISTORY,
    uuid,
    assetType,
});

export const getContinueWatching = (skip = 0, limit = 100, complete = 0) => ({
    type: TYPES.GET_CONTINUE_WATCHING,
    payload: { skip, limit, complete },
});

export const setContinueWatching = (payload) => ({
    type: TYPES.SET_CONTINUE_WATCHING,
    payload,
});

export const getNotificationPreferences = () => ({
    type: TYPES.GET_NOTIFICATION_PREFERENCES,
});

export const setNotificationPreferences = (payload) => ({
    type: TYPES.SET_NOTIFICATION_PREFERENCES,
    payload,
});

export const updateNotificationPreferences = (payload) => ({
    type: TYPES.UPDATE_NOTIFICATION_PREFERENCES,
    payload,
});

export const getMembershipPerks = () => ({
    type: TYPES.GET_MEMBERSHIP_PERKS,
});

export const setMembershipPerks = (payload) => ({
    type: TYPES.SET_MEMBERSHIP_PERKS,
    payload,
});

export const getFAQ = () => ({
    type: TYPES.GET_FAQ,
});

export const setFAQ = (payload) => ({
    type: TYPES.SET_FAQ,
    payload,
});

export const getNakedConversations = (payload) => ({
    type: TYPES.GET_NAKED_CONVERSATIONS,
    payload,
});

export const setNakedConversations = (payload) => ({
    type: TYPES.SET_NAKED_CONVERSATIONS,
    payload,
});

export const getMemberStories = (payload) => ({
    type: TYPES.GET_MEMBER_STORIES,
    payload,
});

export const setMemberStories = (payload) => ({
    type: TYPES.SET_MEMBER_STORIES,
    payload,
});

export const validateCouponCode = (payload) => ({
    type: TYPES.POST_COUPON_CODE,
    payload,
});

export const applyMemberCoupon = (coupon, location) => ({
    type: TYPES.APPLY_MEMBER_COUPON,
    payload: { coupon, location },
});

export const setCoupon = (payload) => ({
    type: TYPES.SET_COUPON,
    payload,
});

export const getInfoDrawer = () => ({
    type: TYPES.GET_INFO_DRAWER,
});
export const setInfoDrawer = (payload) => ({
    type: TYPES.SET_INFO_DRAWER,
    payload,
});

export const setAccountSettingsMessage = (payload) => ({
    type: TYPES.SET_ACCOUNT_SETTINGS_MESSAGE,
    payload,
});

export const getCancellationOffer = (payload) => ({
    type: TYPES.GET_CANCELLATION_OFFER,
    payload,
});

export const setCancellationOffer = (payload) => ({
    type: TYPES.SET_CANCELLATION_OFFER,
    payload,
});

export const getSubscriptionPlanType = () => ({
    type: TYPES.GET_SUSBSCRIPTION_PLAN_TYPE,
});

export const setSubscriptionPlanType = (payload) => ({
    type: TYPES.SET_SUSBSCRIPTION_PLAN_TYPE,
    payload,
});
