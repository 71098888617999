/**
 * Braintree Dropin component presented during re-registration
 */
import React, {useEffect, useRef, useState} from "react"
import dropin from "braintree-web-drop-in"
import axios from "axios";
import ScaleLoader from "react-spinners/ScaleLoader";
import styled from "styled-components";
import * as STYLES from "../../Templates/Global/default-styles";
import {useStateMachine} from "little-state-machine";
import {CouponField} from "./coupon-field";
import {useForm} from "react-hook-form";
import {Alert} from "../Alert";


const BraintreeButton = styled.button`
  ${STYLES.BRAINTREE_FORM_BUTTON}
  &.inactive {
    background: #2D5F62;
    opacity: .5;
  }
`
const ButtonText = styled.div`
  ${STYLES.CREATE_ACCOUNT_BTN}
`

const BraintreeContainer = styled.div`
  max-width: 90%;
  margin: 20px auto 0;
`

/**
 * THIS IS THE DROPIN FOR AN RETURNING USER
 * @type {React.NamedExoticComponent<{readonly user?: *, readonly setBraintreeNonce?: *}>}
 */
export const BraintreeDropinReturningUser = React.memo(({data, user}) => {
    const [paymentError, setPaymentError] = React.useState(null);
    const [braintreeInstance, setBraintreeInstance] = React.useState(undefined);
    const [braintreeClientToken, setBraintreeClientToken] = React.useState(null)

    const [paymentButtonLoader, setPaymentButtonLoader] = useState(true);
    const [activeButton, setActiveButton] = useState(false)
    const BTDropin = React.useRef();


    const {register, handleSubmit, setValue, getValues, formState: {isValid}} = useForm({mode: 'onChange'});

    const paymentForm = useRef(null);
    const registrationForm = useRef(null);


    const {state} = useStateMachine();

    React.useEffect(() => {
        GetBraintreeClientToken();
    }, []);

    useEffect(() => {
        setPaymentError(state.data.coupon.errorMessage)
    }, [state.data.coupon])

    React.useEffect(() => {
        if (braintreeClientToken && JSON.stringify(user) !== '{}') {
            BTDropin.current.innerHTML = '';


            if (braintreeInstance) {
                braintreeInstance.teardown().then(() => {
                    initializeBraintree();
                });
            } else {
                initializeBraintree();
            }
        }

    }, [braintreeClientToken, user])


    React.useEffect(() => {
        if (braintreeInstance) {
            braintreeInstance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                if (requestPaymentMethodErr) {
                    setPaymentError(requestPaymentMethodErr)
                }
            });

            setActiveButton(true)
        }
    }, [braintreeInstance])


    const initializeBraintree = () => {
        BTDropin.current.innerHTML = '';
        dropin.create({
            authorization: braintreeClientToken,
            container: '#braintree-dropin-div',
            paypal: {
                flow: 'vault'
            },
            googlePay: {
                googlePayVersion: 2,
                merchantId: "BCR2DN4TW2LIRKCN",
                transactionInfo: {
                    totalPriceStatus: 'FINAL',
                    totalPrice: state.data.subscription.price ? state.data.subscription.price.toString() : "",
                    currencyCode: 'USD'
                },
                allowedPaymentMethods: [{
                    type: 'CARD',
                    parameters: {
                        billingAddressRequired: true,
                        billingAddressParameters: {
                            format: 'FULL'
                        }
                    }
                }]
            }
        }, function (error, instance) {
            if (error) {
                setPaymentError(error);
            } else {
                setBraintreeInstance(instance);
            }
        });
    };

    async function GetBraintreeClientToken() {
        await axios.get('/braintree-client-token').then(response => {
            setBraintreeClientToken(response.data.token)
        }).catch(() => {
            setPaymentError('We are having trouble getting your payment information')
        })
    }


    async function restartSubscription(data) {
        if (!data.payment_method_nonce) {
            return setPaymentError('We were unable to connect using this payment. Please refresh and try again.')
        }

        if (data.promo_code) {
            data.coupon = data.promo_code;
        }

        data.subscription = state.data.subscription;
        data.uuid = "returning-user";
        data.first_name = "Returning";
        data.last_name = "User";
        data.activeTest = "Returning User";

        await axios.post('/register', data).then((response) => {
            if (response.data.status === "success") {
                window.location.replace("/browse")
            }
        }).catch((error) => {
            setActiveButton(true)
            setPaymentButtonLoader(true)
            setPaymentError(error.response.data.message)
        })
    }

    const onPaymentCompleted = (nonce) => {
        setValue('payment_method_nonce', nonce)
        setActiveButton(false)
        registrationForm.current.dispatchEvent(new Event("submit", {cancelable: true, bubbles: true}))
    }


    return (
        <React.Fragment>
            <BraintreeContainer id={data.attributes.id} ref={paymentForm} properties={data.properties}>

                {
                    paymentError &&
                    (
                        paymentError.message ?
                            <Alert data={{text: paymentError.message, properties: {}, className: "alert-danger"}}/>
                            :
                            <Alert data={{text: paymentError, properties: {}, className: "alert-danger"}}/>
                    )
                }


                <form ref={registrationForm} onSubmit={handleSubmit(restartSubscription)}>
                    <input type="hidden" {...register("payment_method_nonce")}/>
                    <div id="braintree-dropin-div" ref={BTDropin}></div>


                    {
                        data.attributes._displayCouponField &&
                        <CouponField
                            register={register}
                            setValue={setValue}
                            getValues={getValues}
                        />
                    }


                </form>
                <BraintreeButton
                    type="primary"
                    disabled={!braintreeInstance || !activeButton}
                    className={activeButton ? 'active' : 'inactive'}
                    onClick={() => {
                        setPaymentButtonLoader(false)
                        setActiveButton(false)
                        if (braintreeInstance) {
                            braintreeInstance.requestPaymentMethod(
                                (requestPaymentMethodErr, payload) => {
                                    if (requestPaymentMethodErr) {
                                        setPaymentError(requestPaymentMethodErr.message)
                                    } else {
                                        onPaymentCompleted(payload.nonce)
                                    }
                                });
                        }
                    }}>
                    {
                        <ButtonText>
                            {
                                (paymentButtonLoader) ? <span>Continue</span> :
                                    <ScaleLoader height="10px" color="#fff"/>
                            }
                        </ButtonText>
                    }
                </BraintreeButton>

            </BraintreeContainer>
        </React.Fragment>

    )
});
