import React, {useState, useRef} from "react";
import NakedConversations from "../Sidebar/Submenu/naked-conversations";

import "./account-extras-2022.scss";
import ProfileLayout from "../ProfileLayout";
import {HandleRouteChange} from "../../../utils/Segment/segment-methods";

const ExtrasScreen = () => {
    const pageTitle = "Extras"
    const [isNakedConvoShown, setIsNakedConvoShown] = useState(true);
    const [isMemberStoriesShown, setIsMemberStoriesShown] = useState(false);

    const nakedConversations = useRef();
    const memberStories = useRef();


    /**
     * Send page view to Mixpanel
     */
    React.useEffect(() => {
        HandleRouteChange({pathname: '/account/extras'}, {email: null}, 'page-view', pageTitle)
    }, [])

    const changeDisplay = (e) => {
        let chosenLink = e.currentTarget.dataset.name;

        setIsNakedConvoShown(false);
        setIsMemberStoriesShown(false);

        let shownArrow = document.getElementsByClassName("show-arrow");
        shownArrow = shownArrow[0];
        shownArrow.classList.remove("show-arrow");

        let activeLink = document.getElementsByClassName("active-text_link");
        activeLink = activeLink[0];
        activeLink.classList.remove("active-text_link");

        switch (chosenLink) {
            case "nakedConversations":
                setIsNakedConvoShown(true);
                nakedConversations.current.children[0].classList.add("show-arrow");
                nakedConversations.current.children[1].classList.add("active-text_link");
                break;
            case "memberStories":
                setIsMemberStoriesShown(true);
                memberStories.current.children[0].classList.add("show-arrow");
                memberStories.current.children[1].classList.add("active-text_link");
                break;
        }
    }


    return (
        <ProfileLayout title={pageTitle} content="component-container">
            <div id="extras_2022">
                <div className="link-column">

                    <h1 className="font-bold font-primary">{pageTitle}</h1>
                    <div className="link-container">
                        <div className="membership-link" ref={nakedConversations} data-name="nakedConversations"
                             onClick={changeDisplay}>
                            <div className="arrow-right show-arrow"></div>
                            <div className="link-text active-text_link">Naked Conversations</div>
                        </div>
                        <div className="membership-link" ref={memberStories} data-name="memberStories"
                             onClick={changeDisplay}>
                            <div className="arrow-right"></div>
                            <div className="link-text">Member Stories</div>
                        </div>
                    </div>
                </div>
                <div className="membership-display">
                    {isNakedConvoShown && (
                        <div className="page-displayed" data-name="nakedConversations">
                            <NakedConversations/>
                        </div>
                    )}

                    {isMemberStoriesShown && (
                        <div className="page-displayed" data-name="memberStories">
                            <NakedConversations/>
                        </div>
                    )}
                </div>
            </div>
        </ProfileLayout>
    )
}

export default ExtrasScreen;
