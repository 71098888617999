import React from "react";
import GeneralButton from "../buttons/generalButton";
import WhiteCopyIcon from "../../assets/images/white-copy-icon.svg";

import "./perk-coupon.scss";

import styled from "styled-components";
import {Track} from "../../utils/Segment/segment-methods";
import PropTypes from 'prop-types';

const PerkCoupon = (props) => {

    const Coupon = styled.div`
        background-image: url(${props.perk.background_image})
    `

    function PerkSelected() {
        Track('membership_perks_selected', {title: props.perk.title})
        props.perk.type === "link" ? userClickedLink(props.perk.link_meta.link) : props.copyCode(props.perk.code)

    }

    const userClickedLink = (url) => {
        window.open(url)
    }

    return (
        <Coupon className="perk-coupon_container">
            <div className="coupon-title">
                {props.perk.title}
            </div>
            <div className="coupon-description" dangerouslySetInnerHTML={{__html: props.perk.description}}/>
            <div className="coupon-button_container">
                <GeneralButton
                    buttonText={props.perk.link_meta.button_text ?? "Click to Copy"}
                    textColor={"#fff"}
                    backgroundColor={"#2D5F62"}
                    icon={props.perk.type === "link" ? null : WhiteCopyIcon}
                    onClick={() => PerkSelected()}
                />
            </div>
            <div className="coupon-code">
                {props.perk.code}
            </div>
        </Coupon>
    )

}


PerkCoupon.propTypes = {
    perk: PropTypes.object.isRequired,
}

export default PerkCoupon;
