import React from "react";
import MarriagePickAsset from "../../MarriagePickAssets";
import GeneralButton from "../../buttons/generalButton";
import PropTypes from 'prop-types';
import "./my-marriage-header.scss";

const MyMarriageHeader = (props) => {

    return (
        <div className="my-marriage_header__container">
            <div className="my-marriage_header__content">
            <h2 className="my-marriage_title">Marriage Checkup Score</h2>
            <div className="pick-history">
                <MarriagePickAsset
                    isCurrentScore={props.isCurrentScore}
                    isScoreHistory={props.isScoreHistory}
                    showDrawer={props.showDrawer}
                    user={props.user}
                />
            </div>
            <div className="last-taken_desktop">
                Last Taken: {props.lastTaken}
            </div>
            <div className="take-checkup">
                <GeneralButton
                    icon={""}
                    buttonText={"Take Marriage365 Checkup"}
                    backgroundColor={"#2D5F62"}
                    onClick={props.buttonFunction}
                />
            </div>
            <div className="last-taken">
                Last Taken: {props.lastTaken}
            </div>
            </div>

        </div>
    )

}

MyMarriageHeader.propTypes = {
    buttonFunction: PropTypes.func.isRequired,
    isCurrentScore: PropTypes.number,
    isScoreHistory: PropTypes.arrayOf(PropTypes.object),
    lastTaken: PropTypes.element,
    showDrawer: PropTypes.func,
    user: PropTypes.object,
}

export default MyMarriageHeader;
