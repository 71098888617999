import React from 'react'
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';


import {useLayout, useLayoutUpdate} from '../../LayoutProvider';
import {ChevronLeft} from "react-feather";


function ToggleSidebar({variant}) {
    const layout = useLayout();
    const layoutUpdate = useLayoutUpdate();

    const btnClass = classNames({
        'btn-icon d-lg-none d-inline-flex sidebar-toggle': true,
        'active': layout.sidebarActive
    })

    return (
        <div className="profile-sidebar-toggle me-n1">
            <Button size="md" variant={variant || 'no-hover'} onClick={layoutUpdate.sidebarMobile}
                    className={btnClass}>
                <ChevronLeft/>
            </Button>
        </div>
    )
}

export default ToggleSidebar;
