import { isEmpty } from 'lodash';
import React from 'react';

import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import classNames from "classnames";
import ProfileLayout from "../ProfileLayout";
import ProfileSubMenu from "../Data/ProfileSubMenu";

import LoadingScreen from "../../../components/LoadingScreen";

import {RemoveClass} from "../../../utils/StyleUtilities";

import './my-membership-2022.scss';

import PropTypes from 'prop-types';
import {HandleRouteChange} from "../../../utils/Segment/segment-methods";
import {useLocation} from "react-router-dom";

import { getSubscriptionPlanType } from '../store/actions';
import {
    SUBSCRIPTION_TYPE_PLANS_INFO_PROPTYPES,
} from '../../../global/propTypesConstants';

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getSubscriptionPlanType,
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    subscriptionPlans: appState.AccountSettings.subscriptionPlans,
    user: appState.User.data,
});

const MyMembershipScreen = ({
    getSubscriptionPlanType,
    subscriptionPlans,
    user,
}) => {
    const pageTitle = "My Membership"
    // set the active tab to a page that can be accessed by both primary and secondary users
    const [activeSubLinkIndex, setActiveSubLinkIndex] = React.useState(3);
    // Dynamically create references for each item in the submenu array.
    const elementRefs = React.useRef(ProfileSubMenu.items.map(() => React.createRef()))
    const location = useLocation();

    /**
     * Send page view to Mixpanel
     */
    React.useEffect(() => {

        if (JSON.stringify(user) !== '{}') {
            HandleRouteChange({pathname: '/account/subscription-payment'}, user, 'page-view', pageTitle)
        }
    }, [user])

    /**
     * This is not the most elegant way to handle the active tab on page load, but it works for the moment since
     * the notification page is the only one we directly link to.
     */
    React.useEffect(() => {
        if(location.search === '?tab=notification'){
            setActiveSubLinkIndex(1);
        } else if(location.search === '?tab=partner-pairing'){
            setActiveSubLinkIndex(0);
        }  else if(location.search === '?tab=subscription-payment'){
            setActiveSubLinkIndex(3);
        } else if(location.search === '?tab=delete-account'){
            setActiveSubLinkIndex(4);
        } else if(location.search === '?tab=change-password'){
            setActiveSubLinkIndex(2);
        }
    },[location])

    // Get all available subscription plans
    React.useEffect(() => {
        if (isEmpty(subscriptionPlans)) {
            getSubscriptionPlanType();
        }
        window.scroll(0, 0);
    }, [
        subscriptionPlans,
    ]);


    const changeDisplay = (reference, subLinkIndex) => {
        // set the index to the active tab to display
        setActiveSubLinkIndex(subLinkIndex)
        // remove the active classes from all elements in document
        RemoveClass('show-arrow')
        RemoveClass('active-text_link')
    }

    function SubMenuList({className, ...props}) {
        const compClass = classNames({
            'sub-menu': true,
            [className]: className
        })
        return (
            <ul className={compClass}>
                {props.children}
            </ul>
        )
    }

    function SubMenuItem({className, displayToPrimaryOnly = true, ...props}) {
        const compClass = classNames({
            'sub-menu-item': true,
            [className]: className
        });
        return displayToPrimaryOnly && (
            <li className={compClass}>
                {props.children}
            </li>
        )
    }

    function SubMenuItemLink({text, reference, index}) {
        return (
            <div className="membership-link" ref={reference} onClick={() => changeDisplay(reference, index)}>
                <div className={`${index === activeSubLinkIndex && 'show-arrow'} arrow-right`}></div>
                <div className={`${index === activeSubLinkIndex && 'active-text_link'} link-text `}>
                    {text}
                </div>
            </div>
        )
    }


    return (
        <ProfileLayout title={pageTitle} content="component-container">
            {
                JSON.stringify(user) === '{}' ?
                    <LoadingScreen/>
                    :
                    <div id="my-membership_2022">
                        <div className="link-column">
                            <h1 className="font-bold font-primary">{pageTitle}</h1>
                            <div className="link-container">
                                <SubMenuList>
                                    {
                                        (ProfileSubMenu.items || []).map((item, index) => (
                                                <SubMenuItem key={index} displayToPrimaryOnly={item.displayToPrimaryOnly && user.is_primary}>
                                                    <SubMenuItemLink index={index}
                                                                     reference={elementRefs.current[index]}
                                                                     text={item.text}/>
                                                </SubMenuItem>
                                            )
                                        )
                                    }
                                </SubMenuList>
                            </div>
                        </div>
                        <div className="membership-display">
                            {
                                (ProfileSubMenu.items || []).map((item, index) => (
                                        index === activeSubLinkIndex && (
                                            React.createElement(item.component, {key: index})
                                        )
                                    )
                                )
                            }
                        </div>
                    </div>
            }
        </ProfileLayout>
    )
}

MyMembershipScreen.propTypes = {
    getSubscriptionPlanType: PropTypes.func,
    subscriptionPlans: SUBSCRIPTION_TYPE_PLANS_INFO_PROPTYPES.isRequired,
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(MyMembershipScreen)
