import React from 'react';
import { Link } from 'react-router-dom';
import WhiteCopy from '../../assets/images/white-copy-icon.svg';
import GeneralButton from '../buttons/generalButton';
import DownloadArrow from '../../assets/images/download-arrow-white.svg';
import WorksheetIcon from '../../assets/images/worksheet.svg';
import PropTypes from 'prop-types';
import './courseDetails.scss';

const CourseDetails = (props) => {

    const openLink = () => {
        props.sendProgress();
        window.open(props.resourceLink.url)
    }

    // console.log("all lessons", props.allLessons);

    return (
        <div className='course-video_details'>
            <div className='current-course_content'>
                <div className="lesson-title">{props.name}</div>
                <div className='course-description' dangerouslySetInnerHTML={{ __html: props.description }}></div>
                {props.isResource && !props.connectingQuestions && (<div className="worksheet-link_container">
                    <div >
                        <GeneralButton
                            buttonText={"View Worksheet"}
                            icon={WorksheetIcon}
                            backgroundColor={"#2D5F62"}
                            textColor="#fff"
                            onClick={openLink}
                        />
                    </div>
                    {/* <a href={props.resourceLink.download} download>
                        <GeneralButton
                        buttonText={"Download Worksheet"}
                        icon={DownloadArrow}
                        backgroundColor={"#2D5F62"}
                        textColor="#fff"
                        onClick={props.sendProgress}
                    />
                    </a> */}

                </div>)}
                {props.connectingQuestions && (
                    <div className="connecting-question_container">
                        <a href={props.connectingQuestions.url} target="_blank">
                            <GeneralButton
                                buttonText={"Connecting Questions"}
                                icon={WhiteCopy}
                                backgroundColor={"#2D5F62"}
                                textColor="#fff"
                                onClick={() => { return }}
                            />
                        </a>
                    </div>)}
            </div>
            <div className="next-course_container">
                <div className="course-progress_container">
                    <div className="course-subtitle">COURSE</div>
                    <div className="course-progress">{props.stepNumber}/{props.allLessons.length}</div>
                </div>
                <div className='next-course_section_title'>{props.courseName}</div>
                <div className='next-course' ref={props.courseContainer}>
                    {props.allLessons}
                </div>
            </div>
        </div>
    )
}

CourseDetails.propTypes = {
    allLessons: PropTypes.arrayOf(PropTypes.element).isRequired,
    connectingQuestions: PropTypes.objectOf(PropTypes.string),
    courseContainer: PropTypes.object,
    courseName: PropTypes.string.isRequired,
    course_uuid: PropTypes.string.isRequired,
    description: PropTypes.string,
    isResource: PropTypes.bool,
    lesson_uuid: PropTypes.string.isRequired,
    name: PropTypes.string,
    onEnded: PropTypes.func,
    resourceLink: PropTypes.string,
    sendProgress: PropTypes.func,
    status: PropTypes.string,
    stepNumber: PropTypes.number,
    uuid: PropTypes.string.isRequired,
}

export default CourseDetails;
