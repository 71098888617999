import React, {useEffect} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import './pick-quiz.scss';
import {HandleRouteChange, Track} from "../../utils/Segment/segment-methods";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {captureException} from "@sentry/react";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
});

const TOTAL_INTRO_QUIZ_QUESTION = 7;
const TOTAL_FULL_QUIZ_QUESTION = 28;

const PickQuiz = ({user}) => {
    let location = useLocation()
    let navigate = useNavigate()

    // Fetch on boarding flow questions list
    const questionStepInfo = user?.onboarding_flow?.steps?.find((step) => step.type === 'marriage_quiz');
    const demographicQuestionList = questionStepInfo?.data?.questions?.filter((question) => question.question_type === 'preassessment');

    // Check if preassesment question is already completed
    const isPreAssessmentComplete = user?.preassessment_complete ?? false;

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Checkup Selection')
        }
    }, [user, location])


    function LogActionRoutUser(quiz_type) {
        let segmentData, route

        if (quiz_type === "intro") {
            route = '/quiz/intro'
            segmentData = {checkup_type: 'intro', first_checkup: true}
            try {
                Track('checkup_started', segmentData)
            } catch (error){
                captureException(error)
            }


        } else {
            route = '/quiz/full'
            segmentData = {checkup_type: 'full', first_checkup: true}
            try {
                Track('checkup_started', segmentData)
            } catch (error){
                captureException(error)
            }
        }

        navigate(route)

    }

    const newTotalQuizIntroQuestion = isPreAssessmentComplete ? TOTAL_INTRO_QUIZ_QUESTION : TOTAL_INTRO_QUIZ_QUESTION + (demographicQuestionList?.length ?? 0);
    const newTotalQuizFullQuestion = isPreAssessmentComplete ? TOTAL_FULL_QUIZ_QUESTION : TOTAL_FULL_QUIZ_QUESTION + (demographicQuestionList?.length ?? 0);


    return (
        <div className="pick-quiz_page">
            <div className="m365-logo"/>
            <div className="pick-quiz_content">
                <h1 className="pick-title">Get Started...</h1>
                <div className="pick-text">
                    Choose your checkup... answer questions so we can suggest videos and topics for you to focus on.
                </div>
                <div className='pick-card_container'>
                    <div onClick={() => LogActionRoutUser('intro')} className="pointer">
                        <div className='quick-quiz_container choice-container'>
                            <div className="quick-icon quiz-icon">

                            </div>
                            <h2 className="quiz-title">
                                Quick Intro Quiz
                            </h2>
                            <div className="quiz-question_length">
                                <div className="question-icon"/>
                                <div className="question-length">
                                    {`${newTotalQuizIntroQuestion} Questions`}
                                </div>
                            </div>
                            <div className="quiz-time_length">
                                <div className="time-icon"/>
                                <div className="time-length">
                                    30 seconds
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => LogActionRoutUser('full')} className="pointer">
                        <div className='full-quiz_container choice-container'>
                            <div className="full-icon quiz-icon">

                            </div>
                            <h2 className="quiz-title">
                                Full Marriage365 Checkup
                            </h2>
                            <div className="quiz-question_length">
                                <div className="question-icon"/>
                                <div className="question-length">
                                {`${newTotalQuizFullQuestion} Questions`}
                                </div>
                            </div>
                            <div className="quiz-time_length">
                                <div className="time-icon"/>
                                <div className="time-length">
                                    5 minutes
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )

}

PickQuiz.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(PickQuiz)

