import React from 'react';
import TemplateRenderer from "./@splitty/Renderer/template-renderer";
import history from './utils/history'
import {AuthProvider} from "./react-authentication";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {StateMachineProvider} from "little-state-machine";
import {SystemSettingsProvider} from "./utils/SystemSettings/provider/system-settings";

// IMPORT PAGES
import AllWorksheets from "./pages/Worksheet/all-worksheets";
import Category from './pages/CoreCategory/category';
import ChallengeVideoPage from './pages/challenge-video';
import Checkup from './pages/Checkup/checkup';
import CompleteRegistration from "./pages/complete-registration";
import ContinueWatchingScreen from "./pages/Account/Screens/continue-watching";
import CourseVideoPage from './pages/course-video';
import Dashboard from "./pages/Dashboard/dashboard";
import ExtrasScreen from "./pages/Account/Screens/extras";
import FaqContent from "./pages/Account/Screens/faq";
import FirstRegistrationPage from './pages/Auth/Register/registration-page';
import ListMarriageQuickies from "./pages/MarriageQuickies/list"
import LoadingScreen from "./components/LoadingScreen";
import Login from './pages/Auth/Login/login';
import MembershipPerksScreen from "./pages/Account/Screens/membership-perks";
import MyMarriage from './pages/MyMarriage/my-marriage';
import MyMarriageCategory from './pages/MyMarriage/my-marriage-category';
import MyMembershipScreen from "./pages/Account/Screens/my-membership";
import Notifications from './pages/notifications';
import PickQuiz from './pages/Checkup/pick-quiz';
import PodcastLandingPage from './pages/Podcast';
import RedeemPage from './pages/redeem-page';
import RenewSubscription from "./pages/Settings/settings-renew-subscription";
import ResetPassword from './pages/reset-password-page';
import SearchPage from './pages/search-page';
import SelectEntry from "./pages/Auth/select-entry";
import SingleChallenge from './pages/single-challenge';
import SingleCourse from './pages/Courses/single-course';
import SingleVideo from './pages/Video/single-video';
import SingleWorksheet from './pages/Worksheet/single-worksheet';
import StartCheckup from "./pages/Checkup/start-checkup";
import WatchHistoryScreen from "./pages/Account/Screens/watch-history";
import WatchlistScreen from "./pages/Account/Screens/watchlist";
import WelcomePage from './pages/welcome-page';
import WelcomeVideoPage from './pages/welcome-video';
import PageRenderer from "./@splitty/Renderer/page-renderer";
import AppSelection from "./pages/AppSelection";
import AllVideos from './pages/Video/all-videos';
import AllCourses from './pages/Courses/all-courses';
import AppPageSelection from "./pages/AppSelection/PageSelection";
import RegisterPartnerWithInviteCode from "./pages/Auth/Register/register-partner-with-invite-code";

// import ResetPasswordTemplate from './onBoarding/auth/forgotPassword/resetPasswordTemplate';
// import ForgotPasswordTemplate from './onBoarding/auth/forgotPassword/forgotPasswordTemplate';
// import WelcomeTemplate from './onBoarding/preAuthTemplate/welcomeTemplate';
// import LoginTemplate from './onBoarding/auth/login/loginTemplate';
// import RegistrationTemplate from './onBoarding/auth/register/registrationTemplate';
// import PartnerPairingIntroTemplate from './onBoarding/partnerPairing/partnerPairingIntroTemplate';
// import PartnerPairing from './onBoarding/partnerPairing/partnerPairing';
// import QuizIntroTemplate from './onBoarding/quiz/quizIntro';
// import VerifyEmailCodeTemplate from './onBoarding/auth/forgotPassword/verifyEmailCodeTemplate';
// import QuizQuestions from './onBoarding/quiz/quizQuestions';

export default function AppRoutes() {

    return (

        <BrowserRouter history={history}>
            <React.Suspense fallback={<div id="loading_screen">
                <LoadingScreen width={120} height={120}/></div>}>
                <Routes>
                    {/*-START UNAUTHENTICATED ROUTES-*/}
                    <Route path="/checkout/:checkout_id/:campaign_slug" element={<StateMachineProvider><TemplateRenderer/></StateMachineProvider>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register-partner" element={<RegisterPartnerWithInviteCode/>}/>
                    <Route path="/password/reset" element={<ResetPassword/>}/>
                    <Route path="/redeem" element={<RedeemPage/>}/>
                    <Route path="/register" element={<FirstRegistrationPage/>}/>
                    <Route path="/_a/click" element={<AppSelection/>}></Route>
                    <Route path="/_p/click" element={<AppPageSelection/>}></Route>
                    {/*<Route path="/static-checkout/:checkout_id" element={<SecondRegistrationPage/>}/>*/}
                    <Route path="/select-entry" element={<SelectEntry/>}/>
                    {/* The implementation of this new onboarding feature is on hold for now. This can be brought back in the future.
                    <Route path="/onboarding/welcome" element={<WelcomeTemplate/>}/>
                    <Route path="/onboarding/login" element={<LoginTemplate/>}/>
                    <Route path="/onboarding/register" element={<RegistrationTemplate/>}/>
                    <Route path="/onboarding/reset-password" element={<ResetPasswordTemplate/>}/>
                    <Route path="/onboarding/verify-email-code" element={<VerifyEmailCodeTemplate/>}/>
                    <Route path="/onboarding/forgot-password" element={<ForgotPasswordTemplate/>}/>
                    <Route path="/onboarding/partner-pairing/intro" element={<PartnerPairingIntroTemplate/>}/>
                    </*<Route path="/onboarding/partner-pairing" element={<PartnerPairingMainTemplate/>}/>
                    */}


                    {/*-START AUTHENTICATED ROUTES-*/}
                    <Route element={<AuthProvider><SystemSettingsProvider/></AuthProvider>}>
                        <Route path="/account" element={<MyMembershipScreen/>}/>
                        <Route path="/account/continue-watching" element={<ContinueWatchingScreen/>}/>
                        <Route path="/account/extras" element={<ExtrasScreen/>}/>
                        <Route path="/account/faqs" element={<FaqContent/>}/>
                        <Route path="/account/my-history" element={<WatchHistoryScreen/>}/>
                        <Route path="/account/my-membership-perks" element={<MembershipPerksScreen/>}/>
                        <Route path="/account/watchlist" element={<WatchlistScreen/>}/>
                        <Route path="/browse" element={<Dashboard/>}/>
                        <Route path="/category/:slug" element={<Category/>}/>
                        <Route path="/challenge/:challenge_uuid" element={<SingleChallenge/>}/>
                        <Route path="/checkup" element={<MyMarriage/>}/>
                        <Route path="/complete-registration" element={<CompleteRegistration/>}/>
                        <Route path="/course/:course_uuid" element={<SingleCourse/>}/>
                        <Route path="/get-challenge-lesson/:challenge_uuid/:lesson_uuid" element={<ChallengeVideoPage/>}/>
                        <Route path="/get-course-lesson/:course_uuid/:lesson_uuid" element={<CourseVideoPage/>}/>
                        <Route path="/my-marriage/:category_id" element={<MyMarriageCategory/>}/>
                        <Route path="/notifications" element={<Notifications/>}/>
                        <Route path="/pick-quiz" element={<PickQuiz/>}/>
                        <Route path="/podcast" element={<PodcastLandingPage/>}/>
                        <Route path="/profile" element={<MyMembershipScreen/>}/>
                        <Route path="/quiz/:quiz_type" element={<Checkup/>}/>
                        <Route path="/read/:asset_id" element={<SingleWorksheet/>}/>
                        <Route path="/renew-subscription" element={
                            <StateMachineProvider>
                                <PageRenderer pageID={`a3c4b0f2-e7bb-40b6-92c4-7f15b570771a`}/>
                            </StateMachineProvider>}
                        />
                        <Route path="/search" element={<SearchPage/>}/>
                        <Route path="/start-assessment" element={<StartCheckup/>}/>
                        <Route path="/tag" element={<ListMarriageQuickies/>}/>
                        <Route path="/watch/:asset_id" element={<SingleVideo/>}/>
                        <Route path="/welcome" element={<WelcomePage/>}/>
                        <Route path="/welcome-page-video" element={<WelcomeVideoPage/>}/>
                        <Route path="/worksheets" element={<AllWorksheets/>}/>
                        <Route path="/all-videos" element={<AllVideos/>}/>
                        <Route path="/all-courses" element={<AllCourses/>}/>
                        {/* The implementation of this new onboarding feature is on hold for now. This can be brought back in the future.
                        <Route path="/onboarding/partner-pairing/intro" element={<PartnerPairingIntroTemplate/>}/>
                        <Route path="/onboarding/partner-pairing" element={<PartnerPairing/>}/>
                        <Route path="/onboarding/take-quiz" element={<QuizIntroTemplate/>}/>
                        <Route path="/onboarding/quiz-questions" element={<QuizQuestions/>}/>
                        */}
                    </Route>
                </Routes>
            </React.Suspense>
        </BrowserRouter>
    );

}
