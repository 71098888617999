import * as TYPES from './actionTypes.js';

const initial_state = {
    authenticated: false,
    isFetching: false,
    data: {},
    error: null,
    successMessage: null,
};
const user = (state = initial_state, action) => {
    switch (action.type) {
    case TYPES.GET_USER:
        return {
            ...state,
            isFetching: true,
        };

    case TYPES.SET_AUTHENTICATION:
        return {
            ...state,
            authenticated: action.payload,
        };

    case TYPES.SET_USER:
        return {
            ...state,
            data: action.payload,
            isFetching: false,
        };
    case TYPES.SET_USER_SUCCESS_MESSAGE:
        return {
            ...state,
            successMessage: action.payload,
            isFetching: false,
        };
    case TYPES.SET_USER_ERROR:
        return {
            ...state,
            error: action.payload,
            isFetching: false,
        };
    case TYPES.CLEAR_USER_MESSAGE:
        return {
            ...state,
            error: action.payload,
            successMessage: action.payload,
        };
    case TYPES.UPDATE_SUBSCRIPTION_DATA:
        return {
            ...state,
            data: {
                ...state.data,
                subscription: action.payload,
            },
        };
    case TYPES.SET_CONTINUE_WATCHING:
        return {
            ...state,
            data: {
                ...state.data,
                viewed: action.payload,
            },
        };

    case TYPES.SET_FAVORITES:
        return {
            ...state,
            data: {
                ...state.data,
                favorite_assets: action.payload,
            },
        };

    case TYPES.REMOVE_FAVORITES:
        return {
            ...state,
            data: {
                ...state.data,
                favorite_assets: action.payload,
            },
        };

    case TYPES.SET_TUTORIAL_WATCHED:
        return {
            ...state,
            data: {
                ...state.data,
                tutorial_watched: action.payload,
            },
        };

    case TYPES.UPDATE_USER:
        return {
            ...state,
        };
    case TYPES.UPDATE_PROFILE_IMAGE:
        return {
            ...state,
        };

    case TYPES.UPDATE_USER_PASSWORD:
        return {
            ...state,
        };
    case TYPES.ADD_SECONDARY_USER:
        return {
            ...state,
        };
    case TYPES.REMOVE_SECONDARY_USER:
        return {
            ...state,
        };
    default:
        return state;
    }
};

export default user;
