import React from 'react';
import { useSelector } from 'react-redux';
import { SusbscriptionType } from '../../../../../global/models';
import SubscriptionSectionInfo from './subscriptionSectionInfo.jsx';
import MobileAppSubscriptionMessage from './mobileAppSubscriptionMessage';
function AccountSubscription() {
    const userInfo = useSelector((state) => state.User.data);
    // eslint-disable-next-line max-len
    const isUserRegisteredViaMobileApp = userInfo?.subscription_type === SusbscriptionType.App;
    return (isUserRegisteredViaMobileApp ? (React.createElement(MobileAppSubscriptionMessage, null)) : (React.createElement(SubscriptionSectionInfo, null)));
}
export default AccountSubscription;
