import React from "react";
import './mobile-login.scss';
import LoginForm from "../Forms/login";
import PropTypes from 'prop-types';

const  MobileLogin = (props) => {
    return (
        <div className="mobile-login_container">
            <div className="back-chevron" onClick={props.backButton}/>
            <div className="m365-logo_container"/>
            <LoginForm formClass={`form-container`} buttonClass={`button-container flex-column flex-md-row`}/>
            <div className="mobile-partner-link">
                Need to Connect Partner? <a href={'/register-partner'}>Click Here</a>
            </div>
        </div>
    )
}

MobileLogin.propTypes = {
    backButton: PropTypes.func,
}

export default MobileLogin;
