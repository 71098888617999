import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import './mobile-footer.scss';


const MobileFooter = () => {

    const [isHomeActive, setIsHomeActive] = useState(false);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isCheckupActive, setIsCheckupActive] = useState(false);
    const [isPodcastActive, setIsPodcastActive] = useState(false);

    let url = window.location.href.toString();


    useEffect(() => {
        resetState();
        setHighlight(url);
    }, [url]);

    const resetState = () => {
        setIsHomeActive(false);
        setIsSearchActive(false);
        setIsCheckupActive(false);
        setIsPodcastActive(false);
    }

    const setHighlight = (string) => {
        let currentPage = string.split('/');
        currentPage = currentPage[currentPage.length - 1];
        switch (currentPage) {
            case "browse":
                setIsHomeActive(true);
                break;
            case "search":
                setIsSearchActive(true);
                break;
            case "checkup":
                setIsCheckupActive(true);
                break;
            case "podcast":
                setIsPodcastActive(true);
                break;
            default:
                return;
                break;
        }

    }


    return (
        <div className="mobile-footer_container">
            <div id="mobile-footer" className="mobile-link_container">
                <div className="footer-flex_container">
                    <Link className="mobile-footer_link" to={"/browse"}>
                        <div className={`footer-menu_item ${isHomeActive ? 'active' : ''}`}>
                            <div className="footer-icon home"/>
                            <div className="footer-text">Home</div>
                        </div>
                    </Link>

                    <Link className="mobile-footer_link" to={"/checkup"}>
                        <div className={`footer-menu_item ${isCheckupActive ? 'active' : ''}`}>
                            <div className="footer-icon my-marriage"/>
                            <div className="footer-text">My Marriage</div>
                        </div>
                    </Link>
                    <Link className="mobile-footer_link" to={"/podcast"}>
                        <div className={`footer-menu_item ${isPodcastActive ? 'active' : ''}`}>
                            <div className="footer-icon podcast"/>
                            <div className="footer-text">Podcast</div>
                        </div>
                    </Link>
                    <Link className="mobile-footer_link" to={"/search"}>
                        <div className={`footer-menu_item ${isSearchActive ? 'active' : ''}`}>
                            <div className="footer-icon search"/>
                            <div className="footer-text">Search</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )

}

export default MobileFooter;
