/**
 * Template Name: Registration Alt One
 *
 * Description: Template containing a media component, list item and two buttons
 * Author: Kurt Weston
 * Version: 1.0
 * Text Domain: registration-alt-one
 *
 */
import React, {useEffect, useState} from "react"
import Components from "../Helpers/componentHelper"
import {getComponentData} from "../Helpers/backend-helper";
import {useParams} from "react-router-dom";
import "../Store"

/**
 * FOR DEVELOPMENT PURPOSES ONLY
 * --
 * Update _DEVELOPMENT to true
 *
 * POINT THE TEMPLATE STRUCTURE TO THE STRUCTURE FILE IN DEVELOPMENT
 */

const _DEVELOPMENT = true;
const _ACTIVE_TEST = "development"
import TEMPLATE_STRUCTURE from "../../../../../../resources/js/frontend/src/components/HeaderPromoBanner/structure.json"
/**
 * END DEVELOPMENT IMPORTS
 */




/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ComponentRenderer = () => {
    const [templateResponse, setTemplateResponse] = useState(null)
    const {campaign_slug} = useParams()
    const [activeTest, setActiveTest] = useState(null)


    useEffect(() => {

        const controller = new AbortController();
        // Load the data from the API
        LoadDataFromAPI();

        return () => controller.abort();

    }, [])

    const LoadDataFromAPI = async () => {
        if (_DEVELOPMENT) {
            setTemplateResponse(TEMPLATE_STRUCTURE)
            setActiveTest(_ACTIVE_TEST)

        } else {
            await getComponentData(campaign_slug).then(r => {
                setTemplateResponse(r.template)
                setActiveTest(r.activeTest)
            });
        }

    }

    return (
        <>
            {
                templateResponse &&
                templateResponse.map(block => {
                    block.activeTest = activeTest;
                    return Components(block)
                })
            }
        </>

    )
};

export default ComponentRenderer;
