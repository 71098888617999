import * as TYPES from "./actionTypes"


export const getWatchlist = () => {
    return {
        type: TYPES.GET_WATCHLIST,
    }
}

export const setWatchlist = (payload) => {
    return {
        type: TYPES.SET_WATCHLIST,
        payload: payload
    }
}

export const addToWatchlist = (payload) => {
    return {
        type: TYPES.ADD_TO_WATCHLIST,
        payload: {
            asset_id: payload.asset_id,
            assetType: payload.assetType
        }
    }
}

export const removeFromWatchlist = (payload) => {
    return {
        type: TYPES.REMOVE_FROM_WATCHLIST,
        payload: {
            asset_id: payload.uuid,
            assetType: payload.assetType
        }
    }
}

export const addToWatchlistComplete = () => {
    return {
        type: TYPES.ADD_TO_WATCHLIST_COMPLETE
    }
}
