import React from "react";
import ReactJWPlayer from 'react-jw-player';
import * as VIDEO_PLAYER_EVENTS from "../../pages/Video/video-events";
import {Track} from "../../utils/Segment/segment-methods";
import PropTypes from 'prop-types';

const VideoPlayer = props => {

    const checkProgress = () => {
        let video = document.getElementsByClassName('jw-video');
        video = Array.from(video);
        video = video[0];
        let startPoint = 0;
        if(props.video.seconds_watched > 0){
            startPoint = props.video.seconds_watched;
        }
        video.currentTime = startPoint;  
    }
   
    // UNABLE TO USE STYLED COMPONENTS HERE DUE TO RE-RENDERING CAUSING PLAYER SETUP ERROR
    return (
        <ReactJWPlayer
            onVideoLoad={checkProgress}
            playerId={props.playerId}
            playerScript={props.playerScript}
            playlist={[{
                image: props.video.thumbnail,
                sources: [{
                    file: props.video.video_url
                }],
                tracks: VIDEO_PLAYER_EVENTS.getVTT(props.video),
            }]}
            isAutoPlay={props.isAutoPlay}
            onPlay={() => props.onPlay ? VIDEO_PLAYER_EVENTS.onPlay(props.document) : null}
            onTenSeconds={() => VIDEO_PLAYER_EVENTS.onTenSeconds(props)}
            onReady={() => props.onReady ? VIDEO_PLAYER_EVENTS.onReady(props.document) : null}
            onResume={() => props.onResume ? VIDEO_PLAYER_EVENTS.onResume(props.document) : null}
            onPause={() => props.onPause ? VIDEO_PLAYER_EVENTS.onPause(VIDEO_PLAYER_EVENTS.getVideoDataForEvent(props)) : null}
            onSeventyFivePercent={() => props.onSeventyFivePercent ? VIDEO_PLAYER_EVENTS.sendProgress(VIDEO_PLAYER_EVENTS.getVideoDataForEvent(props, 'complete')) : null}
            onNinetyFivePercent={() => props.onNinetyFivePercent ? VIDEO_PLAYER_EVENTS.sendProgress(VIDEO_PLAYER_EVENTS.getVideoDataForEvent(props)) : null}
        />
    )
}

VideoPlayer.propTypes = {
  onOneHundredPercent: PropTypes.bool,
  onPause: PropTypes.bool,
  onPlay: PropTypes.bool,
  onResume: PropTypes.bool,
  onSeventyFivePercent: PropTypes.bool,
  playerId: PropTypes.string,
  playerScript: PropTypes.string,
  saveProgress: PropTypes.func,
  user: PropTypes.object,
  video: PropTypes.object,
}

export default VideoPlayer;
