import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import ReactJWPlayer from 'react-jw-player';
import axios from 'axios';
import PropTypes from 'prop-types';
import NewCourseStep from '../components/CourseStep/newCourseStep.jsx';
import CourseDetails from '../components/CourseDetails/index.jsx';
import ChallengeComplete from '../components/ChallengeComplete/index.jsx';
import AudioSwitch from '../components/buttons/audioSwitch.jsx';
import AudioOnly from '../components/AudioOnly/index.jsx';
import ScaleLoader from 'react-spinners/ScaleLoader';

import './course-video.scss';
import LoadingScreen from "../components/LoadingScreen";
import { HandleRouteChange } from "../utils/Segment/segment-methods";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data
});


const CourseVideoPage = ({ user }) => {
    let location = useLocation()

    let localProgress;

    const [isCourseComplete, setIsCourseComplete] = useState(false);
    const [isCurrentCourse, setIsCurrentCourse] = useState();
    const [isResource, setIsResource] = useState(false);
    const [isProgress, setIsProgress] = useState();
    const [isActiveVideo, setIsActiveVideo] = useState();
    const [isNextVideo, setIsNextVideo] = useState();
    const [isAllLessons, setIsAllLessons] = useState();
    const [isBonus, setIsBonus] = useState(false);
    const [isAudioSelected, setIsAudioSelected] = useState(false);
    const [isResourceLink, setIsResourceLink] = useState();
    let { course_uuid, lesson_uuid } = useParams();

    // todo: move this to redux
    const [currentLessonUUID, setCurrentLessonUUID] = useState(null)

    let courseComplete = false;
    let courseContainer = useRef();


    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location && lesson_uuid && isActiveVideo && isCurrentCourse && lesson_uuid === currentLessonUUID) {
            // console.log(isActiveVideo)
            HandleRouteChange(location, user, 'page-view', 'Course Lesson', isActiveVideo.name, isCurrentCourse.core_category)
        }
    }, [user, location, lesson_uuid, isCurrentCourse, isActiveVideo])

    useEffect(() => {
        getCourseData();
        setCurrentLessonUUID(lesson_uuid)
    }, [lesson_uuid])

    const getCourseData = async () => {
        setIsActiveVideo(undefined);
        setIsNextVideo(undefined)
        setIsResource(false);
        await axios.get(`/get-course/${course_uuid}`).then((resp) => {
            setIsCurrentCourse(resp.data);
            setIsProgress(resp.data.progress);
            getLesson(resp.data.lessons, resp.data.progress);
        })
    }

    const getLesson = (lessons, progress) => {
        // check the days and bonus for uuid
        let lessonsCopy = lessons;
        let chosenLesson, nextLesson;
        let currentPosition;
        let bonus = false;
        // need a check to see if this is a bonus item, if so use the bonus array
        chosenLesson = lessonsCopy.filter((e) => {
            return e.uuid === lesson_uuid
        });
        if (lessons.bonus !== undefined && lessons.bonus.length > 0 && chosenLesson.length === 0) {
            bonus = true;
            setIsBonus(true);
            chosenLesson = lessons.bonus.filter((e) => {
                return e.uuid === lesson_uuid
            });
        }
        currentPosition = chosenLesson[0].position;

        // make an exception for last element
        // if bonus exists next becomes 1st bonus

        // if not no next card appears

        let currentStatus, nextStatus;

        // need to make an exception for being the last video
        // with and without bonus

        if (progress.length === 0) {
            chosenLesson[0].status = "active"
        } else if (progress.lessons_completed.length > 0) {
            // check lessons complete for uuids
            currentStatus = progress.lessons_completed.filter(e => e.lesson_uuid === chosenLesson[0].uuid);
            if (currentStatus.length > 0) {
                chosenLesson[0].status = "complete";
            }
            if (currentStatus.length === 0) {
                chosenLesson[0].status === "active";

            }
        }
        // if last day
        if (chosenLesson[0].position === lessons.length) {
            // and bonus exists
            if (lessons.bonus !== undefined) {
                nextLesson = lessons.bonus[0];
            } else {
                nextLesson = null;
            }
        }
        // if bonus, and last bonus day
        if (bonus && chosenLesson[0].position === lessons.bonus.length) {
            // no next
            nextLesson = null;
        }

        //if not the last and bonus exists
        if (nextLesson !== null) {
            // if next lesson is not chosen
            if (!nextLesson) {
                // if bonus pick next from bonus
                if (bonus) {
                    nextLesson = lessons.bonus[currentPosition];
                } else {
                    nextLesson = lessons[currentPosition];
                }
            }

            // if first video, with no progress
            if (progress.length === 0) {
                nextLesson.status = "";

            } else if (progress.lessons_completed.length > 0) {
                // check lessons complete for uuids
                nextStatus = progress.lessons_completed.filter(e => e.lesson_uuid === nextLesson.uuid);
                if (nextStatus.length > 0) {
                    nextLesson.status = "complete";
                } else {
                    nextLesson.status = "active";
                }

                if (currentStatus.length === 0) {
                    nextLesson.status = "";
                }
            }
        }
        // if the current video is a bonus video
        createLessonCards(chosenLesson[0], lessons, progress);
        setIsNextVideo(nextLesson);
        setIsActiveVideo(chosenLesson[0]);
        if (chosenLesson[0].type === "resource" || chosenLesson[0].type === "worksheet") {
            let closeButton = document.getElementById('close-button');
            closeButton.classList.remove('hide');
        }
    }

    const createLessonCards = (activeLesson, lessons, progress) => {

        if (activeLesson.type === "resource" || activeLesson.type === "worksheet") {
            setIsResource(true);
            setIsResourceLink(activeLesson.worksheet);

        }
        // loop through the lessons set the current to active
        let allLessons = lessons;
        let styledLessonArray = [];

        allLessons.forEach((e, i) => {
            let courseStep;
            if (e.uuid === activeLesson.uuid) {
                e.status = "active"
            } else if (progress.lessons_completed !== undefined) {
                for (let j = 0; j < progress.lessons_completed.length; j++) {

                    if (progress.lessons_completed[j].lesson_uuid === e.uuid) {
                        e.status = "complete";
                        break;
                    } else {
                        e.status = ""
                    }
                }
            }

            courseStep = <NewCourseStep
                uuid={e.uuid}
                src={e.thumbnail}
                stepNumber={e.position}
                status={e.status}
                name={e.name}
                type={e.type}
                duration={e.duration}
                description={e.description}
                day={e.position}
                key={i}
                course_uuid={course_uuid}
                lesson_uuid={e.uuid}

            />
            styledLessonArray.push(courseStep);
        });
        setIsAllLessons(styledLessonArray);
    }

    // use js to hide and show close / back button

    const onPlay = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.add('hide');
    };
    const onResume = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.add('hide');
    }
    const onPause = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.remove('hide');
    }
    const onReady = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.remove('hide');
    }

    const onEnded = () => {
        let closeButton = document.getElementById('close-button');
        closeButton.classList.remove('hide');

        // update the cards here
        let lessonArray = isCurrentCourse.lessons;
        let lessonPosition, nextLesson;

        // check each lesson for the current lesson uuid
        lessonArray.forEach((e, i) => {
            if (e.uuid === lesson_uuid) {
                lessonPosition = i;
            }
        });

        // set that lesson to complete, use I to get the next lesson
        lessonArray[lessonPosition].status = "complete";

        if (lessonArray[lessonPosition + 1] !== undefined) {
            nextLesson = lessonArray[lessonPosition + 1];
            nextLesson.status = "active";
            // use the current function to make the cards.
            createLessonCards(nextLesson, lessonArray, localProgress);
        }

        // check progress here and see
        if (courseComplete) {
            setIsCourseComplete(true);
        }
    }

    const sendProgress = async () => {

        // check for when the next course is updated make sure the current one changes to complete
        let progressObj = {
            lesson_uuid: lesson_uuid,
            course_uuid: course_uuid,
        }
        // check the user progress to see if this lesson has been completed to prevent a 400 response

        if (isProgress.is_complete) {
            courseComplete = true;
        } else {

            if (isProgress.lessons_completed !== undefined && isProgress.lessons_completed.length > 0) {
                for (let i = 0; i < isProgress.lessons_completed.length; i++) {
                    if (isProgress.lessons_completed[i].lesson_uuid === lesson_uuid) {
                        return;
                    }
                }
            }

            await axios.post('/course-progress', progressObj).then((resp) => {

                localProgress = resp.data.progress;

                if (resp.data.progress.is_complete) {
                    courseComplete = true;
                }
            });
        }
    }
    const toggleAudioOnly = () => {
        setIsAudioSelected(!isAudioSelected);
    }

    const closeConfetti = () => {
        setIsCourseComplete(false)
    }


    const getVTT = (video) => {
        if (video.vtt) {
            return [{
                file: `${video.vtt}`,
                label: 'English',
                kind: 'captions',
                'default': false
            }]
        } else {
            return [];
        }

    }


    if (isAudioSelected) {
        return (
            <AudioOnly
                audio={isAudioSrc}
                title={isAsset.title}
                isAudioSelected={isAudioSelected}
                toggleAudioOnly={toggleAudioOnly}
                sendProgress={sendProgress}
            />
        )
    } else {
        return (
            <div id="course-video_page">
                {isCourseComplete && isCurrentCourse ? (
                    <ChallengeComplete
                        challenge={false}
                        courseImage={isCurrentCourse.portrait_thumbnail}
                        totalVideos={isCurrentCourse.lessons.length}
                        title={isCurrentCourse.title}
                        closeConfetti={closeConfetti}
                    />
                ) : (
                    <div>
                        {isActiveVideo ? (
                            <div>
                                <div className="video-container">
                                    <div id="close-button" className="hide">
                                        <Link to={`/course/${course_uuid}`}>
                                            <div className="close-video"></div>
                                        </Link>
                                    </div>
                                    {isResource ? (
                                        <div className="resource-image"
                                            style={{ backgroundImage: `url(${isActiveVideo.thumbnail})` }}></div>
                                    ) : (
                                        <ReactJWPlayer
                                            playerId={`player-${isActiveVideo.uuid}`}
                                            playerScript="https://cdn.jwplayer.com/libraries/PKPzctjx.js"
                                            playlist={[{
                                                image: isActiveVideo.thumbnail,
                                                sources: [{
                                                    file: isActiveVideo.video_url
                                                }, {
                                                    file: isActiveVideo.video_url
                                                }],
                                                tracks: getVTT(isActiveVideo),
                                            }]}
                                            onPlay={onPlay}
                                            onPause={onPause}
                                            onReady={onReady}
                                            onResume={onResume}
                                            onOneHundredPercent={onEnded}
                                            onSeventyFivePercent={sendProgress}
                                        />
                                    )}
                                </div>

                                <div id="course-detail">
                                    <CourseDetails
                                        resourceLink={isResource ? isResourceLink : ''}
                                        sendProgress={sendProgress}
                                        isResource={isResource}
                                        uuid={isNextVideo ? isNextVideo.uuid : ''}
                                        stepNumber={isActiveVideo.position}
                                        status={isNextVideo ? isNextVideo.status : ''}
                                        name={isActiveVideo.name}
                                        courseName={isCurrentCourse.title}
                                        description={isActiveVideo.description}
                                        key={Math.random(100)}
                                        onEnded={onEnded}
                                        course_uuid={course_uuid}
                                        lesson_uuid={isNextVideo ? isNextVideo.uuid : ''}
                                        allLessons={isAllLessons}
                                        courseContainer={courseContainer}
                                        connectingQuestions={isActiveVideo.connecting_questions}

                                    />
                                    {/* <div className='course-audio_switch__container'>
                                    <AudioSwitch
                                        isAudioSelected={isAudioSelected}
                                        toggleAudioOnly={toggleAudioOnly}
                                    />
                                </div> */}
                                </div>
                            </div>
                        ) : (
                            <LoadingScreen />
                        )}
                    </div>
                )}
            </div>
        )
    }
}

CourseVideoPage.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseVideoPage)
