import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationCard from '../components/cards/notificationCard';
import LoadingScreen from '../components/LoadingScreen';

import './notifications.scss';
import {HandleRouteChange, Track} from "../utils/Segment/segment-methods";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data
});


const Notifications = ({user}) => {
    let location = useLocation()
    let navigate = useNavigate()
    const [isNotifications, setIsNotifications] = useState();
    const [isStyledNotifications, setIsStyledNotifications] = useState();
    const [isMarkAllShown, setIsMarkAllShown] = useState(false);

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', 'Notifications')
        }
    }, [user, location])

    useEffect(() => {
        getUserNotifications();
    }, []);

    const getUserNotifications = () => {
        axios.get('/user-notifications?skip=0&limit=20').then((resp) => {
            setIsNotifications(resp.data.notifications);
            styleNotifications(resp.data.notifications);
        });
    }

    const styleNotifications = (array) => {
        let notificationCard;
        let styledArray = [];

        array.forEach((e, i) => {
            let external_link = false;
            let type;
            let uuid;
            let link;
            let icon;
            // set the icon if it does not exist
            if (e.data.meta_data.icon.svg) {
                icon = e.data.meta_data.icon.svg
            } else {
                icon = "https://d305pf0gb904h3.cloudfront.net/icons/notification-info.svg";
            }


            if (e.launch_url !== null) {
                external_link = true
                link = e.launch_url
            }


            if (!!e.data.meta_data.asset.type) {
                type = e.data.meta_data.asset.type;
                uuid = e.data.meta_data.asset.uuid;
                switch (type) {
                    case "video":
                        link = `/watch/${uuid}`;
                        break;
                    case "worksheet":
                        link = `/read/${uuid}`;
                        break;
                    case "challenge":
                        link = `/challenge/${uuid}`;
                        break;
                    case "course":
                        link = `/course/${uuid}`;
                        break;
                    default:
                        link = '/browse'
                }


                notificationCard = (
                    <div onClick={() => routeClick(link, e.title, external_link)} className="notification-link pointer"
                         key={i}>
                        <NotificationCard
                            icon={icon}
                            title={e.title}
                            message={e.message}
                            status={e.is_read === 0 ? 'active' : ''}
                        />
                    </div>);
                setIsMarkAllShown(true);

            } else {

                notificationCard =
                    (<div className="notification-link" key={i}>
                        <NotificationCard
                            icon={icon}
                            title={e.title}
                            message={e.message}
                            status={e.is_read === 0 ? 'active' : ''}
                        />
                    </div>);
            }
            styledArray.push(notificationCard);
        })
        setIsStyledNotifications(styledArray);
    }

    const routeClick = (link, title, is_external_link) => {
        Track('notification_selected', {title: title}).then(() => {
            if (is_external_link) {
                window.open(link)
            } else {
                navigate(link)
            }

        })

    }

    const goBack = () => {
        window.history.back();
    }

    const markAllNotifications = () => {
        let isAllNotifications = isNotifications;
        isAllNotifications.forEach(e => {
            e.is_read = 1;
        });
        axios.post('/mark-all-notifications', {status: 'read'}).then((resp) => {
            // console.log('mark all response');
            styleNotifications(isAllNotifications);
        });
        // styleNotifications(isAllNotifications);
    }

    return (
        <div id="notifications-page">
            <div className="back-link" onClick={goBack}>
                <div className="back-button"></div>
            </div>
            {isMarkAllShown && (<div className="mark-all_container" onClick={markAllNotifications}>
                <div className="mark-button">Mark All Read</div>
            </div>)}

            <div className="page-title">Notifications</div>
            <div className="notification-container">
                {isStyledNotifications}
            </div>

        </div>

    )
}

Notifications.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
