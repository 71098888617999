import React from 'react';

import './refinementList.scss';


const RefinementList = ({ items, refine }) => (
    <ul className="refinement-list">
      {items.map(item => (
        <li key={item.label}>
          <div 
            
            style={{ fontWeight: item.isRefined ? 'bold' : '' , backgroundColor:item.isRefined ? '#2D5F62' : '#EFEAE6',borderRadius:'88px',border:'1px solid #E3DCD3',
        color:item.isRefined ? '#fff' : '#000', width:'fit-content', textAlign:'center', padding:"8px 16px"}}
            onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            {item.label}
          </div>
        </li>
      ))}
    </ul>
  );

  export default RefinementList;