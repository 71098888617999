import React from 'react'
import classNames from 'classnames'
import SimpleBar from 'simplebar-react'
import Menu from './Menu'
import {useLayout, useLayoutUpdate} from '../LayoutProvider';
import UserProfile from "./Components/user-profile"

function Sidebar() {
    const layout = useLayout();
    const layoutUpdate = useLayoutUpdate();

    const compClass = classNames({
        'profile-sidebar profile-sidebar-fixed': true,
        'is-compact': layout.sidebarCompact,
        'sidebar-active': layout.sidebarActive,
        [`is-${layout.sidebarVariant}`]: layout.sidebarVariant,
    });

    return (
        <>
            {layout.sidebarActive && <div className="sidebar-overlay" onClick={layoutUpdate.sidebarMobile}></div>}
            <div className={compClass}>
                <SimpleBar className="profile-sidebar-menu">
                    <div className="profile-sidebar-element profile-sidebar-head">
                        <UserProfile/>
                    </div>
                    <div className="profile-sidebar-element profile-sidebar-body">
                        <Menu/>
                    </div>
                </SimpleBar>
            </div>
        </>
    )
}

export default Sidebar
