import React from 'react';
import styled from 'styled-components';
const Container = styled.div `
    height: 100vh;
    position: relative;
    max-width: 650px;
    width: 100%;
`;
const StyledTitle = styled.h3 `
    font-family: Sofia Pro Bold;
`;
const StyledSubTitle = styled.div `
    font-family: Sofia Pro;
    line-height: 1.5;
`;
// eslint-disable-next-line react/prop-types
function MobileAppSubscriptionMessage() {
    return (React.createElement(Container, null,
        React.createElement(StyledTitle, null, "Subscription"),
        React.createElement(StyledSubTitle, null, "You have registered your account via our mobile app. \u00A0To change your subscription, \u00A0please use the mobile app for now.")));
}
export default MobileAppSubscriptionMessage;
