import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import NewChallengeStep from '../components/ChallengeStep/new-challenge-step';

import ChallengeDetails from '../components/ChallengeDetails';
import ChallengeHeaderDesktop from '../components/common/header-component/challenge-header-desktop';

import ScaleLoader from 'react-spinners/ScaleLoader';
import ChallengeHeader from '../components/common/header-component/challenge-header';
import ChallengePickAsset from '../components/ChallengePickAsset';

import './single-challenge.scss';
import { bindActionCreators } from "redux";
import { getDashboard } from "./Dashboard/store/actions";
import { connect } from "react-redux";
import LoadingScreen from "../components/LoadingScreen";
import { HandleRouteChange } from "../utils/Segment/segment-methods";
import {getSingleChallenge, restartChallenge} from "./Challenges/store/actions";


//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {},
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
});

const SingleChallenge = ({ user }) => {
    let location = useLocation()
    const [isChallengeSteps, setIsChallengeSteps] = useState();
    const [isBonusSteps, setIsBonusSteps] = useState([]);
    const [isChallengeTitle, setIsChallengeTitle] = useState();
    const [isChallengeDescription, setIsChallengeDescription] = useState();
    const [isChallengeProgress, setIsChallengeProgress] = useState();
    const [isChallengeHeader, setIsChallengeHeader] = useState();
    const [isChallengeUUID, setIsChallengeUUID] = useState();
    const [isChallengeResponse, setIsChallengeResponse] = useState(null);
    const [isChallengeStarted, setIsChallengeStarted] = useState(false);
    const [isChallengeComplete, setIsChallengeComplete] = useState(false);
    const [isDateCompleted, setIsDateCompleted] = useState(null);
    const [isActiveVideo, setIsActiveVideo] = useState();
    const [isChallengeWatchlist, setIsChallengeWatchlist] = useState(false);

    let { challenge_uuid } = useParams();

    //lock down additional steps until each challenge is completed

    useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location && isChallengeHeader) {
            HandleRouteChange(location, user, 'page-view', 'Single Challenge', isChallengeHeader.title)
        }
    }, [user, location, challenge_uuid, isChallengeHeader])

    useEffect(() => {
        getChallengeData();
        window.scrollTo(0, 0)
    }, [challenge_uuid]);

    // after reset check

    const getChallengeData = async () => {
        // reset state after url change, this is for changing from a course to a course in the search bar
        setIsChallengeResponse(null);
        setIsChallengeTitle(undefined);
        setIsChallengeSteps(undefined);

        await axios.get(`/get-single-challenge/${challenge_uuid}`).then(resp => {
            setIsChallengeResponse(resp.data.lessons.days);
            createHeader(resp.data);
            createChallengeSteps(resp.data.lessons.days, resp.data.progress);
            setIsChallengeUUID(resp.data.uuid);
            if (resp.data.lessons.bonus !== undefined) {
                createBonusSteps(resp.data.lessons.bonus, resp.data.progress)
            }
        })
    };

    const createHeader = (data) => {
        setIsChallengeHeader(data);
        setIsChallengeDescription(data.description);
        setIsChallengeProgress(data.progress);
        /**
         * This is a temporary fix until we use redux to store the challenge.
         * todo: use redux to store the challenge
         * @returns {Promise<void>}
         */
        setIsChallengeWatchlist(data.on_watchlist)
    }

    const createChallengeSteps = (lessonArray, currentProgress) => {

        if (lessonArray) {
            let challengeSteps = lessonArray;
            challengeSteps.sort((a, b) => (a.position > b.position) ? 1 : -1);
            let sortedStyledSteps = challengeSteps.map((e, i) => {
                // progress with be null if there is no progress
                // need to merge for changes
                if (currentProgress === null && e.position === 1) {
                    e.status = 'active';
                    setIsActiveVideo(e.uuid);
                } else {
                    e.status = "disabled";
                }
                // will be null if no progress
                if (currentProgress !== null) {
                    for (let j = 0; j < currentProgress.lessons_completed.length; j++) {
                        if (currentProgress.lessons_completed[j].lesson_uuid === e.uuid) {
                            e.status = "complete";
                            setIsChallengeStarted(true);
                            break;
                        } else if (currentProgress.lessons_completed.length === 0 && e.position === 1) {
                            e.status = "active";
                            setIsActiveVideo(e.uuid);
                            break;
                        } else if (e.position > 1 && (currentProgress.lessons_completed.length + 1) === e.position) {
                            e.status = "active";
                            setIsActiveVideo(e.uuid);
                            setIsChallengeStarted(true);
                            break;
                        } else {
                            e.status = "disabled";
                        }
                    }
                }
                return (
                    <NewChallengeStep
                        uuid={e.uuid}
                        src={e.thumbnail}
                        stepNumber={e.position}
                        status={e.status}
                        name={e.name}
                        type={e.type}
                        duration={e.duration}
                        description={e.description}
                        totalSteps={challengeSteps.length}
                        day={e.position}
                        key={i}
                        challenge_uuid={challenge_uuid}
                        lesson_uuid={e.uuid}
                        bonus={false}
                    />
                )
            });
            if (currentProgress !== null && currentProgress.lessons_completed.length === sortedStyledSteps.length) {
                setIsChallengeComplete(true);
            }

            setIsChallengeSteps(sortedStyledSteps);
        }
    }

    const createBonusSteps = (bonusArray, currentProgress) => {

        // check if bonus exists, add it to the end of the list

        if (bonusArray) {
            let bonusSteps = bonusArray;
            bonusSteps.sort((a, b) => (a.position > b.position) ? 1 : -1);
            let sortedStyledSteps = bonusSteps.map((e, i) => {

                if (currentProgress.is_complete) {
                    // make all active
                    e.status = "active"
                } else {
                    e.status = "disabled"
                }

                return (

                    <NewChallengeStep
                        uuid={e.uuid}
                        src={e.thumbnail}
                        stepNumber={e.position}
                        status={e.status}
                        name={e.name}
                        type={e.type}
                        duration={e.duration}
                        description={e.description}
                        totalSteps={bonusSteps.length}
                        day={e.position}
                        key={i}
                        challenge_uuid={challenge_uuid}
                        lesson_uuid={e.uuid}
                        bonus={true}
                    />
                )
            });
            setIsBonusSteps(sortedStyledSteps);
        }
    }

    const resetChallenge = async () => {
        let restartObj = { uuid: challenge_uuid }
        axios.post('/challenge-restart', restartObj).then((resp) => {
            setIsChallengeComplete(false);
            setIsChallengeStarted(false);
            getChallengeData();
        }
        );

    }


    return (
        <React.Fragment>
            <div id="single-challenge_page">
                <div>
                    {
                        isChallengeSteps === undefined ?
                            <LoadingScreen />
                            :
                            (
                                <ChallengeHeader
                                    isChallengeStarted={isChallengeStarted}
                                    isChallengeComplete={isChallengeComplete}
                                    src={isChallengeHeader.thumbnail}
                                    title={isChallengeHeader.title}
                                    lesson_uuid={isActiveVideo}
                                    numerator={isChallengeProgress === null ? 0 : isChallengeProgress.lessons_completed.length}
                                    duration={isChallengeHeader.duration}
                                    days={isChallengeSteps.length}
                                    videos={isChallengeSteps.length}
                                    resetChallenge={resetChallenge}
                                    challenge_uuid={challenge_uuid}
                                    onWatchlist={isChallengeWatchlist}
                                    setIsChallengeWatchlist={setIsChallengeWatchlist}
                                />
                            )
                    }
                </div>


                <div>
                    {
                        isChallengeSteps !== undefined &&
                        <div className="challenge-steps">
                            <ChallengePickAsset
                                description={isChallengeDescription}
                                challengeSteps={isChallengeSteps}
                                bonusSteps={isBonusSteps}
                            />
                        </div>

                    }
                </div>
            </div>
        </React.Fragment>
    )
}

SingleChallenge.propTypes = {
    user: PropTypes.object,
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleChallenge)
