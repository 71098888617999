import React, {useContext, useEffect, useState} from "react";
import {loadSystemSettings as getSettings} from "../store/actions";
import {Outlet} from "react-router-dom";
import store from "../../../store";
import {useSelector} from "react-redux";

export const SystemSettingsContext = React.createContext();
export const UseSystemSettings = () => useContext(SystemSettingsContext);


export const SystemSettingsProvider = () => {



    const {settings} = useSelector(state => ({
        settings: state.SystemSettings.settings
    }))


    // if (!settings) {
    //     console.log('settings does not exist in the store, so check local: ', settings)
    //     setCurrent(JSON.parse(localStorage.getItem('settings')))
    //     console.log('local settings: ', settings)
    // } else {
    //     setCurrent(settings)
    //     console.log('** system settings now exist: ', settings)
    // }


    useEffect(() => {
        store.dispatch(getSettings());
    }, [])


    return (
        <SystemSettingsContext.Provider value={{settings}}>
            <Outlet/>
        </SystemSettingsContext.Provider>
    );
};
