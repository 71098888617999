import React from 'react';
import { Track } from "../../utils/Segment/segment-methods";

const CustomArrowLeft = (props) => {

    const trackClick = () => {
        Track("clicked_carousel_backward", {Placement:props.source});
    }

    return (
        <button
            aria-label="Go to next slide"
            className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
            onClick={()=>{
                trackClick()
                props.onClick();
            }}
            type="button"
            >
        </button>
    )
}

export default CustomArrowLeft;