import * as TYPES from './actionTypes'

const initialState = {
    error: "",
    loading: true,
    data: null,
    all: null
}

const Worksheet = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_SINGLE_WORKSHEET:
            return {
                ...state,
                loading: true
            }
        case TYPES.SET_SINGLE_WORKSHEET:
            return {
                ...state,
                data: action.payload,
                loading: false
            }

        case TYPES.WORKSHEET_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case TYPES.GET_ALL_WORKSHEETS:
            return {
                ...state,
                loading: true
            }
        case TYPES.SET_ALL_WORKSHEETS:
            return {
                ...state,
                all: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default Worksheet
