import * as TYPES from "./actionTypes"

export const getVideo = (uuid) => {
    return {
        type: TYPES.GET_VIDEO,
        uuid: uuid
    }
}

export const setVideo = payload => {
    return {
        type: TYPES.SET_VIDEO,
        payload: payload
    }
}

export const getAllVideos = (payload) => {
    return {
        type: TYPES.GET_ALL_VIDEOS,
        payload: payload
    }
}

export const setAllVideos = payload => {
    return {
        type: TYPES.SET_ALL_VIDEOS,
        payload: payload
    }
}

export const videoError = error => {
    return {
        type: TYPES.VIDEO_ERROR,
        payload: error
    }
}

export const sendVideoProgress = (uuid, data) => {
    return {
        type: TYPES.SEND_VIDEO_PROGRESS,
        payload: {uuid, data}
    }
}

export const updateWatchlistStatus = payload => {

    return {
        type: TYPES.UPDATE_WATCHLIST_STATUS,
        payload: payload
    }
}


export const getAVideosByTag = payload => {
    return {
        type: TYPES.GET_VIDEO_BY_TAG,
        payload: payload
    }
}

export const storeTaggedVideos = payload => {
    return {
        type: TYPES.SET_TAGGED_VIDEOS,
        payload: payload
    }
}
