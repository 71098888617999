//-DEPENDENCIES
import React, {useState} from 'react';
import styled from "styled-components";

//-ASSETS
import WhiteWorksheet from '../../../assets/images/white-worksheet.svg';

//-COMPONENTS
import GeneralButton from '../../buttons/generalButton';
import VideoPlayer from "../../VideoPlayer";
import WatchlistButton from "../../buttons/watchlistButton";
import PropTypes from 'prop-types';

//-MAP PROPS

//-START FUNCTIONAL COMPONENT
const VideoHeaderDesktop = (props) => {

    const [displayFullscreenVideo, setDisplayFullscreenVideo] = useState(false)

    const BannerContainer = styled.div`
        min-height: ${props.screenWidth * .39}px;
    `

    const BannerImage = styled.div`
        background-image: url(${props.video.thumbnail});
    `

    const VideoContainer = styled.div`
        padding-left: ${props.screenWidth * .09}px;
    `
    const Content = styled.div`
        width: ${props.screenWidth * .345}px;
        max-width: 560px;
    `

    return (
        <>
            <BannerContainer className={`video-header_container ${props.className}`}>
                <VideoContainer className="video-content_container">
                    <Content>
                        <div className="category">
                            {props.video.core_categories}
                        </div>
                        <div className="video-title">
                            {props.video.title}
                        </div>
                        <div className="video-text" dangerouslySetInnerHTML={{__html: props.video.description}}/>

                        <div className="action-container">
                            <div className="pointer play-container"
                                 onClick={() => setDisplayFullscreenVideo(!displayFullscreenVideo)}>
                                <div className="play-button_container">
                                    <div className="play-button"/>
                                </div>
                                <div className="play-text">
                                    Play Video
                                </div>
                            </div>
                            <div className="button-container">

                                <WatchlistButton onWatchlist={props.video.on_watchlist}
                                                 icons={{
                                                     checkmark: props.settings.ICONS.shapes.checkmark.svg,
                                                     plus: props.settings.ICONS.shapes.plus.svg
                                                 }}
                                                 loader={{
                                                     color: '#fff',
                                                     width: '8px'
                                                 }}
                                                 buttonType={`circle`}
                                                 assetID={props.video.asset_id}

                                />
                            </div>
                        </div>
                        {
                            props.video.connecting_questions &&
                            <GeneralButton
                                backgroundColor={props.settings.COLORS.system.PRIMARY.main.hex}
                                buttonText={"Connecting Questions"}
                                icon={WhiteWorksheet}
                                onClick={props.clickAction}
                            />
                        }

                    </Content>
                </VideoContainer>
                <BannerImage className="large-banner_image"/>
            </BannerContainer>
            {
                displayFullscreenVideo &&
                <div className="video-player__fullwidth">
                    <div id="close-button" className="hide pointer"
                         onClick={() => setDisplayFullscreenVideo(false)}>
                        <div>
                            <div className="close-video"/>
                        </div>
                    </div>
                    <div className="mobile-video_container">
                        <VideoPlayer
                            playerId={`player-${props.video.asset_id}-fullscreen`}
                            playerScript="https://cdn.jwplayer.com/libraries/PKPzctjx.js"
                            video={props.video}
                            user={props.user}
                            onPlay
                            onReady
                            onResume
                            onEnded
                            onPause
                            isAutoPlay
                            onSeventyFivePercent
                            onOneHundredPercent
                            saveProgress={props.saveProgress}
                            document={self.document}
                        />

                    </div>
                </div>
            }
        </>

    )

}

VideoHeaderDesktop.propTypes = {
    className: PropTypes.string,
    clickAction: PropTypes.func.isRequired,
    saveProgress: PropTypes.func.isRequired,
    screenWidth: PropTypes.number.isRequired,
    settings: PropTypes.object.isRequired,
    user: PropTypes.object,
    video: PropTypes.object.isRequired,
}

export default VideoHeaderDesktop
