import * as TYPES from './actionTypes'

const initialState = {
    error: "",
    loading: true,
    assets: null,
}

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_DASHBOARD:
            return {
                ...state,
                loading: true
            }

        case TYPES.SET_DASHBOARD:
            return {
                ...state,
                assets: action.payload,
                loading: false
            }

        case TYPES.LOAD_DASHBOARD_ERROR:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        default:
            return state;
    }
}

export default dashboard
