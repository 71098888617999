/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isEmpty, } from 'lodash';
import React, { useCallback, useEffect, useState, } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoDrawer } from '../../../store/actions.js';
import { removeSecondaryUser } from '../../../../../store/user/actions.js';
import MarriageInfoDrawer from '../../../../../components/Modals/marriage-info-drawer.jsx';
import PartnerPairingInviteTemplate from '../../../../../onBoarding/templates/partnerPairing/partnerPairingInviteTemplate';
import PartnerAccountInfo from './partnerAccountInfo';
const Container = styled.div `
    height: 100vh;
    position: relative;
    max-width: 650px;
    width: 100%;
`;
function PartnerPairingInviteProps() {
    const dispatch = useDispatch();
    const [isRemovePartnerModalOpen, setIsRemovePartnerModalOpen] = useState(false);
    const [isInfoDrawerText, setIsInfoDrawerText] = useState(undefined);
    const infoDrawer = useSelector((state) => state.AccountSettings.infoDrawer);
    const userInfo = useSelector((state) => state.User.data);
    // Fetch partner pairing step info
    const partnerPairingStepInfo = userInfo?.onboarding_flow?.steps?.find((step) => step.type === 'partner_pairing');
    const partnerPairingCode = userInfo?.pairing_code ?? '';
    const userEmail = userInfo?.email;
    const secondUserAccountId = userInfo?.secondary_account?.id;
    // Check if secondary account (partner account) is linked or not
    const isPartnerAccountPaired = userInfo?.is_primary
        && !isEmpty(userInfo?.secondary_account);
    const subject = 'Invitation to Join Marriage365';
    const body = `Connect with me on Marriage365, a relationship app for married couples. \n\n Enter your code : ${partnerPairingCode}\n\n Tap here: https://marriage365.com/register-partner`;
    const mailtoUrl = `mailto:${userEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    useEffect(() => {
        if (infoDrawer === null) {
            dispatch(getInfoDrawer());
        }
        if (infoDrawer === null) {
            return;
        }
        setIsInfoDrawerText(infoDrawer?.message);
    }, [
        dispatch,
        infoDrawer,
    ]);
    const onShareInviteButtonClick = useCallback(() => {
        if (!isEmpty(userEmail)) {
            window.location.href = mailtoUrl;
        }
    }, [
        userEmail,
        mailtoUrl,
    ]);
    const onCloseRemovePartnerModal = useCallback(() => {
        setIsRemovePartnerModalOpen(false);
    }, []);
    const unlinkPartnerFromPrimaryAccount = useCallback(() => {
        const bodyParams = {
            sid: secondUserAccountId,
        };
        dispatch(removeSecondaryUser(bodyParams));
        setIsRemovePartnerModalOpen(false);
    }, [dispatch, secondUserAccountId]);
    return (React.createElement(Container, null,
        isPartnerAccountPaired ? (React.createElement(PartnerAccountInfo, { onClickShowRemovePartnerModal: () => setIsRemovePartnerModalOpen(true), partnerEmail: userInfo?.secondary_account?.email, partnerFirstName: userInfo?.secondary_account?.first_name, partnerProfileImageUrl: userInfo?.secondary_account?.profile_image })) : (React.createElement(PartnerPairingInviteTemplate, { data: partnerPairingStepInfo?.data, pairingCode: partnerPairingCode, onShareInviteButtonClick: onShareInviteButtonClick })),
        isRemovePartnerModalOpen && isInfoDrawerText !== undefined && (React.createElement(MarriageInfoDrawer
        // @ts-expect-error: isInfoDrawerText is not undefined
        , { 
            // @ts-expect-error: isInfoDrawerText is not undefined
            header: isInfoDrawerText?.header, 
            // @ts-expect-error: isInfoDrawerText is not undefined
            body: isInfoDrawerText?.body, button: true, onClick: unlinkPartnerFromPrimaryAccount, closeModal: onCloseRemovePartnerModal }))));
}
export default PartnerPairingInviteProps;
