import React from 'react';
import {useLocation} from 'react-router-dom';
import {HandleRouteChange} from "../../../utils/Segment/segment-methods";

import ProfileLayout from "../ProfileLayout";

import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import CustomLoader from "../../../components/CustomLoader";
import AssetCard from "../../../components/cards/watchlistCard2022";

import {getWatchlist, removeFromWatchlist} from "../../Watchlist/store/actions";

import {ListContainer} from "./component-styles";

import PropTypes from 'prop-types';

//-DISPATCH PROPS
const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            getWatchlist,
            removeFromWatchlist,
        },
        dispatch
    )
};

//-MAP PROPS
const mapStateToProps = appState => ({
    user: appState.User.data,
    watchlist: appState.Watchlist.data
});


const WatchlistScreen = ({user, watchlist, getWatchlist, removeFromWatchlist}) => {
    const [isStyledHistory, setIsStyledHistory] = React.useState([]);
    let location = useLocation()
    const pageTitle = "My Watchlist"

    React.useEffect(() => {
        if (JSON.stringify(user) !== '{}' && location) {
            HandleRouteChange(location, user, 'page-view', pageTitle)
        }
    }, [user, location])

    React.useEffect(() => {
        !watchlist && getWatchlist();
        watchlist && setIsStyledHistory(styleList())
    }, [location, watchlist])


    const styleList = () => {
        let styledArray = [];

        if (watchlist.assets.length === 0) {
            let noHistory = <div className="no-history">Nothing to see here</div>
            styledArray.push(noHistory);
        }

        watchlist.assets.forEach((e, i) => {
            let link;
            let showPlay = false;
            switch (e.asset_type) {
                case "video":
                    link = '/watch';
                    showPlay = true;
                    break;
                case "resource":
                    link = '/read';
                    break;
                case "course":
                    link = "/course";
                    break;
                case "challenge":
                    link = "/challenge";
                    break;
                default:
                    link = "/watch";
                    break;

            }
            styledArray.push(
                <AssetCard
                    link={`${link}/${e.uuid}`}
                    uuid={e.uuid}
                    name={e.title}
                    subtitle={e.coreCategory[0]}
                    source={pageTitle}
                    src={e.poster}
                    duration={e.duration}
                    key={Math.random(100)}
                    styleCards={styleList}
                    watchlist={watchlist}
                    position={i}
                    asset_type={e.asset_type}
                    removeVideo={removeFromWatchlist}
                />
            );
        });

        return styledArray
    }


    return (
        <ProfileLayout title={pageTitle} content="component-container">
            <ListContainer>
                <div className="back-link pointer">
                    <div className="back-button"/>
                </div>

                <h1 className="font-bold font-primary">{pageTitle}</h1>
                <div className="watchlist-container">

                    {
                        isStyledHistory.length === 0 ?
                            <div className="full-size_loader">
                                <div className="loader-container">
                                    <CustomLoader/>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                {
                                    isStyledHistory
                                }
                            </React.Fragment>
                    }
                </div>
            </ListContainer>
        </ProfileLayout>
    )
}

WatchlistScreen.propTypes = {
    user: PropTypes.object,
    watchlist: PropTypes.object,
    getWatchlist: PropTypes.func,
    removeVideoFromWatchlist: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistScreen)
