import * as TYPES from './actionTypes'

const initialState = {
    error: "",
    loading: true,
    info: null,
    allStats: null,
    allStatsLoading: true,
    scores: null,
    scoresLoading: true,
    recommended: null,
    recommendedLoading: true,
    categoryStatsLoading: true,
    categoryStats: null
}

const MyMarriage = (state = initialState, action) => {
    switch (action.type) {
        case TYPES.GET_INFO_MESSAGES:
            return {
                ...state,
            }

        case TYPES.SET_INFO_MESSAGES:
            return {
                ...state,
                info: action.payload
            }

        case TYPES.GET_OVERALL_STATS:
            return {
                ...state,
                allStatsLoading: true
            }

        case TYPES.SET_OVERALL_STATS:
            return {
                ...state,
                allStats: action.payload,
                allStatsLoading: false
            }

        case TYPES.GET_CATEGORY_STATS:
            return {
                ...state,
                categoryStatsLoading: true
            }

        case TYPES.SET_CATEGORY_STATS:
            return {
                ...state,
                categoryStats: action.payload,
                categoryStatsLoading: false
            }

        case TYPES.GET_PERFORMANCE_SCORES:
            return {
                ...state,
                scoresLoading: true
            }

        case TYPES.SET_PERFORMANCE_SCORES:
            return {
                ...state,
                scores: action.payload,
                scoresLoading: false
            }

        case TYPES.GET_MY_RECOMMENDED_ASSETS:
            return {
                ...state,
                recommended: null,
                recommendedLoading: true
            }

        case TYPES.SET_MY_RECOMMENDED_ASSETS:
            return {
                ...state,
                recommended: action.payload,
                recommendedLoading: false
            }
        case TYPES.POST_CHECKUP:
            return {
                ...state,
                allStatsLoading: true
            }

        default:
            return state;
    }
}

export default MyMarriage
