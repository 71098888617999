import React, {useState, useRef, useEffect} from "react";
import PropTypes from 'prop-types';
import "./accountOption.scss";

const SwitchOption = (props) => {
 
    // use props to determine switch. pass down current state and set state
    // title, subtitle, switch statue

    const switchOff = useRef();
    const switchOn = useRef();
    const switchContainer = useRef();1

    useEffect(() => {
        checkStatus(props);
    }, []);

    const checkStatus = () => {
        let switchOffElement = switchOff.current;
        let switchOnElement = switchOn.current;
        let switchContainerElement = switchContainer.current;

        if (props.switchState) {
            switchOnElement.classList.add("active-switch");
            switchOffElement.classList.remove("active-switch");
            switchContainerElement.style.backgroundColor = "#2D5F62";
        } else {
            switchOffElement.classList.add("active-switch");
            switchOnElement.classList.remove("active-switch");
            switchContainerElement.style.backgroundColor = "#E3DCD3";
        }
    }

    const toggleSwitch = () => {

        let switchOffElement = switchOff.current;
        let switchOnElement = switchOn.current;
        let switchContainerElement = switchContainer.current;

        if (props.switchState) {
            switchOffElement.classList.add("active-switch");
            switchOnElement.classList.remove("active-switch");
            switchContainerElement.style.backgroundColor = "#E3DCD3";
        } else {
            switchOnElement.classList.add("active-switch");
            switchOffElement.classList.remove("active-switch");
            switchContainerElement.style.backgroundColor = "#2D5F62";
        }

        let updateObj;

        if (props.isEmail) {
            if (props.switchState !== true) {
                updateObj = {
                    id: props.id,
                    email: 1,
                }
            } else {
                updateObj = {
                    id: props.id,
                    email: 0,
                }
            }

        } else {
            if (props.switchState !== true) {
                updateObj = {
                    id: props.id,
                    push: 1,
                }
            } else {
                updateObj = {
                    id: props.id,
                    push: 0,
                }
            }

        }
        let preferences = {
            "preferences": [
                updateObj
            ]
        }

        props.updateNotificationSettings(preferences);
        props.setSwitchState(!props.switchState);
    }
    // gets a title, subtitle if it exists, and right chevron
    // check status and set the background color of settings switch container

    return (
        <div className="switch-option_container">
            <div className="option-content">
                <div className="option-title">
                    {props.title}
                </div>
                {props.subtitle && (
                    <div className="option-subtitle">
                        {props.subtitle}
                    </div>
                )}
                <div className="settings-switch_container" onClick={toggleSwitch} ref={switchContainer}>
                    <div className="switch-off pointer" ref={switchOff}/>
                    <div className="switch-on pointer" ref={switchOn}/>
                </div>
            </div>

        </div>
    )
}

SwitchOption.propTypes = {
    id: PropTypes.number,
    isEmail: PropTypes.bool,
    title: PropTypes.string.isRequired,
    setSwitchState: PropTypes.func,
    subtitle: PropTypes.string,
    switchState: PropTypes.bool,
    updateNotificationSettings: PropTypes.func.isRequired,
}

export default SwitchOption;
