import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import MobileLogin from '../../../components/Login/mobile-login';
import GeneralButton from '../../../components/buttons/generalButton';
import MobileLoginVideo from '../../../components/Login/mobile-login-video';
import DesktopLogin from '../../../components/Login/desktop-login';
import LoginCarousel from '../../../components/Login/LoginCarousel/login-carousel';
import {LoginPage} from "./styles";

const Login = () => {
    const [isMobileLoginShow, setIsMobileLoginShown] = useState(false);

    const showMobileLogin = () => {
        setIsMobileLoginShown(true);
    }

    return (
        <LoginPage className="login-page">
            {
                !isMobileLoginShow && (
                    <div className="login-carousel_mobile">
                        <LoginCarousel/>
                        <div className="button-container flex-column flex-md-row">
                            <GeneralButton
                                buttonText="Login"
                                textColor="#fff"
                                backgroundColor="#2D5F62"
                                onClick={showMobileLogin}
                            />
                            <Link to={'/password/reset'} className="login-forgot_password">Forgot Password?</Link>
                            <div className="mobile-partner-link">
                                Need to Connect Partner? <a href={'/register-partner'}>Click Here</a>
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="login-desktop_view">
                <DesktopLogin/>
            </div>
            {
                isMobileLoginShow && (
                    <div className="login-mobile_view">
                        <MobileLogin
                            backButton={() => {
                                setIsMobileLoginShown(false);
                            }}
                        />
                    </div>
                )
            }
        </LoginPage>
    )
}

export default Login;
