export const PARAGRAPH_STYLE = {
    lineHeight: 1.25, fontSize: "16px"
}


export const BUTTON_TEXT = {
    fill: "#eee",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-45%, -50%)",
    zIndex: 3,
    svg: {
        opacity: 0, marginLeft: "4px", width: "20px",
    }
}

export const BUTTON_CONTAINER = {
    backgroundColor: "",
    borderColor: "",
    width: "",
    height: "40px",
    position: "relative",
    fontWeight: 300,
    whiteSpace: "nowrap",
    overflow: "hidden",
    zIndex: 1,
    "&:hover": {
        "cursor": "pointer"
    }
}

export const BRAINTREE_FORM_CONTAINER = {
    "label": {
        display: "block",
        fontSize: "13px",
        lineHeight: "100%",
        letterSpacing: "0.02em",
        textTransform: "uppercase",
        marginBottom: "10px",
        color: "#000",
        fontFamily: "Sofia Pro",
    },
}

export const BRAINTREE_FORM_NAME_CONTAINER = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    gap: "4px",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "32px 0 16px",

}

export const BRAINTREE_FORM_NAME_ELEMENT = {
    minWidth: 0,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "> input": {
        height: "50px",
        border: "1px solid #ced4da",
        paddingLeft: "8px",
        borderRadius: "2px",
        "&:focus": {
            boxShadow: "none",
            borderColor: "#0aaa96",
            borderWidth: "1px"
        }

    }
}

export const BRAINTREE_FORM_BUTTON = {
    width: "100%",
    height: "52px",
    background: "#2D5F62",
    border: "0.5px solid rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 3px 12px rgba(57, 57, 57, 0.08), 0px 1px 2px rgba(57, 57, 57, 0.12)",
    borderRadius: "50px",
    position: "relative",
    marginTop: "29px",
}

export const ALERT = {
    position: "relative",
    padding: "0.75rem 1.25rem",
    marginBottom: "1rem",
    border: "1px solid transparent",
    borderRadius: "0.25rem",
    fontFamily: "Sofia Pro",
    "&.alert-danger": {
        color: "#721c24",
        backgroundColor: "#f8d7da",
        borderColor: "#f5c6cb",
    },
    "&.alert-success": {
        color: "#2D5F62",
        backgroundColor: "#BBD2D3",
        borderColor: "#a9c5c6",
    }
}

export const INPUT_GROUP = {
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "stretch",
    height: "50px",
    "&.append": {
        display: "flex",
        "> button": {
            height: "50px",
            position: "relative",
            zIndex: 2,
            "&:focus": {
                zIndex: 3
            }
        }
    },
    "> input": {
        border: "1px solid #ced4da",
        borderRight: "none",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        display: "block",
        padding: ".375rem 0 .375rem",
        paddingLeft: "8px",
        flex:1,
        "&:focus": {
            boxShadow: "none",
            borderColor: "#0aaa96",
            borderWidth: "1px",
            borderRight: "none",
        }
    }
}

export const BTN = {
    display: "inline-block",
    fontFamily: "Sofia Pro",
    height: "50px",
    color: "#2D5F62",
    textAlign: "center",
    verticalAlign: "middle",
    "-webkit-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    "user-select": "none",
    backgroundColor: "transparent",
    border: "1px solid #54989c",
    padding: "0.375rem 0.75rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    borderRadius: "0.25rem",
    transition: "color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    "&:hover": {
        color: "#fff",
        backgroundColor: "#54989c",
        cursor: "pointer"
    }
}


export const CREATE_ACCOUNT_BTN = {
    color: "#FFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    "fontFamily": "Sofia Pro Bold",
    "fontSize": "20px",
    transform: "translate(-50%, -50%)",
}

export const TERMS_CONTAINER = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: "8px",

}

export const TERMS_SELECTION = {
    height: "16px",
    width: "16px",
    backgroundColor: "#C0C0C0",
    borderRadius: "1000px",
    "&.active": {
        backgroundImage: "url('/images/green-check.svg')",
        height: "16px",
        width: "16px",
        backgroundColor: "transparent",
        borderRadius: "1000px",
    }
}

export const TERMS_TEXT = {
    fontStyle: "italic",
    fontFamily: "Sofia Pro",
    fontSize: "14px",
    "> a": {
        color: "#00B097",
        textDecoration: "underline"
    }
}
