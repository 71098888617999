import React from 'react';
import PropTypes from 'prop-types';
import './pills.scss';

const CategoryPill = (props) => {

    let title;
    if(typeof(props.category) === 'string'  ){
        title = props.category;
    } else {
        title = props.category[0].title;
    }
    return(
        <div className={`pill-container light-pill ${props.position}`}>
            <div className="pill-text dark-text">{title}</div>
        </div>
    )
}

CategoryPill.propTypes = {
    category: PropTypes.node,
    position: PropTypes.string,
}

export default CategoryPill;
