/* eslint-disable import/extensions */
import React from 'react';
import './subscriptionSectionInfo.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// eslint-disable-next-line import/no-relative-packages
import { BraintreeDropin } from '../../../../../@splitty/Components/BraintreeDropin/braintree.dropin';
import SubscriptionCard from '../../../../../components/cards/subscriptionCard';
import AccountConfirmationModal from '../../../Modals/AccountConfirmation.modal';
import CancelMembershipModal from '../../../Modals/CancelAccount.modal';
import { PaymentInfoLine } from '../../../Components/PaymentInfoLine';
import ApplyCouponModal from '../../../Modals/ApplyCoupon.modal';
import { Track } from '../../../../../utils/Segment/segment-methods';
import {
    SUBSCRIPTION_SECTION_INFO,
    SUBSCRIPTION_COMPONENT_MAPPING,
} from '../../../../../global/constants';
import {
    USER_SUBSCRIPTION_INFO_PROPTYPES,
    SUBSCRIPTION_TYPE_PLANS_INFO_PROPTYPES,
} from '../../../../../global/propTypesConstants';

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {},
    dispatch,
);

const mapStateToProps = (appState) => ({
    user: appState.User.data,
    subscription: appState.User.data.subscription,
    subscriptionPlans: appState.AccountSettings.subscriptionPlans,
});

function SubscriptionSectionInfo({
    user,
    subscription,
    subscriptionPlans,
}) {
    // declare all modal visibility required for the page
    const [isSubscriptionModalShown, setIsSubscriptionModalShown] = React.useState(false);
    const [isCancelMembershipModalShown, setIsCancelMembershipModalShown] = React.useState(false);
    const [isCouponModalShown, setIsCouponModalShown] = React.useState(false);

    // represents the plan that was selected from the subscription card component
    const [selectedPlan, setSelectedPlan] = React.useState(null);
    const [swapPlan, setSwapPlan] = React.useState(null);

    const [braintreePaymentNonce, setBraintreePaymentNonce] = React.useState(null);

    const openCancelModal = () => {
        Track('Cancellation Modal Opened', {
            'Subscription Plan': subscription.plan_name,
            'Subscription Status': subscription.account_status,
        });
        setIsCancelMembershipModalShown(true);
    };

    function ConvertSubtitle(text) {
        return text.replace('[_BILLING_DATE]', subscription.next_billing_date)
            .replace('[_BILLING_AMOUNT]', subscription.next_billing_amount);
    }

    const subscriptionPlansWithComponent = subscriptionPlans?.items?.map((subscriptionItem) => ({
        ...subscriptionItem,
        component: SUBSCRIPTION_COMPONENT_MAPPING[subscriptionItem.component] ?? SubscriptionCard,
    }));

    const subscriptionInfo = {
        ...SUBSCRIPTION_SECTION_INFO,
        items: subscriptionPlansWithComponent,
    };

    if (JSON.stringify(user) === '{}') return null;

    return (
        user.is_primary ? (
            <div className="subscription-container">
                {
                    isCancelMembershipModalShown
                        && (
                            <CancelMembershipModal
                                planDetails={selectedPlan}
                                setIsCancelMembershipModalShown={setIsCancelMembershipModalShown}
                            />
                        )
                }
                {
                    isSubscriptionModalShown
                        && (
                            <AccountConfirmationModal
                                planDetails={selectedPlan}
                                swapPlan={swapPlan}
                                braintreePaymentNonce={braintreePaymentNonce}
                                setIsSubscriptionModalShown={setIsSubscriptionModalShown}
                            />
                        )
                }
                {
                    isCouponModalShown
                        && (
                            <ApplyCouponModal
                                plan={selectedPlan}
                                setIsCouponModalShown={setIsCouponModalShown}
                            />
                        )
                }
                <div className="subscription-page_card">
                    <h3 className="font-bold">{SUBSCRIPTION_SECTION_INFO.page.title}</h3>

                    {
                        subscription.account_status !== 'Canceled'
                            && <div className="subtitle">{ConvertSubtitle(subscriptionInfo.page.subTitle)}</div>
                    }

                    <div className="subscription-card_container">
                        {
                            subscriptionInfo.items.map((item, index) => (
                                React.createElement(item.component, {
                                    key: `plan_name--${item.name}`,
                                    plan: {
                                        name: item.name,
                                        duration: item.duration,
                                        price: item.price,
                                        bestValue: item.bestValue,
                                        subText: item.subText,
                                        cancellationOffer: item.cancellationOffer,
                                    },
                                    setIsSubscriptionModalShown,
                                    setSelectedPlan,
                                    setSwapPlan,
                                    setIsCouponModalShown,
                                })
                            ))
                        }
                    </div>

                    {
                        subscription.account_status !== 'Canceled' && (
                            // eslint-disable-next-line max-len
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                            <div
                                className="cancel-button_container"
                                onClick={openCancelModal}
                            >
                                <div
                                    className="cancel-button_text"
                                >
                                    {subscriptionInfo.page.buttons.cancel}
                                </div>
                            </div>
                        )
                    }

                    <div className="account-status mt-4">
                        <h3>Account Status</h3>
                        <PaymentInfoLine>
                            Account Status:
                            <span>{subscription.account_status}</span>
                        </PaymentInfoLine>
                        {
                            subscription.billing_period_end_date
                                && (
                                    <PaymentInfoLine>
                                        Membership Paid Through:
                                        <span>{subscription.billing_period_end_date}</span>
                                    </PaymentInfoLine>
                                )
                        }

                    </div>

                    <div className="payment-information">
                        <h3>Payment Information</h3>
                        <div className="subtitle">
                            {/* eslint-disable-next-line max-len */}
                            <BraintreeDropin user={user} setBraintreeNonce={setBraintreePaymentNonce} />
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <>
                <h3 className="font-bold">{SUBSCRIPTION_SECTION_INFO.page.title}</h3>
                <p className="text-center">
                    Subscriptions and Payments can only be managed by the primary
                    account
                    holder.
                </p>
            </>
        )
    );
}

SubscriptionSectionInfo.propTypes = {
    subscription: USER_SUBSCRIPTION_INFO_PROPTYPES.isRequired,
    subscriptionPlans: SUBSCRIPTION_TYPE_PLANS_INFO_PROPTYPES.isRequired,
    user: PropTypes.shape({
        is_primary: PropTypes.bool,
    }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionSectionInfo);
