import * as TYPES from "./actionTypes"


export const getIntroSliderCards = () => {
    return {
        type: TYPES.GET_INTRO_SLIDER_CARDS,
    }
}

export const getIntroSliderCardsSuccess = (payload) => {
    return {
        type: TYPES.GET_INTRO_SLIDER_CARDS_SUCCESS,
        payload: payload
    }
}
